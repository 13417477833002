import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { getStorageService } from 'services';
import { AppSettings } from 'types';
import { LS_KEYS } from 'consts';

interface SettingsContext {
    settings: AppSettings | null;
}

const getSettings = () =>
    fetch('/settings/settings.json', {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((response) => response.json())
        .then((settings: AppSettings) => settings)
        .catch((e: Error) => {
            console.warn(`Cannot load app settings: ${e.message}`);
            return {} as AppSettings;
        });

const SettingsContext = createContext<SettingsContext>({ settings: null });

const SettingsProvider: React.FC<PropsWithChildren<unknown>> = ({
    children,
}) => {
    const storageService = getStorageService();
    const [settings, setSettings] = useState<AppSettings | null>(null);

    useEffect(() => {
        getSettings()
            .then((settings: AppSettings) => {
                storageService.setItem<AppSettings>(
                    LS_KEYS['appSettings'],
                    settings
                );
                setSettings(settings);
            })
            .catch((e: Error) => {
                console.warn(`Cannot load app settings: ${e.message}`);
            });
    }, []);

    const memoValue = useMemo(
        () => ({
            settings,
        }),
        [settings]
    );

    return (
        <SettingsContext.Provider value={memoValue}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => useContext(SettingsContext);

export default SettingsProvider;
