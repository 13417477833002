import { SocialLinks } from 'components';
import {
    mainFooterMenuBlock1EN,
    mainFooterMenuBlock1RU,
    mainFooterMenuBlock2EN,
    mainFooterMenuBlock2RU,
} from 'consts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Lang } from 'types';
import { langMapper } from 'utils';
import BottomBlockMenu from './BottomBlockMenu';
import FooterMenu from './FooterMenu';
import {
    Address,
    BottomBlock,
    Column,
    Copyright,
    FooterRoot,
    LogoWrapper,
    NativeLink,
    Wrapper,
} from './styled';

interface FooterProps {
    isCorporatePage: boolean;
}

export const Footer: React.FC<FooterProps> = ({
    isCorporatePage,
}): JSX.Element => {
    const { i18n, t } = useTranslation('translation');

    const curMenuBlock1 =
        langMapper[i18n.language] === Lang.Ru
            ? mainFooterMenuBlock1RU
            : mainFooterMenuBlock1EN;
    const curMenuBlock2 =
        langMapper[i18n.language] === Lang.Ru
            ? mainFooterMenuBlock2RU
            : mainFooterMenuBlock2EN;

    return (
        <FooterRoot
            isCorporatePage={isCorporatePage}
            data-at-selector={'pageFooter'}>
            <Wrapper>
                <Column>
                    <LogoWrapper href={'https://www.kaspersky.com/'} />
                    <Address>{t('address')}</Address>
                    <NativeLink href={'mailto:academy@kaspersky.com'}>
                        academy@kaspersky.com
                    </NativeLink>
                </Column>
                <Column>
                    <FooterMenu items={curMenuBlock1} />
                    <FooterMenu items={curMenuBlock2} />
                </Column>
                <Column>
                    <SocialLinks type={'FOOTER'} />
                </Column>
            </Wrapper>
            <BottomBlock>
                <Wrapper>
                    <Copyright>{`© ${t('copyright')}`}</Copyright>
                    <BottomBlockMenu />
                </Wrapper>
            </BottomBlock>
        </FooterRoot>
    );
};

export default Footer;
