import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AppSettings, IApiService, IStorageService } from 'types';
import { LS_KEYS } from 'consts';

export class ApiService implements IApiService {
    private readonly axios: AxiosInstance;

    constructor(private storageService: IStorageService) {
        const { apiAddress } =
            storageService.getItem<AppSettings>(LS_KEYS['appSettings']) || {};
        this.axios = axios.create({
            baseURL: apiAddress,
        });
    }

    public async request<T>(
        requestConfig: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        const response: AxiosResponse = await this.axios.request<T>(
            requestConfig
        );
        return response;
    }
}
