import { PageSection } from 'components';
import { INTRO_SLIDER_ITEMS } from 'consts';
import { ArrowContainer } from 'containers/pages/for-businesses/components/courses/styled';
import {
    DotsContainer,
    DotsList,
} from 'containers/pages/for-businesses/components/reviews/styled';
import { SpeakersButton } from 'containers/pages/for-businesses/components/speakers/styled';
import {
    ProgramHelpContainer,
    ProgramHelpItem,
    ProgramHelpItemDescription,
    ProgramHelpItemImage,
    ProgramHelpItemTitle,
    ProgramItemContent,
} from 'containers/pages/profession-item/components/program-help/styled';
import { Icon } from 'kl-b2c-ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import {
    Slide,
    SliderStyler,
    SliderSubtitle,
    SliderTitle,
    SliderWrapper,
    TeachTitle,
    Wrapper,
} from './styled';

const TEACH_EXAMPLES_COUNTER = 3;

const sectionConfig = {
    CWrap: {
        css: {
            paddingTop: '170px',
            paddingBottom: '280px',

            '@media(max-width: 1023px)': {
                paddingTop: '60px !important',
                paddingBottom: '120px',
            },
        },
    },
    ContentArea: {
        css: {
            display: 'block',
        },
    },
};

const Intro: React.FC = (): JSX.Element => {
    const { t } = useTranslation('pages/home');
    const navigate = useNavigate();

    const CAROUSEL_SETTINGS: Settings = {
        slidesToScroll: 1,
        slidesToShow: 1,
        arrows: true,
        infinite: true,
        dots: false,
        nextArrow: (
            <ArrowContainer isPrev secondary>
                <Icon name={'ArrowRightThin'} color={'white'} size={24} />
            </ArrowContainer>
        ),
        prevArrow: (
            <ArrowContainer secondary>
                <Icon name={'ArrowLeftThin'} color={'white'} size={24} />
            </ArrowContainer>
        ),
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    dots: true,
                    arrows: false,
                },
            },
        ],
        appendDots: (dots) => (
            <DotsContainer>
                <DotsList
                    styles={{
                        '.slick-active': {
                            backgroundColor: '#fff',
                        },
                    }}>
                    {dots}
                </DotsList>
            </DotsContainer>
        ),
    };

    return (
        <Wrapper>
            <PageSection config={sectionConfig}>
                <SliderStyler>
                    <Slider {...CAROUSEL_SETTINGS}>
                        {INTRO_SLIDER_ITEMS.map((item) => (
                            <Slide key={item}>
                                <SliderWrapper>
                                    <SliderTitle>{t(item)}</SliderTitle>
                                    <SliderSubtitle>
                                        {t('intro-slide-subtitle')}
                                    </SliderSubtitle>
                                    <SpeakersButton
                                        onClick={() => navigate('/courses')}
                                        variant={'contained'}
                                        color={'primary-contrast'}
                                        size={'large'}>
                                        {t('choose-course')}
                                    </SpeakersButton>
                                </SliderWrapper>
                            </Slide>
                        ))}
                    </Slider>
                </SliderStyler>
                <TeachTitle>{t('how-to-teach')}</TeachTitle>
                <ProgramHelpContainer>
                    {Array.from(Array(TEACH_EXAMPLES_COUNTER).keys()).map(
                        (item: number) => (
                            <ProgramHelpItem
                                key={item}
                                color={'#131A23'}
                                styles={{
                                    '@media(max-width: 1023px)': {
                                        height: '350px',
                                    },
                                }}>
                                <ProgramHelpItemImage
                                    src={`/assets/images/bussiness/intro-${item}.png`}
                                />
                                <ProgramItemContent>
                                    <ProgramHelpItemTitle>
                                        {t(`intro-teach-title-${item}`)}
                                    </ProgramHelpItemTitle>
                                    <ProgramHelpItemDescription>
                                        {t(`intro-teach-subtitle-${item}`)}
                                    </ProgramHelpItemDescription>
                                </ProgramItemContent>
                            </ProgramHelpItem>
                        )
                    )}
                </ProgramHelpContainer>
            </PageSection>
        </Wrapper>
    );
};

export default Intro;
