import React, { useEffect, useState } from 'react';
import { useCourse } from 'contexts/course.context';
import { useTranslation } from 'react-i18next';
import {
    ResultItem,
    ResultItemDescription,
    ResultItemImage,
    ResultItemTitle,
    ResultWrapper,
    Wrapper,
} from './styled';
import DOMPurify from 'dompurify';
import { SectionTitle, SectionTitleWrapper } from '../../styled';
import { PageSection } from 'components';
import { CourseResult } from 'types';

const sectionConfig = {
    CWrap: {
        css: {},
    },
    ContentArea: {
        css: {
            display: 'block',
            position: 'relative',
            zIndex: '10',

            '@media(max-width: 1023px)': {
                paddingTop: '0 !important',
            },
        },
    },
};

export const Results = () => {
    const { t } = useTranslation('pages/courses');
    const { course } = useCourse();
    const [resultsChunks, setResultsChunks] = useState<CourseResult[][] | null>(
        null
    );

    const makeChunk = (arr: CourseResult[], size: number) =>
        Array.from(
            { length: Math.ceil(arr.length / size) },
            (_: CourseResult, i: number) => arr.slice(i * size, i * size + size)
        );

    useEffect(() => {
        if (course?.results && course.results.length > 0) {
            const chunks = makeChunk(course?.results, 6);
            setResultsChunks(chunks);
        }
    }, [course?.results]);

    if (!resultsChunks) {
        return null;
    }

    return (
        <PageSection config={sectionConfig}>
            <SectionTitleWrapper>
                <SectionTitle>{t('courseResults')}</SectionTitle>
            </SectionTitleWrapper>

            {resultsChunks.map((chunk: CourseResult[]) => (
                <Wrapper key={window.crypto.randomUUID()}>
                    {chunk.map((result, index) => (
                        <ResultWrapper
                            index={index}
                            count={course?.results.length || 0}
                            key={result.id}>
                            <ResultItem>
                                <ResultItemImage src={result.image?.fileLink} />
                                <ResultItemTitle>{result.name}</ResultItemTitle>
                                <ResultItemDescription
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            result.description || ''
                                        ),
                                    }}
                                />
                            </ResultItem>
                        </ResultWrapper>
                    ))}
                </Wrapper>
            ))}
        </PageSection>
    );
};
