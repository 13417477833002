import { SectionTitle } from 'containers/pages/courses-item/styled';
import styled, { css, CSSObject } from 'styled-components';
import {
    colorBlack,
    colorPrimary,
    colorWhite,
    mediaSm,
    mediaXs,
} from 'theme/theme-helper-selectors';

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 64px 0;
    align-items: center;

    @media (${mediaSm}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin: 0 0 40px 0;
    }
`;

export const Wrapper = styled.div<{ styles?: CSSObject }>`
    padding: 40px;
    background: #23272c;
    border-radius: 16px;
    background-image: url(/assets/images/bussiness/base.png);
    background-size: cover;
    background-repeat: no-repeat;

    @media (${mediaSm}) {
        padding: 24px;
    }

    ${({ styles }) => ({ ...styles })};
`;

export const SkewWrapper = styled.div`
    ${SectionTitle} {
        color: ${({ theme }) => theme.palette.black};
    }
`;

export const Switch = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 28px;
    background: #0f151c;
    height: 42px;

    @media (${mediaXs}) {
        grid-template-columns: 1fr;
        height: auto;
        width: 100%;
        border-radius: 0;

        & > div {
            border-radius: 0;
        }
    }
`;

export const SwitchItem = styled.div<{ isActive: boolean }>`
    padding: 12px 24px;
    color: ${colorWhite};
    font-size: 16px;
    line-height: 18px;
    border-radius: 28px;
    text-align: center;
    font-weight: 500;
    height: 42px;
    cursor: pointer;
    white-space: nowrap;

    ${({ isActive }) =>
        isActive &&
        css`
            background: ${colorPrimary};
            color: ${colorBlack};
        `};
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;

    @media (${mediaSm}) {
        grid-template-columns: 1fr;
    }
`;

export const ContentTitle = styled.h3<{ styles?: CSSObject }>`
    font-size: 32px;
    line-height: 37px;
    color: ${colorWhite};
    font-weight: 400;

    @media (${mediaSm}) {
        font-size: 25px;
        line-height: 27px;
    }

    ${({ styles }) => ({ ...styles })};
`;

export const List = styled.ul`
    padding: 0;
    margin: 0;
`;

export const ListItem = styled.li`
    padding: 14px 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    color: ${colorWhite};
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;

    @media (${mediaSm}) {
        font-size: 15px;
        line-height: 16px;
        padding: 7px 0;
    }
`;
