import React, { FC, useRef } from 'react';
import { PageSection } from 'components';
import { useTranslation } from 'react-i18next';
import { Container, Hexa, SpeakersButton } from '../speakers/styled';
import { Description, DescriptionWrapper, Title, Wrapper } from './styled';
import { Modal, ModalBody, ModalElement } from 'kl-b2c-ui-kit';
import { QuizModal } from 'containers/pages/courses-list/components';
import { CSSObject } from 'styled-components';

const sectionConfig = {
    CWrap: {
        css: {
            paddingTop: 200,
            background: '#3DE8CA',
            backgroundImage:
                'radial-gradient(ellipse farthest-corner at 92% 95%, #4DFF88, transparent 40%), radial-gradient(ellipse farthest-side at 10% 90%, #4DFF88, transparent 40%)',

            '@media(max-width: 1279px)': {
                backgroundImage: 'none',
            },

            '@media(max-width: 1023px)': {
                paddingTop: 100,
            },
        },
    },
    ContentArea: {
        css: {
            display: 'block',
            position: 'relative',
            zIndex: 2,
        },
    },
};

interface TestProps {
    resources?: {
        title: string;
        description: string;
        btnLabel: string;
    };
    action?: () => void;
    styles?: CSSObject;
}

export const Test: FC<TestProps> = ({ resources, action, styles }) => {
    const { t } = useTranslation('pages/for-businesses');
    const modalRef = useRef<ModalElement>(null);

    return (
        <>
            <Wrapper styles={styles}>
                <Hexa
                    secondary
                    image={
                        process.env.PUBLIC_URL +
                        '/assets/images/bussiness/hexa2.png'
                    }
                />
                <PageSection config={sectionConfig}>
                    <Container secondary>
                        <DescriptionWrapper>
                            <Title>
                                {resources?.title
                                    ? resources.title
                                    : t('test-title')}
                            </Title>
                            <Description>
                                {resources?.description
                                    ? resources.description
                                    : t('test-description')}
                            </Description>
                            <SpeakersButton
                                onClick={() =>
                                    action
                                        ? action()
                                        : modalRef?.current?.open()
                                }
                                variant={'contained'}
                                color={'primary-contrast'}
                                size={'large'}>
                                {resources?.btnLabel
                                    ? resources.btnLabel
                                    : t('test-btn')}
                            </SpeakersButton>
                        </DescriptionWrapper>
                    </Container>
                </PageSection>
            </Wrapper>
            <Modal
                ref={modalRef}
                size={'md'}
                showClose
                styles={{
                    borderRadius: '24px',
                    overflow: 'auto',
                }}>
                <ModalBody
                    styles={{
                        minHeight: 858,
                        '@media (max-width: 639px)': {
                            padding: '40px 16px 16px',
                        },
                    }}>
                    <QuizModal />
                </ModalBody>
            </Modal>
        </>
    );
};
