import { GetReviews, Review } from 'types';
import { ApiService } from 'services';
import { PaginationOutput } from 'kl-b2c-ui-kit';

export class ReviewsApi extends ApiService {
    public getReviews(params: GetReviews): Promise<PaginationOutput<Review>> {
        return this.request<Review>({
            method: 'GET',
            url: '/Review/GetReviews',
            params,
        }).then((response) => response.data);
    }
}
