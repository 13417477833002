import React, { FC, useEffect, useState } from 'react';
import {
    Constructor,
    Courses,
    Intro,
    Map,
    Reviews,
    Speakers,
    Test,
} from './components';
import { useMobileMenuContext } from 'contexts';
import { Blur, Hexa } from './styled';
import { useTranslation } from 'react-i18next';
import { getCourseApiService, getProfessionApiService } from 'services';
import i18n from 'config/i18n';
import { CourseForWhom } from 'types/enums';
import { CourseDetail, ProfessionDetail } from 'types/rest';
import { langMapper } from 'utils';
import { CourseDetailWithType, DetailType } from './components/courses/Courses';

const ForBusinesses: FC = () => {
    const { toggleCorporateStyles } = useMobileMenuContext();
    const { t } = useTranslation('pages/for-businesses');
    const [courses, setCourses] = useState<CourseDetailWithType[]>();

    const coursesApi = getCourseApiService();
    const professionsApi = getProfessionApiService();

    useEffect(() => {
        toggleCorporateStyles(true);

        const coursePromise = coursesApi.getList({
            page: 0,
            size: 20,
            search: '',
            domainType: langMapper[i18n.language],
            forWhom: [CourseForWhom.ForEmployers],
        });
        const professionsPromise = professionsApi.getList({
            page: 0,
            size: 20,
            search: '',
            domainType: langMapper[i18n.language],
            forWhom: [CourseForWhom.ForEmployers],
        });

        Promise.all([coursePromise, professionsPromise])
            .then(([courses, professions]) => {
                const coursesProcessed = courses.data.items.map((course) => ({
                    id: (course as CourseDetail).id,
                    name: course.name,
                    groupName: course.groupName,
                    description: (course as CourseDetail)
                        .announcementDescription,
                    type: DetailType.Course,
                }));

                const professionsProcessed = professions.data.items.map(
                    (profession) => ({
                        id: (profession as ProfessionDetail).id,
                        name: profession.name,
                        groupName: profession.groupName,
                        description: (profession as ProfessionDetail)
                            .announcementDescription,
                        type: DetailType.Profession,
                        link: profession.link,
                        previous: profession.previous,
                    })
                );

                setCourses([...coursesProcessed, ...professionsProcessed]);
            })
            .catch((e) => console.error(e));

        window.scrollTo(0, 0);

        return () => {
            toggleCorporateStyles(false);
        };
    }, []);

    return (
        <>
            <Intro />
            <Map />
            <Courses courses={courses} />
            <Constructor
                bgImagePath={
                    process.env.PUBLIC_URL +
                    '/assets/images/bussiness/construct-bg-green.png'
                }
                title={t('constructor-title')}
                bgStyles={{
                    backgroundSize: '60%',
                    backgroundPositionY: '90px',
                }}
            />
            <Speakers
                apiParams={{
                    page: 0,
                    size: 20,
                    isShowCorporatePage: true,
                    domainType: langMapper[i18n.language],
                }}
            />
            <Reviews params={{ isVisibleCorporateBusinessPage: true }} />
            <Test />
            <Hexa />
            <Blur />
            <Blur
                styles={{
                    top: '1810px',
                    '@media(max-width: 1279px)': {
                        top: '1620px',
                    },
                }}
            />
        </>
    );
};

export default ForBusinesses;
