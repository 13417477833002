import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
    colorBlack,
    colorPrimary,
    colorWhite,
    mediaSm,
    mediaXs,
} from 'theme/theme-helper-selectors';

export const Image = styled.div`
    height: 347px;
    margin-bottom: 18px;
    width: 100%;

    img {
        display: block;
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
        max-width: 100%;
        border-radius: 16px;
    }

    &.isFirst {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;

export const Title = styled.span<{ secondary?: boolean }>`
    flex-grow: 1;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
    max-width: 276px;
    color: ${colorBlack};

    display: block;
    position: relative;
    z-index: 5;

    &.isFirst {
        flex-grow: 0;
        color: ${colorWhite};
        font-size: 21px;
        line-height: 25px;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            color: ${colorWhite};
        `};

    @media (${mediaXs}) {
        font-size: 16px;

        &.isFirst {
            font-size: 21px;
        }
    }
`;

export const ArrowIcon = styled.span`
    display: inline-block;
    margin-left: 3px;
    transform: translateX(0px);
    transition: all 0.5s ease;
`;

export const Go2DetailedBtn = styled.span`
    align-items: center;
    color: ${colorPrimary};
    display: inline-flex;
    font-size: 18px;
    line-height: 24px;
    margin-top: 9px;
    position: relative;
    z-index: 5;

    &:hover ${ArrowIcon} {
        transform: translateX(8px);
    }
`;

export const Link2Item = styled(Link)`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    transition: all 0.5s ease;
    width: 100%;

    &.isFirst {
        color: ${colorWhite};
        justify-content: flex-end;
        min-height: 500px;
        padding: 40px;
    }
`;

export const CWrap = styled.div`
    transition: all 0.5s ease;

    &.isFirst {
        grid-column: 1 / 3;
    }

    @media (min-width: 1024px) {
        &:hover {
            transform: translate(-5px, -5px);
        }

        &:hover ${ArrowIcon} {
            transform: translateX(8px);
        }
    }

    @media (${mediaSm}) {
        &.isFirst {
            grid-column: 1 / -1;
            min-height: 420px;
        }
    }

    @media (${mediaXs}) {
        &.isFirst {
            min-height: 347px;
        }
    }
`;
