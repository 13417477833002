import styled from 'styled-components';
import { mediaSm } from '../../../theme/theme-helper-selectors';

export const BottomBlock = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #e8e8e8;

    @media (${mediaSm}) {
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
`;
