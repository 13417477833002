import React from 'react';
import { CWrap } from './styled';

export interface Props {
    id: string;
    name: string;
    isActive: boolean;
    onClick: (id: string) => void;
    type?: 'primary' | 'black';
    disabled?: boolean;
}

export const Tag: React.FC<Props> = ({
    id,
    name,
    isActive,
    onClick,
    type = 'black',
    disabled,
}): JSX.Element => (
    <CWrap
        disabled={disabled}
        type={type}
        className={isActive ? 'isSelected' : ''}
        onClick={() => onClick(id)}>{`${name} `}</CWrap>
);

export default React.memo(Tag);
