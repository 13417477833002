import React from 'react';

import { useTranslation } from 'react-i18next';
import { PageSection } from 'components';
import { SectionTitle, SectionTitleWrapper } from '../../styled';
import { ProgramHelpContainer } from 'containers/pages/profession-item/components/program-help/styled';
import { useCourse } from 'contexts';
import { ProfessionPlus } from 'types';
import {
    CourseHelpItem,
    CourseHelpItemDescription,
    CourseHelpItemImage,
    CourseHelpItemTitle,
} from './styled';
import DOMPurify from 'dompurify';

const sectionConfig = {
    CWrap: {
        css: {},
    },
    ContentArea: {
        css: {
            display: 'block',
            padding: '0 0 120px 0',

            '@media(max-width: 1023px)': {
                padding: '0 0 60px 0',
            },
        },
    },
};

export const CourseHelps = () => {
    const { t } = useTranslation('pages/courses');
    const { course } = useCourse();

    return (
        <PageSection config={sectionConfig}>
            <SectionTitleWrapper>
                <SectionTitle>
                    {course?.plusesDescription
                        ? course.plusesDescription
                        : t('course-helps')}
                </SectionTitle>
            </SectionTitleWrapper>
            <ProgramHelpContainer>
                {course?.pluses.map((plus: ProfessionPlus) => (
                    <CourseHelpItem key={plus.id}>
                        <CourseHelpItemImage src={plus.image?.fileLink} />
                        <CourseHelpItemTitle>{plus.name}</CourseHelpItemTitle>
                        <CourseHelpItemDescription
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    plus.description || ''
                                ),
                            }}
                        />
                    </CourseHelpItem>
                ))}
            </ProgramHelpContainer>
        </PageSection>
    );
};
