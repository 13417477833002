import styled, { CSSObject } from 'styled-components';
import { mediaXs } from 'theme/theme-helper-selectors';

export const DescriptionBlock = styled.div`
    max-width: 673px;
`;

export const InputsRow = styled.div<{ styles?: CSSObject }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 574px;
    gap: 16px;

    @media (${mediaXs}) {
        flex-direction: column;
        max-width: 100%;
    }

    ${({ styles }) => ({ ...styles })};
`;

export const InputCell = styled.div<{ styles?: CSSObject }>`
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (${mediaXs}) {
        width: 100%;
    }

    ${({ styles }) => ({ ...styles })};
`;

export const Hint = styled.p`
    color: #ff3355;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
`;

export const CheckboxLabel = styled.div`
    width: 100%;
`;
