import React, {
    Context,
    createContext,
    FC,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Theme } from 'types/enums/theme';
import { ThemeProvider } from 'kl-b2c-ui-kit';
import { themes } from '../theme';

interface ThemeState {
    theme: Theme;
}

const getInitTheme = (): Theme => {
    let theme = localStorage.getItem('theme') as Theme;

    if (!theme) {
        theme = Theme.Light;
    }

    return theme;
};

const initState: ThemeState = {
    theme: getInitTheme(),
};

const ThemeContext: Context<ThemeState> = createContext(initState);

export const ThemeStateProvider: FC<{ children: ReactNode }> = (props) => {
    const [theme] = useState(initState.theme);

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);

    return (
        <ThemeProvider theme={themes[theme]}>
            <ThemeContext.Provider
                value={{
                    theme,
                }}
                {...props}
            />
        </ThemeProvider>
    );
};

export const useThemeContext = () => useContext(ThemeContext);
