import { PageSection } from 'components';
import { Typography } from 'kl-b2c-ui-kit';
import React, { useEffect } from 'react';
import { Table, Thead, Th, Td, Ul, Li, ScrollableTable } from './styled';

const sectionConfig = {
    CWrap: {
        css: {
            padding: '40px 0',
            '@media(max-width: 767px)': {
                paddingTop: 0,

                p: {
                    wordBreak: 'break-word',
                },
            },
        },
    },
    ContentArea: {
        css: {
            flexDirection: 'column',
            gap: '30px',
        },
    },
};

export const Ru: React.FC = (): JSX.Element => {
    useEffect(() => {
        const { hash } = window.location;

        const elementToScroll = document.getElementById(hash?.replace('#', ''));

        if (!elementToScroll) return;

        window.scrollTo({
            top: elementToScroll.offsetTop - 64,
        });
    }, []);

    return (
        <PageSection config={sectionConfig}>
            <Typography variant={'h4'}>СВЕДЕНИЯ ОБ ОПЕРАТОРЕ ДАННЫХ</Typography>
            <Typography variant={'p2'} isInline>
                Настоящая Политика конфиденциальности применима к веб-сайту о
                карьере в АО &quot;Лаборатория Касперского&quot; (
                <a target={'_blank'} href={window.origin} rel={'noreferrer'}>
                    {window.origin}
                </a>
                ), расположенной по адресу: 125212, Российская Федерация,
                Москва, Ленинградское шоссе, д. 39А, стр. 2 (далее
                &quot;Лаборатория Касперского&quot;, «Компания» или
                &quot;мы&quot;).
            </Typography>
            <Typography variant={'p2'} isInline>
                Если у вас есть вопросы о Политике конфиденциальности веб-сайта
                или вы хотите обновить предоставленную нам информацию или
                предпочтения, обратитесь через форму о защите данных
                здесь:&nbsp;
                <a
                    target={'_blank'}
                    href={'https://support.kaspersky.ru/general/privacy'}
                    rel={'noreferrer'}>
                    https://support.kaspersky.ru/general/privacy
                </a>
            </Typography>
            <Typography variant={'h3'} fontWeight={'regular'}>
                ОБЩАЯ ИНФОРМАЦИЯ
            </Typography>
            <Typography variant={'p2'} isInline>
                Вы можете посещать большинство страниц этого веб-сайта и читать
                размещенную на нем информацию без предоставления каких-либо
                персональных данных.
            </Typography>
            <Typography variant={'p2'} isInline>
                Чтобы предоставлять запрашиваемую вами информацию с наших
                веб-сайтов, наши серверы сохраняют в анонимной форме некоторые
                данные в целях предоставления услуг и/или в статистических целях
                и целях обеспечения безопасности. В этом случае сохраняются
                общие сведения, например, доменное имя или тип вашего браузера.
                Эти данные не могут быть использованы нами для вашей
                идентификации.
            </Typography>
            <Typography variant={'p2'} isInline>
                Мы не обрабатываем «персональные данные особых категорий»
                (сведения о расовой и этнической принадлежности, религиозных или
                философских убеждениях, сексуальной жизни, сексуальной
                ориентации, политических предпочтениях, участии в
                профессиональных союзах, а также сведения о вашем состоянии
                здоровья, генетические и биометрические данные).
            </Typography>
            <Typography variant={'h3'} fontWeight={'regular'}>
                Согласие на обработку данных
            </Typography>
            <Typography variant={'p2'} isInline>
                Во исполнение Федерального закона от 27.07.2006 г. №152-ФЗ «О
                персональных данных» настоящим я даю свое согласие АО
                «Лаборатория Касперского» производить в соответствии с
                законодательством Российской Федерации и внутренними
                нормативными документами Компании обработку информации и
                сведений обо мне, включая сбор, систематизацию, накопление,
                хранение, уточнение (обновление, изменение), использование,
                обезличивание, блокирование и уничтожение персональных данных и
                иной информации, сообщенной мной или моим представителем
                Компании, а также осуществлять хранение такой информации в
                составе следующих персональных данных:
            </Typography>
            <ScrollableTable>
                <Table id={'personal-data-table'}>
                    <Thead>
                        <tr>
                            <Th>
                                Категории персональных данных<sup>1</sup>
                            </Th>
                            <Th>Цель обработки</Th>
                            <Th>Срок хранения</Th>
                            <Th>Согласие на обработку данных</Th>
                        </tr>
                    </Thead>
                    <tbody>
                        <tr>
                            <Td>
                                <Ul>
                                    <Li>Фамилия, имя</Li>
                                    <Li>Адрес электронной почты</Li>
                                    <Li>Номер телефона</Li>
                                    <Li>Страна </Li>
                                    <Li>Наименование организации </Li>
                                    <Li>
                                        Данные об образовании (ступень и курс
                                        обучения)
                                    </Li>
                                    <Li>Должность </Li>
                                </Ul>
                            </Td>
                            <Td>
                                Осуществление коммуникации в рамках прохождения
                                обучения или консультирование по курсу
                            </Td>
                            <Td>5 лет</Td>
                            <Td>
                                Настоящим я разрешаю Компании обрабатывать мои
                                персональные данные, а также передавать
                                персональные данные и поручать их обработку
                                третьим лицам с целью осуществление коммуникации
                                в рамках прохождения обучения или
                                консультирование по курсу АО «Лаборатория
                                Касперского».{' '}
                            </Td>
                        </tr>
                        <tr>
                            <Td>
                                <Ul>
                                    <Li>Фамилия, имя</Li>
                                    <Li>Адрес электронной почты</Li>
                                    <Li>Номер телефона</Li>
                                    <Li>Страна </Li>
                                </Ul>
                            </Td>
                            <Td>
                                Осуществление коммуникации с целью предложения
                                обучения на коммерческой и/или некоммерческой
                                основе, обсуждение партнерства
                            </Td>
                            <Td>5 лет</Td>
                            <Td>
                                Настоящим я разрешаю Компании обрабатывать мои
                                персональные данные, а также передавать
                                персональные данные и поручать их обработку
                                третьим лицам с целью осуществление коммуникации
                                предложения обучения АО «Лаборатория
                                Касперского» и обсуждение партнерства.
                            </Td>
                        </tr>
                        <tr>
                            <Td>
                                <Ul>
                                    <Li>Фамилия, имя</Li>
                                    <Li>Адрес электронной почты</Li>
                                    <Li>Номер телефона</Li>
                                    <Li>Ник в социальных сетях</Li>
                                    <Li>Город проживания</Li>
                                    <Li>Наименование организации </Li>
                                    <Li>
                                        Данные об образовании (ступень и курс
                                        обучения)
                                    </Li>
                                    <Li>Должность </Li>
                                </Ul>
                            </Td>
                            <Td>
                                Предоставление доступа к участию в событиях и
                                мероприятиях, проводимых Лабораторией
                                Касперского и к карьерным и новостным
                                предложениям от Лаборатории Касперского{' '}
                            </Td>
                            <Td>5 лет</Td>
                            <Td>
                                Настоящим я разрешаю Компании обрабатывать мои
                                персональные данные, а также передавать
                                персональные данные и поручать их обработку
                                третьим лицам с целью предоставления доступа к
                                участию в событиях и мероприятиях, проводимых АО
                                «Лаборатория Касперского».
                            </Td>
                        </tr>
                        <tr>
                            <Td>
                                <Ul>
                                    <Li>Фамилия, имя</Li>
                                    <Li>Адрес электронной почты</Li>
                                </Ul>
                            </Td>
                            <Td>
                                Осуществление маркетинговой деятельности
                                Лабораторией Касперского
                            </Td>
                            <Td>5 лет</Td>
                            <Td>
                                Настоящим я разрешаю Компании обрабатывать мои
                                персональные данные, а также передавать
                                персональные данные и поручать их обработку
                                третьим лицам с целью осуществления АО
                                «Лаборатория Касперского» маркетинговой
                                деятельности
                            </Td>
                        </tr>
                    </tbody>
                </Table>
            </ScrollableTable>

            <Typography variant={'p4'} isInline>
                <sup>1</sup> &nbsp; Набор обрабатываемых персональных данных
                может быть изменен в зависимости от каждого отдельного
                мероприятия, но не превышать набор персональных данных для
                каждой отдельной цели
            </Typography>
            <Typography variant={'p2'} isInline>
                Я подтверждаю, что я проинформирован(а) о порядке отзыва
                настоящего согласия путем подачи письменного заявления в АО
                «Лаборатория Касперского» в случаях, предусмотренных
                законодательством РФ.
            </Typography>
            <Typography variant={'p2'} isInline>
                Мы получаем ваши личные данные от вас напрямую.
            </Typography>
            <Typography variant={'h3'} fontWeight={'regular'}>
                ЮРИДИЧЕСКОЕ ОСНОВАНИЕ ДЛЯ ОБРАБОТКИ ВАШИХ ПЕРСОНАЛЬНЫХ ДАННЫХ
            </Typography>
            <Typography variant={'p2'} isInline>
                Наши законные основания для обработки ваших персональных данных:
            </Typography>
            <Ul>
                <Li>
                    <Typography variant={'p2'} isInline>
                        Ваше согласие на обработку персональных данных с целью
                        рассмотрения моей кандидатуры и включения в кадровый
                        резерв.
                    </Typography>
                </Li>
                <Li>
                    <Typography variant={'p2'} isInline>
                        Ваше согласие на обработку данных в целях получения
                        маркетинговой информации;
                    </Typography>
                </Li>
                <Li>
                    <Typography variant={'p2'} isInline>
                        Обработка, необходимая для выполнения требований
                        действующего законодательства.
                    </Typography>
                </Li>
            </Ul>
            <Typography variant={'h3'} fontWeight={'regular'}>
                ГДЕ МЫ ОБРАБАТЫВАЕМ ДАННЫЕ
            </Typography>
            <Typography variant={'p2'} isInline>
                Персональные данные, предоставляемые пользователями этого сайта,
                обрабатываются на территории России и иных государств,
                обеспечивающих адекватную защиту прав субъектов персональных
                данных.
            </Typography>
            <Typography variant={'h3'} fontWeight={'regular'}>
                ВРЕМЯ, В ТЕЧЕНИЕ КОТОРОГО МЫ ХРАНИМ ВАШИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ
            </Typography>
            <Typography variant={'p2'} isInline>
                Персональные данные хранятся в течение 5 лет, пока пользователем
                не будет отозвано согласие на обработку его персональных данных.
            </Typography>
            <Typography variant={'h3'} fontWeight={'regular'}>
                Использование cookies
            </Typography>
            <Typography variant={'p2'} isInline>
                Файлы cookie представляют собой файлы или фрагменты информации,
                которые могут быть сохранены на вашем компьютере или других
                Интернет-совместимых устройствах (например, смартфон или
                планшет), когда вы посещаете наш веб-сайт.
            </Typography>
            <Typography variant={'p2'} isInline>
                На этом сайте мы используем cookies в виде электронных меток
                (веб-маяки) для получения комплексной статистики в отношении
                эффективности наших рекламных кампаний и иных операций.
            </Typography>
            <Typography variant={'p2'} isInline>
                Вы можете настроить параметры браузера таким образом, чтобы
                файлы cookie блокировались или ваша система сообщала вам, когда
                веб-сайт хочет установить файлы cookie. Обратите внимание, что
                блокирование файлов cookie может привести к тому, что вы не
                сможете использовать все функции нашего веб-сайта.
            </Typography>
            <Typography variant={'p2'} isInline>
                Мы также используем различные типы &quot;модулей для социальных
                сетей&quot;. Ознакомьтесь{' '}
                <a
                    href={'https://www.kaspersky.ru/third-party-tracking'}
                    target={'_blank'}
                    rel={'noreferrer'}>
                    со списком cookie, встроенных в наши веб-сайты и таблицей,
                    разъясняющей, как мы используем модули социальных сетей.
                </a>
            </Typography>
            <Typography variant={'h3'} fontWeight={'regular'}>
                ВАШИ ПРАВА И ВАШИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ И СПОСОБЫ ИХ РЕАЛИЗАЦИИ
            </Typography>
            <Ul>
                <Li>
                    <Typography variant={'p2'} isInline>
                        Ваши права в отношении персональных данных предусмотрены
                        действующим законодательством.
                    </Typography>
                </Li>
            </Ul>
            <Typography variant={'p2'} isInline>
                Если вы хотите воспользоваться этими правами, вы можете в любое
                время связаться с нами через форму по адресу&nbsp;
                <a
                    href={'https://support.kaspersky.ru/general/privacy'}
                    target={'_blank'}
                    rel={'noreferrer'}>
                    https://support.kaspersky.ru/general/privacy
                </a>
            </Typography>
            <Typography variant={'h3'} fontWeight={'regular'}>
                ЖАЛОБЫ
            </Typography>
            <Typography variant={'p2'} isInline>
                Если вы хотите подать жалобу в отношении данной Политики
                конфиденциальности или того, как мы используем ваши Персональные
                данные, свяжитесь с нами, перейдя по{' '}
                <a
                    href={'https://support.kaspersky.ru/general/privacy'}
                    target={'_blank'}
                    rel={'noreferrer'}>
                    этой ссылке
                </a>
                .
            </Typography>
        </PageSection>
    );
};
