import React, {
    Context,
    createContext,
    PropsWithChildren,
    useContext,
    useMemo,
    useState,
} from 'react';
import { getConfirmationApi } from 'services/api.factory';

interface ConfirmationContext {
    loading: boolean;
    success: boolean;
    confirm: (agreementId: string) => Promise<void>;
}

const ConfirmationContext: Context<ConfirmationContext> =
    createContext<ConfirmationContext>({} as ConfirmationContext);

const ConfirmationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const api = getConfirmationApi();

    const confirm = (agreementId: string): Promise<void> => {
        setLoading(true);
        return api
            .confirmation(agreementId)
            .then(
                () => {
                    setSuccess(true);
                },
                (e: unknown) => {
                    console.error(e);
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const memoValue = useMemo(
        () => ({
            confirm,
            success,
            loading,
        }),
        [confirm, success, loading]
    );

    return (
        <ConfirmationContext.Provider value={memoValue}>
            {children}
        </ConfirmationContext.Provider>
    );
};

export const useConfirm = () => useContext(ConfirmationContext);
export default ConfirmationProvider;
