import {
    NEWS_DATE,
    ONLY_DATE_WITH_MONTH,
    ONLY_DAY,
    ONLY_MONTH_WITH_YEAR,
} from 'consts/formats';
import dayjs from 'dayjs';
import { i18n } from 'i18next';

export const getDates = (
    i18n: i18n,
    startDate?: string,
    finishDate?: string
): string => {
    const start = dayjs(startDate);
    const finish = dayjs(finishDate);

    if (!start && !finish) return '';
    if (!start) return finish.locale(i18n.language).format(NEWS_DATE);
    if (!finish) return start.locale(i18n.language).format(NEWS_DATE);

    if (start.toDate().getFullYear() !== finish.toDate().getFullYear())
        return `${start.locale(i18n.language).format(NEWS_DATE)} - ${finish
            .locale(i18n.language)
            .format(NEWS_DATE)}`;

    if (start.toDate().getMonth() !== finish.toDate().getMonth())
        return `${start
            .locale(i18n.language)
            .format(ONLY_DATE_WITH_MONTH)} - ${finish
            .locale(i18n.language)
            .format(NEWS_DATE)}`;

    return `${start.locale(i18n.language).format(ONLY_DAY)} - ${finish
        .locale(i18n.language)
        .format(NEWS_DATE)}`;
};

export const getDatesOnlyMonth = (i18n: i18n, startDate?: string): string => {
    const start = dayjs(startDate);

    if (!start) return '';

    return start.locale(i18n.language).format(ONLY_MONTH_WITH_YEAR);
};
