import React from 'react';
import {
    MobileWrap,
    Nav,
    NavBarCollapse,
    NavList,
    MobileSubmenuList,
    NavListItem,
} from './styled';
import { HeaderLink, HeaderHamburger, LanguageMenu } from '..';
import { LangBlock } from './styled/lang-block';
import { headerSubMenuItemsRu as subMenulinks } from 'consts';
import { MenuElement } from 'types';

export interface Props {
    links: MenuElement[];
    handleOnClick: () => void;
    closeMenu: () => void;
    isPressed: boolean;
    isMobileMenuOpened: boolean;
}

export const HeaderNav: React.FC<Props> = ({
    links,
    isPressed,
    handleOnClick,
    closeMenu,
    isMobileMenuOpened,
}) => (
    <Nav data-at-selector={'pageHeader'}>
        <NavBarCollapse collapse={!isMobileMenuOpened}>
            <MobileWrap>
                <NavList>
                    {links.map((item) => (
                        <NavListItem key={item.i18nKey}>
                            <HeaderLink {...item} onClick={closeMenu} />
                        </NavListItem>
                    ))}
                </NavList>
                <MobileSubmenuList>
                    {subMenulinks.map((item) => (
                        <NavListItem key={item.i18nKey}>
                            <HeaderLink {...item} />
                        </NavListItem>
                    ))}
                </MobileSubmenuList>
                <HeaderHamburger
                    handleOnClick={() => handleOnClick()}
                    isPressed={isPressed}
                    isMobile={false}
                />
                <LangBlock>
                    <NavListItem>
                        <LanguageMenu />
                    </NavListItem>
                </LangBlock>
            </MobileWrap>
        </NavBarCollapse>
    </Nav>
);

export default HeaderNav;
