import React from 'react';
import {
    PromoSection,
    InfoBlock,
    AboutCourses,
    TeamSection,
    Directions,
} from './components';
import { CooperationForm } from 'components';

export const About: React.FC = (): JSX.Element => (
    <>
        <PromoSection />
        <InfoBlock />
        <Directions />
        <AboutCourses />
        <TeamSection />
        <CooperationForm />
    </>
);

export default About;
