import { Button } from 'kl-b2c-ui-kit';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
    colorBlack,
    colorWhite,
    headerHeight,
    mediaSm,
    mediaXs,
    transitionBase,
} from 'theme/theme-helper-selectors';

export const Wrapper = styled.div`
    background-color: #4dff88;
    margin-top: -${headerHeight};
    position: relative;
    overflow: hidden;
    margin-bottom: 64px;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 802.49px;
        top: 58px;
        left: -64.36px;
        background: #33bbff;
        border-radius: 50%;
        filter: blur(476.989990234375px);
    }
`;

export const BackContainer = styled(Link)`
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 0 70px 0;
    position: relative;
    z-index: 5;

    svg {
        transition: ${transitionBase};
    }

    &:hover {
        svg {
            transform: translateX(-5px);
        }
    }
`;

export const BackText = styled.span`
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: ${colorBlack};
`;

export const MainContainer = styled.div`
    position: relative;
    z-index: 5;
    background: ${colorWhite};

    &:before {
        content: '';
        width: 100%;
        height: 200px;
        position: absolute;
        background: ${colorWhite};
        top: 0;
        left: 0;
        transform-origin: left bottom;
        transform: translateY(-30px) skewY(-3deg);
        border-radius: 40px 40px 0 0;
        z-index: 2;
    }
`;

export const TagsWrapper = styled.div`
    margin: 0 0 32px 0;
`;

export const ContentDivider = styled.div`
    display: flex;
    flex-direction: row;
    gap: 50px;
    width: 100%;

    @media (${mediaSm}) {
        flex-direction: column;
        flex-flow: column-reverse;
    }
`;

export const ContentWrapper = styled.div`
    width: 50%;

    @media (${mediaSm}) {
        width: 100%;
    }
`;

export const Title = styled.h1`
    font-size: 72px;
    line-height: 72px;
    font-weight: 400;
    margin: 0 0 24px 0;

    @media (${mediaSm}) {
        font-size: 52px;
        line-height: 52px;
    }

    @media (${mediaXs}) {
        font-size: 48px;
        line-height: 48px;
    }
`;

export const AnnounceDescription = styled.p`
    font-size: 24px;
    line-height: 27px;
    font-weight: 300;

    margin: 0 0 24px 0;

    @media (${mediaSm}) {
        font-size: 20px;
        line-height: 21px;
    }
`;

export const RegisterButton = styled(Button)<{ secondary?: boolean }>`
    border-radius: 8px;
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0;

    ${({ secondary }) =>
        secondary &&
        css`
            background: linear-gradient(
                216.53deg,
                #8dffb3 15.95%,
                #33bbff 83.66%
            );
            color: ${colorBlack};
        `};
`;

export const RegisterButtonWrapper = styled.div`
    margin: 0 0 60px 0;

    @media (${mediaSm}) {
        margin: 0 0 30px 0;
    }
`;

export const Description = styled.p`
    font-size: 21px;
    line-height: 24px;
    font-weight: 300;
    max-width: 800px;

    @media (${mediaSm}) {
        font-size: 19px;
        line-height: 21px;
    }
`;

export const AdditionalImage = styled.div<{ src?: string }>`
    height: 340px;
    width: 530px;
    border-radius: 16px;
    margin-left: auto;

    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (${mediaSm}) {
        width: 100%;
        margin: initial;
        height: 400px;
    }
`;
