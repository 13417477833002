import { RegisterOptions } from 'react-hook-form';
import { CooperationFormData } from 'types';

export type CooperationFormDataUI = Omit<
    CooperationFormData,
    | 'domainType'
    | 'formType'
    | 'courseId'
    | 'captchaCode'
    | 'utmTerm'
    | 'utmMedium'
    | 'utmSource'
    | 'agreement'
    | 'utmCampaign'
    | 'utmContent'
    | 'type'
>;

export const getCooperationFormRules = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof CooperationFormDataUI, RegisterOptions> => ({
    name: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 50,
            message: t('max-length', {
                ns: 'common/errors',
                length: 50,
            }),
        },
    },
    email: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 254,
            message: t('max-length', {
                ns: 'common/errors',
                length: 254,
            }),
        },
        pattern: {
            value: /\S+@\S+\.\S+/,
            message: t('email-format', {
                ns: 'common/errors',
            }),
        },
    },
    phone: {},
    country: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 50,
            message: t('max-length', {
                ns: 'common/errors',
                length: 50,
            }),
        },
    },
});
