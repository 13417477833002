import { createGlobalStyle } from 'styled-components';
import {
    colorText,
    fontFamilyMain,
    fontSize,
} from '../theme/theme-helper-selectors';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

export const GlobalStyle = createGlobalStyle`
  .grecaptcha-badge {
    visibility: hidden;
    opacity: 0;
  }
  body {
    height: 100%;
    width: auto;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    background-color: ${({ theme }) => theme.palette.background};
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;

    &.is-header-menu-open {
      height: 100dvh;
      width: 100vw;
      overflow: hidden;
      position: fixed;
    }
  }

  html,
  body {
    height: 100%;
    color: ${colorText};
    font-size: ${fontSize}px;
    font-weight: 400;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    font-family: ${fontFamilyMain};
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  p {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  a[role='button'],
  button {
    &:focus,
    &:active,
    &:hover {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
  
  input {
    &:focus,
    &:hover {
      outline: none;
    }
  }
`;
