import React from 'react';
import { CardConfig } from 'components/description-card/DescriptionCard';
import { ReactComponent as CityHall } from 'assets/icons/city-hall.svg';
import { ReactComponent as Briefcase } from 'assets/icons/briefcase.svg';
import { ReactComponent as Admin } from 'assets/icons/admin.svg';

export const GOVERNMENTS_DESCRIPTION_CARDS: CardConfig[] = [
    {
        title: 'intro-description-card-1',
        subtitle: 'intro-description-card-subtitle-1',
        icon: <CityHall />,
    },
    {
        title: 'intro-description-card-2',
        subtitle: 'intro-description-card-subtitle-2',
        icon: <Briefcase />,
    },
    {
        title: 'intro-description-card-3',
        subtitle: 'intro-description-card-subtitle-3',
        icon: <Admin />,
    },
];
