import styled, { CSSObject } from 'styled-components';
import {
    colorBlack,
    headerHeight,
    mediaSm,
} from 'theme/theme-helper-selectors';

export const Hexa = styled.div`
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 1600px;
    top: -${headerHeight};
    right: 0;
    background-image: url(/assets/images/bussiness/hexa-blue.png);
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Blur = styled.div<{ styles?: CSSObject }>`
    z-index: 4;
    position: absolute;
    width: 100%;
    height: 200px;
    top: 1060px;
    background: ${colorBlack};
    filter: blur(30.5px);

    ${({ styles }) => ({ ...styles })};

    @media (${mediaSm}) {
        display: none;
    }
`;
