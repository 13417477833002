import styled from 'styled-components';
import { colorBlack, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Body = styled.div`
    background-color: ${colorBlack};
    color: ${({ theme }) => theme.palette.white};
    padding-bottom: 83px;
    padding-top: 20px;
    @media (${mediaSm}) {
        padding-bottom: 50px;
    }
    @media (${mediaXs}) {
        padding-bottom: 60px;
    }
`;
