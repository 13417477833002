import React, { PropsWithChildren, FC, useState } from 'react';
import { headerMenuItemsRu, LS_KEYS } from 'consts';
import { Header, Footer, Main, CookieBanner } from 'components';
import { StickyHeaderStateProvider, useMobileMenuContext } from 'contexts';
import { StorageService } from 'services';
import { Lang } from 'types/index';
import { langMapper } from 'utils';
import i18n from 'config/i18n';

export const AppLayout: FC<PropsWithChildren> = ({ children }): JSX.Element => {
    const { isCorporatePage, isProfessionPage, isCoursePage } =
        useMobileMenuContext();

    const storageService = new StorageService();
    const [cookieBannerVisible, setCookieBannerVisible] = useState<boolean>(
        !storageService.getItem<boolean>(LS_KEYS['cookiesSuppressed']) &&
            langMapper[i18n.language] === Lang.Ru
    );

    return (
        <>
            <StickyHeaderStateProvider>
                <Header links={headerMenuItemsRu} />
            </StickyHeaderStateProvider>
            <Main
                isCorporatePage={isCorporatePage}
                isProfessionPage={isProfessionPage}
                isCoursePage={isCoursePage}>
                {children}
            </Main>
            <Footer
                isCorporatePage={
                    isCorporatePage || isProfessionPage || isCoursePage
                }
            />
            {cookieBannerVisible && (
                <CookieBanner
                    accept={() => {
                        storageService.setItem(
                            LS_KEYS['cookiesSuppressed'],
                            true
                        );
                        setCookieBannerVisible(false);
                    }}
                />
            )}
        </>
    );
};

export default AppLayout;
