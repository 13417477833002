import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CompareLink,
    ContactCard,
    ContactImage,
    ContactUsBlock,
    ContactUsLink,
    Content,
    ContentBody,
    EmailImage,
    EmilLink,
} from './styled';

export const Contacts = () => {
    const { t } = useTranslation('pages/academy-alliance');
    return (
        <Content>
            <ContentBody>
                <ContactCard>
                    <ContactImage />
                    <EmilLink
                        href={`/files/alliance/${t('allianceLink')}`}
                        dangerouslySetInnerHTML={{ __html: t('compareLink') }}
                        target={'_blank'}
                    />
                </ContactCard>
                <ContactCard>
                    <EmailImage />
                    <CompareLink
                        href={`mailto:${t('allianceMailLink')}`}
                        dangerouslySetInnerHTML={{ __html: t('emailLink') }}
                        target={'_blank'}
                    />
                </ContactCard>
            </ContentBody>
            <ContactUsBlock>
                <ContactUsLink
                    href={`mailto:${t('allianceMailLink')}`}
                    dangerouslySetInnerHTML={{ __html: t('contactUs') }}
                    target={'_blank'}
                />
            </ContactUsBlock>
        </Content>
    );
};
