import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BackgroundBlock,
    ContentBlock,
    Description,
    DescriptionBlock,
    Title,
} from './styled';
import { ButtonBlack } from '../header/styled';
import { CentralBlock } from '../styled';

interface IProps {
    openConstruct: () => void;
}

export const Footer: FC<IProps> = ({ openConstruct }) => {
    const { t } = useTranslation('pages/professions');

    return (
        <ContentBlock>
            <BackgroundBlock />
            <CentralBlock>
                <DescriptionBlock>
                    <Title>{t('where-is-course')}</Title>
                    <Description>{t('we-will-help')}</Description>
                    <ButtonBlack onClick={openConstruct}>
                        {t('button-text')}
                    </ButtonBlack>
                </DescriptionBlock>
            </CentralBlock>
        </ContentBlock>
    );
};
