import React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialLink, SocialLinksType } from 'types';
import { CWrap, Item } from './styled';
import { getSocialLinks } from './api';

export interface Props {
    type: keyof typeof SocialLinksType;
}

const SocialLinks: React.FC<Props> = ({ type }) => {
    const { i18n } = useTranslation();
    const icons: SocialLink[] = getSocialLinks(i18n.language, type);

    return (
        <CWrap type={type}>
            {icons.map(({ link, Icon }) => (
                <Item key={link} href={link}>
                    <Icon />
                </Item>
            ))}
        </CWrap>
    );
};

export default SocialLinks;
