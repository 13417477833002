import React, { useState, useEffect, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NewsCardPlate, PageSection, TagHeap } from 'components';
import { Header, MoreButton, Title } from './styled';
import { getNewsApiService } from 'services';
import { NewsListItem, NewsTag } from 'types';
import { Loader, Typography } from 'kl-b2c-ui-kit';
import { langMapper } from 'utils';
import { LoaderWrapper } from '../events-list/styled';
import { useLocation } from 'react-router-dom';
import { CSSObject } from 'styled-components';

export const BASE_NEWS_COUNT = 11;

const sectionConfig = {
    CWrap: {
        css: {
            minHeight: 'calc(100vh - 470px)',
        },
    },
    ContentArea: {
        css: {
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            backgroundImage: "url('/assets/images/news-decor.png')",
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingBottom: '80px',

            '@media (max-width: 767px)': {
                padding: '0 16px',
            },
        },
    },
};

interface NewsListProps {
    tags?: NewsTag[];
    nested?: boolean;
    params?: Record<string, boolean | string | number>;
    tagHeapHidden?: boolean;
    title?: ReactElement;
    exceptId?: string;
    redirectOnMore?: () => void;
    disabledTags?: boolean;
    secondary?: boolean;
    buttonStyles?: CSSObject;
    moreButtonHidden?: boolean;
}

export const NewsList: React.FC<NewsListProps> = ({
    tags = null,
    nested = false,
    params,
    tagHeapHidden,
    title,
    exceptId,
    disabledTags = false,
    redirectOnMore,
    secondary,
    buttonStyles,
    moreButtonHidden,
}) => {
    const [newsList, setNewsList] = useState<{
        count: number;
        items: NewsListItem[];
    } | null>(null);
    const [page2Fetch, setPage2Fetch] = useState(0);
    const { i18n, t } = useTranslation('pages/news');
    const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set());
    const [internalTags, setInternalTags] = useState<NewsTag[]>();
    const API = getNewsApiService();

    const langDomain = langMapper[i18n.language];
    const location = useLocation();

    const onFetchTags = () =>
        tags
            ? new Promise<NewsTag[]>((resolve) => resolve(tags))
            : API.getTags(langDomain);

    const onClickTag = (id: string) => {
        if (disabledTags) return;
        if (selectedTags.has(id)) {
            selectedTags.delete(id);
        } else {
            selectedTags.add(id);
        }

        setSelectedTags(selectedTags);
        setPage2Fetch(0);

        const reqParams = {
            DomainType: langDomain,
            Page: 0,
            Size: BASE_NEWS_COUNT,
            tagsId: Array.from(selectedTags),
        };

        API.getNewsList(reqParams).then((fetchedList) => {
            setNewsList(fetchedList);
        });
    };

    useEffect(() => {
        API.getNewsList({
            DomainType: langDomain,
            Page: page2Fetch,
            Size: BASE_NEWS_COUNT,
            TagsId: tags?.map((tag) => tag.id) || [],
            ExceptId: exceptId ? exceptId : '',
            ...params,
        }).then((fetchedList) => {
            setNewsList(fetchedList);
        });
    }, [exceptId]);

    useEffect(() => {
        onFetchTags().then((tags) => {
            setInternalTags(tags);
        });
    }, [location, tags]);

    if (newsList && newsList.count === 0) {
        return null;
    }

    return (
        <PageSection
            nested={nested}
            config={{
                ...sectionConfig,
                ContentArea: {
                    ...sectionConfig.ContentArea,
                    css: {
                        ...sectionConfig.ContentArea.css,
                        backgroundImage: nested
                            ? 'none'
                            : "url('/assets/images/news-decor.png')",
                    },
                },
            }}>
            {!newsList ? (
                <LoaderWrapper>
                    <Loader transparent variant={'static'} />
                </LoaderWrapper>
            ) : (
                <>
                    <Header className={nested ? 'nested' : ''}>
                        {title ? title : <Title>{t('newsListTitle')}</Title>}
                        {!tagHeapHidden && (
                            <TagHeap
                                disabled={disabledTags}
                                onClickTag={onClickTag}
                                tags={internalTags || []}
                                selectedTags={selectedTags}
                                type={nested ? 'primary' : 'black'}
                            />
                        )}
                    </Header>
                    {newsList?.count === 0 ? (
                        <Typography variant={'p1'}>{t('newsEmpty')}</Typography>
                    ) : (
                        <>
                            <NewsCardPlate
                                secondary={secondary}
                                items={newsList.items || []}
                            />
                            {(newsList.count > newsList.items.length ||
                                (redirectOnMore && newsList.count > 0)) &&
                                !moreButtonHidden && (
                                    <MoreButton
                                        type={'button'}
                                        styles={buttonStyles}
                                        onClick={() => {
                                            if (redirectOnMore) {
                                                return redirectOnMore();
                                            }
                                            const curPage = page2Fetch + 1;
                                            API.getNewsList({
                                                DomainType: langDomain,
                                                Page: curPage,
                                                Size: BASE_NEWS_COUNT,
                                                tagsId: Array.from(
                                                    selectedTags
                                                ),
                                                ...params,
                                            }).then((fetchedList) => {
                                                setPage2Fetch(curPage);
                                                setNewsList((prevState) => ({
                                                    count: fetchedList.count,
                                                    items: [
                                                        ...(prevState?.items ||
                                                            []),
                                                        ...fetchedList.items,
                                                    ],
                                                }));
                                            });
                                        }}>
                                        {t('moreItemsBtnCaption')}
                                    </MoreButton>
                                )}
                        </>
                    )}
                </>
            )}
        </PageSection>
    );
};

export default NewsList;
