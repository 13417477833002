import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mediaXs, mediaSm } from 'theme/theme-helper-selectors';

export const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;

    @media (${mediaXs}) {
        display: block;
    }
`;

export const SectionTitle = styled.h2`
    bottom: 268px;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    left: 0;
    line-height: 1;
    max-width: 487px;
    position: absolute;
    z-index: 2;

    @media (${mediaSm}) {
        bottom: auto;
        font-size: 38px;
        max-width: 380px;
        top: 200px;
    }

    @media (${mediaXs}) {
        font-size: 36px;
        left: 0;
        max-width: 100%;
        position: relative;
        top: 0;
    }
`;

const ADDITION_CONTAINER_WIDTH = 1600;

export const CarouselContainer = styled.div`
    position: relative;
    width: calc(100% + ${ADDITION_CONTAINER_WIDTH}px);
    margin: 0 -${ADDITION_CONTAINER_WIDTH / 2}px 0 -${ADDITION_CONTAINER_WIDTH /
        2}px;

    @media only screen and (max-width: 1280px) {
        width: 100%;
        margin: 0 auto;
    }

    @media (${mediaXs}) {
        .slick-list {
            overflow: visible;
        }
    }

    .slick-slide {
        &:not(.slick-active) {
            @media (${mediaXs}) {
                visibility: hidden;
            }
        }
    }
`;

export const Slide = styled.div`
    padding-bottom: 108px;
    padding-top: 360px;
    position: relative;

    &:focus {
        outline: 0 !important;
        outline: none !important;
    }

    @media (${mediaXs}) {
        padding-bottom: 58px;
        padding-top: 305px;
    }
`;

export const SlideImage = styled.img<{ isActive: boolean }>`
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: all 0.5s ease;
    bottom: 0;
    height: 665px;
    left: 50%;
    max-width: none;
    position: absolute;
    transform: translateX(-50%);
    width: auto;
    z-index: 0;

    @media (${mediaSm}) {
        height: 520px;
    }
`;

export const SlideLink = styled(Link)<{ isActive: boolean; gradient: string }>`
    align-items: center;
    border-radius: 135px;
    display: flex;
    height: 118px;
    justify-content: center;
    position: relative;
    text-align: center;
    z-index: 1;

    color: ${({ theme }) => theme.palette.white};
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 36px;
    margin: 0 auto;
    width: 588px;
    padding: 0 60px;

    &:hover {
        color: ${({ theme }) => theme.palette.white};
    }

    pointer-events: ${({ isActive }) => (isActive ? 'all' : 'none')};
    background: ${({ isActive, gradient }) =>
        isActive ? gradient : 'transparent'};

    .arrow {
        display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    }

    span {
        color: ${({ isActive, theme }) =>
            isActive ? theme.palette.white : '#c1c1c1'};
    }

    @media (${mediaSm}) {
        font-size: 24px;
        line-height: 28px;
        padding: 0 60px;
        width: 100%;
        max-height: 90px;
    }

    @media (${mediaXs}) {
        font-size: 20px;
        line-height: 23px;
        max-height: 80px;
    }
`;

export const ArrowNext = styled.div`
    background-color: ${({ theme }) => theme.palette.white};
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px 30px;
    border: 0;
    border-radius: 50%;
    height: 48px;
    position: absolute;
    width: 48px;
    z-index: 100;
    bottom: 50%;
    transform: translateY(50%);
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (${mediaXs}) {
        height: 35px;
        width: 35px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
`;

export const ArrowPrev = styled.div`
    background-color: ${({ theme }) => theme.palette.white};
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px 30px;
    border: 0;
    border-radius: 50%;
    height: 48px;
    position: absolute;
    width: 48px;
    z-index: 100;
    bottom: 50%;
    transform: translateY(50%);
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (${mediaXs}) {
        height: 35px;
        width: 35px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
`;
