export enum CourseType {
    ForCyberSecurity = 1,
    ForManagers = 2,
    ForSales = 3,
    ForStudents = 4,
    ForItSpecialists = 5,
}

export enum CourseSkill {
    Technical = 1,
    KnowledgeCyberSecurity = 2,
    CommunicatesManagers = 3,
}

export enum CourseForWhom {
    ForMySelf = 1,
    ForEmployers = 2,
    ForStudents = 3,
}

export enum CourseDirection {
    AdvancedCybersecurity = 1,
    ForItProfessionals = 2,
    ManagementAndCommunications = 3,
    SalesAndMarketing = 4,
    ForTopManagers = 5,
    ForStudentsAndInterns = 6,
    ForEmployeesAwareness = 7,
    FreeCourse = 8,
}

export enum ConditionCategory {
    Knowledge = 1,
    Skill = 2,
}
