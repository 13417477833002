import styled, { CSSObject } from 'styled-components';
import {
    colorBlack,
    colorGray,
    colorTeal,
    colorWhite,
    mediaLg,
    mediaMd,
    mediaSm,
    mediaXs,
    transitionBase,
} from 'theme/theme-helper-selectors';

export const Container = styled.div`
    position: relative;
    background: ${colorBlack};
    color: ${colorWhite};

    &:before {
        content: '';
        width: 100%;
        height: 190px;
        position: absolute;
        background: ${colorBlack};
        top: 0;
        transform-origin: left bottom;
        transform: translateY(-100%) skewY(3deg);
        border-radius: 40px 40px 0 0;
        z-index: 2;

        @media (${mediaSm}) {
            height: 100px;
        }
    }

    &:after {
        content: '';
        width: 100%;
        height: 190px;
        position: absolute;
        background: ${colorBlack};
        bottom: 0;
        transform-origin: left bottom;
        transform: translateY(30%) skewY(3deg);
        border-radius: 0 0 40px 40px;
        z-index: 2;

        @media (${mediaSm}) {
            height: 100px;
        }
    }
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 90px 0;

    @media (${mediaMd}) {
        flex-direction: column;
        gap: 40px;
    }

    @media (${mediaSm}) {
        flex-direction: column;
        gap: 20px;

        margin: 0 0 50px 0;
    }
`;

export const Title = styled.h2<{ styles?: CSSObject }>`
    font-weight: 400;
    font-size: 72px;
    line-height: 72px;
    margin-top: -10px;

    @media (${mediaMd}) {
        font-size: 45px;
        line-height: 45px;
    }

    @media (${mediaXs}) {
        font-size: 32px;
        line-height: 32px;
        margin-top: -5px;
    }

    ${({ styles }) => ({ ...styles })};
`;

export const Action = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    @media (${mediaMd}) {
        width: 100%;
    }
`;

export const ActionDescription = styled.p`
    font-size: 24px;
    line-height: 27px;
    font-weight: 200;

    @media (${mediaMd}) {
        font-size: 19px;
        line-height: 21px;
    }
`;

export const Logo = styled.img`
    width: 678px;

    @media (${mediaMd}) {
        width: 450px;
    }

    @media (${mediaXs}) {
        width: 300px;
    }
`;

export const TitleWrapper = styled.div`
    width: 70%;

    @media (${mediaMd}) {
        width: 100%;
    }
`;

export const SliderContainer = styled.div`
    margin: 0 -12px;

    .slick-arrow {
        @media (${mediaLg}) {
            display: none !important;
        }
    }

    .slick-slide {
        padding: 0 12px;
    }
`;

export const DotsContainer = styled.div<{ styles?: CSSObject }>`
    margin: 45px 0 0 0;
    position: static;

    ${({ styles }) => ({ ...styles })};
`;

export const DotsList = styled.ul<{ styles?: CSSObject }>`
    padding: 0;
    li {
        width: 10px;
        height: 10px;
        background: #333f4e;
        border-radius: 10px;
        transition: ${transitionBase};

        button {
            &:before {
                visibility: hidden;
            }
        }
    }
    .slick-active {
        width: 70px;
        background: ${colorTeal};
    }

    ${({ styles }) => ({ ...styles })};
`;

export const Slide = styled.div`
    background: #2a3039;
    color: ${colorWhite};
    border-radius: 20px;
    height: 660px;
    position: relative;
    padding: 40px 40px 290px 40px;

    @media (${mediaSm}) {
        padding: 20px 20px 190px 20px;
        height: 500px;
    }
`;

export const SlideTitle = styled.h3`
    font-size: 42px;
    line-height: 42px;
    font-weight: 300;
    margin: 0 0 8px 0;

    @media (${mediaSm}) {
        font-size: 32px;
        line-height: 32px;
    }
`;

export const SlideSubtitle = styled.p`
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 15px 0;

    @media (${mediaSm}) {
        font-size: 16px;
        line-height: 18px;
    }
`;

export const TextWrapper = styled.div`
    height: 219px;
    overflow: auto;

    ::-webkit-scrollbar {
        width: 9px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${colorGray};
        border-radius: 5px;
    }
`;

export const SlideDescription = styled.p`
    font-size: 16px;
    line-height: 18px;
    opacity: 0.6;
    font-weight: 200;
`;

export const SlideImage = styled.div<{ src?: string }>`
    position: absolute;
    width: 100%;
    height: 270px;
    bottom: 0;
    left: 0;
    background-color: #3a424f;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0 0 20px 20px;

    @media (${mediaSm}) {
        height: 200px;
    }

    @media (${mediaXs}) {
        height: 175px;
    }
`;
