import styled from 'styled-components';
import {
    colorBackground,
    headerHeight,
    mediaSm,
    transitionBase,
} from 'theme/theme-helper-selectors';

export const MobileWrap = styled.div`
    transition: ${transitionBase};
    position: relative;
    display: flex;

    @media (${mediaSm}) {
        flex-direction: column;
        background: ${colorBackground};
        overflow: scroll;

        height: calc(100dvh - ${headerHeight});
    }
`;
