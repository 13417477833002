import styled, { CSSObject, css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
    colorTeal,
    mediaSm,
    mediaMd,
    mediaXs,
} from 'theme/theme-helper-selectors';

export const Header = styled.div`
    grid-column-gap: 60px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr 550px;
    margin-bottom: 40px;
    padding-bottom: 40px;
    position: relative;
    z-index: 1;

    @media (${mediaSm}) {
        grid-row-gap: 30px;
        grid-template-columns: 1fr;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
`;

export const Main = styled.div`
    grid-column-gap: 60px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr 180px;
    margin-bottom: 40px;
    padding-bottom: 40px;
    position: relative;
    z-index: 1;

    @media (${mediaSm}) {
        grid-template-columns: 1fr;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
`;

export const Column = styled.div<{ isMain?: boolean }>`
    font-size: 24px;
    line-height: 29px;
    font-weight: 300;

    @media (${mediaMd}) {
        padding: 0;
    }

    @media (${mediaSm}) {
        font-size: 16px;
        line-height: 24px;
        padding: 0;
        max-width: calc(100vw - 60px);

        * img {
            max-width: 100% !important;
            height: auto !important;
        }

        blockquote {
            margin: 0;
        }
    }
    p:not(:first-child) {
        padding-top: 30px;
        @media (${mediaXs}) {
            padding-top: 20px;
        }
    }
`;

export const RightSide = styled.div<{ isTags?: boolean }>`
    display: flex;
    flex-direction: column;

    ${({ isTags }) =>
        isTags &&
        css`
            @media (${mediaSm}) {
                order: -1;
                align-items: flex-start;
            }
        `}
`;

export const TagsContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (${mediaSm}) {
        order: -1;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
    }
`;

export const Title = styled.h1`
    font-size: 50px;
    font-weight: 400;
    line-height: 60px;
    margin-bottom: 40px;
    min-width: 0;

    @media (${mediaSm}) {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 20px;
    }
`;

export const DateOfPublish = styled.span`
    color: #000;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;

    @media (${mediaSm}) {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const AdditionalImg = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;

    img {
        display: block;
        height: 409px;
        mask: url('/assets/images/news-mask.png') center / contain;
        mask-repeat: no-repeat;
        max-width: none;
        object-fit: cover;
        width: 405px;

        @media (${mediaSm}) {
            height: 320px;
            width: 320px;
        }
    }

    &:after {
        background-image: url('/assets/images/news-bg.png');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 620px;
        left: 47%;
        position: absolute;
        top: 44%;
        transform: translate(-50%, -50%);
        width: 640px;
        z-index: -1;

        @media (${mediaSm}) {
            height: 520px;
            width: 520px;
        }
    }
`;

export const HashTag = styled.span`
    font-feature-settings: 'liga' off;
    color: ${({ theme }) => theme.palette.teal};
    -moz-column-gap: 10px;
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 29px;

    @media (${mediaSm}) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }
`;

export const Back2ListBtn = styled(Link)`
    border: none;
    background: transparent;
    display: inline-flex;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 70px;
    color: ${colorTeal};
    align-items: center;
    gap: 10px;

    @media (${mediaSm}) {
        margin-bottom: 30px;
    }

    svg {
        transition: all 0.5s ease;
    }

    &:hover svg {
        transform: translateX(-8px);
    }
`;

export const CarouselContainer = styled.div`
    width: 560px;
    margin: 0 auto;
    padding: 40px 0 0 0;
    grid-column: 1/4;

    @media (${mediaSm}) {
        grid-column: 1;
        width: 300px;

        .slick-next,
        .slick-prev {
            display: none !important;
        }
    }

    .slick-list {
        border-radius: 24px;
    }

    .slick-prev,
    .slick-next {
        width: 64px;
        height: 64px;
    }

    .slick-next {
        right: -100px;
    }

    .slick-prev {
        left: -100px;
    }

    .slick-dots {
        ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
`;

export const CustomDot = styled.div<{
    isActive: boolean;
    styles?: { container: CSSObject; dot: CSSObject; dotActive: CSSObject };
}>`
    width: 40px;
    height: 4px;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
    background: ${({ theme }) => theme.palette.white};
    transition: opacity 0.2s ease-in-out;
    ${({ styles }) => ({ ...styles?.container, ...styles?.dot })};

    &.active {
        ${({ styles }) => ({ ...styles?.dotActive })};
    }
`;

export const DotsContainer = styled.div<{ styles?: CSSObject }>`
    bottom: 5%;
    ${({ styles }) => ({ ...styles })};
`;
