import dayjs from 'dayjs';
import { Event } from 'types';

export const getCalendarLink = (event: Event): string => {
    const startDate = dayjs(event.startDate)
        .toISOString()
        .replace(/[^\w\s]/gi, '');
    const finishDate = dayjs(event.finishDate)
        .toISOString()
        .replace(/[^\w\s]/gi, '');
    return event.location !== null
        ? encodeURI(
              `http://www.google.com/calendar/event?action=TEMPLATE&text=${event.name}&dates=${startDate}/${finishDate}&details=&location=${event.location}`
          )
        : encodeURI(
              `http://www.google.com/calendar/event?action=TEMPLATE&text=${event.name}&dates=${startDate}/${finishDate}&details=`
          );
};
