import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CWrap, ContentWrap, MessageWrap, BtnWrap } from './styled';
import PageSection from 'components/page-section';
import { PrimaryButton } from 'components/button';

interface Model {
    accept: () => void;
}

const sectionConfig = {
    CWrap: {
        css: {},
    },
    ContentArea: {
        css: {},
    },
};

const CookieBanner: FC<Model> = ({ accept }) => {
    const { t } = useTranslation('translation');

    return (
        <CWrap>
            <PageSection config={sectionConfig}>
                <ContentWrap>
                    <MessageWrap>
                        {t('cookie-message')}
                        <a
                            rel={'noreferrer'}
                            target={'_blank'}
                            href={
                                'https://www.kaspersky.ru/third-party-tracking'
                            }>
                            {t('cookie-more')}
                        </a>
                    </MessageWrap>
                    <BtnWrap>
                        <PrimaryButton onClick={accept}>
                            {t('accept-close')}
                        </PrimaryButton>
                    </BtnWrap>
                </ContentWrap>
            </PageSection>
        </CWrap>
    );
};

export default CookieBanner;
