import { Container } from 'components/description-card/styled';
import { Icon } from 'kl-b2c-ui-kit';
import { Link } from 'react-router-dom';
import styled, { css, CSSObject } from 'styled-components';
import {
    colorGray,
    colorTeal,
    colorWhite,
    mediaLg,
    mediaMd,
    mediaSm,
    transitionBase,
} from 'theme/theme-helper-selectors';

export const DescriptionContainer = styled.div`
    margin: 0 0 60px 0;
    max-width: 570px;
    color: ${colorWhite};
    position: relative;
    z-index: 6;

    @media (${mediaSm}) {
        margin: 0 0 40px 0;
    }
`;

export const DescriptionTitle = styled.h2`
    font-size: 54px;
    line-height: 54px;
    font-weight: 400;
    margin: 0 0 36px 0;

    @media (${mediaSm}) {
        font-size: 40px;
        line-height: 42px;
        margin: 0 0 25px 0;
    }
`;

export const DescriptionContent = styled.p`
    font-size: 24px;
    line-height: 27px;
    margin: 0 0 16px 0;
    font-weight: 200;
    max-width: 380px;

    @media (${mediaSm}) {
        font-size: 19px;
        line-height: 24px;
        margin: 0 0 10px 0;
    }
`;

export const ArrowIcon = styled(Icon)`
    fill: ${colorTeal};
    transition: ${transitionBase};
`;

export const DescriptionLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    cursor: pointer;

    &:hover {
        ${ArrowIcon} {
            transform: translateX(5px);
        }
    }
`;

export const DescriptionLink = styled(Link)`
    font-size: 21px;
    line-height: 24px;
    color: ${colorTeal};
    font-weight: 400;

    @media (${mediaSm}) {
        font-size: 17px;
        line-height: 19px;
    }
`;

export const Worker = styled.img`
    width: 850px;
    position: absolute;
    top: -250px;
    right: 0;
    z-index: 5;

    @media (${mediaSm}) {
        display: none;
    }
`;

export const SlideTitle = styled.h4`
    font-size: 32px;
    line-height: 36px;
    margin: 0 0 18px 0;
    font-weight: 400;
    transition: ${transitionBase};

    @media (${mediaSm}) {
        font-size: 24px;
        line-height: 28px;

        color: ${colorTeal};
    }
`;

export const SliderArrow = styled(Icon)`
    opacity: 0;
    transition: ${transitionBase};
    position: absolute;
    right: 50px;
    bottom: 50px;

    @media (${mediaMd}) {
        right: 20px;
        bottom: 20px;
        opacity: 1;
    }
`;

export const Slide = styled(Link)`
    padding: 38px;
    background: #2a3039;
    color: ${colorWhite};
    border-radius: 20px;
    min-height: 387px;
    position: relative;
    display: block;

    @media (${mediaMd}) {
        padding: 20px;
    }

    @media (${mediaSm}) {
        min-height: 300px;
    }

    &:hover {
        cursor: pointer;

        ${SlideTitle} {
            color: ${colorTeal};
        }

        ${SliderArrow} {
            opacity: 1 !important;
        }
    }
`;

export const SlideDescription = styled.p`
    font-size: 21px;
    line-height: 24px;
    font-weight: 200;
    color: ${colorWhite};
    height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0.8;

    @media (${mediaMd}) {
        height: 220px;
    }

    @media (${mediaSm}) {
        height: 150px;
    }

    ::-webkit-scrollbar {
        width: 9px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${colorGray};
        height: 30px;
        border-radius: 5px;
    }

    @media (${mediaSm}) {
        font-size: 18px;
        line-height: 21px;
    }
`;

export const SliderStyler = styled.div`
    position: relative;
    z-index: 6;

    .slick-track {
        margin-left: 0;
    }

    .slick-list {
        margin: 0 -10px;

        & .slick-slide > div {
            padding: 0 10px;
        }
    }

    ${Container} {
        @media (${mediaSm}) {
            min-height: 200px;
        }
    }
`;

export const ArrowContainer = styled.div<{
    isPrev?: boolean;
    secondary?: boolean;
}>`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: linear-gradient(216.53deg, #8dffb3 15.95%, #33bbff 83.66%);
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: ${({ isPrev }) =>
        isPrev ? 'translate(100%, -50%)' : 'translate(-100%, -50%)'};
    left: ${({ isPrev }) => (!isPrev ? '-16px' : 'auto')};
    right: ${({ isPrev }) => (isPrev ? '-16px' : 'auto')};
    z-index: 5;

    &:before {
        display: none;
    }

    &:hover {
        background: linear-gradient(216.53deg, #8dffb3 15.95%, #33bbff 83.66%);

        ${({ secondary }) =>
            secondary &&
            css`
                background: #131a23;
                opacity: 1;
            `};
    }

    @media (${mediaLg}) {
        display: none;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            background: #131a23;
            opacity: 0.6;
        `};
`;

export const Wrapper = styled.div<{ styles?: CSSObject }>`
    ${({ styles }) => ({ ...styles })};
`;
