import { Link } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';
import { colorBlack, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    background: rgba(244, 247, 250, 1);
    overflow: hidden;
    &:hover {
        background: rgba(255, 247, 229, 1);
    }
    @media (${mediaXs}) {
        flex-direction: column;
    }
`;

export const Description = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: column;
    flex: 1;
    order: 1;
    @media (${mediaXs}) {
        order: 2;
        padding: 16px 24px;
    }
`;

export const ImageBlock = styled.div<{ image: string }>`
    width: 45%;
    background-image: url(${({ image }) => image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    order: 2;
    @media (${mediaSm}) {
        height: auto;
    }
    @media (${mediaXs}) {
        order: 1;
        height: 179px;
        width: 100%;
    }
`;

export const TagBlock = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    flex-wrap: wrap;
`;

export const Tag = styled.span<{
    isGreen?: boolean;
    white?: boolean;
    styles?: CSSObject;
}>`
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    padding: 5px 12px;
    border-radius: 24px;
    color: ${({ theme }) => theme.palette.black};
    background: ${({ isGreen = false, white = false }) =>
        isGreen
            ? 'linear-gradient(45deg, #3DE8CA 38.74%, #33FF77 100%)'
            : white
            ? 'rgba(255, 239, 157, 1)'
            : 'rgba(190, 233, 255, 1)'};
    text-transform: uppercase;

    ${({ styles }) => ({ ...styles })};
`;

export const Title = styled(Link)<{ noPadding?: boolean }>`
    padding: ${({ noPadding }) => (noPadding ? '0 0 24px' : '24px 0')};
    font-size: 36px;
    font-weight: 500;
    line-height: 42.19px;
    color: ${colorBlack};
    @media (${mediaXs}) {
        font-size: 21px;
        line-height: 24px;
        padding: 16px 0px 8px 0px;
    }

    &:hover {
        color: ${colorBlack};
    }
`;

export const Announcement = styled.p`
    padding-bottom: 24px;
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    color: ${({ theme }) => theme.palette.black};
    @media (${mediaXs}) {
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 16px;
        color: rgba(42, 53, 65, 0.7);
    }
`;

export const DurationText = styled.p`
    color: rgba(106, 114, 122, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
`;
