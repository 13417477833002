import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.div`
    max-width: 1240px;
    margin: 0 auto;
    margin-bottom: 130px;
    padding: 0 20px;
    @media (${mediaSm}) {
        margin-bottom: 80px;
    }
    @media (${mediaSm}) {
        margin-bottom: 40px;
    }
`;

export const ContentTitle = styled.h3`
    margin-top: 0;
    font-size: 46px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 40px;
    @media (${mediaSm}) {
        font-size: 24px;
    }
    @media (${mediaXs}) {
        font-size: 18px;
    }
`;

export const ContentTitle2 = styled(ContentTitle)`
    margin-bottom: 40px;
    margin-top: 98px;
    @media (${mediaSm}) {
        font-size: 24px;
    }
    @media (${mediaXs}) {
        margin-bottom: 20px;
        margin-top: 40px;
    }
`;

export const ContentText = styled.p`
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    @media (${mediaSm}) {
        font-size: 24px;
    }
    @media (${mediaXs}) {
        font-size: 18px;
    }
`;
