import styled from 'styled-components';
import { mediaSm } from 'theme/theme-helper-selectors';

export const TabContent = styled.div<{
    backgroundColor?: string;
    textColor?: '';
    rootStyles?: string;
}>`
    background: ${({ backgroundColor }) =>
        backgroundColor ? backgroundColor : ''};
    color: ${({ textColor }) => (textColor ? textColor : 'black')};
    ${({ rootStyles }) => rootStyles}
`;
export const TabHeader = styled.div<{ headerStyles?: string }>`
    ${({ headerStyles }) => headerStyles}
`;
export const TabMenu = styled.ul<{ menuStyles?: string }>`
    align-items: center;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
    display: flex;
    list-style: none;
    margin: 0;
    padding: 30px 40px;
    column-gap: 100px;
    ${({ menuStyles }) => menuStyles}

    @media (${mediaSm}) {
        flex-direction: column;
        gap: 25px;
    }
`;
export const TabMenuItem = styled.li<{
    isActive?: boolean;
    menuItemStyles?: string;
}>`
    font-size: 36px;
    font-weight: 700;
    line-height: 43px;
    cursor: pointer;
    position: relative;

    @media (${mediaSm}) {
        font-size: 25px;
        line-height: 35px;
        width: 100%;
        text-align: center;

        &:after {
            bottom: -15px !important;
        }
    }

    ${({ isActive, theme }) =>
        isActive
            ? `
            &:after {
                background: ${theme.palette.white};
                bottom: -30px;
                content: "";
                height: 2px;
                left: -20px;
                position: absolute;
                right: -20px;
            }
        `
            : ''};
    ${({ menuItemStyles }) => menuItemStyles}
`;
export const TabBody = styled.div<{ bodyStyles?: string }>`
    padding: 30px 40px;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    ul {
        list-style: disc;
        line-height: 29px;
        grid-row-gap: 16px;
        display: grid;
    }
    ${({ bodyStyles }) => bodyStyles}
`;
