import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    padding: 0 34px;
`;

export const ContentBody = styled.div`
    margin-bottom: 140px;
    @media (${mediaSm}) {
        margin-bottom: 80px;
    }
    @media (${mediaXs}) {
        margin-bottom: 40px;
    }
`;

export const Title = styled.h2`
    font-size: 51px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 79px;
    text-align: center;
    @media (${mediaXs}) {
        font-size: 32px;
        margin-bottom: 20px;
    }
`;

export const InsideList = styled.ul`
    grid-row-gap: 33px;
    grid-column-gap: 22px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;
    @media (${mediaSm}) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (${mediaXs}) {
        grid-row-gap: 16px;
        grid-template-columns: 1fr;
    }
`;

const InsideItem = styled.li`
    font-size: 32px;
    align-items: center;
    border-radius: 31px;
    display: flex;
    flex-shrink: 0;
    font-style: normal;
    font-weight: 300;
    justify-content: center;
    line-height: 120%;
    min-height: 217px;
    padding: 25px 10px;
    text-align: center;
    @media (${mediaXs}) {
        border-radius: 20px;
        font-size: 24px;
        min-height: 160px;
    }
`;

export const InsideItem1 = styled(InsideItem)`
    background: linear-gradient(152deg, #46efa6, #3edbce);
`;
export const InsideItem2 = styled(InsideItem)`
    background: linear-gradient(157deg, #42e5bb, #46b7fe);
`;
export const InsideItem3 = styled(InsideItem)`
    background: linear-gradient(146deg, #4bb5fe, #61a8fc);
`;
export const InsideItem4 = styled(InsideItem)`
    background: linear-gradient(146deg, #42e6b9, #33c2ff);
`;
export const InsideItem5 = styled(InsideItem)`
    background: linear-gradient(164deg, #35c7f5, #6ea1fc);
`;
export const InsideItem6 = styled(InsideItem)`
    background: linear-gradient(165deg, #7a9bfb, #a285f9 100%, #a87dff 0);
`;
