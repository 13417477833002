import { Icon } from 'kl-b2c-ui-kit';
import React, { FC, PropsWithChildren } from 'react';
import { Close, Content, Title } from './styled';

interface RegistrationModalProps {
    title: string;
    close: () => void;
}

const RegistrationModal: FC<PropsWithChildren<RegistrationModalProps>> = ({
    title,
    close,
    children,
}) => (
    <Content>
        <Close onClick={close}>
            <Icon name={'CrossThin'} size={22} />
        </Close>
        <Title variant={'h3'}>{title}</Title>
        {children}
    </Content>
);

export default RegistrationModal;
