import styled from 'styled-components';
import { Button } from 'kl-b2c-ui-kit';
import { colorPrimary } from 'theme/theme-helper-selectors';

export const PrimaryButton = styled(Button)<{ width?: string }>`
    padding: 16px 21px;
    text-transform: unset;
    background: ${colorPrimary};
    box-shadow: 0 8px 32px rgba(52, 208, 184, 0.48);
    color: ${({ theme }) => theme.palette.white};
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0;
    width: ${({ width = 'fit-content' }) => width};
    border-radius: 8px;

    &:hover:not(:disabled) {
        background: ${({ theme }) => theme.palette.teal};
        box-shadow: 0 12px 40px rgba(21, 190, 164, 0.48);
    }
`;

export const SecondaryButton = styled(Button).attrs({
    variant: 'outlined',
})`
    padding: 12px 21px;
    font-weight: 400;
    text-transform: none;
    line-height: 1.25;
    color: ${({ theme }) => theme.palette.white};
    border: 2px solid ${({ theme }) => theme.palette.white};
    letter-spacing: 0;
    width: fit-content;
`;
