import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
    colorBlack,
    colorWhite,
    mediaSm,
    mediaTablet,
} from 'theme/theme-helper-selectors';
import { NavList, NavListItem } from './components/header-nav/styled';
import { HamburgerLine } from './components/header-hamburger/styled/line';

const hideHeaderMixin = css`
    transform: translateY(-250%);

    @media ${({ theme }) => theme.media.sm} {
        transform: translateY(0);
    }
`;

export const HeaderRoot = styled.header<{
    isHeaderHide: boolean;
    disabledStickyHeader?: boolean;
    isCorporate?: boolean;
    isProfession?: boolean;
    isHeaderDirty?: boolean;
}>`
    display: flex;
    justify-content: center;
    position: ${({ disabledStickyHeader }) =>
        disabledStickyHeader ? 'absolute' : 'fixed'};
    top: 0;
    z-index: 1000;
    width: 100%;
    background: ${({ theme, isCorporate, isProfession }) =>
        isCorporate || isProfession
            ? 'transparent'
            : theme.palette.headerBackground};
    box-shadow: ${({ isCorporate, isProfession }) =>
        isCorporate || isProfession
            ? 'none'
            : '-3.7375px 3.7375px 22.425px rgba(35, 209, 174, 0.3)'};
    transition: transform 0.5s ease;

    ${({ isHeaderHide }) => isHeaderHide && hideHeaderMixin};

    ${({ isProfession }) =>
        isProfession &&
        css`
            ${NavListItem} {
                a {
                    &:hover {
                        border-color: transparent;
                        color: ${colorBlack};
                    }
                }
            }
        `};

    ${({ isCorporate }) =>
        isCorporate &&
        css`
            ${NavListItem} {
                a {
                    @media (${mediaTablet}) {
                        color: ${colorWhite};

                        &:hover {
                            border-color: transparent;
                        }
                    }
                }
            }

            ${NavList} {
                svg {
                    fill: ${colorWhite};
                }
            }

            ${HamburgerLine} {
                background: ${colorWhite};
            }

            ${LogoWrapper} {
                background-image: url(/assets/images/logo-white.svg);
                margin-left: 25px;

                @media (${mediaTablet}) {
                    margin-left: 15px;
                }
            }
        `};

    ${({ isCorporate, isHeaderHide, isHeaderDirty }) =>
        isCorporate &&
        !isHeaderHide &&
        isHeaderDirty &&
        css`
            background: ${colorBlack};
        `};

    ${({ isProfession, isHeaderHide, theme, isHeaderDirty }) =>
        isProfession &&
        !isHeaderHide &&
        isHeaderDirty &&
        css`
            background: ${theme.palette.headerBackground};
        `};
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    max-width: 1240px;

    @media (${mediaSm}) {
        padding: 0;
        justify-content: space-between;
    }
`;

export const WrapperContainer = styled.div`
    width: 100%;
    display: flex;
`;

export const LogoWrapper = styled(Link)`
    flex-grow: 1;
    font-size: 28px;
    font-weight: 400;
    max-width: 350px;

    color: #000;
    background-image: url(/assets/images/logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 64px;
    margin-left: -20px;

    @media (${mediaSm}) {
        margin-left: 0px;
        font-size: 20px;
        order: 1;
        flex-grow: 1;
    }
`;
