import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputBlock, InputText } from './styled';

interface IProps {
    onChange: (val: string) => void;
    val: string;
}

export const TextBlock: FC<IProps> = ({ onChange, val }) => {
    const { t } = useTranslation('common/shared');
    return (
        <InputBlock>
            <span />
            <InputText
                type={'text'}
                placeholder={t('common/shared:course-search')}
                value={val}
                onChange={(e) => onChange(e.target.value)}
            />
        </InputBlock>
    );
};
