import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBlock, Content, ContentText } from './styled';
import { BackButton } from '../styled';

const TeacherWelcome = () => {
    const { t } = useTranslation('pages/quiz');

    return (
        <Content>
            <ContentText>{t('teacher-welcome')}</ContentText>
            <ButtonBlock>
                <BackButton as={'a'} href={'/academy-alliance/'}>
                    {t('next')}
                </BackButton>
            </ButtonBlock>
        </Content>
    );
};

export default TeacherWelcome;
