import { Typography } from 'kl-b2c-ui-kit';
import styled from 'styled-components';
import { colorBlack, mediaSm } from 'theme/theme-helper-selectors';

export const Table = styled.table`
    border-collapse: collapse;
`;

export const Th = styled.th`
    border: 1px solid ${colorBlack};
    padding: 15px;
    text-align: left;
`;

export const Thead = styled.thead``;

export const Td = styled.td`
    border: 1px solid ${colorBlack};
    padding: 15px;
`;

export const Ul = styled.ul`
    padding: 0;
    list-style: none;
`;

export const Li = styled.li`
    display: block;
    position: relative;
    padding: 0 0 10px 10px;

    &:before {
        content: '•';
        vertical-align: top;
        margin-right: 10px;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

export const ScrollableTable = styled.div`
    width: 100%;
    overflow-x: auto;
`;

export const PageHeader = styled(Typography)`
    @media (${mediaSm}) {
        font-size: 32px;
        line-height: 35px;

        word-break: break-word;
    }
`;
