import React, { createContext, useContext } from 'react';
import { apiDeclarations } from 'consts';
import { genAPI } from './api';
import { APIDictionary, EndPointName } from 'types';
import { useSettings } from 'contexts';

export interface Props {
    children: React.ReactNode;
}

export const RESTProviderContext: React.Context<APIDictionary> = createContext(
    {} as APIDictionary
);

export const RESTProvider: React.FC<Props> = ({ children }): JSX.Element => {
    const { settings } = useSettings();
    const apiMethodsMap = genAPI(apiDeclarations, settings?.apiAddress || '');

    return (
        <RESTProviderContext.Provider value={apiMethodsMap}>
            {children}
        </RESTProviderContext.Provider>
    );
};

export const useREST = (
    methods: (keyof typeof EndPointName)[]
): APIDictionary => {
    const context: APIDictionary = useContext(RESTProviderContext);
    if (context === undefined)
        throw new Error('useREST must be used within RESTProvider');

    const result: APIDictionary = {};
    methods.map((methodName) => {
        if (context[methodName]) result[methodName] = context[methodName];
    });
    return result;
};

export default RESTProvider;
