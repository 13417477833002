import styled, { css } from 'styled-components';
import { colorText, colorPrimary } from '../../../theme/theme-helper-selectors';
import { Link } from 'react-router-dom';

const linkStyles = css`
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    border: 1px solid transparent;
    transition: all 0.5s ease;
    text-decoration: none;
    padding: 4px 0px;
`;

export const FooterMenuList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin: 0;
    padding: 0;
`;

export const FooterMenuItem = styled.li`
    position: relative;
    display: flex;
`;
export const ArrowIconWrap = styled.div``;

export const ArrowIcon = styled.span`
    margin-left: 3px;
    margin-top: 2px;
    transition: padding 0.5s ease;
`;

export const FooterMenuLink = styled(Link)`
    ${linkStyles}
    color: ${colorText};
`;

export const FooterMenuExternalLink = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    ${linkStyles}
    color: ${colorPrimary};

    &:hover ${ArrowIcon} {
        padding-left: 4px;
    }
`;
