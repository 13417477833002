import styled from 'styled-components';
import { NavList } from './nav-list';
import { headerHeight, mediaMd, mediaSm } from 'theme/theme-helper-selectors';
import {
    DropdownContent,
    DropdownTitle,
} from '../../header-menu-dropdown/styled';
import { ButtonNavAction } from '../../header-link/styled';

export const LangBlock = styled(NavList)`
    ${DropdownContent} {
        right: 0;
        bottom: 0;
        transform: translateY(100%);
        min-width: auto;
        padding: 0 20px 0 10px;
        text-align: center;

        @media (${mediaMd}) {
            padding: 0;

            ${ButtonNavAction} {
                width: 100%;
            }
        }

        @media (${mediaSm}) {
            flex-direction: column;
            align-items: flex-start;
            transform: translateY(0);
        }
    }

    ${DropdownTitle} {
        min-height: ${headerHeight};
        width: 100%;

        span,
        &:after {
            display: none;
        }

        @media (${mediaSm}) {
            span,
            &:after {
                display: inline-block;
            }
        }
    }
`;
