import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BodyContent,
    Content,
    ContentBlock,
    InnerCheckBox,
    ListBlockCourses,
    ListBlockProfessions,
    MenuBlock,
    MenuList,
    MenuTitle,
    SearchBlock,
    TitleBlock,
    WhiteHeader,
    Title,
    DescriptionBlock,
    ButtonBlock,
    MoreButton,
    TextInput,
    ListEmpty,
    ChangeFilters,
    MenuItemBlock,
    TextInputMobileBlock,
    TextInputDesktopBlock,
    AllFilterMobileBlock,
    HideBlock,
    MenuListItem,
} from './styled';
import { CentralBlock } from '../styled';
import { useCourse } from 'contexts/course.context';
import { useProfessions } from 'contexts/profession.context';
import {
    ForWhomViewType,
    LanguageViewType,
    SkillViewType,
    StudyFormViewType,
    DirectionViewType,
} from '../../types';
import {
    CourseDirection,
    CourseForWhom,
    CourseSkill,
    EventType,
    LanguageType,
} from 'types/enums';
import { ProfessionItem } from '../profession-item';
import { CourseItem } from '../course-item';
import { TextBlock } from '../text-block';
import { ReservedView } from '../reserved-view';
import { Icon } from 'kl-b2c-ui-kit';
import { CourseDetail, ProfessionDetail } from 'types/rest';

interface IProps {
    studyFor: ForWhomViewType[];
    changeStudyFor: (ids: CourseForWhom[]) => void;
    studyForm: StudyFormViewType[];
    changeStudyForm: (ids: EventType[]) => void;
    direction: DirectionViewType[];
    changeDirection: (ids: CourseDirection[]) => void;
    skills: SkillViewType[];
    changeSkill: (ids: CourseSkill[]) => void;
    languages: LanguageViewType[];
    changeLanguages: (ids: LanguageType[]) => void;
    professionLoading: boolean;
    courseLoading: boolean;
    nextCoursePage: () => void;
    nextProfessionPage: () => void;
    searchString: string;
    onChangeSearchString: (val: string) => void;
    openRegister: (profession: ProfessionDetail) => void;
    openCourseRegister: (course: CourseDetail) => void;
}

export const BodyBlock: FC<IProps> = ({
    studyFor,
    changeStudyFor,
    studyForm,
    changeStudyForm,
    direction,
    changeDirection,
    skills,
    changeSkill,
    languages,
    changeLanguages,
    nextCoursePage,
    nextProfessionPage,
    searchString,
    onChangeSearchString,
    openRegister,
    openCourseRegister,
}) => {
    const { t } = useTranslation([
        'pages/professions',
        'common/shared',
        'pages/quiz',
    ]);
    const { courses, reservedCourses } = useCourse();
    const { professions } = useProfessions();
    const [showFilters, setShowFilters] = useState(false);

    const selectedFilters =
        studyForm.filter((r) => r.isSelected).length +
        studyFor.filter((r) => r.isSelected).length +
        direction.filter((r) => r.isSelected).length +
        skills.filter((r) => r.isSelected).length +
        languages.filter((r) => r.isSelected).length;

    return (
        <BodyContent>
            <WhiteHeader />
            <Content>
                <CentralBlock>
                    <ContentBlock>
                        <MenuBlock>
                            <MenuItemBlock>
                                <MenuTitle>
                                    {t('pages/professions:study-for')}
                                </MenuTitle>
                                <MenuList>
                                    {studyFor.map((r, index) => (
                                        <MenuListItem
                                            key={`study-for-${r.forWhom}`}>
                                            <InnerCheckBox
                                                value={r.isSelected}
                                                onChange={(val) => {
                                                    studyFor[index].isSelected =
                                                        val;
                                                    const request = studyFor
                                                        .filter(
                                                            (r) => r.isSelected
                                                        )
                                                        .map((r) => r.forWhom);
                                                    changeStudyFor(request);
                                                }}
                                                size={'medium'}>
                                                <span>
                                                    {t(
                                                        `common/shared:study-for-${r.forWhom}`
                                                    )}
                                                </span>
                                            </InnerCheckBox>
                                        </MenuListItem>
                                    ))}
                                </MenuList>
                            </MenuItemBlock>
                            <MenuItemBlock>
                                <MenuTitle>
                                    {t('pages/professions:study-format')}
                                </MenuTitle>
                                <MenuList>
                                    {studyForm.map((r, index) => (
                                        <MenuListItem
                                            key={`studyForm-${r.form}`}>
                                            <InnerCheckBox
                                                value={r.isSelected}
                                                onChange={(val) => {
                                                    studyForm[
                                                        index
                                                    ].isSelected = val;
                                                    const request = studyForm
                                                        .filter(
                                                            (r) => r.isSelected
                                                        )
                                                        .map((r) => r.form);
                                                    changeStudyForm(request);
                                                }}
                                                size={'medium'}>
                                                <span>
                                                    {t(
                                                        `common/shared:studyForm-${r.form}`
                                                    )}
                                                </span>
                                            </InnerCheckBox>
                                        </MenuListItem>
                                    ))}
                                </MenuList>
                            </MenuItemBlock>
                            <HideBlock hide={!showFilters}>
                                <>
                                    <MenuItemBlock>
                                        <MenuTitle>
                                            {t('pages/professions:for-whom')}
                                        </MenuTitle>
                                        <MenuList>
                                            {direction.map((r, index) => (
                                                <MenuListItem
                                                    key={`courseDirection-${r.direction}`}>
                                                    <InnerCheckBox
                                                        value={r.isSelected}
                                                        onChange={(val) => {
                                                            direction[
                                                                index
                                                            ].isSelected = val;
                                                            const request =
                                                                direction
                                                                    .filter(
                                                                        (r) =>
                                                                            r.isSelected
                                                                    )
                                                                    .map(
                                                                        (r) =>
                                                                            r.direction
                                                                    );
                                                            changeDirection(
                                                                request
                                                            );
                                                        }}
                                                        size={'medium'}>
                                                        <span>
                                                            {t(
                                                                `common/shared:direction-form-${r.direction}`
                                                            )}
                                                        </span>
                                                    </InnerCheckBox>
                                                </MenuListItem>
                                            ))}
                                        </MenuList>
                                    </MenuItemBlock>
                                    <MenuItemBlock>
                                        <MenuTitle>
                                            {t(
                                                'pages/professions:skills-format'
                                            )}
                                        </MenuTitle>
                                        <MenuList>
                                            {skills.map((r, index) => (
                                                <MenuListItem
                                                    key={`skill-form-${r.skill}`}>
                                                    <InnerCheckBox
                                                        value={r.isSelected}
                                                        onChange={(val) => {
                                                            skills[
                                                                index
                                                            ].isSelected = val;
                                                            const request =
                                                                skills
                                                                    .filter(
                                                                        (r) =>
                                                                            r.isSelected
                                                                    )
                                                                    .map(
                                                                        (r) =>
                                                                            r.skill
                                                                    );
                                                            changeSkill(
                                                                request
                                                            );
                                                        }}
                                                        size={'medium'}>
                                                        <span>
                                                            {t(
                                                                `common/shared:skill-form-${r.skill}`
                                                            )}
                                                        </span>
                                                    </InnerCheckBox>
                                                </MenuListItem>
                                            ))}
                                        </MenuList>
                                    </MenuItemBlock>
                                    <MenuItemBlock>
                                        <MenuTitle>
                                            {t('pages/professions:lang-format')}
                                        </MenuTitle>
                                        <MenuList>
                                            {languages.map((r, index) => (
                                                <MenuListItem
                                                    key={`language-${r.language}`}>
                                                    <InnerCheckBox
                                                        value={r.isSelected}
                                                        onChange={(val) => {
                                                            languages[
                                                                index
                                                            ].isSelected = val;
                                                            const request =
                                                                languages
                                                                    .filter(
                                                                        (r) =>
                                                                            r.isSelected
                                                                    )
                                                                    .map(
                                                                        (r) =>
                                                                            r.language
                                                                    );
                                                            changeLanguages(
                                                                request
                                                            );
                                                        }}
                                                        size={'medium'}>
                                                        <span>
                                                            {t(
                                                                `common/shared:lang-${r.language}`
                                                            )}
                                                        </span>
                                                    </InnerCheckBox>
                                                </MenuListItem>
                                            ))}
                                        </MenuList>
                                    </MenuItemBlock>
                                    <TextInputMobileBlock>
                                        <TextInput>
                                            <TextBlock
                                                val={searchString}
                                                onChange={onChangeSearchString}
                                            />
                                        </TextInput>
                                    </TextInputMobileBlock>
                                </>
                            </HideBlock>
                            <AllFilterMobileBlock>
                                <MoreButton
                                    onClick={() =>
                                        setShowFilters(!showFilters)
                                    }>
                                    {showFilters ? (
                                        <p>
                                            {t('common/shared:hide-filters')}
                                            <Icon
                                                name={'ShowLess'}
                                                color={'black'}
                                                size={16}
                                            />
                                        </p>
                                    ) : (
                                        <p>
                                            {selectedFilters
                                                ? t(
                                                      'common/shared:show-filters',
                                                      {
                                                          count: selectedFilters,
                                                      }
                                                  )
                                                : t(
                                                      'common/shared:show-filters2'
                                                  )}
                                            <Icon
                                                name={'ShowMore'}
                                                color={'black'}
                                                size={16}
                                            />
                                        </p>
                                    )}
                                </MoreButton>
                            </AllFilterMobileBlock>
                        </MenuBlock>
                        <SearchBlock>
                            <TextInputDesktopBlock>
                                <TextInput>
                                    <TextBlock
                                        val={searchString}
                                        onChange={onChangeSearchString}
                                    />
                                </TextInput>
                            </TextInputDesktopBlock>
                            {professions.count === 0 && courses.count === 0 && (
                                <>
                                    <ListEmpty>
                                        {t('common/shared:list-empty')}
                                    </ListEmpty>
                                    <ChangeFilters>
                                        {t('common/shared:change-filters')}
                                    </ChangeFilters>
                                    {reservedCourses.length !== 0 && (
                                        <ReservedView />
                                    )}
                                </>
                            )}
                            {professions.count !== 0 && (
                                <>
                                    <TitleBlock>
                                        <Title>
                                            {t('pages/professions:programs')}
                                            <span>{professions.count}</span>
                                        </Title>
                                    </TitleBlock>
                                    <DescriptionBlock>
                                        {t(
                                            'pages/professions:programs-description'
                                        )}
                                    </DescriptionBlock>
                                    <ListBlockProfessions>
                                        {professions.items.map((profession) => (
                                            <ProfessionItem
                                                openRegister={openRegister}
                                                key={profession.groupName}
                                                profession={profession}
                                            />
                                        ))}
                                    </ListBlockProfessions>
                                    <ButtonBlock>
                                        {professions.count !==
                                            professions.items.length && (
                                            <MoreButton
                                                onClick={nextProfessionPage}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: t(
                                                            'pages/professions:watch-more',
                                                            {
                                                                count:
                                                                    professions.count -
                                                                    professions
                                                                        .items
                                                                        .length,
                                                            }
                                                        ),
                                                    }}
                                                />
                                            </MoreButton>
                                        )}
                                    </ButtonBlock>
                                </>
                            )}
                            {courses.count !== 0 && (
                                <>
                                    <TitleBlock>
                                        <Title>
                                            {t('pages/professions:courses')}
                                            <span>{courses.count}</span>
                                        </Title>
                                    </TitleBlock>
                                    <DescriptionBlock>
                                        {t(
                                            'pages/professions:courses-description'
                                        )}
                                    </DescriptionBlock>
                                    <ListBlockCourses>
                                        {courses.items.map((course) => (
                                            <CourseItem
                                                key={course.groupName}
                                                course={course}
                                                openCourseRegister={
                                                    openCourseRegister
                                                }
                                            />
                                        ))}
                                    </ListBlockCourses>
                                    <ButtonBlock style={{ paddingBottom: 0 }}>
                                        {courses.count !==
                                            courses.items.length && (
                                            <MoreButton
                                                onClick={nextCoursePage}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: t(
                                                            'pages/professions:watch-more',
                                                            {
                                                                count:
                                                                    courses.count -
                                                                    courses
                                                                        .items
                                                                        .length,
                                                            }
                                                        ),
                                                    }}
                                                />
                                            </MoreButton>
                                        )}
                                    </ButtonBlock>
                                </>
                            )}
                        </SearchBlock>
                    </ContentBlock>
                </CentralBlock>
            </Content>
        </BodyContent>
    );
};
