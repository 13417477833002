import styled, { css } from 'styled-components';
import { mediaSm } from 'theme/theme-helper-selectors';
import { DropdownTitle } from './dropdown-title';
import { DropdownContent } from './dropdown-content';

const openedDropdownMixin = css`
    ${DropdownTitle} {
        &:after {
            transform: rotate(-135deg) translate(-50%, -50%);
        }
    }

    ${DropdownContent} {
        display: block;
    }
`;

export const DropdownRoot = styled.div<{ isOpen: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 5px;
    cursor: pointer;

    @media (${mediaSm}) {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
    }

    ${({ isOpen }) => isOpen && openedDropdownMixin}
`;
