import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BlueCard,
    CardBody,
    CardContent,
    CardTitle,
    Content,
    ContentBody,
    LearnMore,
    List,
    MembersDescription,
    PurpleCard,
    Title,
} from './styled';

export const Memberships = () => {
    const { t } = useTranslation('pages/academy-alliance');
    const [isActive1, setIsActive1] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const active1 = (val: boolean) => {
        setIsActive1(val);

        if (val) setIsActive2(false);
    };
    const active2 = (val: boolean) => {
        setIsActive2(val);

        if (val) setIsActive1(false);
    };
    return (
        <Content>
            <ContentBody>
                <Title>{t('membersTitle')}</Title>
                <MembersDescription>
                    {t('membersDescription')}
                </MembersDescription>
                <List>
                    <BlueCard>
                        <CardTitle onClick={() => active1(!isActive1)}>
                            {t('cardTitle1')}
                        </CardTitle>
                        <CardBody isActive={isActive1}>
                            <CardContent
                                dangerouslySetInnerHTML={{
                                    __html: t('cardContent1'),
                                }}
                            />
                            <LearnMore
                                href={`/files/alliance/${t('associateLink')}`}
                                target={'_blank'}>
                                {t('learnMore')}
                            </LearnMore>
                        </CardBody>
                    </BlueCard>
                    <PurpleCard>
                        <CardTitle onClick={() => active2(!isActive2)}>
                            {t('cardTitle2')}
                        </CardTitle>
                        <CardBody isActive={isActive2}>
                            <CardContent
                                dangerouslySetInnerHTML={{
                                    __html: t('cardContent2'),
                                }}
                            />
                            <LearnMore
                                href={`/files/alliance/${t('advanceLink')}`}
                                target={'_blank'}>
                                {t('learnMore')}
                            </LearnMore>
                        </CardBody>
                    </PurpleCard>
                </List>
            </ContentBody>
        </Content>
    );
};
