import { PageSection } from 'components';
import { Icon, PaginationOutput } from 'kl-b2c-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider, { Settings } from 'react-slick';
import { getReviewsApiService } from 'services/api.factory';
import DOMPurify from 'dompurify';
import { GetReviews, Review } from 'types/rest';
import { langMapper } from 'utils/lang-convert';
import { IntroButton } from '../intro/styled';
import {
    Action,
    ActionDescription,
    Container,
    DescriptionWrapper,
    DotsContainer,
    DotsList,
    Logo,
    Slide,
    SlideDescription,
    SlideImage,
    SliderContainer,
    SlideSubtitle,
    SlideTitle,
    Title,
    TitleWrapper,
    TextWrapper,
} from './styled';
import { ArrowContainer } from '../courses/styled';
import { CarouselConfig } from 'containers/pages/for-governments/components/organizations/Organizations';

const sectionConfig = {
    CWrap: {
        css: {
            paddingTop: 0,
            paddingBottom: 60,

            '@media(max-width: 1279px)': {
                paddingBottom: 80,
            },
        },
    },
    ContentArea: {
        css: {
            display: 'block',
            position: 'relative',
            zIndex: 5,
        },
    },
};

interface ReviewsProps {
    params: Pick<
        GetReviews,
        | 'isVisibleCorporateBusinessPage'
        | 'isVisibleGovernmentPage'
        | 'isVisibleHomePage'
    >;
    resources?: {
        subtitle?: string;
        button: string;
    };
    redirect?: () => void;
}

const DEFAULT_SLIDES_COUNT = 3;

export const Reviews: FC<ReviewsProps> = ({ params, resources, redirect }) => {
    const { t, i18n } = useTranslation('pages/for-businesses');
    const reviewsApi = getReviewsApiService();
    const [reviews, setReviews] = useState<Review[]>();
    const [carouselConfig, setCarouselConfig] = useState<CarouselConfig>({
        slidesToShow: 3,
        arrows: true,
        dots: true,
    });

    const CAROUSEL_SETTINGS: Settings = {
        speed: 500,
        slidesToScroll: 3,
        infinite: false,
        nextArrow: (
            <ArrowContainer isPrev>
                <Icon name={'ArrowRightThin'} color={'black'} size={24} />
            </ArrowContainer>
        ),
        prevArrow: (
            <ArrowContainer>
                <Icon name={'ArrowLeftThin'} color={'black'} size={24} />
            </ArrowContainer>
        ),
        ...carouselConfig,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
        appendDots: (dots) => (
            <DotsContainer>
                <DotsList> {dots} </DotsList>
            </DotsContainer>
        ),
    };

    useEffect(() => {
        reviewsApi
            .getReviews({
                page: 0,
                size: 20,
                domain: langMapper[i18n.language],
                ...params,
            })
            .then((reviews: PaginationOutput<Review>) => {
                if (reviews.items && reviews.items.length) {
                    setReviews(reviews?.items);

                    if (reviews?.items.length <= DEFAULT_SLIDES_COUNT) {
                        setCarouselConfig({
                            dots: false,
                            arrows: false,
                            slidesToShow: reviews.items.length,
                        });
                    }
                }
            })
            .catch((e) => console.error(e));
    }, []);

    if (!reviews) {
        return null;
    }

    return (
        <Container>
            <PageSection config={sectionConfig}>
                <DescriptionWrapper>
                    <TitleWrapper>
                        <Logo
                            src={
                                process.env.PUBLIC_URL +
                                '/assets/images/logo-white.svg'
                            }
                            alt={'Academy logo'}
                        />
                        <Title>{t('reviews-title')}</Title>
                    </TitleWrapper>

                    <Action>
                        <ActionDescription>
                            {resources?.subtitle
                                ? resources?.subtitle
                                : t('reviews-description')}
                        </ActionDescription>
                        <IntroButton
                            size={'large'}
                            onClick={
                                redirect
                                    ? () => redirect()
                                    : () =>
                                          (window.location.href =
                                              'mailto:Academy@kaspersky.com')
                            }>
                            {resources?.subtitle
                                ? resources?.button
                                : t('intro-button')}
                        </IntroButton>
                    </Action>
                </DescriptionWrapper>

                <SliderContainer>
                    <Slider {...CAROUSEL_SETTINGS} ref={(slider) => slider}>
                        {reviews.map((review: Review) => (
                            <Slide key={review.id}>
                                <SlideTitle>
                                    {review.firstName} {review.lastName}
                                </SlideTitle>
                                <TextWrapper>
                                    <SlideSubtitle>
                                        {review.position}
                                    </SlideSubtitle>
                                    <SlideDescription
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                review.text
                                            ),
                                        }}
                                    />
                                </TextWrapper>
                                <SlideImage src={review.image?.fileLink} />
                            </Slide>
                        ))}
                    </Slider>
                </SliderContainer>
            </PageSection>
        </Container>
    );
};
