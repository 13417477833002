import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageSection, Paragraph } from 'components';
import { DescriptionBlock, Title, SubTitle } from './styled';

const sectionConfig = {
    CWrap: {
        css: {
            padding: '0',
        },
    },
    ContentArea: {
        css: {
            paddingBottom: '120px',
            '@media(max-width: 1023px)': {
                paddingTop: '0 !important',
                paddingBottom: '80px',
            },
            '@media(max-width: 767px)': {
                flexDirection: 'column',
            },
        },
    },
};

const TeamSection: React.FC = (): JSX.Element => {
    const { t } = useTranslation('pages/about');

    return (
        <PageSection config={sectionConfig}>
            <Title>{t('teamTitle')}</Title>
            <DescriptionBlock>
                <SubTitle>{t('teamSubTitle')}</SubTitle>
                <Paragraph>{t('teamText')}</Paragraph>
            </DescriptionBlock>
        </PageSection>
    );
};

export default TeamSection;
