import styled from 'styled-components';
import { colorBlack, colorTeal, mediaSm } from 'theme/theme-helper-selectors';

export const Container = styled.div`
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 190px;
        position: absolute;
        background: ${colorBlack};
        bottom: 0;
        transform-origin: left bottom;
        transform: translateY(30%) skewY(3deg);
        border-radius: 0 0 40px 40px;
        z-index: 2;

        @media (${mediaSm}) {
            height: 100px;
        }
    }
`;

export const MainBlock = styled.div`
    background: #383f48;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 440px;
    margin: 0 0 24px 0;
    border-radius: 16px;

    @media (${mediaSm}) {
        flex-direction: column;
        height: auto;
    }
`;

export const MainBlockTitle = styled.h1`
    font-size: 48px;
    line-height: 48px;
    font-weight: 300;
    max-width: 480px;

    span {
        color: ${colorTeal};
    }

    @media (${mediaSm}) {
        font-size: 38px;
        line-height: 40px;
    }
`;

export const Left = styled.div`
    width: 50%;
    padding: 48px;

    @media (${mediaSm}) {
        width: 100%;
        padding: 24px;
    }
`;

export const Right = styled.div<{ image: string }>`
    width: 50%;
    background-image: ${({ image }) => `url(${image})`};
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0 16px 16px 0;

    @media (${mediaSm}) {
        width: 100%;
        height: 300px;

        border-radius: 0 0 16px 16px;
    }
`;

export const MessagesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    height: 232px;

    @media (${mediaSm}) {
        grid-template-columns: 1fr;
    }
`;
