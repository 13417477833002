import React, { PropsWithChildren } from 'react';
import { GlobalStyle } from 'styles/global-styles';
import {
    ConfigProvider,
    LangStateProvider,
    ThemeStateProvider,
    ApiProvider,
} from 'contexts';
import { RESTProvider } from './components';
import { GlobalStyles as UiKitGlobalStyles } from 'kl-b2c-ui-kit';
import { NormalizeCss } from 'styles/normalize-css';
import { BrowserRouter } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CoreProvider: React.FC<PropsWithChildren> = ({
    children,
}): JSX.Element => (
    <RESTProvider>
        <ApiProvider>
            <BrowserRouter>
                <ThemeStateProvider>
                    <UiKitGlobalStyles />
                    <NormalizeCss />
                    <GlobalStyle />
                    <LangStateProvider>
                        <ConfigProvider>{children}</ConfigProvider>
                    </LangStateProvider>
                </ThemeStateProvider>
            </BrowserRouter>
        </ApiProvider>
    </RESTProvider>
);

export default CoreProvider;
