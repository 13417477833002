import React from 'react';
import ReactDOM from 'react-dom/client';
import { SettingsProvider } from 'contexts';
import App from './App';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <SettingsProvider>
        <App />
    </SettingsProvider>
);

// eslint-disable-next-line no-console
// reportWebVitals(console.log);
