import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const CWrap = styled.div`
    grid-gap: 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
    padding: 0 0 40px 0;
    width: 100%;

    @media (${mediaSm}) {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        grid-template-columns: 1fr 1fr;
    }

    @media (${mediaXs}) {
        grid-template-columns: 1fr;
    }
`;
