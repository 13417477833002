import { PageSection } from 'components';
import { Typography } from 'kl-b2c-ui-kit';
import React from 'react';
import {
    Li,
    Table,
    Td,
    Th,
    Thead,
    Ul,
    ScrollableTable,
    PageHeader,
} from './styled';

const sectionConfig = {
    CWrap: {
        css: {
            padding: '40px 0',
            '@media(max-width: 767px)': {
                paddingTop: 0,

                p: {
                    wordBreak: 'break-word',
                },
            },
        },
    },
    ContentArea: {
        css: {
            flexDirection: 'column',
            gap: '30px',
        },
    },
};

export const En: React.FC = (): JSX.Element => (
    <PageSection config={sectionConfig}>
        <PageHeader variant={'h2'}>
            DATA PRIVACY STATEMENT KASPERSKY LAB CAREER SITE
            (ACADEMY.KASPERSKY.COM)
        </PageHeader>
        <Typography variant={'h3'} fontWeight={'regular'}>
            DATA CONTROLLER INFORMATION
        </Typography>
        <Typography variant={'p2'}>
            AO Kaspersky Lab, located at address: 39A/3 Leningradskoe Shosse,
            Moscow 125212, Russian Federation (“Kaspersky”, “Kaspersky Lab”,
            “KL” or “we”)
        </Typography>
        <Typography variant={'p2'} isInline>
            If you have any questions regarding the processing of your personal
            information, please contact our Kaspersky representative via e-mail
            or phone: Kaspersky Labs GmbH, Ingolstadt, Germany,&nbsp;
            <a href={'mailto:info@kaspersky.de'}>info@kaspersky.de</a>, +49 (0)
            841 98 18 90
        </Typography>
        <Typography variant={'p2'} isInline>
            If you have any additional questions about Kaspersky’s privacy
            practices or if you would like us to update information or
            preferences you provided to us, please contact our Data Protection
            Officer —{' '}
            <a
                href={'https://support.kaspersky.com/general/privacy'}
                target={'_blank'}
                rel={'noreferrer'}>
                click here
            </a>
            .
        </Typography>
        <Typography variant={'h3'} fontWeight={'regular'}>
            YOUR PERSONAL DATA — WHAT IS IT?
        </Typography>
        <Typography variant={'p2'} isInline>
            Personal data relates to a living individual who can be identified
            from that data. Identification can be by the information alone or in
            conjunction with any other information in the data controller’s
            possession or likely to come into such possession.
        </Typography>
        <Typography variant={'p2'} isInline>
            The processing of personal data within the EU is governed by the
            General Data Protection Regulation 2016/679 (the “GDPR”).
        </Typography>
        <Typography variant={'h3'} fontWeight={'regular'}>
            WHY WE PROCESS YOUR PERSONAL DATA
        </Typography>
        <Typography variant={'p2'} isInline>
            We process the following categories of your personal data for the
            following purposes:
        </Typography>
        <ScrollableTable>
            <Table>
                <Thead>
                    <tr>
                        <Th>Categories of personal data</Th>
                        <Th>
                            Purpose of processing and legitimate interests,
                            where applicable
                        </Th>
                        <Th>Legal basis</Th>
                        <Th>Storage period</Th>
                    </tr>
                </Thead>
                <tbody>
                    <tr>
                        <Td>
                            <Ul>
                                <Li>First and second name</Li>
                                <Li>Email address </Li>
                                <Li>Phone number</Li>
                                <Li>Country of residence</Li>
                                <Li>Company name </Li>
                                <Li>
                                    Education information (degree and year of
                                    studies)
                                </Li>
                                <Li>Position </Li>
                                <Li>Required course information</Li>
                                (number of students and format)
                                <Li>Comment</Li>
                            </Ul>
                        </Td>
                        <Td>
                            <Ul>
                                <Li>
                                    Process your application for jobs opened at
                                    Kaspersky
                                </Li>
                                <Li>
                                    Competition selection and signing of
                                    employment contract
                                </Li>
                            </Ul>
                        </Td>
                        <Td>
                            In order to take steps prior to entering into a
                            contract
                        </Td>
                        <Td>
                            Period of application to course or until refusal
                        </Td>
                    </tr>
                    <tr>
                        <Td>
                            <Ul>
                                <Li>First and second name</Li>
                                <Li>Email address </Li>
                                <Li>Phone number</Li>
                                <Li>Country of residence</Li>
                            </Ul>
                        </Td>
                        <Td>
                            Communication to offer training on a commercial
                            and/or non-profit basis, discuss partnership.
                        </Td>
                        <Td>
                            In order to take steps prior to entering into a
                            contract
                        </Td>
                        <Td>
                            Until the withdrawal or expiry of consent, maximum
                            12 months after last distribution
                        </Td>
                    </tr>
                    <tr>
                        <Td>
                            <Ul>
                                <Li>First and second name</Li>
                                <Li>Email address </Li>
                                <Li>Phone number</Li>
                                <Li>Nickname in social networks</Li>
                                <Li>City of residence</Li>
                                <Li>Company name</Li>
                                <Li>
                                    Education information (degree and year of
                                    studies)
                                </Li>
                                <Li>Position</Li>
                            </Ul>
                        </Td>
                        <Td>Participation in events provided by Kaspersky.</Td>
                        <Td />
                        <Td>Period of application to event or until refusal</Td>
                    </tr>
                    <tr>
                        <Td>
                            <Ul>
                                <Li>First and second name</Li>
                                <Li>Email address </Li>
                                <Li>Phone number</Li>
                                <Li>Country of residence</Li>
                            </Ul>
                        </Td>
                        <Td>Providing marketing and service information</Td>
                        <Td>Consent</Td>
                        <Td>
                            Until the withdrawal or expiry of consent, maximum
                            12 months after last distribution of marketing
                            information
                        </Td>
                    </tr>
                </tbody>
            </Table>
        </ScrollableTable>

        <Typography variant={'p2'} isInline>
            We have obtained your personal data from you directly.
        </Typography>
        <Typography variant={'h3'} fontWeight={'regular'}>
            SHARING YOUR PERSONAL DATA
        </Typography>
        <Typography variant={'p2'} isInline>
            We may disclose your personal data as follows, provided the legal
            requirements are met:
        </Typography>
        <Ul>
            <Li>
                <Typography variant={'p2'} isInline>
                    Within the Group of Kaspersky Companies with strict access
                    policy rights for the purpose of employment.
                </Typography>
            </Li>
            <Li>
                <Typography variant={'p2'} isInline>
                    With service providers which we use for the provision of
                    certain services e.g. vendors. As far as such external
                    service providers need to have access to personal data, we
                    ensure that any such access is limited to the extent
                    necessary for the provision of the respective service.
                    Furthermore, such external service providers of course have
                    to submit themselves to comply with all applicable data
                    protection regulations.
                </Typography>
            </Li>
        </Ul>
        <Typography variant={'h3'} fontWeight={'regular'}>
            TRANSFER OF DATA ABROAD
        </Typography>
        <Typography variant={'p2'} isInline>
            The personal data provided by users to Kaspersky Lab can be
            processed in the following countries, including countries outside
            European Union (EU) or the European Economic Area (EEA) which have
            not been deemed to have an adequate level of data protection by the
            European Commission: <b>non-EEA:</b> Russia.
        </Typography>
        <Typography variant={'p2'} isInline>
            Kaspersky Lab has taken appropriate security measures to protect
            your personal data in accordance with security and privacy best
            practices (more information&nbsp;
            <a
                href={'https://www.kaspersky.com/global-privacy-policy'}
                target={'_blank'}
                rel={'noreferrer'}>
                https://www.kaspersky.com/global-privacy-policy
            </a>
            ), including, utilizing the European Commission’s Standard
            Contractual Clauses for transfers of personal information between
            group companies (You can find these standard contract clauses under
            the following{' '}
            <a
                href={
                    'https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en?prefLang=de'
                }
                target={'_blank'}
                rel={'noreferrer'}>
                link
            </a>
            ), which requires all group companies to protect personal
            information being processed from the EEA to an equivalent standard
            to that required under EU data protection law. Where we share your
            personal data with a third party service provider outside of the
            European Economic Area and Switzerland (as detailed in the section
            entitled “Sharing your information”), we contractually oblige the
            third party service provider to implement adequate safeguards to
            protect your information.
        </Typography>
        <Typography variant={'h3'} fontWeight={'regular'}>
            HOW LONG DO WE KEEP YOUR PERSONAL DATA?
        </Typography>
        <Typography variant={'p2'} isInline>
            We retain your personal data only as long as necessary to fulfill
            the stated purposes, see column “storage period” in the chapter “WHY
            WE PROCESS YOUR PERSONAL DATA” above.
        </Typography>
        <Typography variant={'h3'} fontWeight={'regular'}>
            AUTOMATED DECISION MAKING
        </Typography>
        <Typography variant={'p2'} isInline>
            We do not use automated decision making.
        </Typography>
        <Typography variant={'h3'} fontWeight={'regular'}>
            YOUR RIGHTS AND YOUR PERSONAL DATA
        </Typography>
        <Typography variant={'p2'} isInline>
            We inform you that you have certain rights regarding the personal
            data we maintain about you:
        </Typography>
        <Ul>
            <Li>
                <Typography variant={'p2'} isInline>
                    <b>Right to be informed.</b> You have the right to be
                    provided with clear, transparent and easily understandable
                    information about how we use your personal data, and your
                    rights.
                </Typography>
            </Li>
            <Li>
                <Typography variant={'p2'} isInline>
                    <b>Right of access.</b> You have the right to request
                    information about how we use your personal data. In
                    addition, you can request to receive a copy of the Personal
                    Data we process about you and to check that we are lawfully
                    processing it.
                </Typography>
            </Li>
            <Li>
                <Typography variant={'p2'} isInline>
                    <b>Right to rectification.</b> Request correction of the
                    personal data that we process about you.
                </Typography>
            </Li>
            <Li>
                <Typography variant={'p2'} isInline>
                    <b>Right to erasure (Right to be forgotten).</b> This
                    enables you to ask us to delete or remove personal data
                    where there is no good reason, such as statutory retention
                    periods, for us continuing to process it.
                </Typography>
            </Li>
            <Li>
                <Typography variant={'p2'} isInline>
                    <b>Right of restriction of processing.</b> This enables you
                    to ask us to suspend the processing of personal data about
                    you, for example if you want us to establish its accuracy or
                    the reason for processing it.
                </Typography>
            </Li>
            <Li>
                <Typography variant={'p2'} isInline>
                    <b>Right to data portability.</b> Insofar as the processing
                    of your personal data is based on your consent or on a
                    contract (Articles 6 (1) (a) and (b) GDPR, you may request
                    to receive the personal data you have provided to us in a
                    structured, commonly used and machine-readable format and to
                    have the personal data transmitted to another controller.
                </Typography>
            </Li>
            <Li>
                <Typography variant={'p2'} isInline>
                    <b>Right to object.</b> You have the right to object, on
                    grounds relating to your particular situation, at any time
                    to processing of personal data concerning you which is based
                    on Article 6 (1) (f) of the GDPR (data processing for the
                    purposes of the legitimate interests). If you object, we
                    will no longer process your personal data unless we can
                    demonstrate compelling legitimate grounds for the processing
                    which override your interests, rights and freedoms or for
                    the establishment, exercise or defence of legal claims.
                    Additionally, you have the right to object at any time to
                    processing of personal data for direct marketing purposes
                    without giving any reasons.
                </Typography>
            </Li>
            <Li>
                <Typography variant={'p2'} isInline>
                    <b>Right to withdraw data protection consent.</b> If you
                    have given us your consent to the processing of your
                    personal data, you may withdraw the consent at any time with
                    effect for the future. This does not affect the lawfulness
                    of the processing of your personal data before the
                    withdrawal of the consent.
                </Typography>
            </Li>
            <Li>
                <Typography variant={'p2'} isInline>
                    <b>Right to complain.</b> You have the right to contact the
                    data protection authority in order to lodge a complaint
                    against our data protection and privacy practices.
                </Typography>
            </Li>
        </Ul>
        <Typography variant={'h3'} fontWeight={'regular'}>
            HOW TO EXERCISE YOUR RIGHTS
        </Typography>
        <Typography variant={'p2'} isInline>
            If you wish to exercise these rights towards us, you may at any time{' '}
            <a
                href={'https://support.kaspersky.com/general/privacy'}
                target={'_blank'}
                rel={'noreferrer'}>
                contact us directly
            </a>
            &nbsp; or our representative in EU: Kaspersky Labs GmbH, Ingolstadt,
            Germany, <a href={'mailto:info@kaspersky.de'}>info@kaspersky.de</a>,
            +49 (0) 841 98 18 90.
        </Typography>
        <Typography variant={'p2'} isInline>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.
        </Typography>
        <Typography variant={'p2'} isInline>
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
        </Typography>
        <Typography variant={'h3'} fontWeight={'regular'}>
            COMPLAINTS
        </Typography>
        <Typography variant={'p2'} isInline>
            If you would like to make a complaint regarding this Privacy
            Statement or our practices in relation to your personal data, please
            contact us at{' '}
            <a
                href={'https://support.kaspersky.com/general/privacy'}
                target={'_blank'}
                rel={'noreferrer'}>
                click here
            </a>
            .
        </Typography>
        <Typography variant={'p2'} isInline>
            If you consider that the processing of personal data relating to you
            infringes applicable data protection law, you have the right to
            lodge a complaint with the competent supervisory authority at any
            time. Which supervisory authority has competence for your complaint
            can depend on the country where you reside.
        </Typography>
    </PageSection>
);
