import React, {
    Context,
    createContext,
    FC,
    ReactNode,
    useCallback,
    useContext,
    useState,
} from 'react';

interface StickyHeaderState {
    disabledStickyHeader: boolean;
    changeDisabledStickyHeader: (disabled: boolean) => void;
}

const initState: StickyHeaderState = {
    disabledStickyHeader: false,
    changeDisabledStickyHeader: () => {},
};

const StickyHeaderContext: Context<StickyHeaderState> =
    createContext(initState);

export const StickyHeaderStateProvider: FC<{ children: ReactNode }> = (
    props
) => {
    const [disabledStickyHeader, setDisabledStickyHeader] = useState(
        initState.disabledStickyHeader
    );

    const changeDisabledStickyHeader = useCallback((disabled: boolean) => {
        setDisabledStickyHeader(disabled);
    }, []);

    return (
        <StickyHeaderContext.Provider
            value={{
                disabledStickyHeader,
                changeDisabledStickyHeader,
            }}
            {...props}
        />
    );
};

export const useStickyHeaderContext = () => useContext(StickyHeaderContext);
