import styled from 'styled-components';
import { mediaXs } from 'theme/theme-helper-selectors';

export const Line = styled.hr`
    width: 100%;
    margin-bottom: 20px;
`;
export const MeyBe = styled.h3`
    font-size: 48px;
    font-weight: 400;
    line-height: 56.26px;
    padding-bottom: 40px;
    @media (${mediaXs}) {
        font-size: 24px;
        font-weight: 400;
        line-height: 27px;
        padding-bottom: 0px;
    }
`;
