import { PageSection, Paragraph } from 'components';
import { getFormsApiService } from 'services/api.factory';
import FormBase from 'components/form-base';
import { useUtmParams, TextField, PhoneNumber } from 'kl-b2c-ui-kit';
import React, { useState } from 'react';
import {
    useForm,
    SubmitHandler,
    FieldValues,
    FormProvider,
    Controller,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CooperationFormData } from 'types/components';
import { InputsRow, InputCell, Hint, DescriptionBlock } from './styled';
import { LegalType } from 'types/enums';
import { getCooperationFormRules } from './configs';

const sectionConfig = {
    CWrap: {
        css: {
            paddingTop: '40px',
            '@media(max-width: 767px)': {
                overflow: 'hidden',
                padding: '0',
            },
        },
    },
    ContentArea: {
        css: {
            justifyContent: 'center',
            '@media(max-width: 1023px)': {
                paddingTop: '0 !important',
            },

            '@media(max-width: 767px)': {
                background: '#fff',
            },
        },
    },
};

const CooperationForm: React.FC = (): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>();

    const methods = useForm<CooperationFormData>();

    const {
        control,
        formState: { errors },
    } = methods;

    const { t } = useTranslation(['common/cooperation-form', 'common/errors']);
    const formsAPI = getFormsApiService();

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoading(true);
        const utm = useUtmParams();
        let status = 0;
        try {
            const requestData: CooperationFormData = {
                ...(data as CooperationFormData),
                agreement: {
                    ...data.agreement,
                    marketingCheckBoxText: t(
                        'common/cooperation-form:marketingLabel'
                    ),
                    privacyPolicyCheckBoxText: t(
                        'common/cooperation-form:privacyPolicyLabel'
                    ),
                },
                type: data.type ? LegalType.Legal : LegalType.Individual,
                ...utm,
            };

            const result = await formsAPI.sendCooperationFormData(requestData);
            status = result.status;
            setSuccess(true);
        } catch (error) {
            setSuccess(false);
        } finally {
            setLoading(false);
        }

        return status;
    };

    const rules = getCooperationFormRules(t);

    return (
        <PageSection config={sectionConfig}>
            <FormProvider {...methods}>
                <FormBase
                    success={success}
                    loading={loading}
                    resources={{
                        submitLabel: t(
                            'common/cooperation-form:saveBtnCaption'
                        ),
                        successMessage: t('common/cooperation-form:successMsg'),
                        title: t('common/cooperation-form:title'),
                    }}
                    submit={onSubmit}
                    styles={{
                        '@media(max-width: 767px)': {
                            padding: '20px 0 40px',
                        },
                    }}
                    policies={[
                        {
                            name: 'type',
                            label: t(
                                'common/cooperation-form:legalEntityLabel'
                            ),
                        },
                        {
                            name: 'agreement.privacyPolicyIsSelected',
                            label: t(
                                'common/cooperation-form:privacyPolicyLabel'
                            ),
                            required: true,
                        },
                        {
                            name: 'agreement.marketingIsSelected',
                            label: t('common/cooperation-form:marketingLabel'),
                        },
                    ]}>
                    <DescriptionBlock>
                        <Paragraph align={'center'}>{`${t(
                            'common/cooperation-form:text1'
                        )}`}</Paragraph>
                        <Paragraph align={'center'}>{`${t(
                            'common/cooperation-form:text2'
                        )}`}</Paragraph>
                    </DescriptionBlock>
                    <InputsRow>
                        <InputCell>
                            <Controller
                                name={'name'}
                                control={control}
                                rules={rules['name']}
                                render={({ field: { onChange } }) => (
                                    <TextField
                                        fullWidth
                                        variant={'border'}
                                        type={'text'}
                                        onChange={onChange}
                                        placeholder={t(
                                            'common/cooperation-form:namePlaceholder'
                                        )}
                                        error={!!errors.name}
                                    />
                                )}
                            />
                            <Hint>
                                {!!errors.name ? errors.name.message : ''}
                            </Hint>
                        </InputCell>
                        <InputCell>
                            <Controller
                                name={'email'}
                                control={control}
                                rules={rules['email']}
                                render={({ field: { onChange } }) => (
                                    <TextField
                                        fullWidth
                                        variant={'border'}
                                        type={'text'}
                                        onChange={onChange}
                                        placeholder={t(
                                            'common/cooperation-form:emailPlaceholder'
                                        )}
                                        error={!!errors.email}
                                    />
                                )}
                            />
                            <Hint>
                                {!!errors.email ? errors.email.message : ''}
                            </Hint>
                        </InputCell>
                    </InputsRow>
                    <InputsRow>
                        <InputCell>
                            <Controller
                                name={'phone'}
                                control={control}
                                rules={rules['phone']}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneNumber
                                        value={value}
                                        variant={'border'}
                                        placeholder={t(
                                            'common/cooperation-form:phonePlaceholder'
                                        )}
                                        error={!!errors.phone}
                                        onChange={onChange}
                                        fullWidth
                                    />
                                )}
                            />
                            <Hint>
                                {!!errors.phone ? errors.phone.message : ''}
                            </Hint>
                        </InputCell>
                        <InputCell>
                            <Controller
                                name={'country'}
                                control={control}
                                rules={rules['country']}
                                render={({ field: { onChange } }) => (
                                    <TextField
                                        fullWidth
                                        variant={'border'}
                                        type={'text'}
                                        onChange={onChange}
                                        placeholder={t(
                                            'common/cooperation-form:countryPlaceholder'
                                        )}
                                        error={!!errors.country}
                                    />
                                )}
                            />
                            <Hint>
                                {!!errors.country ? errors.country.message : ''}
                            </Hint>
                        </InputCell>
                    </InputsRow>
                </FormBase>
            </FormProvider>
        </PageSection>
    );
};

export default CooperationForm;
