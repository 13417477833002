import {
    ArrowIcon,
    FooterMenuExternalLink,
    FooterMenuItem,
    FooterMenuLink,
    FooterMenuList,
} from './styled';
import React from 'react';
import { MenuElement } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
    items: MenuElement[];
}

const FooterMenu: React.FC<Props> = ({ items }) => {
    const { t } = useTranslation('common/nav');
    const toTopPage = () => {
        scrollTo(0, 0);
    };

    return (
        <FooterMenuList>
            {items.map(
                ({ i18nKey, link, dataAtSelector, isExternal, withArrow }) => (
                    <FooterMenuItem key={link}>
                        {isExternal ? (
                            <FooterMenuExternalLink
                                href={link}
                                data-at-selector={dataAtSelector}>
                                {t(i18nKey)}
                                {withArrow && <ArrowIcon>➜</ArrowIcon>}
                            </FooterMenuExternalLink>
                        ) : (
                            <FooterMenuLink
                                onClick={toTopPage}
                                to={link}
                                data-at-selector={dataAtSelector}>
                                {t(i18nKey)}
                                {withArrow && <ArrowIcon>➜</ArrowIcon>}
                            </FooterMenuLink>
                        )}
                    </FooterMenuItem>
                )
            )}
        </FooterMenuList>
    );
};

export default FooterMenu;
