import { PageSection } from 'components';
import { CarouselConfig } from 'containers/pages/for-governments/components/organizations/Organizations';
import DOMPurify from 'dompurify';
import { Icon } from 'kl-b2c-ui-kit';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider, { Settings } from 'react-slick';
import { DotsContainer, DotsList } from '../reviews/styled';
import {
    ArrowContainer,
    ArrowIcon,
    DescriptionContainer,
    DescriptionContent,
    DescriptionLink,
    DescriptionLinkContainer,
    DescriptionTitle,
    Slide,
    SlideDescription,
    SliderArrow,
    SliderStyler,
    SlideTitle,
    Worker,
    Wrapper,
} from './styled';
import { CSSObject } from 'styled-components';
import { ProfessionTable } from 'types';

const sectionConfig = {
    CWrap: {
        css: {
            paddingTop: 0,

            '@media(max-width: 1279px)': {
                paddingBottom: 0,
            },

            '@media(max-width: 1023px)': {
                paddingTop: 20,
            },
        },
    },
    ContentArea: {
        css: {
            display: 'block',
            position: 'relative',
        },
    },
};

export enum DetailType {
    Course = 1,
    Profession = 2,
}

export type CourseDetailWithType = {
    id: string;
    type: DetailType;
    name: string;
    description: string;
    link?: string;
    previous?: ProfessionTable[];
    groupName?: string;
};

interface CoursesProps {
    courses?: CourseDetailWithType[];
    styles?: CSSObject;
    config?: {
        description: string;
        image: string;
    };
}

export const Courses: FC<CoursesProps> = ({ courses, styles, config }) => {
    const { t } = useTranslation('pages/for-businesses');
    const [carouselConfig] = useState<CarouselConfig>({
        slidesToShow: 3,
        arrows: courses && courses?.length > 3 ? true : false,
        dots: courses && courses?.length > 3 ? true : false,
    });

    const CAROUSEL_SETTINGS: Settings = {
        slidesToScroll: 1,
        infinite: false,
        nextArrow: (
            <ArrowContainer isPrev>
                <Icon name={'ArrowRightThin'} color={'black'} size={24} />
            </ArrowContainer>
        ),
        prevArrow: (
            <ArrowContainer>
                <Icon name={'ArrowLeftThin'} color={'black'} size={24} />
            </ArrowContainer>
        ),
        ...carouselConfig,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
        ],
        appendDots: (dots) => (
            <DotsContainer>
                <DotsList> {dots} </DotsList>
            </DotsContainer>
        ),
    };

    const getRedirectLink = (course: CourseDetailWithType): string => {
        if (course.link && (!course.previous || course.previous.length === 0)) {
            return course.link;
        }

        return `/${
            course.type === DetailType.Course ? 'course' : 'profession'
        }/${course.groupName}`;
    };

    if (!courses) {
        return null;
    }

    return (
        <Wrapper styles={styles}>
            <PageSection config={sectionConfig}>
                <DescriptionContainer>
                    <DescriptionTitle>{t('course-title')}</DescriptionTitle>
                    <DescriptionContent>
                        {config?.description
                            ? config.description
                            : t('course-description')}
                    </DescriptionContent>
                    <DescriptionLinkContainer>
                        <DescriptionLink to={'/courses'}>
                            {t('course-show-all')}
                        </DescriptionLink>
                        <ArrowIcon
                            name={'ArrowRight'}
                            color={'teal'}
                            size={28}
                        />
                    </DescriptionLinkContainer>
                </DescriptionContainer>
                <Worker
                    src={
                        config?.image
                            ? process.env.PUBLIC_URL + config?.image
                            : process.env.PUBLIC_URL +
                              '/assets/images/bussiness/worker.png'
                    }
                    alt={'Worker image'}
                />

                {courses && courses.length > 0 && (
                    <SliderStyler>
                        <Slider {...CAROUSEL_SETTINGS}>
                            {courses.map((course) => (
                                <Slide
                                    key={course.id}
                                    to={getRedirectLink(course)}>
                                    <SlideTitle>{course.name}</SlideTitle>
                                    <SlideDescription
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                course.description
                                            ),
                                        }}
                                    />
                                    <SliderArrow
                                        name={'ArrowRightThin'}
                                        color={'teal'}
                                        size={53}
                                    />
                                </Slide>
                            ))}
                        </Slider>
                    </SliderStyler>
                )}
            </PageSection>
        </Wrapper>
    );
};
