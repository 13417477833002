import React from 'react';
import { useTranslation } from 'react-i18next';
import { Content, ContentText, ContentTitle, ContentTitle2 } from './styled';

export const AdditionalDescription = () => {
    const { t } = useTranslation('pages/academy-alliance');
    return (
        <Content>
            <ContentTitle>{t('title1')}</ContentTitle>
            <ContentText>{t('chapter1')}</ContentText>
            <ContentTitle2>{t('title2')}</ContentTitle2>
            <ContentText>{t('chapter2')}</ContentText>
        </Content>
    );
};
