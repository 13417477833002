import React, { useEffect } from 'react';
import { useSettings } from 'contexts';
import AppRouter from 'containers/router/AppRouter';
import { CoreProvider } from 'containers';
import './config/i18n';
import { useUtmLSParams } from 'kl-b2c-ui-kit';

import * as dayjs from 'dayjs';
import 'dayjs/locale/ru';
dayjs.locale('en');

const App = () => {
    const { settings } = useSettings();

    useEffect(() => {
        useUtmLSParams();
    }, []);

    if (!settings) return null;

    return (
        <CoreProvider>
            <AppRouter />
        </CoreProvider>
    );
};

export default App;
