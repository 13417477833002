import React, { FC, useEffect } from 'react';
import FullScreenLoader from 'components/full-screen-loader';
import { useConfirm } from 'contexts/confirmation.context';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageSection } from 'components';
import { useMobileMenuContext } from 'contexts/mobile-menu.context';
import { Icon } from 'kl-b2c-ui-kit';
import {
    Wrapper,
    BackContainer,
    BackText,
    MainContainer,
} from '../profession-item/components/intro/styled';
import {
    MessageBlock,
    TextBlock,
    Title,
    Text,
    ImageBlock,
    ErrorImage,
    MessageImage,
    ComputerImage,
    ArrowImage,
} from './styled';

const sectionConfig = {
    CWrap: {
        css: {},
    },
    ContentArea: {
        css: {
            paddingTop: 90,
            display: 'block',

            '@media(max-width: 1023px)': {
                paddingTop: '90px !important',
            },
        },
    },
};

const mainSectionConfig = {
    CWrap: {
        css: {
            zIndex: 5,
        },
    },
    ContentArea: {
        css: {
            paddingBottom: 0,
            display: 'block',
        },
    },
};

const Confirmation: FC = () => {
    const { toggleProfessionStyles } = useMobileMenuContext();
    const { t } = useTranslation('pages/confirmation');
    const { loading, success, confirm } = useConfirm();
    const { agreementId } = useParams();

    useEffect(() => {
        confirm(agreementId ?? '');
        toggleProfessionStyles(true);

        return () => {
            toggleProfessionStyles(false);
        };
    }, []);

    return (
        <>
            {loading ? (
                <FullScreenLoader />
            ) : (
                <Wrapper>
                    <PageSection config={sectionConfig}>
                        <BackContainer to={'/courses'}>
                            <Icon
                                name={'ArrowLeft'}
                                color={'black'}
                                size={25}
                            />
                            <BackText>{t('all-courses')}</BackText>
                        </BackContainer>
                    </PageSection>
                    <MainContainer>
                        <PageSection config={mainSectionConfig}>
                            <MessageBlock>
                                <TextBlock>
                                    <Title>
                                        {success
                                            ? t('success-title')
                                            : t('error-title')}
                                    </Title>
                                    <Text>
                                        {success
                                            ? t('success-text')
                                            : t('error-text')}
                                    </Text>
                                </TextBlock>
                                <ImageBlock success={success}>
                                    {success ? (
                                        <>
                                            <MessageImage />
                                            <ComputerImage />
                                            <ArrowImage />
                                        </>
                                    ) : (
                                        <ErrorImage />
                                    )}
                                </ImageBlock>
                            </MessageBlock>
                        </PageSection>
                    </MainContainer>
                </Wrapper>
            )}
        </>
    );
};

export default Confirmation;
