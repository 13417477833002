import { DefaultTheme, ThemeProps } from 'styled-components';

export const colorPrimary = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.palette.green;
export const colorWhite = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.palette.white;
export const colorBlack = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.palette.black;
export const colorTeal = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.palette.teal;
export const colorPrimaryLight = ({
    theme,
}: ThemeProps<DefaultTheme>): string => theme.palette.teal;
export const colorBackground = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.palette.background;
export const colorText = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.palette.text.primary;
export const colorGray = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.palette.gray['500'];
export const colorGray100 = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.palette.gray['100'];
export const colorMenuBackground = ({
    theme,
}: ThemeProps<DefaultTheme>): string => theme.palette.menuBackground;
export const shadowDropdown = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.shadows.dropdown;

export const fontFamilyMain = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.commonTypography.fontFamily;
export const fontSize = ({ theme }: ThemeProps<DefaultTheme>): number =>
    theme.commonTypography.fontSize;

export const mediaMd = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.media.md;
export const mediaXs = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.media.xs;
export const mediaSm = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.media.sm;
export const mediaLg = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.media.lg;
export const mediaMobile = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.media.mobile;
export const mediaTablet = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.media.tablet;
export const mediaBigTablet = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.media.bigTablet;
export const mediaSuperBigTablet = ({
    theme,
}: ThemeProps<DefaultTheme>): string => theme.media.superBigTablet;

export const transitionBase = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.animation.base;

export const headerHeight = ({ theme }: ThemeProps<DefaultTheme>): string =>
    theme.shape.headerHeight;
