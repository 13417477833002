import React from 'react';
import { EventTag, NewsTag } from 'types';
import { CWrap } from './styled';
import Tag from '../tag';
import { TagType } from 'components/tag/styled';

export interface Props {
    tags: EventTag[] | NewsTag[];
    selectedTags: Set<string>;
    onClickTag: (id: string) => void;
    type?: TagType;
    disabled?: boolean;
}

export const TagHeap: React.FC<Props> = ({
    tags,
    selectedTags,
    onClickTag,
    type = 'black',
    disabled,
}): JSX.Element => (
    <CWrap type={type} fullScreen={disabled}>
        {tags.map((tag: EventTag) => {
            const { name, id } = tag;

            return (
                <Tag
                    key={id}
                    disabled={disabled}
                    onClick={() => {
                        onClickTag(id);
                    }}
                    id={id}
                    name={name}
                    isActive={selectedTags.has(id)}
                    type={type}
                />
            );
        })}
    </CWrap>
);

export default TagHeap;
