import styled from 'styled-components';
import { Typography } from 'kl-b2c-ui-kit';
import { mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.section<{ background?: string }>`
    width: 510px;
    max-width: 100%;
    position: relative;

    @media (${mediaXs}) {
        width: 100%;
    }
`;

export const Title = styled(Typography)`
    font-size: 40px;
    font-weight: 500;
    line-height: 49px;
    text-align: center;
    position: relative;
    margin: 0 0 24px 0;

    @media (${mediaXs}) {
        max-width: 80%;
        font-size: 25px;
        line-height: 27px;
        margin: 0 auto;
    }
`;

export const Close = styled.div`
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #ebebeb;
    position: absolute;
    top: 0;
    right: -72px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (${mediaXs}) {
        right: -15px;
        top: -15px;
        width: 35px;
        height: 35px;

        svg {
            height: 20px;
            width: 20px;
        }
    }

    @media (max-width: 639px) {
        right: 0;
        top: -30px;
    }
`;

export const Tabs = styled.ul`
    border-bottom: 2px solid #ebebeb;
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
`;

export const Tab = styled.li`
    font-size: 14px;
    line-height: 16px;
    padding: 20px 16px;
    color: ${({ theme }) => theme.palette.black};
    cursor: pointer;
`;
