import styled, { css } from 'styled-components';
import { headerHeight, mediaSm } from 'theme/theme-helper-selectors';
import { HamburgerLine } from './line';

const submenuMixin = css`
    width: 28px;
    height: 28px;
    align-self: center;
    justify-content: space-between;
    flex-direction: column;
    background: transparent;
    padding: 5px;

    @media (${mediaSm}) {
        display: none;
    }
`;

const mobileMixin = css`
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    width: 64px;
    height: ${headerHeight};
    padding: 23px;
    margin: 0;
    vertical-align: middle;
    background: transparent;
`;

export const HamburgerButton = styled.button<{
    isMobile: boolean;
    isMobileMenuOpened: boolean;
}>`
    order: 2;
    display: ${({ isMobile }) => (isMobile ? 'none' : 'inline-flex')};
    border: 0;
    outline: none !important;
    cursor: pointer;

    ${({ isMobile }) => (isMobile ? css`` : submenuMixin)};

    @media (${mediaSm}) {
        transition: all 0.3s ease-in-out;
        ${({ isMobile }) => (isMobile ? mobileMixin : css``)};

        ${({ isMobileMenuOpened }) =>
            isMobileMenuOpened &&
            css`
                ${HamburgerLine} {
                    width: 18px;
                }

                ${HamburgerLine}:nth-child(2) {
                    opacity: 0;
                }

                ${HamburgerLine}:nth-child(1) {
                    transform: translateY(8px) rotate(45deg);
                }

                ${HamburgerLine}:nth-child(3) {
                    transform: translateY(-8px) rotate(-45deg);
                }
            `}
    }
`;
