import { IConfirmationApiService } from 'types';
import { ApiService } from 'services';
import { AxiosResponse } from 'axios';
import { urlEncodeUtil } from 'utils/url-encode';

export class ConfirmationApi
    extends ApiService
    implements IConfirmationApiService
{
    confirmation(agreementGuid: string): Promise<AxiosResponse<void>> {
        return this.request<void>({
            method: 'GET',
            url: '/MarketingConfirmation/Agree',
            params: {
                agreementId: agreementGuid,
            },
            paramsSerializer: urlEncodeUtil,
        }).then((response) => response);
    }
}
