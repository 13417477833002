import { AxiosResponse } from 'axios';
import { ApiService } from 'services';
import {
    CourseDetail,
    CourseRegistration,
    CourseTable,
    GetCourse,
    GetCourses,
    ICourseService,
    Paging,
} from 'types';
import { urlEncodeUtil } from 'utils';

export class CourseApi extends ApiService implements ICourseService {
    getList(params: GetCourses): Promise<AxiosResponse<Paging<CourseTable>>> {
        return this.request<Paging<CourseTable>>({
            method: 'GET',
            url: '/Course/GetItems',
            params,
            paramsSerializer: urlEncodeUtil,
        }).then((response) => response);
    }
    getDetailed(params: GetCourse): Promise<AxiosResponse<CourseDetail>> {
        return this.request<CourseDetail>({
            method: 'GET',
            url: '/Course/GetItem',
            params,
            paramsSerializer: urlEncodeUtil,
        }).then((response) => response);
    }
    public register(data: CourseRegistration): Promise<AxiosResponse> {
        return this.request({
            method: 'POST',
            url: '/CourseForm/Add',
            data,
        });
    }
}
