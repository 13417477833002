import styled from 'styled-components';
import { mediaSm } from 'theme/theme-helper-selectors';

export const DropdownItem = styled.div<{ level: number }>`
    padding-left: ${({ level }) => (level ? 20 * level : 10)}px;

    &:last-child {
        @media (${mediaSm}) {
            margin-bottom: 100px;
        }
    }

    &.isActive {
        background: ${({ theme }) => theme.palette.teal};
        & button {
            color: ${({ theme }) => theme.palette.white};
        }
    }
`;
