import styled from 'styled-components';
import { colorBlack } from 'theme/theme-helper-selectors';

export const HamburgerLine = styled.span<{
    isMobile: boolean;
    isPressed: boolean;
}>`
    width: 100%;
    height: 2px;
    border: 0;
    background-color: ${colorBlack};
    display: block;
    transition: all 0.3s ease-in-out;

    &:nth-child(1) {
        transform: ${({ isPressed }) =>
            !isPressed ? 'none' : 'rotate(45deg) translate(6px, 6px)'};
    }

    &:nth-child(2) {
        display: ${({ isPressed }) => (isPressed ? 'none' : 'inline-flex')};
    }

    &:nth-child(3) {
        transform: ${({ isPressed }) =>
            !isPressed ? 'none' : 'rotate(-45deg) translate(5px, -5px)'};
    }
`;
