import styled, { CSSObject } from 'styled-components';
import {
    colorGray,
    colorWhite,
    mediaSm,
    mediaXs,
} from 'theme/theme-helper-selectors';

export const ProgramHelpContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    @media (${mediaSm}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (${mediaXs}) {
        grid-template-columns: 1fr;
    }
`;

export const ProgramHelpItem = styled.div<{
    color?: string;
    styles?: CSSObject;
}>`
    border-radius: 16px;
    background: ${({ color }) => (color ? color : '#21272f')};
    height: 520px;
    color: ${colorWhite};
    padding: 244px 32px 32px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 105px;
        background: linear-gradient(
            0deg,
            ${({ color }) => (color ? color : '#21272f')} 0%,
            rgba(33, 39, 47, 0) 94.53%
        );
        left: 0;
        top: 140px;
        z-index: 5;

        @media (${mediaSm}) {
            height: 90px;
            top: 110px;
        }
    }

    @media (${mediaSm}) {
        height: 400px;
        padding: 200px 16px 16px 16px;
    }

    ${({ styles }) => ({ ...styles })};
`;

export const ProgramHelpItemImage = styled.div<{ src?: string }>`
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: absolute;
    height: 244px;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 16px 16px 0 0;

    @media (${mediaSm}) {
        height: 200px;
    }
`;

export const ProgramHelpItemTitle = styled.h4`
    font-weight: 400;
    margin: 0 0 16px 0;
    font-size: 32px;
    line-height: 36px;

    @media (${mediaSm}) {
        font-size: 22px;
        line-height: 23px;
    }
`;

export const ProgramHelpItemDescription = styled.p`
    font-size: 21px;
    line-height: 24px;
    opacity: 0.8;
    font-weight: 200;

    @media (${mediaSm}) {
        font-size: 18px;
        line-height: 20px;
    }
`;

export const ProgramItemContent = styled.div`
    overflow: auto;
    height: 251px;

    ::-webkit-scrollbar {
        width: 9px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${colorGray};
        height: 30px;
        border-radius: 5px;
    }

    @media (${mediaSm}) {
        height: 180px;
    }
`;
