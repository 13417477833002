import styled from 'styled-components';
import {
    mediaMd,
    mediaSm,
    mediaXs,
} from '../../../theme/theme-helper-selectors';

export const Wrapper = styled.div`
    padding: 24px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1230px;
    width: 100%;

    @media (${mediaMd}) {
        align-items: flex-start;
        padding: 16px 20px 8px;
    }
    @media (${mediaSm}) {
        padding: 24px 20px 10px;
    }
    @media (${mediaXs}) {
        flex-direction: column;
        gap: 40px;
        padding: 20px 20px 10px;
    }
`;
