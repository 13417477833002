import { PrimaryButton } from 'components/button';
import styled, { CSSObject } from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 70px 0;

    &.nested {
        background-image: none;
        padding: 0 0 20px 0;
        justify-content: flex-start;
        gap: 20px;
        align-items: flex-end;

        .content-area {
            padding: 0;
        }

        h1 {
            width: auto;
        }

        @media (${mediaSm}) {
            align-items: flex-start;
            flex-direction: column;
            h1 {
                font-size: 36px;
                line-height: 43px;
                margin-bottom: 0;
            }
        }
    }

    @media (${mediaXs}) {
        flex-direction: column;
    }

    @media (${mediaSm}) {
        h1 {
            font-size: 50px;
            line-height: 1;
            margin-bottom: 30px;
        }
    }
`;

export const Title = styled.h1`
    width: 50%;
    font-size: 64px;
    font-weight: 400;
    line-height: 77px;
`;

export const MoreButton = styled(PrimaryButton)<{ styles?: CSSObject }>`
    margin: 15px auto 0;
    box-shadow: none;

    ${({ styles }) => ({ ...styles })};
`;
