import styled from 'styled-components';
import { colorWhite } from 'theme/theme-helper-selectors';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContentText = styled.h3`
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
`;

export const ButtonBlock = styled.div`
    display: flex;
    padding: 10px 0px;
    a {
        border-radius: 3px;
        &:hover {
            color: ${colorWhite};
        }
    }
`;
