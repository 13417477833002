import React, { ReactElement } from 'react';
import { Container, IconContainer, Subtitle, Title } from './styled';

export type CardType = 'primary' | 'secondary';

export type CardConfig = {
    icon: ReactElement;
    title: string;
    subtitle?: string;
};

export interface DescriptionCardProps {
    type: CardType;
    config: CardConfig;
}

export const DescriptionCard: React.FC<DescriptionCardProps> = ({
    type,
    config: { icon, title, subtitle },
}): JSX.Element => (
    <Container type={type}>
        <IconContainer>{icon}</IconContainer>

        <Title>{title}</Title>

        {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Container>
);

export default DescriptionCard;
