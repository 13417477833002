import React from 'react';
import { Content, LoaderWrapper } from './styled';
import { Loader } from 'kl-b2c-ui-kit';

const FullScreenLoader = () => (
    <Content>
        <LoaderWrapper>
            <Loader transparent variant={'static'} />
        </LoaderWrapper>
    </Content>
);

export default FullScreenLoader;
