import styled, { CSSObject } from 'styled-components';
import { colorPrimary, mediaSm } from 'theme/theme-helper-selectors';

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0 0 40px 0;

    @media (${mediaSm}) {
        flex-direction: column;
        align-items: flex-start;

        button {
            margin: 20px 0 0 0;
        }
    }
`;

export const Left = styled.div`
    width: 50%;

    @media (${mediaSm}) {
        width: 100%;
        margin: 0 0 20px 0;
    }
`;

export const PriceDescription = styled.p<{ styles?: CSSObject }>`
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;

    ul,
    ol {
        padding: 0;
        li {
            margin: 0 0 13px 0;
        }
    }

    ul {
        list-style: none;

        li {
            position: relative;
            padding: 0 0 0 10px;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #23d1ae;
                flex-shrink: 0;
            }
        }
    }

    ${({ styles }) => ({ ...styles })};
`;

export const Right = styled.div`
    width: 50%;

    @media (${mediaSm}) {
        width: 100%;
    }

    ${PriceDescription} {
        color: #2a3541b2;
    }
`;

export const Price = styled.div`
    &:not(&:last-child) {
        margin: 0 0 80px 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 10;

    @media (${mediaSm}) {
        flex-direction: column;
    }
`;

export const PriceTitle = styled.h3`
    color: ${colorPrimary};
    font-weight: 500;
    font-size: 64px;
    line-height: 52px;
    white-space: nowrap;
    margin: 0 0 20px 0;
`;
