import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 1240px;
    padding: 0 34px;
    @media (${mediaSm}) {
        padding: 0 20px;
    }
    @media (${mediaXs}) {
        padding: 0 15px;
    }
`;

export const ContentBody = styled.div`
    grid-column-gap: 44px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 180px;
    @media (${mediaSm}) {
        column-gap: 24px;
        margin-bottom: 80px;
    }
    @media (${mediaXs}) {
        grid-row-gap: 20px;
        grid-template-columns: 1fr;
        margin-bottom: 40px;
    }
`;

export const ContactCard = styled.div`
    border: 1px solid #adadad;
    border-radius: 40px;
    display: grid;
    padding: 63px;
    @media (max-width: 1600px) {
        padding: 60px;
    }
    @media (${mediaSm}) {
        border-radius: 20px;
        padding: 50px;
    }
    @media (${mediaXs}) {
        padding: 40px;
    }
`;

export const ContactImage = styled.div`
    align-items: flex-end;
    display: flex;
    height: 72px;
    justify-content: center;
    margin-bottom: 36px;
    background-image: url('/assets/images/alliance/schedule.svg');
    background-position-x: center;
    background-repeat: no-repeat;
    @media (${mediaSm}) {
        height: 60px;
    }
    @media (${mediaXs}) {
        height: 40px;
    }
`;

export const EmailImage = styled.div`
    align-items: flex-end;
    display: flex;
    height: 72px;
    justify-content: center;
    margin-bottom: 36px;
    background-image: url('/assets/images/alliance/email.svg');
    background-position-x: center;
    background-repeat: no-repeat;
    @media (${mediaSm}) {
        height: 60px;
    }
    @media (${mediaXs}) {
        height: 40px;
    }
`;

export const EmilLink = styled.a`
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    transition: all 0.5s ease;
    color: ${({ theme }) => theme.palette.white};
    @media (max-width: 1600px) {
        font-size: 32px;
    }
    @media (${mediaSm}) {
        font-size: 24px;
    }
    @media (${mediaXs}) {
        font-size: 16px;
    }
`;

export const CompareLink = styled(EmilLink)`
    text-decoration-line: underline;
`;

export const ContactUsBlock = styled.div`
    display: flex;
    justify-content: center;
`;

export const ContactUsLink = styled.a`
    font-size: 51px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    transition: all 0.5s ease;
    color: ${({ theme }) => theme.palette.white};
`;
