import React from 'react';
import { Icon } from 'kl-b2c-ui-kit';
import { useTranslation } from 'react-i18next';
import {
    CWrap,
    Image,
    Title,
    Link2Item,
    ArrowIcon,
    Go2DetailedBtn,
} from './styled';

export interface NewsListItem {
    imageURL: string;
    title: string;
    link: string;
}

export interface Props {
    data: NewsListItem;
    isFirstInBlock: boolean;
    secondary?: boolean;
}

export const NewsCard: React.FC<Props> = ({
    data: { imageURL, title, link },
    isFirstInBlock,
    secondary,
}): JSX.Element => {
    const { t } = useTranslation('pages/news');

    return (
        <CWrap className={isFirstInBlock ? 'isFirst' : ''}>
            <Link2Item className={isFirstInBlock ? 'isFirst' : ''} to={link}>
                <Image className={isFirstInBlock ? 'isFirst' : ''}>
                    <img src={imageURL} alt={title} />
                </Image>
                <Title
                    className={isFirstInBlock ? 'isFirst' : ''}
                    secondary={secondary}>
                    {title}
                </Title>
                <Go2DetailedBtn>
                    {t('moreCaption')}
                    <ArrowIcon>
                        <Icon name={'ArrowRight'} color={'primary'} size={24} />
                    </ArrowIcon>
                </Go2DetailedBtn>
            </Link2Item>
        </CWrap>
    );
};

export default React.memo(NewsCard);
