import styled from 'styled-components';
import { mediaXs } from 'theme/theme-helper-selectors';

export const Title = styled.h2`
    color: ${({ theme }) => theme.palette.black};
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: 52px;
    width: 50%;

    @media (${mediaXs}) {
        width: 100%;
    }
`;

export const DescriptionBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (${mediaXs}) {
        width: 100%;
    }
`;

export const SubTitle = styled.h3`
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    margin-bottom: 20px;
`;
