import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Container,
    MapDisclaimer,
    MapDisclaimerDescription,
    MapDisclaimerSubtitle,
    MapDisclaimerTitle,
} from './styled';
import { PageSection } from 'components';

const sectionConfig = {
    CWrap: {
        css: {
            '@media(max-width: 1023px)': {
                display: 'none',
            },
        },
    },
    ContentArea: {
        css: {
            display: 'block',
        },
    },
};

export const Map = () => {
    const { t } = useTranslation('pages/for-businesses');

    return (
        <PageSection config={sectionConfig}>
            <Container>
                <MapDisclaimer
                    styles={{
                        width: '163px',
                        top: 0,
                        left: '32%',
                        '@media(max-width: 1023px)': {
                            width: '115px',
                        },
                    }}>
                    <MapDisclaimerTitle>
                        {t('map-disclaimer-title-1')}
                    </MapDisclaimerTitle>
                    <MapDisclaimerSubtitle>
                        {t('map-disclaimer-subtitle-1')}
                    </MapDisclaimerSubtitle>
                    <MapDisclaimerDescription>
                        {t('map-disclaimer-description-1')}
                    </MapDisclaimerDescription>
                </MapDisclaimer>

                <MapDisclaimer
                    styles={{
                        width: '216px',
                        bottom: '37%',
                        left: 0,
                        '@media(max-width: 1279px)': {
                            left: '4%',
                            bottom: '18%',
                        },
                        '@media(max-width: 1023px)': {
                            width: '165px',
                        },
                    }}>
                    <MapDisclaimerTitle>
                        {t('map-disclaimer-title-2')}
                    </MapDisclaimerTitle>
                    <MapDisclaimerSubtitle>
                        {t('map-disclaimer-subtitle-2')}
                    </MapDisclaimerSubtitle>
                    <MapDisclaimerDescription>
                        {t('map-disclaimer-description-2')}
                    </MapDisclaimerDescription>
                </MapDisclaimer>

                <MapDisclaimer
                    styles={{
                        width: '208px',
                        bottom: '230px',
                        right: '19%',
                        '@media(max-width: 1023px)': {
                            width: '150px',
                            bottom: '10%',
                            right: '10%',
                        },
                    }}>
                    <MapDisclaimerTitle>
                        {t('map-disclaimer-title-3')}
                    </MapDisclaimerTitle>
                    <MapDisclaimerSubtitle>
                        {t('map-disclaimer-subtitle-3')}
                    </MapDisclaimerSubtitle>
                    <MapDisclaimerDescription>
                        {t('map-disclaimer-description-3')}
                    </MapDisclaimerDescription>
                </MapDisclaimer>
            </Container>
        </PageSection>
    );
};
