import { APIMap, EndPointName } from 'types';

export const apiDeclarations: APIMap = {
    [EndPointName.getSpeakers]: {
        name: EndPointName.getSpeakers,
        path: '/Speaker/GetSpeakers',
        type: 'GET',
    },
    [EndPointName.setSpeaker]: {
        name: EndPointName.setSpeaker,
        path: '/speaker/set',
        type: 'POST',
    },
};

export default apiDeclarations;
