import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 99999;
`;

export const LoaderWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
`;
