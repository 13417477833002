import React from 'react';
import { Root, Navbar } from './styled';
import { headerSubMenuItemsRu, headerSubMenuItemsEn } from 'consts';
import { MenuBlock } from 'components';
import { useTranslation } from 'react-i18next';
import { Lang } from 'types';
import { langMapper } from 'utils';

export interface Props {
    isVisible: boolean;
    onItemClick?: () => void;
    isCorporatePage?: boolean;
}

export const SubMenu: React.FC<Props> = ({
    isVisible,
    onItemClick,
    isCorporatePage,
}) => {
    const { i18n } = useTranslation();
    const curItems =
        langMapper[i18n.language] === Lang.Ru
            ? headerSubMenuItemsRu
            : headerSubMenuItemsEn;

    return (
        <Root
            isVisible={isVisible}
            isCorporatePage={isCorporatePage}
            data-at-selector={'pageHeader'}>
            <Navbar>
                <MenuBlock items={curItems} onItemClick={onItemClick} />
            </Navbar>
        </Root>
    );
};

export default SubMenu;
