import React, { FC } from 'react';
import { Content, Description, GoButton, Title } from './styled';
import DOMPurify from 'dompurify';

interface IProps {
    title: string;
    description: string;
    buttonText: string;
    action: () => void;
    color?: 'error' | 'primary' | undefined;
}

export const SendMessage: FC<IProps> = ({
    title,
    description,
    action,
    buttonText,
    color = 'primary',
}) => (
    <Content>
        <Title>{title}</Title>
        <Description
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description),
            }}
        />
        <GoButton color={color} size={'small'} onClick={action}>
            {buttonText}
        </GoButton>
    </Content>
);
