import { PrimaryButton } from 'components/button';
import styled from 'styled-components';
import { mediaXs } from 'theme/theme-helper-selectors';

export const HeaderBlock = styled.div`
    background: rgba(77, 255, 136, 1);
    width: 100%;
    position: relative;
    overflow: hidden;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 200px;
    @media (${mediaXs}) {
        padding-bottom: 150px;
    }
`;

export const BlueShare = styled.div`
    background: rgba(51, 187, 255, 0.8);
    box-shadow: 0px 0px 300px 300px rgba(51, 187, 255, 0.8);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    left: 449px;
    top: 400px;
    z-index: 1;
`;

export const Title = styled.h4`
    padding: 68px 0px 34px 0px;
    font-size: 72px;
    font-weight: 400;
    line-height: 72px;
    color: ${({ theme }) => theme.palette.black};
    @media (${mediaXs}) {
        font-size: 32px;
        line-height: 36px;
        padding-bottom: 16px;
    }
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.palette.black};
    font-size: 24px;
    font-weight: 300;
    line-height: 27px;
    padding-bottom: 34px;
    @media (${mediaXs}) {
        font-size: 18px;
        line-height: 21px;
        padding-bottom: 26px;
    }
`;

export const ButtonBlack = styled(PrimaryButton)`
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.black};
    &:hover:not(:disabled) {
        background: ${({ theme }) => theme.palette.black};
    }
    @media (max-width: 500px) {
        width: 100%;
    }
`;
