import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageSection, Paragraph } from 'components';
import {
    AdvantagesWrap,
    Title,
    CardsWrap,
    Card,
    Advantage,
    AdvantageValue,
    AdvantageLabel,
} from './styled';

const sectionConfig = {
    CWrap: {
        css: {
            paddingTop: '80px',

            '@media(max-width: 767px)': {
                paddingTop: '0',
            },
        },
    },
    ContentArea: {
        css: {
            flexDirection: 'column',
            gap: '40px',
        },
    },
};

const InfoBlock: React.FC = (): JSX.Element => {
    const { t } = useTranslation('pages/about');

    return (
        <PageSection config={sectionConfig}>
            <Title>{t('infoBlockTitle')}</Title>
            <AdvantagesWrap>
                <Advantage>
                    <AdvantageValue>
                        {t('infoBlockAdvantage1Value')}
                    </AdvantageValue>
                    <AdvantageLabel>
                        {t('infoBlockAdvantage1Label')}
                    </AdvantageLabel>
                </Advantage>
                <Advantage>
                    <AdvantageLabel>
                        {t('infoBlockAdvantage2_1Label')}
                    </AdvantageLabel>
                    <AdvantageValue>
                        {t('infoBlockAdvantage2Value')}
                    </AdvantageValue>
                    <AdvantageLabel>
                        {t('infoBlockAdvantage2_2Label')}
                    </AdvantageLabel>
                </Advantage>
                <Advantage>
                    <AdvantageValue>
                        {t('infoBlockAdvantage3Value')}
                    </AdvantageValue>
                    <AdvantageLabel>
                        {t('infoBlockAdvantage3Label')}
                    </AdvantageLabel>
                </Advantage>
            </AdvantagesWrap>
            <CardsWrap>
                <Card>{t('infoBlockEduCard1')}</Card>
                <Card>{t('infoBlockEduCard2')}</Card>
                <Card>{t('infoBlockEduCard3')}</Card>
                <Card>{t('infoBlockEduCard4')}</Card>
                <Card>{t('infoBlockEduCard5')}</Card>
                <Card>{t('infoBlockEduCard6')}</Card>
            </CardsWrap>
            <Paragraph>{t('infoBlockText')}</Paragraph>
        </PageSection>
    );
};

export default InfoBlock;
