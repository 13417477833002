import styled from 'styled-components';
import { colorWhite, mediaSm } from 'theme/theme-helper-selectors';

export const DoubleWhiteSkew = styled.div`
    position: relative;

    @media (${mediaSm}) {
        padding: 0;
    }

    &:after {
        content: '';
        width: 100%;
        height: 190px;
        position: absolute;
        background: ${colorWhite};
        bottom: 0;
        transform-origin: left bottom;
        transform: translateY(30%) skewY(3deg);
        border-radius: 0 0 40px 40px;
        z-index: 2;

        @media (${mediaSm}) {
            height: 100px;
        }
    }

    &:before {
        content: '';
        width: 100%;
        height: 200px;
        position: absolute;
        background: ${colorWhite};
        top: 0;
        left: 0;
        transform-origin: left bottom;
        transform: translateY(-160px) skewY(3deg);
        border-radius: 40px 40px 0 0;
        z-index: 2;

        @media (${mediaSm}) {
            transform: translateY(-80px) skewY(3deg);
        }
    }
`;

export const WhiteSkew = styled.div`
    position: relative;

    @media (${mediaSm}) {
        padding: 0;
    }

    &:after {
        content: '';
        width: 100%;
        height: 190px;
        position: absolute;
        background: ${colorWhite};
        bottom: 0;
        transform-origin: left bottom;
        transform: translateY(30%) skewY(3deg);
        border-radius: 0 0 40px 40px;
        z-index: 2;

        @media (${mediaSm}) {
            height: 100px;
        }
    }
`;

export const SectionTitleWrapper = styled.div`
    margin: 0 0 40px 0;

    @media (${mediaSm}) {
        margin: 0 0 20px 0;
    }
`;

export const SectionTitle = styled.h2`
    font-size: 54px;
    line-height: 52px;
    font-weight: 400;

    @media (${mediaSm}) {
        font-size: 39px;
        line-height: 41px;
    }
`;

export const TeaserWrapper = styled.div`
    background: #23272c;
    padding: 190px 0 90px;

    @media (${mediaSm}) {
        padding: 90px 0 40px;
    }
`;

export const ResultsWrapper = styled.div<{ noPadding?: boolean }>`
    padding: ${({ noPadding }) => (noPadding ? '0' : '100px 0 0 0;')};
`;
