import React from 'react';
import { footerBottomBlockMenuRu, footerBottomBlockMenuEn } from 'consts';
import { BottomBlockMenuItem, BottomBlockMenuList } from './styled';
import { useTranslation } from 'react-i18next';
import { Lang } from 'types';
import { langMapper } from 'utils';

const BottomBlockMenu: React.FC = () => {
    const { t, i18n } = useTranslation('translation');
    const langDomain = langMapper[i18n.language];

    return (
        <BottomBlockMenuList>
            {(i18n.language === 'ru'
                ? footerBottomBlockMenuRu
                : footerBottomBlockMenuEn
            ).map(({ link, i18nKey, dataAtSelector, isFile }) => (
                <BottomBlockMenuItem
                    key={window.crypto.randomUUID()}
                    href={`${link}`}
                    data-at-selector={dataAtSelector}
                    download={isFile}>
                    {t(i18nKey)}
                </BottomBlockMenuItem>
            ))}
            {langDomain === Lang.En && (
                <BottomBlockMenuItem
                    href={''}
                    onClick={(e) => {
                        e.preventDefault();
                        window.Cookiebot.renew();
                    }}
                    data-at-selector={'renew-cookies'}>
                    {t('cookies')}
                </BottomBlockMenuItem>
            )}
        </BottomBlockMenuList>
    );
};

export default BottomBlockMenu;
