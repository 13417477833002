import { AxiosResponse } from 'axios';
import React, {
    Context,
    createContext,
    PropsWithChildren,
    useContext,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getEventApiService } from 'services/api.factory';
import {
    Event,
    EventRegistration,
    EventShort,
    EventTag,
    GetEvent,
    GetEvents,
    Lang,
    Paging,
} from 'types';

interface EventContext {
    events?: Paging<EventShort>;
    event: Event | undefined;
    tags: EventTag[];
    loading: boolean;
    isError: boolean;
    errorText: string;
    getList: (params: GetEvents, append?: boolean) => Promise<void>;
    getDetailed: (params: GetEvent) => Promise<void>;
    getTags: (DomainType: Lang) => Promise<void>;
    register: (data: EventRegistration) => Promise<AxiosResponse>;
}

const EventContext: Context<EventContext> = createContext<EventContext>(
    {} as EventContext
);

const EventProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation('pages/events');
    const [eventsShort, setEventsShort] = useState<Paging<EventShort>>();
    const [tags, setTags] = useState<EventTag[]>([]);
    const [event, setEvent] = useState<Event>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState('');
    const api = getEventApiService();

    const getList = (params: GetEvents, append?: boolean): Promise<void> => {
        setLoading(true);
        return api
            .getList(params)
            .then(
                (response: { data: Paging<EventShort> }) => {
                    const { data } = response;
                    setEventsShort(
                        append
                            ? {
                                  count: data.count,
                                  items: [
                                      ...(eventsShort?.items || []),
                                      ...data.items,
                                  ],
                              }
                            : data
                    );
                },
                (e: unknown) => {
                    setIsError(true);

                    if (e instanceof Error) {
                        setErrorText(e.message);
                    } else {
                        setErrorText(t('error'));
                    }
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const getDetailed = (params: GetEvent): Promise<void> => {
        setLoading(true);
        setIsError(false);
        return api
            .getDetailed(params)
            .then(
                (data) => {
                    setEvent(data?.data ?? undefined);
                },
                (e) => {
                    setIsError(true);
                    throw e;
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const getTags = (domainType: Lang): Promise<void> => {
        setLoading(true);
        return api
            .getTags(domainType)
            .then(
                (data) => {
                    setTags(data?.data ?? []);
                },
                (e: unknown) => {
                    setIsError(true);

                    if (e instanceof Error) {
                        setErrorText(e.message);
                    } else {
                        setErrorText(t('error'));
                    }
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const register = (data: EventRegistration): Promise<AxiosResponse> =>
        api.register(data).catch(() => new Promise((_, reject) => reject()));

    const memoValue = useMemo(
        () => ({
            events: eventsShort,
            event,
            tags,
            loading,
            isError,
            errorText,
            getList,
            getDetailed,
            getTags,
            register,
        }),
        [eventsShort, event, loading]
    );

    return (
        <EventContext.Provider value={memoValue}>
            {children}
        </EventContext.Provider>
    );
};

export const useEvent = () => useContext(EventContext);
export default EventProvider;
