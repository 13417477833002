import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageSection } from 'components';
import { CardsWrap, Title, Card } from './styled';

const sectionConfig = {
    CWrap: {
        css: {
            padding: '40px 0',
        },
    },
    ContentArea: {
        css: {
            flexDirection: 'column',
            gap: '40px',
        },
    },
};

const AboutCourses: React.FC = (): JSX.Element => {
    const { t } = useTranslation('pages/about');

    return (
        <PageSection config={sectionConfig}>
            <Title>{t('aboutCoursesTitle')}</Title>
            <CardsWrap>
                <Card>{t('aboutCoursesCard1')}</Card>
                <Card>{t('aboutCoursesCard2')}</Card>
                <Card>{t('aboutCoursesCard3')}</Card>
                <Card>{t('aboutCoursesCard4')}</Card>
                <Card>{t('aboutCoursesCard5')}</Card>
            </CardsWrap>
        </PageSection>
    );
};

export default AboutCourses;
