import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const ContentBlock = styled.div`
    display: flex;
    width: 100%;
    min-height: 300px;
    position: relative;
    flex-direction: column;
    padding-top: 80px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (${mediaXs}) {
        margin-bottom: 100px;
    }
`;
export const BackgroundBlock = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-image: url(/assets/images/medium.svg);
    z-index: -1;
    border-radius: 30px;
    transform: matrix(1, 0.06, 0, 1, 0, 0);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

export const DescriptionBlock = styled.div`
    padding-left: 220px;
    display: flex;
    flex-direction: column;
    padding-top: 150px;
    padding-bottom: 100px;
    @media (${mediaSm}) {
        padding-left: 0px;
        padding-top: 50px;
        padding-bottom: 0px;
    }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.palette.black};
    font-size: 81px;
    font-weight: 400;
    line-height: 81px;
    padding-bottom: 40px;
    max-width: 400px;
    @media (${mediaXs}) {
        font-size: 32px;
        font-weight: 500;
        line-height: 36px;
        padding-bottom: 24px;
    }
`;

export const Description = styled.p`
    padding-bottom: 40px;
    color: ${({ theme }) => theme.palette.black};
    font-size: 24px;
    font-weight: 300;
    line-height: 28.13px;
    max-width: 450px;
    @media (${mediaXs}) {
        font-size: 18px;
        line-height: 21px;
    }
`;
