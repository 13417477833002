import styled, { css } from 'styled-components';
import {
    colorBackground,
    colorMenuBackground,
    mediaSm,
    shadowDropdown,
} from 'theme/theme-helper-selectors';

const rootDropdownContentMixin = css`
    z-index: 100;
    position: absolute;
    box-shadow: ${shadowDropdown};

    @media (${mediaSm}) {
        box-shadow: none;
        position: relative;
    }
`;

export const DropdownContent = styled.div<{ root: boolean }>`
    min-width: 190px;
    display: none;
    background-color: ${colorBackground};
    padding: 0 !important;

    ${({ root }) => root && rootDropdownContentMixin}

    @media (${mediaSm}) {
        background: ${colorMenuBackground};
        margin-left: 20px;
        position: static;
        transform: translate(0);
    }
`;
