import { CourseDetail, Lang } from 'types';

export const courseRedirectResolver = (
    course: CourseDetail,
    langDomain: Lang,
    action: () => void
) => {
    if (course?.isAwareness) {
        return window.open(
            langDomain === Lang.Ru
                ? 'https://www.kaspersky.ru/enterprise-security/contact?utm_campaign=kaspersky-academy&utm_source=academy-cource&utm_medium=referral&utm_content=ksa'
                : 'https://www.kaspersky.com/enterprise-security/contact?utm_campaign=kaspersky-academy&utm_source=academy-cource&utm_medium=referral&utm_content=ksa',
            '_blank',
            'noopener,noreferrer'
        );
    } else if (course?.isProduct) {
        return window.open(
            langDomain === Lang.Ru
                ? ' https://locator.kaspersky.com/learning/?locale=ru'
                : ' https://locator.kaspersky.com/learning/?locale=en',
            '_blank',
            'noopener,noreferrer'
        );
    }

    return action();
};
