import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { CheckboxRow, Form, SuccessMsg, Title, RecaptchaNote } from './styled';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Checkbox } from 'kl-b2c-ui-kit';
import { useFormContext, Controller, FieldValues } from 'react-hook-form';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useSettings } from 'contexts/settings.context';
import { PrimaryButton } from 'components/button';
import { CSSObject } from 'styled-components';
import { PrivacyPolicy } from 'containers/pages';

type Policy = {
    name: string;
    label: string;
    required?: boolean;
};

type FormBaseProps = {
    policies: Policy[];
    submit: (data: FieldValues) => unknown;
    loading?: boolean;
    success: boolean | undefined;
    resources?: {
        submitLabel?: string;
        successMessage?: string;
        title?: string;
    };
    styles?: CSSObject;
};

const FormBase = (props: PropsWithChildren<FormBaseProps>) => {
    const { policies, children, submit, loading, resources, success, styles } =
        props;
    const captchaRef = useRef<ReCAPTCHA>(null);
    const [doi, setDoi] = useState<string>();
    const { t } = useTranslation('translation');
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useFormContext();
    const { settings } = useSettings();

    const PPRef = useRef<HTMLDivElement>(null);

    const onSubmit = async (data: FieldValues) => {
        const captchaCode = await captchaRef?.current?.executeAsync();
        const status = (await submit({
            ...data,
            captchaCode,
            agreement: {
                ...data.agreement,
                privacyPolicyText: PPRef?.current?.innerHTML,
            },
        })) as number;

        if (status === 202) setDoi(t('success-confirm'));
    };

    useEffect(
        () => () => {
            // This will reset form on close/or submit
            reset();
        },
        []
    );

    const getMessage = (): string =>
        doi
            ? doi
            : success === true
            ? resources?.successMessage
                ? resources.successMessage
                : t('success')
            : t('oops');

    return (
        <>
            <Form
                loading={loading}
                styles={styles}
                onSubmit={handleSubmit(onSubmit)}>
                {resources?.title && <Title>{resources?.title}</Title>}
                {success !== undefined ? (
                    <SuccessMsg>{getMessage()}</SuccessMsg>
                ) : (
                    <>
                        {children}
                        {policies.map(({ name, label, required }) => (
                            <CheckboxRow key={name}>
                                <Controller
                                    name={name}
                                    control={control}
                                    rules={{ required }}
                                    defaultValue={false}
                                    render={({
                                        field: { onChange, value },
                                    }) => {
                                        const [root, key] = name.split('.');
                                        return (
                                            <Checkbox
                                                value={value}
                                                size={'large'}
                                                onChange={(value) => {
                                                    onChange(value);
                                                }}
                                                isValid={
                                                    !(
                                                        errors &&
                                                        errors[root] &&
                                                        // @ts-ignore
                                                        errors[root][key]
                                                    )
                                                }>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(
                                                            label,
                                                            {
                                                                ADD_ATTR: [
                                                                    'target',
                                                                ],
                                                            }
                                                        ),
                                                    }}
                                                />
                                            </Checkbox>
                                        );
                                    }}
                                />
                            </CheckboxRow>
                        ))}

                        {settings && (
                            <>
                                <ReCAPTCHA
                                    size={'invisible'}
                                    ref={captchaRef}
                                    badge={'bottomright'}
                                    sitekey={settings.captchaSiteKey}
                                />

                                <RecaptchaNote>
                                    This site is protected by reCAPTCHA and the
                                    Google&nbsp;
                                    <a
                                        target={`_blank`}
                                        rel={`noopener noreferrer`}
                                        href={`https://policies.google.com/privacy`}>
                                        Privacy Policy
                                    </a>
                                    &nbsp; and&nbsp;
                                    <a
                                        target={`_blank`}
                                        rel={`noopener noreferrer`}
                                        href={`https://policies.google.com/terms`}>
                                        Terms of Service
                                    </a>
                                </RecaptchaNote>
                            </>
                        )}

                        <PrimaryButton type={'submit'}>
                            {resources?.submitLabel
                                ? resources.submitLabel
                                : t('saveBtnCaption')}
                        </PrimaryButton>
                    </>
                )}
            </Form>
            <PrivacyPolicy isHidden ref={PPRef} />
        </>
    );
};

export default FormBase;
