import { AxiosResponse } from 'axios';
import { ApiService } from 'services';
import {
    GetProfession,
    GetProfessions,
    IProfessionService,
    Paging,
    ProfessionDetail,
    ProfessionRegistration,
    ProfessionTable,
} from 'types';
import { urlEncodeUtil } from 'utils';

export class ProfessionApi extends ApiService implements IProfessionService {
    getList(
        params: GetProfessions
    ): Promise<AxiosResponse<Paging<ProfessionTable>>> {
        return this.request<Paging<ProfessionTable>>({
            method: 'GET',
            url: '/Profession/GetItems',
            params,
            paramsSerializer: urlEncodeUtil,
        }).then((response) => response);
    }
    getDetailed(
        params: GetProfession
    ): Promise<AxiosResponse<ProfessionDetail>> {
        return this.request<ProfessionDetail>({
            method: 'GET',
            url: '/Profession/GetItem',
            params,
            paramsSerializer: urlEncodeUtil,
        }).then((response) => response);
    }
    public register(data: ProfessionRegistration): Promise<AxiosResponse> {
        return this.request({
            method: 'POST',
            url: '/ProfessionForm/Add',
            data,
        });
    }
}
