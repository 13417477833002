import styled, { css } from 'styled-components';
import { Palette } from 'kl-b2c-ui-kit';
import { mediaSm } from 'theme/theme-helper-selectors';

export type TagType = 'black' | 'primary';

export const CWrap = styled.span<{ type: TagType; disabled?: boolean }>`
    font: inherit;
    font-weight: 400;
    cursor: ${({ disabled = false }) => (!disabled ? 'pointer' : 'auto')};
    letter-spacing: -0.01em;
    line-height: 29px;
    padding: 0;
    transition: all 0.5s ease;

    ${({ type }) => getTypeMixin(type)}
    color: ${({ type, theme }) => getColor(type, theme.palette)};

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.palette.teal};
        }
    }

    &.isSelected {
        color: ${({ theme }) => theme.palette.teal};
    }

    ${({ disabled }) => {
        if (disabled)
            return `
        font-size: 24px;`;
    }}
`;

const getColor = (type: TagType, palette: Palette) => {
    switch (type) {
        case 'primary':
            return palette.teal;
        case 'black':
            return palette.black;
        default:
            'inherit';
    }
};

const getTypeMixin = (type: TagType) => {
    switch (type) {
        case 'black': {
            return {
                fontSize: 24,
                lineHeight: '29px',
            };
        }
        case 'primary': {
            return css`
                font-size: 30px;
                line-height: 50px;

                @media (${mediaSm}) {
                    font-size: 18px;
                    line-height: 24px;
                }
            `;
        }
    }
};
