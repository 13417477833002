import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseDetail, CourseTable } from 'types';
import {
    Announcement,
    Content,
    Description,
    DurationText,
    Tag,
    TagBlock,
    Title,
} from './styled';
import { CourseDirection, EventType, Lang } from 'types/enums';
import DOMPurify from 'dompurify';
import { ButtonBlock, ButtonClick, ButtonLink } from '../styled';
import { langMapper } from 'utils';

interface IProps {
    course: CourseTable;
    openCourseRegister?: (course: CourseDetail) => void;
}

export const CourseItem: FC<IProps> = ({ course, openCourseRegister }) => {
    const { t, i18n } = useTranslation(['common/shared', 'pages/courses']);
    const langDomain = langMapper[i18n.language];
    return (
        <Content>
            <Description>
                <TagBlock>
                    {course?.directionTypes?.findIndex(
                        (r) => r === CourseDirection.FreeCourse
                    ) >= 0 && <Tag isGreen>{t('common/shared:free')}</Tag>}
                    {course.studyForms.map((r) => (
                        <Tag
                            white={r === EventType.Offline}
                            key={`study-form-key-${r}`}>
                            {t(`common/shared:studyForm-${r}`)}
                        </Tag>
                    ))}
                </TagBlock>
                <Title to={`/course/${course.groupName}`}>{course.name}</Title>
                <Announcement
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            course.announcementDescription
                        ),
                    }}
                />
                <DurationText>{course?.duration ?? ''}</DurationText>
                <ButtonBlock>
                    {course.groupName !== 'cyberhygiene' &&
                        (course.isAwareness ? (
                            <ButtonLink
                                as={'a'}
                                target={'_blank'}
                                href={
                                    langDomain === Lang.Ru
                                        ? 'https://www.kaspersky.ru/enterprise-security/contact?utm_campaign=kaspersky-academy&utm_source=academy-cource&utm_medium=referral&utm_content=ksa'
                                        : 'https://www.kaspersky.com/enterprise-security/contact?utm_campaign=kaspersky-academy&utm_source=academy-cource&utm_medium=referral&utm_content=ksa'
                                }>
                                {t('common/shared:buy')}
                            </ButtonLink>
                        ) : course.isProduct ? (
                            <ButtonLink
                                as={'a'}
                                target={'_blank'}
                                href={
                                    langDomain === Lang.Ru
                                        ? ' https://locator.kaspersky.com/learning/?locale=ru'
                                        : ' https://locator.kaspersky.com/learning/?locale=en'
                                }>
                                {t('pages/courses:find-a-provider')}
                            </ButtonLink>
                        ) : (
                            <ButtonClick
                                onClick={() =>
                                    openCourseRegister &&
                                    openCourseRegister(course as CourseDetail)
                                }>
                                {t('common/shared:buy')}
                            </ButtonClick>
                        ))}

                    <ButtonLink to={`/course/${course.groupName}`}>
                        {t('common/shared:know-more')}
                    </ButtonLink>
                </ButtonBlock>
            </Description>
        </Content>
    );
};
