import React, { FC, useEffect, useState } from 'react';
import { TabContent, TabHeader, TabMenu, TabMenuItem, TabBody } from './styled';

type Props = {
    tabs: {
        title: JSX.Element;
        body: JSX.Element;
        isActive?: boolean;
        key: string;
    }[];
    styles?: {
        rootStyles?: string;
        backgroundColor?: string;
        headerStyles?: string;
        menuStyles?: string;
        menuItemStyles?: string;
        bodyStyles?: string;
    };
    onChange?: (value: number) => void;
};

export const Tab: FC<Props> = ({ tabs, styles, onChange }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [items, setItems] = useState(tabs);

    const setActive = (newActiveIndex: number) => {
        const newState = items.map((item, index) => {
            if (index === newActiveIndex) item.isActive = true;
            else item.isActive = false;

            return { ...item };
        });
        setItems([...newState]);
        setActiveIndex(newActiveIndex);

        if (onChange) {
            onChange(newActiveIndex);
        }
    };

    useEffect(() => {
        let index = tabs.findIndex((r) => r.isActive);
        if (index < 0) index = 0;
        setActive(index);
    }, []);

    return (
        <TabContent {...styles}>
            <TabHeader {...styles}>
                <TabMenu {...styles}>
                    {tabs.map((item, index) => (
                        <TabMenuItem
                            className={index === activeIndex ? 'is-active' : ''}
                            isActive={index === activeIndex}
                            key={item.key}
                            onClick={() => setActive(index)}
                            {...styles}>
                            {item.title}
                        </TabMenuItem>
                    ))}
                </TabMenu>
            </TabHeader>
            <TabBody {...styles}>{tabs[activeIndex].body}</TabBody>
        </TabContent>
    );
};
