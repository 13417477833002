/* eslint-disable import/no-named-as-default-member */
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { languages } from 'consts/languages';

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: process.env.REACT_APP_LOCALE,
        defaultNS: 'translation',
        load: 'languageOnly',
        supportedLngs: Object.keys(languages),
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;
