import React, { FC, useState } from 'react';
import { useCourse } from 'contexts/course.context';
import { useTranslation } from 'react-i18next';
import { PageSection } from 'components';
import DOMPurify from 'dompurify';
import { langMapper } from 'utils/lang-convert';
import { ConditionCategory, Lang } from 'types/enums';
import {
    Content,
    ContentTitle,
    SkewWrapper,
    Switch,
    SwitchItem,
    TitleWrapper,
    Wrapper,
} from 'containers/pages/profession-item/components/conditions/styled';
import { ButtonContainer, Footer, FooterTitle, ListWrapper } from './styled';
import { SectionTitle } from '../../styled';
import { Button } from 'kl-b2c-ui-kit';
import { courseRedirectResolver } from 'utils';

interface IProps {
    onOpenRegisterModal: () => void;
}

const sectionConfig = {
    CWrap: {
        css: {},
    },
    ContentArea: {
        css: {
            display: 'block',
            padding: '0 20px 0',
            overflow: 'hidden',
        },
    },
};

export const Program: FC<IProps> = ({ onOpenRegisterModal }) => {
    const { t, i18n } = useTranslation('pages/courses');
    const { course } = useCourse();
    const langDomain = langMapper[i18n.language];

    const [conditionCategory, setConditionCategory] =
        useState<ConditionCategory>(ConditionCategory.Knowledge);

    if (!course) {
        return null;
    }

    return (
        <SkewWrapper>
            <PageSection config={sectionConfig}>
                <Wrapper
                    styles={{
                        backgroundImage: 'none',
                        background: '#F4F7FA',
                        position: 'relative',
                        zIndex: 5,
                    }}>
                    <TitleWrapper>
                        <SectionTitle>{t('program')}</SectionTitle>
                        <Switch>
                            <SwitchItem
                                onClick={() =>
                                    setConditionCategory(
                                        ConditionCategory.Knowledge
                                    )
                                }
                                isActive={
                                    conditionCategory ===
                                    ConditionCategory.Knowledge
                                }>
                                {t('topic')}
                            </SwitchItem>
                            <SwitchItem
                                onClick={() =>
                                    setConditionCategory(
                                        ConditionCategory.Skill
                                    )
                                }
                                isActive={
                                    conditionCategory ===
                                    ConditionCategory.Skill
                                }>
                                {t('practicePart')}
                            </SwitchItem>
                        </Switch>
                    </TitleWrapper>

                    <Content>
                        <ContentTitle styles={{ color: '#1D1D1B' }}>
                            {conditionCategory === ConditionCategory.Knowledge
                                ? t('topic')
                                : t('practicePart')}
                        </ContentTitle>
                        <ListWrapper
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    conditionCategory ===
                                        ConditionCategory.Knowledge
                                        ? course.topic
                                        : course.practicePart
                                ),
                            }}
                        />
                    </Content>

                    {(course?.isAwareness ||
                        course?.additionalProgram?.fileLink) && (
                        <Footer>
                            <FooterTitle>{t('course-more')}</FooterTitle>
                            <ButtonContainer>
                                <Button
                                    onClick={() => {
                                        if (course?.isAwareness) {
                                            return window.open(
                                                langDomain === Lang.Ru
                                                    ? 'https://www.kaspersky.ru/enterprise-security'
                                                    : 'https://www.kaspersky.com/enterprise-security',
                                                '_blank',
                                                'noopener,noreferrer'
                                            );
                                        } else if (
                                            course?.additionalProgram?.fileLink
                                        )
                                            return window.open(
                                                course?.additionalProgram
                                                    ?.fileLink,
                                                '_blank',
                                                'noopener,noreferrer'
                                            );
                                    }}
                                    variant={'outlined'}
                                    color={'primary'}
                                    size={'large'}>
                                    {t('more')}
                                </Button>
                                <Button
                                    onClick={() =>
                                        courseRedirectResolver(
                                            course,
                                            langDomain,
                                            onOpenRegisterModal
                                        )
                                    }
                                    variant={'contained'}
                                    color={'primary'}
                                    size={'large'}>
                                    {course.isProduct
                                        ? t('pages/courses:find-a-provider')
                                        : t('pages/courses:request-button')}
                                </Button>
                            </ButtonContainer>
                        </Footer>
                    )}
                </Wrapper>
            </PageSection>
        </SkewWrapper>
    );
};
