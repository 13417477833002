import styled, { CSSObject } from 'styled-components';
import {
    colorTeal,
    colorWhite,
    mediaMd,
    mediaSm,
} from 'theme/theme-helper-selectors';

export const Container = styled.div`
    position: relative;
    height: 890px;
    background-image: url(/assets/images/bussiness/map.png);
    background-repeat: no-repeat;
    background-size: 115%;
    background-position: center -80px;

    @media (${mediaMd}) {
        height: 600px;
        background-size: contain;
        background-position: center;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    @media (${mediaSm}) {
        background-size: cover;
        background-position: center;
        flex-direction: column;
        height: auto;
        padding: 0 0 40px 0;
    }
`;

export const MapDisclaimer = styled.div<{ styles: CSSObject }>`
    padding: 20px;
    background: #2a3039;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    z-index: 7;

    ${({ styles }) => ({ ...styles })};
`;

export const MapDisclaimerTitle = styled.h4`
    color: ${colorTeal};
    font-size: 54px;
    line-height: 64px;
    font-weight: 300;

    @media (${mediaMd}) {
        font-size: 40px;
        line-height: 52px;
    }
`;

export const MapDisclaimerSubtitle = styled.p`
    color: ${colorWhite};
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;

    @media (${mediaMd}) {
        font-size: 21px;
        line-height: 24px;
    }
`;

export const MapDisclaimerDescription = styled.p`
    color: #6a727a;
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;

    @media (${mediaMd}) {
        font-size: 16px;
        line-height: 19px;
    }
`;
