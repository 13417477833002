import {
    Event,
    EventRegistration,
    EventShort,
    EventTag,
    GetEvent,
    GetEvents,
    Lang,
    Paging,
} from 'types';
import { ApiService } from 'services';
import { AxiosResponse } from 'axios';
import { urlEncodeUtil } from 'utils';

export class EventApi extends ApiService {
    public getList(
        params: GetEvents
    ): Promise<AxiosResponse<Paging<EventShort>>> {
        return this.request<{ count: 0; items: EventShort[] }>({
            method: 'GET',
            url: '/Event/GetItems',
            params,
            paramsSerializer: urlEncodeUtil,
        }).then(
            (response: AxiosResponse<{ count: 0; items: EventShort[] }>) =>
                response
        );
    }

    public getDetailed(params: GetEvent): Promise<AxiosResponse<Event>> {
        return this.request<Event>({
            method: 'GET',
            url: '/Event/GetItem',
            params,
            paramsSerializer: urlEncodeUtil,
        }).then((response: AxiosResponse<Event>) => response);
    }

    public getTags(DomainType: Lang): Promise<AxiosResponse<EventTag[]>> {
        return this.request<EventTag[]>({
            method: 'GET',
            url: '/Event/GetTags',
            params: {
                DomainType,
            },
        }).then((response: AxiosResponse<EventTag[]>) => response);
    }

    public register(data: EventRegistration): Promise<AxiosResponse> {
        return this.request({
            method: 'POST',
            url: '/EventForm/Add',
            data,
        });
    }
}
