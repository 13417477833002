import React from 'react';
import { HamburgerButton, HamburgerLine } from './styled';

export interface Props {
    isMobile: boolean;
    isPressed: boolean;
    handleOnClick: () => void;
}

export const HeaderHamburger: React.FC<Props> = ({
    isMobile,
    isPressed,
    handleOnClick,
}): JSX.Element => (
    <HamburgerButton
        title={'Menu'}
        type={'button'}
        isMobile={isMobile}
        isMobileMenuOpened={isPressed}
        onClick={() => handleOnClick()}>
        <HamburgerLine isPressed={isPressed} isMobile={isMobile} />
        <HamburgerLine isPressed={isPressed} isMobile={isMobile} />
        <HamburgerLine isPressed={isPressed} isMobile={isMobile} />
    </HamburgerButton>
);

export default HeaderHamburger;
