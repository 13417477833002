import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.section`
    margin-bottom: 90px;
    @media (${mediaSm}) {
        margin-bottom: 60px;
    }
    @media (${mediaXs}) {
        margin-bottom: 30px;
    }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.palette.black};
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.03em;
    line-height: 52px;
    margin-bottom: 38px;
    text-align: left;
    @media (${mediaXs}) {
        font-size: 36px;
        line-height: 43px;
    }
`;

export const ListContent = styled.ul`
    grid-gap: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 5;

    @media (${mediaSm}) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (${mediaXs}) {
        grid-gap: 16px;
        grid-template-columns: 1fr;
    }
`;

export const ListItem = styled.li`
    grid-column-gap: 20px;
    background: ${({ theme }) => theme.palette.white};
    border-radius: 10px;
    box-shadow: ${({ theme }) => theme.shadows.card.hover};
    display: grid;
    grid-template-columns: 74px 1fr;
    min-height: 186px;
    padding: 22px 20px;
`;

export const ListItemImage = styled.div`
    img {
        height: auto;
        max-width: 100%;
        display: block;
        width: 64px;
    }
`;

export const ListItemText = styled.div`
    h3 {
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        margin-bottom: 10px;
    }
    p {
        color: ${({ theme }) => theme.palette.black};
        font-size: 16px;
        font-weight: 300;
        line-height: 19px;
    }
`;

const getWidth = (index: number, count: number) => {
    switch (index) {
        case 0:
        case 1:
        case 2:
            return '33.3%';
            break;
        case 3:
            if (count === 5) {
                return '50%';
            } else {
                return '100%';
            }
            break;
        case 4:
        case 5:
            if (count === 5) {
                return '50%';
            } else {
                return '50%';
            }
            break;
    }
};

export const ResultWrapper = styled.div<{ index: number; count: number }>`
    padding: 20px 12px;

    width: ${({ index, count }) => getWidth(index, count)};

    @media (${mediaSm}) {
        width: 100%;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 10;
    margin: -20px -12px 20px -12px;
`;

export const ResultItem = styled.div`
    width: 100%;
    border-radius: 16px;
    padding: 112px 24px 24px;
    position: relative;
    height: 100%;

    background: #f0f4f7;
`;

export const ResultItemImage = styled.div<{ src?: string }>`
    width: 64px;
    height: 64px;
    position: absolute;
    top: 24px;
    left: 24px;

    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
`;

export const ResultItemTitle = styled.h4`
    font-weight: 400;
    margin: 0 0 16px 0;
    font-size: 24px;
    line-height: 27px;

    @media (${mediaSm}) {
        font-size: 22px;
        line-height: 23px;
    }
`;

export const ResultItemDescription = styled.p`
    font-size: 16px;
    line-height: 18px;
    opacity: 0.5;
    font-weight: 300;

    @media (${mediaSm}) {
        font-size: 18px;
        line-height: 20px;
    }
`;
