import styled, { CSSObject, css } from 'styled-components';
import { mediaXs } from 'theme/theme-helper-selectors';

export const Form = styled.form<{ loading?: boolean; styles?: CSSObject }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc5NTUnIGhlaWdodD0nODY0JyBmaWxsPSdub25lJz48cGF0aCBmaWxsPScjZmZmJyBkPSdtMTg0LjI1MiA4MDAtMTY5LjctMjg4Ljc2OEMtLjU0IDQ4NS41NTItMy42MzIgNDU0LjM3NiA2LjA4NSA0MjUuODZMMTI1LjM4OCA3NS43NzdjMTQuMzE1LTQyLjAwNCA1My41OTctNzAuNTMyIDk3LjYxLTcwLjg4NUw3NjQuNDEyLjUzOWM0NS44LS4zNjkgODUuODUyIDI5LjgxNiA5OC4xMjkgNzMuOTUxbDg4LjU1NSAzMTguMzcxYzcuMTMxIDI1LjYzNyA0LjAyIDUzLjE5OC04LjY3NyA3Ni44NjFMNzYwLjYxMSA4MDguNTcxYy0xOC44MDggMzUuMDUzLTU1LjgwNCA1Ni40NjQtOTUuMTY5IDU1LjA3N2wtMzk3Ljc2MS0xNC4wMTVjLTM0LjU3MS0xLjIxOS02NS45NDctMTkuODg0LTgzLjQyOS00OS42MzNaJy8+PC9zdmc+');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 880px;
    padding: 60px 0 40px;
    position: relative;
    width: 880px;

    ${({ styles }) => ({ ...styles })};

    &:before {
        background: linear-gradient(
                144.23deg,
                rgba(126, 255, 51, 0.4),
                rgba(35, 209, 174, 0.4) 79.06%
            ),
            ${({ theme }) => theme.palette.teal};
        content: '';
        display: block;
        filter: blur(70px);
        height: 100%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 755px;
        z-index: -1;
    }

    @media (${mediaXs}) {
        width: 100%;
        background: ${({ theme }) => theme.palette.white};
    }
    ${({ loading }) =>
        loading &&
        css`
            pointer-events: none;
            opacity: 0.6;
        `}

    ${({ styles }) => ({ ...styles })};
`;

export const CheckboxRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 574px;
    gap: 16px;

    @media (${mediaXs}) {
        max-width: 100%;
    }
`;

export const SuccessMsg = styled.p`
    font-size: 36px;
    line-height: 52px;
    position: relative;
    text-align: center;
`;

export const Title = styled.h2`
    font-size: 48px;
    line-height: 52px;
    position: relative;
    text-align: center;
`;

export const RecaptchaNote = styled.div`
    margin-top: -40px;
    color: #999;
    font-size: 16px;
    line-height: 19px;
    max-width: 500px;

    a {
        font-weight: 700;
        color: ${({ theme }) => theme.palette.black};
    }
`;
