import styled from 'styled-components';
import { mediaXs } from '../../../theme/theme-helper-selectors';

export const BottomBlockMenuList = styled.div`
    display: flex;
    padding: 0;
    margin: 0;
    gap: 30px;

    @media (${mediaXs}) {
        flex-direction: column;
    }
`;

export const BottomBlockMenuItem = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    color: #000;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    text-decoration-line: underline;
    transition: all 0.5s ease;
`;
