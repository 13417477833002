import { Button } from 'kl-b2c-ui-kit';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorBlack, mediaXs } from 'theme/theme-helper-selectors';

export const CentralBlock = styled.div`
    width: 100%;
    max-width: 1240px;
    padding: 0px 20px;
    position: relative;
    z-index: 10;
`;

export const ButtonBlock = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 24px;
    gap: 14px;
    @media (${mediaXs}) {
        flex-direction: column;
    }
`;

export const ButtonLink = styled(Link)`
    padding: 14px 24px;
    border: 2px solid rgba(167, 174, 182, 1);
    border-radius: 8px;
    color: ${colorBlack};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;

    &:hover {
        color: ${colorBlack};
    }

    @media (${mediaXs}) {
        font-size: 14px;
        line-height: 16px;
        padding: 10px 24px;
    }
`;

export const ButtonClick = styled(Button)`
    padding: 14px 24px;
    border: 2px solid rgba(167, 174, 182, 1);
    border-radius: 8px;
    color: ${colorBlack};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    background-color: transparent;
    letter-spacing: 0;

    &:hover {
        color: ${colorBlack};
    }

    @media (${mediaXs}) {
        font-size: 14px;
        line-height: 16px;
        padding: 10px 24px;
    }
`;
