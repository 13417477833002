import { TagType } from 'components/tag/styled';
import styled from 'styled-components';

export const CWrap = styled.div<{ type?: TagType; fullScreen?: boolean }>`
    width: ${({ fullScreen }) => (!fullScreen ? '50%' : '100%')};
    margin-bottom: ${({ type }) => (type === 'black' ? 0 : '6px')};
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
`;
