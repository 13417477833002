import React from 'react';
import { CardConfig } from 'components/description-card/DescriptionCard';
import { ReactComponent as Diploma } from 'assets/icons/diploma.svg';
import { ReactComponent as Eye } from 'assets/icons/search-eye.svg';
import { ReactComponent as Globe } from 'assets/icons/globe.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';

export const DESCRIPTION_CARDS: CardConfig[] = [
    {
        title: 'intro-description-card-1',
        icon: <Diploma />,
    },
    {
        title: 'intro-description-card-2',
        icon: <Eye />,
    },
    {
        title: 'intro-description-card-3',
        icon: <Globe />,
    },
    {
        title: 'intro-description-card-4',
        icon: <Lock />,
    },
];
