import { RegisterOptions } from 'react-hook-form';
import { CourseRegistration } from 'types';

export type CourseRegistrationUI = Omit<
    CourseRegistration,
    | 'domainType'
    | 'formType'
    | 'courseId'
    | 'captchaCode'
    | 'utmTerm'
    | 'utmMedium'
    | 'utmSource'
    | 'agreement'
    | 'utmCampaign'
    | 'utmContent'
>;

export const getCourseCooperationFormRules = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof CourseRegistrationUI, RegisterOptions> => ({
    firstName: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 50,
            message: t('max-length', {
                ns: 'common/errors',
                length: 50,
            }),
        },
    },
    lastName: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 50,
            message: t('max-length', {
                ns: 'common/errors',
                length: 50,
            }),
        },
    },
    email: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 254,
            message: t('max-length', {
                ns: 'common/errors',
                length: 254,
            }),
        },
        pattern: {
            value: /\S+@\S+\.\S+/,
            message: t('email-format', {
                ns: 'common/errors',
            }),
        },
    },
    phone: {},
    country: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 50,
            message: t('max-length', {
                ns: 'common/errors',
                length: 50,
            }),
        },
    },
    company: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 200,
            message: t('max-length', {
                ns: 'common/errors',
                length: 200,
            }),
        },
    },
    university: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 200,
            message: t('max-length', {
                ns: 'common/errors',
                length: 200,
            }),
        },
    },
    studyLevel: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
    },
    studyCourse: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
    },
    studyForm: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
    },
    position: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 100,
            message: t('max-length', {
                ns: 'common/errors',
                length: 100,
            }),
        },
    },
    countOfPeople: {
        required: {
            value: true,
            message: t('required-field', {
                ns: 'common/errors',
            }),
        },
        maxLength: {
            value: 100,
            message: t('max-length', {
                ns: 'common/errors',
                length: 100,
            }),
        },
    },
    comment: {
        maxLength: {
            value: 1000,
            message: t('max-length', {
                ns: 'common/errors',
                length: 1000,
            }),
        },
    },
});
