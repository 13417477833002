import React from 'react';
import { NativeNavLink, RouterNavLink } from './styled';
import { MenuElement } from 'types';
import { useTranslation } from 'react-i18next';

type HeaderLinkProps = MenuElement & {
    className?: string;
    level?: number;
    actionClick?: (actionType: string) => void;
    onClick?: () => void;
};

const HeaderLink: React.FC<HeaderLinkProps> = (props) => {
    const { i18nKey, dataAtSelector, ...rest } = props;
    const { t } = useTranslation('common/nav');

    if (props.isExternal) {
        return (
            <NativeNavLink
                rel={'noopener noreferrer'}
                href={props.link}
                target={'_blank'}
                data-at-selector={dataAtSelector}
                {...rest}>
                {t(i18nKey)}
            </NativeNavLink>
        );
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <RouterNavLink
            to={props.link}
            onClick={scrollToTop}
            data-at-selector={dataAtSelector}
            {...rest}>
            {t(i18nKey)}
        </RouterNavLink>
    );
};

export default HeaderLink;
