import styled from 'styled-components';

export const InputBlock = styled.div`
    padding: 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(34, 52, 73, 0.4);
    span {
        width: 16px;
        height: 16px;
        background-image: url(/assets/images/search.svg);
        margin-right: 8px;
    }
`;

export const InputText = styled.input`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    border: none;
    width: 100%;
`;
