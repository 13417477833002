import React from 'react';
import styled, { css } from 'styled-components';

export interface Props {
    children: React.ReactNode;
    noHeader?: boolean;
    isCorporatePage?: boolean;
    isProfessionPage?: boolean;
    isCoursePage?: boolean;
}

const StyledMain = styled.main<
    Pick<Props, 'noHeader'> & {
        isCorporatePage?: boolean;
        isProfessionPage?: boolean;
        isCoursePage?: boolean;
    }
>`
    position: relative;
    background-attachment: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: ${({ noHeader, theme }) =>
        noHeader ? 0 : theme.shape.headerHeight};
    overflow: hidden;

    ${({ isCorporatePage }) =>
        isCorporatePage &&
        css`
            border-radius: 0 0 40px 40px;
            background: #1d1d1b;
            overflow: visible;
        `};

    ${({ isProfessionPage, isCoursePage }) =>
        (isProfessionPage || isCoursePage) &&
        css`
            border-radius: 0 0 40px 40px;
            overflow: visible;
        `};
`;

const Main: React.FC<Props> = ({
    children,
    isCorporatePage,
    isProfessionPage,
    isCoursePage,
}) => (
    <StyledMain
        isCorporatePage={isCorporatePage}
        isProfessionPage={isProfessionPage}
        isCoursePage={isCoursePage}>
        {children}
    </StyledMain>
);

export default Main;
