import { VideoCourse, VideoCourseQuestion } from 'types';

export const VIDEO_COURSE_ITEMS: VideoCourse[] = [
    {
        title: 'Урок 1. Что такое кибергигиена и зачем она нужна ',
        description:
            'В этом уроке вы узнаете, что такое кибергигиена, какие риски есть в интернете и как учиться на этом курсе.',
        subtitle: 'После урока рекомендуем:',
        advices: `<ul>
        <li>выбрать устройство, с которого будете проходить курс — используйте его на протяжении всего обучения, чтобы ваш прогресс сохранился;</li>
        <li>закрепить знания с помощью теста;</li>
        <li>скачать памятку по уроку.</li>
      </ul>`,
        timeCodes: [
            { start: 4, finish: 72, title: 'Что вас ждет на курсе' },
            { start: 73, finish: 137, title: 'Что такое кибергигиена' },
            {
                start: 138,
                finish: 294,
                title: 'Какие бывают угрозы в интернете',
            },
            { start: 294, finish: 305, title: 'Чему вы научитесь на курсе' },
            {
                start: 305,
                finish: 456,
                title: 'Как вам помогут правила кибергигиены',
            },
            { start: 473, finish: 520, title: 'Как проходить этот курс' },
            { start: 524, finish: 554, title: 'Итоги урока' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_1.mp4',
        poster: '/assets/images/posters/1.jpg',
        memo: 'https://box.kaspersky.com/f/00c0572bf2f94f1e9273/?dl=1',
    },
    {
        title: 'Урок 2. Как защитить смартфоны и планшеты ',
        description: `<p>В этом уроке вы изучите основные принципы защиты мобильных устройств —  смартфонов и планшетов.</p>
       <p>Вы узнаете:</p>
      <ul>
        <li>Почему важно защищать мобильные устройства;</li>
        <li>Как безопасно использовать смартфоны и планшеты;</li>
        <li>Как защититься от вредоносного программного обеспечения;</li>
        <li>Что делать, если ваше устройство украли.</li>
      </ul>`,
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>установите надёжное защитное решение и пароль для блокировки телефона;</li>
        <li>отключите показ текста уведомлений на экране;</li>
        <li>регулярно обновляйте операционную систему и установленные приложения;</li>
        <li>включите дистанционное удаление данных;</li>
        <li>сделайте резервную копию данных.</li>
      </ul>`,
        timeCodes: [
            { start: 4, finish: 44, title: 'Что вас ждет в этом уроке' },
            {
                start: 44,
                finish: 380,
                title: 'Зачем и как защищать мобильное устройство',
            },
            {
                start: 380,
                finish: 540,
                title: 'Что делать, если смартфон украли',
            },
            {
                start: 540,
                finish: 867,
                title: 'Как защититься от вредоносных программ',
            },
            { start: 867, finish: 951, title: 'Как создать надёжный пароль' },
            { start: 951, finish: 1031, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_2.mp4',
        poster: '/assets/images/posters/2.jpg',
        memo: 'https://box.kaspersky.com/f/3e9fef07d4ef41d5917c/?dl=1',
    },
    {
        title: 'Урок 3. Как защитить компьютеры и ноутбуки ',
        description:
            'В этом уроке вы узнаете, как защитить ваши компьютеры и ноутбуки. Поймёте, как создавать безопасные пароли и защищать устройство от вредоносного ПО и фишинга. Также обсудим, почему важно делать резервное копирование данных, и расскажем, что делать если ваше устройство украли. ',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>установите защитное решение и надёжный пароль на компьютер;</li>
        <li>включите файервол;</li>
        <li>используйте защищённое подключение к Wi-Fi;</li>
        <li>регулярно обновляйте программное обеспечение и операционную систему;</li>
        <li>сделайте резервную копию данных.</li>
      </ul>`,
        timeCodes: [
            { start: 36, finish: 60, title: 'Что будет в этом уроке' },
            { start: 60, finish: 91, title: 'Как защищаться от киберугроз' },
            {
                start: 91,
                finish: 280,
                title: 'Правила работы с паролями',
            },
            {
                start: 280,
                finish: 390,
                title: 'Как защититься от вредоносного ПО',
            },
            {
                start: 390,
                finish: 537,
                title: 'Фишинг: что делать',
            },
            {
                start: 537,
                finish: 632,
                title: 'Можно ли подключаться к публичному WiFi',
            },
            {
                start: 632,
                finish: 762,
                title: 'Как делать резервное копирование',
            },
            {
                start: 762,
                finish: 784,
                title: 'Разбираем пример',
            },
            {
                start: 784,
                finish: 820,
                title: 'Итоги',
            },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_3.mp4',
        poster: '/assets/images/posters/3.jpg',
        memo: 'https://box.kaspersky.com/f/a09f653839fc4cf9912f/?dl=1',
    },
    {
        title: 'Урок 4. Как защитить роутеры и умные устройства',
        description:
            'В этом уроке вы узнаете, что такое роутер и умные устройства, каким киберрискам они подвержены и что нужно сделать, чтобы защитить устройства от интернет-злоумышленников.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>в настройках роутера дайте имена всем устройствам, подключённым к домашней сети;</li>
        <li>отключите удалённый доступ к настройкам администратора для вашего роутера;</li>
        <li> измените пароли роутера и умных устройств, которые были настроены по умолчанию, на надёжные пароли;</li>
        <li>изучите информацию об атаках на умные устройства, которыми вы пользуетесь.</li>
      </ul>`,
        timeCodes: [
            { start: 3, finish: 63, title: 'Что будет в этом уроке' },
            {
                start: 63,
                finish: 368,
                title: 'Роутер - что это и как его атакуют злоумышленники',
            },
            {
                start: 368,
                finish: 518,
                title: 'Как обезопасить роутеры: советы',
            },
            { start: 518, finish: 644, title: 'Как защитить умные устройства' },
            { start: 644, finish: 687, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_4.mp4',
        poster: '/assets/images/posters/4.jpg',
        memo: 'https://box.kaspersky.com/f/25c45eb6dab244fb8366/?dl=1',
    },
    {
        title: 'Урок 5. Как создать надёжный пароль ',
        description:
            'В этом уроке вы узнаете, что такое надёжный пароль, как его создать и хранить. Поймёте, почему важно применять правило «для каждого аккаунта — свой пароль». А ещё разберётесь, как злоумышленники могут взломать пароль и как можно противостоять этому.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul> 
        <li>проверьте свои пароли на соответствие правилам составления безопасных паролей:<br /><br />
        → длина — 16 символов и больше.<br /><br />
        → используются разнообразные символы: цифры, буквы, специальные символы.<br /><br />
        → пароль легко запомнить.<br /><br />
        → в пароле не используется ваша личная информация: имя, дата рождения, кличка питомца.<br /><br />
        → в пароле нет очевидных замен символов: например, буквы о на 0<br /><br />
        </li>
        <li>если пароли не соответствуют правилам, установите новые. Проверить пример пароля на надёжность можно с помощью <a href='https://password.kaspersky.com/ru/' target='_blank'>нашего сайта</a>. Не вводите туда пароль, которым собираетесь пользоваться.</li>
        <li>используйте разные пароли для разных сервисов, сайтов, приложений;</li>
        <li>убедитесь, что храните пароль безопасно: запоминаете его или используете менеджер паролей.</li>
      </ul>`,
        timeCodes: [
            { start: 2, finish: 41, title: 'Что будет в этом уроке' },
            {
                start: 41,
                finish: 115,
                title: 'Как сохранить аккаунт в безопасности',
            },
            {
                start: 115,
                finish: 206,
                title: 'Как работают злоумышленники',
            },
            { start: 218, finish: 292, title: 'Как создать надежный пароль' },
            { start: 297, finish: 389, title: 'Надёжный пароль – какой он' },
            {
                start: 395,
                finish: 403,
                title: 'Проверяем надёжность пароля',
            },
            { start: 403, finish: 586, title: 'Как безопасно хранить пароли' },
            { start: 586, finish: 623, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_5.mp4',
        poster: '/assets/images/posters/5.jpg',
        memo: 'https://box.kaspersky.com/f/2ad21ffa4544480eb168/?dl=1',
    },
    {
        title: 'Урок 6. Что такое двухфакторная аутентификация и зачем она нужна',
        description:
            'В этом уроке вы разберётесь, что такое двухфакторная аутентификация и для чего она нужна. Также поймёте, какие бывают вторые факторы, и сможете настроить двухфакторную аутентификацию.',
        subtitle: 'Советы по безопасности:',
        advices: `<p>Настройте двухфакторную аутентификацию в сервисах, где есть ваши личные и платёжные данные:</p>
      <ul>
        <li>онлайн-банках;</li>
        <li>аккаунтах в соцсетях и мессенджерах; </li>
        <li>электронной почте;</li>
        <li>сервисах с документами. </li>
      </ul>
      <p>Сделать это можно в настройках сервиса, либо в разделах «Безопасность» или «Подтверждение входа».</p>`,
        timeCodes: [
            { start: 3, finish: 18, title: 'Что будет в этом уроке' },
            { start: 18, finish: 125, title: 'Зачем нужны логин и пароль' },
            {
                start: 125,
                finish: 137,
                title: 'Как можно дополнительно защитить аккаунт',
            },
            {
                start: 137,
                finish: 360,
                title: 'Двухфакторная аутентификация: как её настроить',
            },
            {
                start: 379,
                finish: 425,
                title: 'Итоги',
            },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_6.mp4',
        poster: '/assets/images/posters/6.jpg',
        memo: 'https://box.kaspersky.com/f/858c2fb9f04340d0bd53/?dl=1',
    },
    {
        title: 'Урок 7. Что такое цифровой след',
        description:
            'В этом уроке вы узнаете, что такое цифровой след и за счёт чего он формируется. А ещё разберётесь с тем, как можно провести аудит своего цифрового следа и обезопасить его.',
        subtitle: 'Советы по безопасности:',
        advices: `<p>Проведите аудит своего цифрового следа:</p>
      <ul>
        <li>введите своё имя в поисковиках и социальных сетях и изучите эти материалы;</li>
        <li>удалите конфиденциальные данные, которыми вы могли поделиться в этих аккаунтах; </li>
        <li>удалите учётные записи, которыми вы больше не пользуетесь;</li>
        <li>если увидели на ресурсах свои личные данные, но вы сами их там не оставляли, попросите администраторов ресурса удалить их.</li>
      </ul>
      <p>Настройте сбор куки:</p>
      <ol>
       <li>перейдите в раздел «Файлы Cookie» — чаще всего его можно найти в «Настройках» на вкладке «Приватность».</li>
       <li>установите флажки напротив файлов куки, которые вы готовы принимать. Некоторые браузеры предупреждают, что файлы будут храниться на устройстве.</li>
       <li>если куки вам не нужны, снимите флажки.</li>
       <li>установите параметры конфиденциальности в соцсетях и мессенджерах. Сделать это можно с помощью Kaspersky Privacy checker.</li>
       </ol>`,
        timeCodes: [
            { start: 3, finish: 23, title: 'Что будет в этом уроке' },
            { start: 24, finish: 159, title: 'Что такое цифровой след' },
            {
                start: 159,
                finish: 295,
                title: 'Как использовать куки',
            },
            {
                start: 295,
                finish: 382,
                title: 'Защищаем пароль и свое устройство',
            },
            { start: 382, finish: 410, title: 'Параметры конфиденциальности' },
            {
                start: 410,
                finish: 421,
                title: 'Настраиваем приватность аккаунтов',
            },
            { start: 421, finish: 497, title: 'Несколько важных рекомендаций' },
            { start: 521, finish: 588, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_7.mp4',
        poster: '/assets/images/posters/7.jpg',
        memo: 'https://box.kaspersky.com/f/da43c420c9ca43b4910e/?dl=1',
    },
    {
        title: 'Урок 8. Как безопасно искать информацию в интернете',
        description:
            'В этом уроке вы узнаете, как проверять информацию в интернете на достоверность и как можно защитить свои данные в интернете. Ещё разберётесь, как работают нейросети и как их используют злоумышленники.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>проверьте, установлены ли расширения или плагины в вашем браузере из официальных магазинов. Если нет – удалите их;</li>
        <li>очистите историю поиска и кэш;</li>
        <li>разрешайте сервисам собирать только нужные куки;</li>
        <li>не делитесь личной информацией с чат-ботами на основе нейросетей.</li>
      </ul>`,
        timeCodes: [
            { start: 3, finish: 37, title: 'Что будет в этом уроке' },
            { start: 37, finish: 92, title: 'Как работает браузер' },
            {
                start: 92,
                finish: 166,
                title: 'Что такое безопасное соединение',
            },
            { start: 166, finish: 299, title: 'Как обезопасить данные' },
            {
                start: 299,
                finish: 625,
                title: 'Как проверить информацию на достоверность',
            },
            {
                start: 632,
                finish: 856,
                title: 'Нейросеть: что это и как безопасно в ней работать',
            },
            { start: 856, finish: 890, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_8.mp4',
        poster: '/assets/images/posters/8.jpg',
        memo: 'https://box.kaspersky.com/f/3e8168b7a28d49979dac/?dl=1',
    },
    {
        title: 'Урок 9. Как безопасно использовать приложения',
        description:
            'В этом уроке вы узнаете, какие риски скрываются в неблагонадёжных приложениях. Научитесь узнавать, какие расширения запрашивают приложения. А ещё разберетесь в том, откуда нужно скачивать приложения и как их безопасно использовать.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>проверьте, к чему есть доступ у ваших приложений. Для этого зайдите в «Настройки» на вашем смартфоне и найдите раздел «Приложение», «Управление приложениями» или «Конфиденциальность». Закройте доступ к тем данным, которые не нужны приложению;</li>
        <li>ознакомьтесь с политикой конфиденциальности и пользовательским соглашением приложения, которое вы будете устанавливать. Оцените, насколько вас устраивает то, на каких условиях работает приложение и как оно работает с предоставляемой информацией. Подумайте, насколько безопасно пользоваться таким приложением.</li>
      </ul>`,
        timeCodes: [
            { start: 3, finish: 47, title: 'О чем этот урок' },
            { start: 52, finish: 94, title: 'Киберриски приложений' },
            {
                start: 94,
                finish: 242,
                title: 'Как отличать нужные и ненужные запросы приложений',
            },
            {
                start: 244,
                finish: 323,
                title: 'Правила безопасного использования приложений',
            },
            {
                start: 323,
                finish: 501,
                title: 'Как понять, что приложение – вредоносное',
            },
            {
                start: 501,
                finish: 660,
                title: 'Как комплексно защищать устройства и приложения',
            },
            { start: 660, finish: 710, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_9.mp4',
        poster: '/assets/images/posters/9.jpg',
        memo: 'https://box.kaspersky.com/f/0b86012382314e5bab23/?dl=1',
    },
    {
        title: 'Урок 10. Как защитить детей в интернете',
        description:
            'В этом уроке вы узнаете, какие угрозы поджидают детей в интернете. Разберётесь, как защитить детей от рисков с помощью технических и нетехнических методов, а также поймёте, как обсудить с ребёнком тему киберугроз.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>установите родительский контроль и детский профиль;</li>
        <li>обсудите с ребёнком правила безопасного использования интернета;</li>
        <li>спросите ребёнка, какие приложения и сайты он использует. Обсудите все возможные проблемы и причины, по которым не стоит использовать приложение или сайт. И по возможности, вместе решите, стоит ли ими пользоваться дальше;</li>
        <li>убедите ребёнка, что он всегда может с вами поделиться тем, с чем он столкнётся в интернете. Донесите, что об этом лучше рассказать вам, а не держать в себе. А также покажите, как можно блокировать нежелательный контент;</li>
        <li>установите границы, но будьте реалистом. Границы использования интернета должны зависеть от возраста ребёнка и того, что приемлемо в вашей семье.</li>
      </ul>`,
        timeCodes: [
            { start: 3, finish: 51, title: 'Исследование Kaspersky' },
            { start: 51, finish: 61, title: 'Что ждет в этом уроке' },
            {
                start: 61,
                finish: 139,
                title: 'Какие риски для детей есть в интернете',
            },
            {
                start: 139,
                finish: 384,
                title: 'Правила защиты детей от киберрисков',
            },
            {
                start: 384,
                finish: 491,
                title: 'Как говорить с ребенком о безопасности в интернете',
            },
            { start: 491, finish: 577, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_10.mp4',
        poster: '/assets/images/posters/10.jpg',
        memo: 'https://box.kaspersky.com/f/acb7d0118aee48808a4c/?dl=1',
    },
    {
        title: 'Урок 11. Что такое социальная инженерия и как от неё уберечься ',
        description:
            'В этом уроке вы узнаете, что такое социальная инженерия и как её можно распознать. Вместе мы разберём приёмы, которыми пользуются злоумышленники, и вы поймёте, как защитить себя от них.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>уменьшите свой цифровой след и регулярно делайте это в будушем;</li>
        <li>настройте спам-фильтр;</li>
        <li>подключайте критическое мышление, когда получаете информацию по телефону, в почте, мессенджере и соцсетях;</li>
        <li>обращайте внимание на следующие приёмы, которые могут использовать злоумышленники:
          <ul>
            <li>→ вас торопят;</li>
            <li>→ атакующий притворяется лицом, вызывающим доверие: представителем органа власти, службы безопасность банка, начальником, родственником;</li>
            <li>→ у вас пытаются выведать конфиденциальные данные;</li>
            <li>→ вам обещают выигрыш.</li>
          </ul>  
        </li>
        <li>используйте ещё один способ связи, чтобы проверить достоверность информации. Например, если вы получили сообщение якобы от банка, перезвоните в него самостоятельно по номеру, указанном на официальном сайте.</li>
      </ul>`,
        timeCodes: [
            { start: 3, finish: 24, title: 'Что будет в этом уроке' },
            { start: 24, finish: 225, title: 'Социальная инженерия - что это' },
            {
                start: 225,
                finish: 502,
                title: 'Как злоумышленники крадут данные',
            },
            {
                start: 502,
                finish: 1028,
                title: 'Как не попасться на уловки мошенников: советы',
            },
            { start: 1045, finish: 1100, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_11.mp4',
        poster: '/assets/images/posters/11.jpg',
        memo: 'https://box.kaspersky.com/f/45c731e6102e4046bcc8/?dl=1',
    },
    {
        title: 'Урок 12. Как защитить электронную почту ',
        description:
            'В этом уроке вы узнаете, как злоумышленники могут атаковать вашу почту. На примерах разберётесь, что такое фишинг, спам и как их распознать. А ещё получите рекомендации, как себя от них защитить.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>создайте надёжный пароль для почты и безопасно храните его. Пароль должен быть длиной не менее 16 символов, содержать разнообразные символы и легко запоминаться;</li>
        <li>подключите двухфакторную аутентификацию к почте;</li>
        <li>создайте общедоступный адрес электронной почты, который будете использовать для подписки на рассылки, оформления покупок и других подобных действий;</li>
        <li>не открывайте сомнительные письма и не переходите по подозрительным ссылкам;</li>
        <li>не отвечайте на спам.</li>
      </ul>`,
        timeCodes: [
            { start: 3, finish: 15, title: 'Что будет в этом уроке' },
            { start: 15, finish: 161, title: 'Что такое спам и фишинг' },
            {
                start: 161,
                finish: 471,
                title: 'Признаки и виды фишинговых писем',
            },
            {
                start: 471,
                finish: 661,
                title: 'Как обезопасить себя от фишинга',
            },
            {
                start: 661,
                finish: 757,
                title: 'Рекомендации по защите',
            },
            { start: 757, finish: 819, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_12.mp4',
        poster: '/assets/images/posters/12.jpg',
        memo: 'https://box.kaspersky.com/f/083586ae848f49838cdf/?dl=1',
    },
    {
        title: 'Урок 13. Как защититься от телефонных мошенников ',
        description:
            'В этом уроке вы узнаете, какими схемами пользуются телефонные мошенники и как их распознать. На примерах познакомитесь с признаками телефонного мошенничества и получите рекомендации, как себя от них защитить.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li> установите приложение, блокирующее спам-звонки и звонки с подозрением на мошенничество;</li>
        <li>не вступайте в разговор со злоумышленниками; </li>
        <li>установите защитное решение на свой телефон.</li>
      </ul>`,
        timeCodes: [
            { start: 3, finish: 36, title: 'Что будет в этом уроке' },
            {
                start: 36,
                finish: 151,
                title: 'Как злоумышленники атакуют с помощью телефонных звонков и SMS',
            },
            {
                start: 151,
                finish: 496,
                title: 'Методы телефонного мошенничества',
            },
            {
                start: 496,
                finish: 728,
                title: 'Что делать, если позвонил злоумышленник',
            },
            {
                start: 728,
                finish: 915,
                title: 'Как защитить себя от мошенников',
            },
            { start: 915, finish: 990, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_13.mp4',
        poster: '/assets/images/posters/13.jpg',
        memo: 'https://box.kaspersky.com/f/ed49ffafd3cf424f8927/?dl=1',
    },
    {
        title: 'Урок 14. Как безопасно покупать онлайн ',
        description:
            'В этом уроке вы узнаете о том, как злоумышленники могут обмануть вас во время онлайн-покупок. Научитесь распознавать поддельные сайты и неблагонадёжных продавцов, а также получите рекомендации, как безопасно покупать онлайн.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>проверяйте правильность доменного имени сайта, где вы собираетесь что-то купить;</li>
        <li>заведите отдельную карту (можно виртуальную), на которой вы будете хранить деньги только для предстоящей покупки;</li>
        <li>тщательно проверяйте ресурсы, которым вы собираетесь передать какую-либо информацию о себе — особенно, если речь идёт о платёжных данных;</li>
        <li>не переходите по ссылкам из подозрительных писем — лучше поищите заинтересовавшую вас информацию из письма на официальном сайте;</li>
        <li>никому не передавайте код на обратной стороне карты, пин-код из SMS или push-уведомлений, пин-код от карты.</li>
      </ul>`,
        timeCodes: [
            { start: 3, finish: 47, title: 'О чем этот урок' },
            {
                start: 47,
                finish: 422,
                title: 'Как отличить поддельный сайт от настоящего',
            },
            {
                start: 422,
                finish: 510,
                title: '“Классическая” схема обмана при онлайн-покупках',
            },
            {
                start: 510,
                finish: 883,
                title: 'Как еще обманывают при онлайн-покупках',
            },
            {
                start: 883,
                finish: 946,
                title: 'Как обезопасить себя при покупке со смартфона',
            },
            {
                start: 946,
                finish: 1077,
                title: 'Как обезопасить финансовые данные',
            },
            { start: 1077, finish: 1160, title: 'Итоги' },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_14.mp4',
        poster: '/assets/images/posters/14.jpg',
        memo: 'https://box.kaspersky.com/f/c613d47dfe9c435ca3aa/?dl=1',
    },
    {
        title: 'Урок 15. Как безопасно пользоваться социальными сетями и мессенджерами',
        description:
            'В этом уроке вы узнаете, как настроить приватность аккаунтов в мессенджерах и социальных сетях. Познакомитесь с рисками, с которыми можно столкнуться. Разберётесь, как защититься от доксинга, кэтфишинга, кибербулинга, киберсталкинга. А также поймёте, что делать, если ваш аккаунт взломали.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>настройте приватность мессенджеров и соцсетей c <a aria-label="Kaspersky Privacy checker" href="https://privacy.kaspersky.com/ru/"> Kaspersky Privacy checker</a>;</li>
        <li>пересмотрите своё понимание персональных данных — это любая информация, которая может вас идентифицировать напрямую или косвенно;</li>
        <li>настройте двухфакторную аутентификацию для социальных сетей и мессенджеров.</li>
      </ul>`,
        timeCodes: [
            { start: 3, finish: 50, title: 'Что будет в этом уроке' },
            {
                start: 50,
                finish: 334,
                title: 'Как настроить приватность аккаунта',
            },
            {
                start: 370,
                finish: 492,
                title: 'Какой информацией не стоит делиться в социальных сетях и мессенджерах',
            },
            { start: 492, finish: 599, title: 'Как стоит общаться в соцсети' },
            { start: 599, finish: 732, title: 'Что такое кэтфишинг' },
            {
                start: 732,
                finish: 936,
                title: 'Что такое доксинг',
            },
            { start: 936, finish: 1164, title: 'Что делать при кибербуллинге' },
            {
                start: 1164,
                finish: 1293,
                title: 'Как защититься от кибиресталкинга',
            },
            {
                start: 1293,
                finish: 1436,
                title: 'Как взламывают аккаунт',
            },
            {
                start: 1436,
                finish: 1658,
                title: 'Как обезопасить себя в соцсетях и мессенджерах',
            },
            {
                start: 1658,
                finish: 1728,
                title: 'Итоги',
            },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_15.mp4',
        poster: '/assets/images/posters/15.jpg',
        memo: 'https://box.kaspersky.com/f/e53cbfb7247e43f89865/?dl=1',
    },
    {
        title: 'Урок 16. Как обезопасить свои данные в интернете',
        description:
            'В этом уроке вы узнаете, какими бывают личные данные. Мы обсудим, какими данными лучше не делиться в интернете. Разберёмся, как обезопасить личные данные и не позволить злоумышленникам использовать их против вас. Ещё кратко поговорим о том, как ваши личные данные защищаются законом.',
        subtitle: 'Советы по безопасности:',
        advices: `<ul>
        <li>настройте максимально возможный уровень приватности в соцсетях c помощью <a aria-label="Kaspersky Privacy checker" href="https://privacy.kaspersky.com/ru/"> Kaspersky Privacy checker</a>;</li>
        <li>удалите случайных и незнакомых людей из списка друзей;</li>
        <li>минимизируйте или полностью исключите публикацию конфиденциальной информации;</li>
        <li>не публикуйте в открытом доступе фотографии, сканы и данные ваших документов, билетов и платёжных чеков.</li>
      </ul>`,
        timeCodes: [
            { start: 3, finish: 33, title: 'Что будет в этом уроке' },
            { start: 33, finish: 112, title: 'Какими бывают данные' },
            {
                start: 140,
                finish: 267,
                title: 'Какими данными делиться не стоит',
            },
            {
                start: 267,
                finish: 624,
                title: 'Как можно защитить свои данные',
            },
            {
                start: 624,
                finish: 678,
                title: 'Что такое согласие на обработку персональных данных',
            },
            {
                start: 678,
                finish: 844,
                title: 'Как организации защищают ваши данные',
            },
            {
                start: 844,
                finish: 920,
                title: 'Итоги',
            },
        ],
        video: 'https://admin.careers.kaspersky.ru/api/BigData/GetFileRange/Lesson_16.mp4',
        poster: '/assets/images/posters/16.jpg',
        memo: 'https://box.kaspersky.com/f/e293d788342d4e79ad20/?dl=1',
    },
    {
        title: 'Поздравляем — вы успешно изучили все уроки курса «Кибергигиена»!',
        description: `Вы проделали отличную работу — научились создавать надёжные пароли, подключать двухфакторную аутентификацию, защищать устройства, данные и освоили множество других навыков, которые помогут сделать цифровую жизнь комфортнее и безопаснее. <br /> <br />
            Теперь вы точно сможете распознавать киберугрозы и защищать себя от уловок злоумышленников. Чтобы успешно применять новые знания в жизни, скачайте памятку по курсу — пусть правила кибергигиены всегда будут под рукой. <br /> <br />
            <a href="https://box.kaspersky.com/f/08761e2043c649489f2c/?dl=1 " target={'_blank'} rel={'noreferrer'}>Скачать памятку</a>
            <br /><br />
            Также предлагаем пройти финальное тестирование на нашем сертификационном портале и получить сертификат. Просто перейдите по ссылке ниже и заведите аккаунт на нём. Заполните анкету и активируйте аккаунт по ссылке, которая придёт на указанную вами почту. Затем снова перейдите по ссылке и введите промокод <b>b@u0!27y0twf*B</b>. Если ответите правильно на 6 из 12 вопросов, сертификат об успешном освоении курса — ваш. <br /><br />
            Желаем удачи!
            <br /><br />
            <a href="https://certification.kaspersky.com/partner?code=YkB1MCEyN3kwdHdmKkI=&lang=ru&zipCoded=true" target="_blank">Пройти финальное тестирование</a>
            `,
    },
];

export const VIDEO_COURSE_QUESTIONS: VideoCourseQuestion[][] = [
    [
        {
            title: 'Что такое кибергигиена? Выберите один верный ответ',
            type: 'single',
            variants: [
                {
                    text: 'Правила цифровой грамотности, которые помогают эффективно использовать интернет, смартфоны и компьютеры',
                    correct: false,
                    message:
                        'Кибергигиена — это правила и привычки, которые помогают повысить уровень безопасности при использовании гаджетов и интернета. Цифровая грамотность же направлена на то, чтобы научиться использовать устройства и интернет.',
                },
                {
                    text: 'Рекомендации, как можно защитить себя и близких от злоумышленников в интернете',
                    correct: false,
                    message:
                        'Подобные рекомендации — лишь часть киберигигены. Кибергигиена в целом помогает повысить уровень безопасности, когда вы пользуетесь гаджетами и интернетом. ',
                },
                {
                    text: 'Правила и привычки, которые помогают повысить уровень безопасности при использовании гаджетов и интернета',
                    correct: true,
                    message:
                        'Кибергигиена помогает научиться использовать устройства и интернет безопасно.',
                },
                {
                    text: 'Способы снизить киберриски в интернете с помощью защитных решений ',
                    correct: false,
                    message:
                        'Защитные решения — это инструмент, который помогает более безопасно проводить время в Сети. Понятие «кибергигиена» шире — это правила и привычки, позволяющие повысить уровень безопасности при использовании гаджетов и интернета. ',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Как защитить смартфон или планшет от киберугроз? Выберите все верные варианты',
            type: 'multiple',
            variants: [
                {
                    text: 'Выключить блокировку экрана',
                    correct: false,
                    message:
                        'Блокировать экран нужно. Если этого не сделать, украв смартфон, злоумышленники получат доступ к личной информации. А ещё на незаблокированном телефоне легко подсмотреть ваши данные.',
                },
                {
                    text: 'Защищать важные приложения паролем',
                    correct: true,
                    message:
                        'Это следует сделать. Установите пароль с помощью встроенных функций телефона для каждого приложения.',
                },
                {
                    text: 'Делать резервную копию данных',
                    correct: true,
                    message:
                        'Резервное копирование защитит ваши данные, если вы случайно их удалите, потеряете или сломаете телефон.',
                },
                {
                    text: 'Критически относиться к информации',
                    correct: true,
                    message:
                        'Сообщения в почте, мессенджерах или соцсетях — один из самых распространённых способов атак. Злоумышленники могут отправлять сообщения от лица известных компаний или от других пользователей — в том числе  с украденных аккаунтов.',
                },
                {
                    text: 'Не обновлять приложения и операционную систему',
                    correct: false,
                    message:
                        'Это нужно делать — каждое обновление защищает устройство от новых, недавно выявленных угроз.',
                },
                {
                    text: 'Использовать надёжное защитное решение',
                    correct: true,
                    message:
                        'Оно поможет защитить устройство от вредоносных программ, фишинга и спама.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Выберите все верные действия, которые нужно сделать, если у вас украли смартфон',
            type: 'multiple',
            variants: [
                {
                    text: 'Заблокировать SIM-карту',
                    correct: true,
                    message:
                        'Это можно сделать в салоне оператора связи, или самостоятельно через личный кабинет сотового оператора.',
                },
                {
                    text: 'Сменить пароль только от электронной почты',
                    correct: false,
                    message:
                        'Нужно сменить пароли от всех аккаунтов, в которые вы заходили —  онлайн-банков, мессенджеров, соцсетей и почты. По возможности, удалённо завершите все сессии этих сервисов на утерянном смартфоне.',
                },
                {
                    text: 'Попробовать найти телефон через сервисы геолокации',
                    correct: true,
                    message:
                        'В зависимости от операционной системы эта функция называется «Найти устройство» или «Локатор».',
                },
                {
                    text: 'Никому не говорить, что телефон украли',
                    correct: false,
                    message:
                        'Обязательно предупредите о краже близких. Злоумышленники могут писать и звонить им с телефона, выдавая себя за вас.',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Что поможет защитить компьютер? Выберите все верные ответы',
            type: 'multiple',
            variants: [
                {
                    text: 'Обновление операционной системы и программ',
                    correct: true,
                    message:
                        'Регулярно обновляйте программы и операционную систему, чтобы атакующие не заразили ваше устройство.',
                },
                {
                    text: 'Резервное копирование данных',
                    correct: true,
                    message:
                        'Оно защитит ваши данные, если вы случайно удалите данные, а также если ваш компьютер сломается или его украдут.',
                },
                {
                    text: 'Отключение файервола',
                    correct: false,
                    message:
                        'Файервол — это программа-фильтр между компьютером и сетью, которая проверяет безопасность входящих и исходящих данных и предотвращает атаки злоумышленников. Обязательно включайте его, чтобы он ограничил доступ к вашей системе.',
                },
                {
                    text: 'Установка защитного решения',
                    correct: true,
                    message:
                        'Комплексное защитное решение поможет безопасно использовать компьютер и защитит его от злоумышленников',
                },
            ],
        },
        {
            title: 'Вопрос 2. Определение какого термина перед вами? Выберите один правильный ответ. <br> …. — это вид интернет-мошенничества, цель которого — получить идентификационные данные пользователей. Сюда относятся кражи паролей, номеров кредитных карт, банковских счетов и другой конфиденциальной информации.',
            type: 'single',
            variants: [
                {
                    text: 'Фишинг',
                    correct: true,
                    message:
                        'При фишинге злоумышленники «выуживают» данные пользователей, чтобы потом использовать их для своих атак.',
                },
                {
                    text: 'Вишинг',
                    correct: false,
                    message:
                        'Перед вам определение фишинга. Вишинг — это вид фишинга, при котором мошенники пытаются получить данные пользователей через телефонный звонок.',
                },
                {
                    text: 'Смишинг',
                    correct: false,
                    message:
                        'Это определение фишинга. Смишинг — это вид фишинга, при котором мошенники пытаются получить данные пользователей через SMS-сообщения.',
                },
                {
                    text: 'Зловред',
                    correct: false,
                    message:
                        'Зловред — это ещё одно название вредоносного ПО. А в вопросе определение фишинга. ',
                },
            ],
        },
        {
            title: 'Вопрос 3. Как обезопасить себя от фишинга? Выберите все верные ответы',
            type: 'multiple',
            variants: [
                {
                    text: 'Не вводить данные на малознакомых сайтах',
                    correct: true,
                    message:
                        'При вводе данных на малознакомом сайте есть риск, что их получат злоумышленники.',
                },
                {
                    text: 'Вводить логин и пароль только на официальных сайтах, страницах соцсетей и мессенджеров',
                    correct: true,
                    message:
                        'Злоумышленники могут заманить вас на поддельные ресурсы, чтобы получить учётные данные от аккаунта. Поэтому всегда проверяйте, что  находитесь на официальном сайте.',
                },
                {
                    text: 'Не переходить по ссылкам, которые прислали малознакомые люди или организации',
                    correct: true,
                    message:
                        'За ссылками от малознакомых отправителей могут скрываться фишинговые сайты.',
                },
                {
                    text: 'Критически мыслить, когда получаете сообщения и информацию',
                    correct: true,
                    message:
                        'Всегда задавайте себе вопрос, насколько реальна и правдоподобна информация, которую вы получаете через телефонные звонки, сообщения и письма, видите на сайтах и ресурсах',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Что может случиться, если злоумышленники атакуют роутер или умное устройство? Выберите все верные ответы',
            type: 'multiple',
            variants: [
                {
                    text: 'Атаковав роутер, злоумышленники могут перенаправлять вас на вредоносные сайты',
                    correct: true,
                    message:
                        'Вы будете думать, что переходите на настоящий сайт, а на самом деле он будет поддельный.',
                },
                {
                    text: 'Злоумышленники могут перехватывать незашифрованные данные',
                    correct: true,
                    message:
                        'А потом использовать их, чтобы получить доступ к аккаунтам в разных сервисах.',
                },
                {
                    text: 'Если злоумышленники атакуют умные часы, под угрозой любые устройства во внутренней сети',
                    correct: false,
                    message:
                        'Лишь атака на роутер может привести к тому, что под угрозой будут любые устройства во внутренней сети — в том числе ноутбуки и смартфоны',
                },
                {
                    text: 'Атакующие могут следить за цифровой жизнью владельца умного устройства и его предпочтениями',
                    correct: true,
                    message:
                        'Получив данные с вашего устройства, злоумышленники  могут использовать их в фишинговых атаках. Также ваши фото, документы, переписка могут использоваться с целью шантажа.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Какое действие НЕ ПОМОЖЕТ вам защитить роутер и умное устройство? Выберите один вариант',
            type: 'single',
            variants: [
                {
                    text: 'Изменение паролей на устройствах по умолчанию',
                    correct: false,
                    message:
                        'Надёжные пароли для каждого устройства и роутера помогут вам защитить роутер и умные устройства.',
                },
                {
                    text: 'Использование механизма авторизации WPS',
                    correct: true,
                    message:
                        'Избегайте такого механизма авторизации — он известен своей незащищённостью. WPS не поможет вам в защите роутера и умных устройств.',
                },
                {
                    text: 'Обновление устройств',
                    correct: false,
                    message:
                        'Установка новой прошивки на устройства улучшит их функциональность и обезопасит устройство от атак.',
                },
                {
                    text: 'Изучение информации о проблемах и угрозах устройств',
                    correct: false,
                    message:
                        'Сообщения о потенциальных проблемах и угрозах помогут быть в курсе актуальных проблем кибербезопасности и защитить роутеры и устройства.',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Как составить безопасный пароль? Выберите все верные утверждения',
            type: 'multiple',
            variants: [
                {
                    text: 'Составляйте пароль из разных символов',
                    correct: true,
                    message:
                        'Пароль из разных символов — строчных и заглавных букв, цифр и специальных символов — труднее взломать.',
                },
                {
                    text: 'Используйте необычные сочетания слов',
                    correct: true,
                    message:
                        'Такой пароль будет сложнее взломать. Не составляйте пароль из личных данных: даты рождения, фамилии, имени, отчество, имён детей и кличек питомцев.',
                },
                {
                    text: 'Не используйте один пароль для разных аккаунтов',
                    correct: true,
                    message:
                        'Разные пароли для разных аккаунтов помогут вам обезопасить себя от атак злоумышленников.',
                },
                {
                    text: 'Используйте пароль, который сложно запомнить',
                    correct: false,
                    message:
                        'Пароль, который вы сами не можете запомнить, бесполезен. Вы будете вынуждены постоянно его восстанавливать и придумывать новый пароль.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Какой пароль вы бы установили себе в приложение, которым часто пользуетесь?',
            type: 'single',
            variants: [
                {
                    text: 'Qwerty123 — легко запомнить, есть разные символы',
                    correct: false,
                    message:
                        'Этот пароль попадает в группу «популярных» паролей наряду с «123456» и «password». Такие пароли легко взломать или подобрать. Не используйте подобные комбинации.',
                },
                {
                    text: 'KaZHDyi56oKHoTiK& — довольно легко запомнить, есть разные символы',
                    correct: true,
                    message:
                        'Пароль надёжный — в нём больше 16 символов. Он содержит разные символы, в нём нет личных данных. А ещё его легко запомнить, потому что он является началом фразы «Каждый охотник желает знать…».',
                },
                {
                    text: '&Kyт89tuJ$!XMb34^ — запомнить сложно, но символов много ',
                    correct: false,
                    message:
                        'Несмотря на то, что пароль удовлетворяет всем правилам по созданию безопасного пароля, его сложно запомнить. Пароль, который не пустит в ваш аккаунт даже вас самих, бесполезен.',
                },
                {
                    text: '1238907689815713311 - много символов, легко запомнить',
                    correct: false,
                    message:
                        'В этом пароле есть только цифры — его легко могут подобрать злоумышленники даже не смотря на то, что символов много. Не используйте подобные пароли.',
                },
            ],
        },
        {
            title: 'Вопрос 3. Какие правила из перечисленных ниже относятся к безопасному использованию паролей?',
            type: 'multiple',
            variants: [
                {
                    text: 'Записывайте пароли на бумаге или храните их в приложении «Заметки» на телефоне',
                    correct: false,
                    message:
                        'Это небезопасно — злоумышленники могут похитить или подсмотреть пароль.',
                },
                {
                    text: 'Сохраняйте пароли в автозаполнении браузера — не придётся их запоминать',
                    correct: false,
                    message:
                        'Не делайте так. Если злоумышленник завладеет вашим устройством, он сможет войти в сервисы с помощью паролей, сохранённых в браузере.',
                },
                {
                    text: 'Регулярно меняйте пароль',
                    correct: true,
                    message:
                        'Это поможет вам защитить свой аккаунт от последствий утечек данных. Если злоумышленник получит авторизационные данные из старых утечек, то вероятность того, что они будут актуальны, значительно снижается.',
                },
                {
                    text: 'Используйте менеджер паролей',
                    correct: true,
                    message:
                        'Менеджер паролей — это программа, которая позволяет хранить пароли и логины от аккаунтов для различных сервисов в зашифрованном виде. Даже если злоумышленник завладеет вашим устройством, он не сможет получить доступ к менеджеру паролей.',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Верно ли данное определение двухфакторной аутентификации? <br> Двухфакторная аутентификация — это последовательный ввод двух факторов: логина + пароля и затем  SMS-кода в сервисе.',
            type: 'single',
            variants: [
                {
                    text: 'Верно',
                    correct: false,
                    message:
                        'Вторым фактором в двухфакторной аутентификации — то есть дополнительным слоем защиты логина и пароля — может быть не только SMS-код, но и кодовая фраза, специальное USB-устройство или биометрические данные.',
                },
                {
                    text: 'Не верно',
                    correct: true,
                    message:
                        'Вторым фактором, кроме SMS-кода, может быть кодовая фраза, специальное USB-устройство или биометрические данные.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Выберите все сервисы, на которых необходимо настроить двухфакторную аутентификацию',
            type: 'multiple',
            variants: [
                {
                    text: 'Интернет-банк',
                    correct: true,
                    message:
                        'Здесь есть доступ к вашим финансам — обязательно обезопасьте онлайн-банк с помощью двухфакторной аутентификации.  ',
                },
                {
                    text: 'Аккаунт в соцсети',
                    correct: true,
                    message:
                        'В соцсети есть ваши личные данные и доступ к контактам. Получив их, злоумышленники могут использовать информацию для шантажа или писать близким от вашего лица, вымогая деньги. Так что двухфакторную аутентификацию в соцсетях точно нужно настроить.',
                },
                {
                    text: 'Мессенджер',
                    correct: true,
                    message:
                        'Здесь, как и в соцсетях, есть доступ к вашим данным и контактам. Поэтому двухфакторная аутентификация нужна и в мессенджерах. ',
                },
                {
                    text: 'Электронная почта ',
                    correct: true,
                    message:
                        'В почте есть ваши данные, поэтому её нужно дополнительно обезопасить.',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Выберите пункты, которые «пополняют» цифровой след',
            type: 'multiple',
            variants: [
                {
                    text: 'Покупка футболки в интернет-магазине',
                    correct: true,
                    message:
                        'Это формирует цифровой след, поскольку вы оставляете свои платёжные данные на сайте интернет-магазина.',
                },
                {
                    text: 'Подписка на рассылку новостей «Лаборатории Касперского»',
                    correct: true,
                    message:
                        'Это пополнит цифровой след, потому что вы оставляете свой электронный адрес «Лаборатории Касперского».',
                },
                {
                    text: 'Скачивание фото из социальной сети и их резервное копирование на жёсткий диск',
                    correct: false,
                    message:
                        'Это не влияет ваш цифровой след: фотографии уже были в соцсети и вы их скачиваете.',
                },
                {
                    text: 'Пост в социальной сети с впечатлениями о курсе «Кибергигиена»',
                    correct: true,
                    message:
                        'Это формирует ваш цифровой след, потому что вы постите в социальную сеть новый текст',
                },
            ],
        },
        {
            title: 'Вопрос 2. Выберите все верные утверждения о куки',
            type: 'multiple',
            variants: [
                {
                    text: 'Куки помогают сайту узнавать вас и «вспоминать» ваши настройки',
                    correct: true,
                    message:
                        'Например, галочка «Запомнить меня» в соцсети позволяет не вводить логин и пароль при каждом новом визите.',
                },
                {
                    text: 'Куки позволяют сервисам собирать данные о пользователях',
                    correct: true,
                    message:
                        'На их основе сервис предлагает информацию и показывает рекламу. Такие куки могут принадлежать компании, с которой владельцы сервиса заключили партнёрское соглашение. Это называется «сторонние файлы cookie».',
                },
                {
                    text: 'Соглашаться на использование куки – обязательно',
                    correct: false,
                    message:
                        'Наоборот — это необязательно. Вы можете разрешить сохранять лишь те типы типы куки, которые вам удобны и нужны. ',
                },
                {
                    text: 'Куки не представляют интерес для злоумышленников',
                    correct: false,
                    message:
                        'Если устройство будет заражено вредоносным ПО, злоумышленники могут использовать куки. Например, существуют программы, которые крадут файлы cookie, сохранённые в браузере на смартфонах и в приложениях популярных социальных сетей. Используя их, злоумышленники могут незаметно получать контроль над аккаунтом человека в социальной сети и распространять контент от его лица. ',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Какие действия помогут искать информацию безопасно? Выберите все верные ответы',
            type: 'multiple',
            variants: [
                {
                    text: 'Установка приложений или программ с максимальным рейтингом ',
                    correct: false,
                    message:
                        'Максимальный рейтинг не гарантирует, что сервис подлинный.  Программы нужно устанавливать либо с официальных сайтов разработчиков, либо в магазинах приложений.',
                },
                {
                    text: 'Игнорирование обновлений программного обеспечения',
                    correct: false,
                    message:
                        'Это снижает безопасность — устаревшее ПО может быть уязвимым. ',
                },
                {
                    text: 'Использование режима «Инкогнито» в браузере',
                    correct: true,
                    message:
                        'Режим «Инкогнито» не сохраняет историю посещений, куки и данные авторизации — это защищает вашу конфиденциальность.',
                },
                {
                    text: 'Очистка истории поиска и кэша',
                    correct: true,
                    message:
                        'Это поможет защитить вашу конфиденциальность в интернете.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Какие меры помогут проверить, что информация достоверна? Выберите все верные варианты',
            type: 'multiple',
            variants: [
                {
                    text: 'Проверка фактов в других источниках',
                    correct: true,
                    message:
                        'Сравнивайте информацию на разных ресурсах — это помогает отличить правду от неверных фактов.',
                },
                {
                    text: 'Оценка источника информации — если он выглядит профессионально и надёжно, можно не искать другие',
                    correct: false,
                    message:
                        'Визуальная привлекательность не гарантирует, что информация достоверна. Всегда проверяйте факты в нескольких источниках. ',
                },
                {
                    text: 'Чтение отзывов и рейтингов о сайте или материале',
                    correct: true,
                    message:
                        'Это эффективный способ — отзывы могут дать представление о надёжности источника.',
                },
                {
                    text: 'Оценка цели публикации материала',
                    correct: true,
                    message:
                        'Это важный шаг — сможете понять, является материал шуточным или нет.',
                },
            ],
        },
        {
            title: 'Вопрос 3. Какое утверждение о чат-ботах на основе нейросетей – верное?',
            type: 'multiple',
            variants: [
                {
                    text: 'Нужно делиться с чат-ботами своими личными данными, чтобы получить более точный ответ',
                    correct: false,
                    message:
                        'Делиться личной информацией с чат-ботами небезопасно — она может утечь. ',
                },
                {
                    text: 'Нужно проверять генерируемую информацию, прежде чем её использовать',
                    correct: true,
                    message:
                        'Чат-боты могут давать неточные ответы — проверяйте, что они написали, в других источниках.',
                },
                {
                    text: 'Данные из чат-ботов не могут утечь',
                    correct: false,
                    message:
                        'Нейросети не всегда генерируют уникальные картинки. Иногда можно заставить нейросеть выдать практически неизмененное оригинальное изображение, которое ранее использовалось для обучения. Это значит, что нейросети могут невольно раскрыть приватную информацию.',
                },
                {
                    text: 'Нужно использовать только ChatGPT — он самый безопасный',
                    correct: false,
                    message:
                        'Проявляйте осторожность, чтобы не попасться на удочку злоумышленников. Пользуясь недоступностью популярных сервисов в некоторых регионах, атакующие могут под их видом создавать мошеннические сайты и даже распространять вредоносное ПО.',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Какую информацию о безопасности стоит проверить перед установкой нового приложения?',
            type: 'multiple',
            variants: [
                {
                    text: 'Политику конфиденциальности приложения',
                    correct: true,
                    message:
                        'Политика конфиденциальности объясняет, как приложение собирает, использует и защищает вашу информацию — эти знания важны для вашей безопасности.',
                },
                {
                    text: 'Цены на приложение в магазине',
                    correct: false,
                    message:
                        'Цены никак не влияют на безопасность приложения. ',
                },
                {
                    text: 'Рейтинг приложения',
                    correct: false,
                    message:
                        'Высокий рейтинг — не гарантия безопасности. Даже приложение с высоким рейтингом может быть ненадёжным, но обладать высоким рейтингом.',
                },
                {
                    text: 'Отзывы пользователей, которые уже установили приложение',
                    correct: true,
                    message:
                        'Они помогут понять, как ведёт себя приложение и насколько оно безопасно.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Какие меры помогут избежать установки вредоносных приложений?',
            type: 'multiple',
            variants: [
                {
                    text: 'Чтение отзывов пользователей в магазине приложений',
                    correct: true,
                    message:
                        'Отзывы могут дать представление о реальном опыте пользователей и помочь выявить потенциально опасные приложения.',
                },
                {
                    text: 'Скачивание приложений только из официальных источников',
                    correct: true,
                    message:
                        'Установка приложений только с официальных магазинов или сайтов разработчиков снижает риск, что на вашем устройстве появятся вредоносные программы.',
                },
                {
                    text: 'Приём всех запрашиваемых разрешений приложения',
                    correct: false,
                    message:
                        'Наоборот — если так делать, приложение может получить доступ к вашим личным данным, которые ему вовсе не нужны.',
                },
                {
                    text: 'Откладывание установки обновлений приложения',
                    correct: false,
                    message:
                        'Постоянное обновление приложений помогает устранять возможные уязвимости и усиливает безопасность.',
                },
            ],
        },
        {
            title: 'Вопрос 3. Верно ли это утверждение:<br> В политике конфиденциальности прописано, на каких условиях будет работать приложение. Принимая политику, вы соглашаетесь с этими условиями.',
            type: 'single',
            variants: [
                {
                    text: 'Верно',
                    correct: false,
                    message:
                        'Перед вами определение пользовательского соглашения — это документ, который объясняет, как приложение собирает, использует и защищает информацию, которую пользователь предоставляет при использовании приложения. В соглашении описываются правила и процедуры, которым следует приложение, чтобы сохранить конфиденциальность данных пользователей.',
                },
                {
                    text: 'Неверно',
                    correct: true,
                    message:
                        'Это описание пользовательского соглашения — документа, который объясняет, как приложение собирает, использует и защищает информацию, которую пользователь предоставляет при использовании приложения',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Какие действия помогут вам защитить детей от неподходящего контента в интернете?',
            type: 'multiple',
            variants: [
                {
                    text: 'Запрещать детям пользоваться смартфонами и разрешать использовать компьютер только для учёбы',
                    correct: false,
                    message:
                        'Полное ограничение доступа может привести к тому, что дети не научатся пользоваться гаджетами — из-за этого выше риск, что они столкнутся с кибератаками в будущем.',
                },
                {
                    text: 'Использовать родительский контроль',
                    correct: true,
                    message:
                        'Родительский контроль позволяет ограничивать доступ к нежелательным ресурсам и следить за активностью детей в интернете.',
                },
                {
                    text: 'Обсуждать с детьми все сайты и приложения, которыми они пользуются',
                    correct: true,
                    message:
                        'Техническую защиту в виде родительского контроля и детского профиля всегда лучше дополнять общением. Так вы сможете эффективнее защитить ребёнка от интернет-угроз.',
                },
                {
                    text: 'Запрещать использовать социальные сети',
                    correct: false,
                    message:
                        'Ребёнку может это не понравиться, и велика вероятность, что он начнёт делать это в тайне от вас — тогда вам будет сложнее защитить его от злоумышленников. Лучше объясните ребёнку, как безопасно использовать соцсети, а также подключите родительский контроль и детский профиль.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Какие рекомендации помогут вам научить ребёнка основам кибергигиены?',
            type: 'multiple',
            variants: [
                {
                    text: 'Не касаться обсуждений рисков кибербезопасности, этим должна заниматься школа ',
                    correct: false,
                    message:
                        'Безопасность ребёнка в интернете — ваша ответственность. Хорошо, если в школе обсуждают вопросы кибербезопасности, но если такого нет, нужно взять обучение в свои руки.',
                },
                {
                    text: 'Обсудить суть и последствия онлайн-груминга',
                    correct: true,
                    message:
                        'Онлайн-груминг — это попытка злоумышленника установить доверительные отношения с ребёнком. Цель — получить приватные фотографии или видео для шантажа, а также добиться встречи в реальной жизни. Если дети будут осознавать эту угрозу, они будут более бдительны и смогут не попасть в потенциально опасные ситуации.',
                },
                {
                    text: 'Спросить ребёнка о его любимых приложениях и сайтах',
                    correct: true,
                    message:
                        'Так сможете понять, какие ресурсы использует ребёнок, и выявить потенциальные риски.',
                },
                {
                    text: 'Рассказать ребёнку о киберугрозах на самых ярких примерах с негативными последствиями, чтобы он точно усвоил риски ',
                    correct: false,
                    message:
                        'Так можно запугать ребёнка. Лучше не драматизировать и рассказать о главных принципах кибергигиены.',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Верно ли следующее утверждение?<br>Социальная инженерия — это вид телефонного мошенничества, когда у вас пытаются похитить деньги с помощью психологических манипуляций.',
            type: 'single',
            variants: [
                {
                    text: 'Верно',
                    correct: false,
                    message:
                        'В телефонном мошенничестве действительно используется множество приёмов социальной инженерии, но само это понятие — шире. Социальная инженерия — психологические манипуляции, которыми пользуются злоумышленники, чтобы убедить людей сделать что-то или раскрыть ценную информацию.',
                },
                {
                    text: 'Неверно',
                    correct: true,
                    message:
                        'Телефонное мошенничество — это лишь один из видов атак, в котором злоумышленники используют приёмы социальной инженерии.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Выберите все верные признаки социальной инженерии',
            type: 'multiple',
            variants: [
                {
                    text: 'Вас торопят принять решение',
                    correct: true,
                    message:
                        'Злоумышленники просят совершить выгодные им действия как можно скорее, чтобы у вас не было времени критически осмыслить ситуацию.',
                },
                {
                    text: 'Давят на ваши эмоции',
                    correct: true,
                    message:
                        'Страх, жалость, чувство выгоды —  это то, на что давят злоумышленники, чтобы добиться своей цели.',
                },
                {
                    text: 'Ваше устройство заразили вредоносной программой',
                    correct: false,
                    message:
                        'Злоумышленники могут заразить компьютер или смартфон вредоносным ПО, используя методы социальной инженерии. Но сама по себе такая программа — не признак социальной инженерии.',
                },
                {
                    text: 'У вас пытаются узнать конфиденциальные данные',
                    correct: true,
                    message:
                        'Например, злоумышленник могут просить назвать пароли, коды из SMS и push-уведомлений или просить перевести деньги на «специальный» счёт.',
                },
            ],
        },
        {
            title: 'Вопрос 3. Какие меры помогут вам защититься от социальной инженерии?',
            type: 'multiple',
            variants: [
                {
                    text: 'Связываться с человеком или компанией по альтернативному каналу связи',
                    correct: true,
                    message:
                        'Так сможете перепроверить информацию и не попасться на уловки злоумышленников.',
                },
                {
                    text: 'Брать паузу, чтобы обдумать информацию',
                    correct: true,
                    message:
                        'Мошенники часто давят на эмоции, сообщают множество фактов и требуют немедленных действий от вас. Чтобы не попасться на их приёмы, возьмите паузу и перепроверьте, что вам говорят.',
                },
                {
                    text: 'Не отвечать на подозрительные сообщения, но переходить по ссылкам в них.',
                    correct: false,
                    message:
                        'Не стоит так делать — есть риск, что по ссылке вы перейдёте на поддельный сайт. Там у вас могут попытаться получить конфиденциальную информацию',
                },
                {
                    text: 'Не сообщать конфиденциальные данные по телефону, почте, сообщениям',
                    correct: true,
                    message:
                        'Цель злоумышленников — выведать у вас платёжные данные, коды из SMS и другую конфиденциальную информацию. Вас сразу должны насторожить подобные запросы. Никогда не сообщайте личные данные устно или письменно, кем бы ни представлялся человек.',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Какие из характеристик могут указывать на то, что сообщение — фишинговое? Выберите все подходящие варианты',
            type: 'multiple',
            variants: [
                {
                    text: 'Письмо содержит ссылки на ресурсы с названием компании с опечаткой ',
                    correct: true,
                    message:
                        'Злоумышленники часто используют подобные уловки, чтобы заставить пользователей перейти на поддельный сайт.',
                },
                {
                    text: 'Письмо начинается с общепринятого приветствия, типа «Уважаемый клиент», вместо обращения по имени.',
                    correct: false,
                    message:
                        'Это не признак фишинга. Так может выглядеть информационная рассылка от компании или сервиса, которыми вы пользуетесь.',
                },
                {
                    text: 'В письме вас просят срочно обновить пароль для защиты вашей учётной записи, угрожая приостановкой доступа',
                    correct: true,
                    message:
                        'Это типичная уловка, чтобы создать угрозу и заставить пользователя быстро отреагировать, игнорируя меры предосторожности.',
                },
                {
                    text: 'Письмо содержит фразы типа «Выигрыш» или «Денежная компенсация»',
                    correct: true,
                    message:
                        'Такие броские заголовки часто используются для привлечения внимания и манипуляции эмоциями пользователя.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Как можно защитить свою электронную почту от злоумышленников? Выберите все подходящие варианты',
            type: 'multiple',
            variants: [
                {
                    text: 'Проверять адрес отправителя перед тем, как открывать письма',
                    correct: true,
                    message:
                        'Это поможет не попасться в ловушку фишинга, так как многие злоумышленники используют поддельные адреса организаций или людей.',
                },
                {
                    text: 'Регулярно обновлять ПО и ОС на компьютере, чтобы защититься от уязвимостей',
                    correct: true,
                    message:
                        'Обновления помогают предотвратить использование известных уязвимостей злоумышленниками.',
                },
                {
                    text: 'После получения подозрительного письма всегда переходить по предоставленным ссылкам, чтобы узнать больше информации',
                    correct: false,
                    message:
                        'Переход по таким ссылкам может привести к заражению вашего устройства вредоносным ПО или к утечке личных данных.',
                },
                {
                    text: 'Публиковать свой личный адрес электронной почты на общедоступных ресурсах, чтобы его могли видеть администраторы для восстановления доступа к аккаунту',
                    correct: false,
                    message:
                        'Публикация личного адреса может привести к тому, что он попадёт в базы данных спамеров и злоумышленников. ',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Какие признаки могут говорить о том, что вы сталкиваетесь с телефонным мошенничеством? Выберите все подходящие варианты',
            type: 'multiple',
            variants: [
                {
                    text: 'Звонок поступает с официального номера банка',
                    correct: true,
                    message:
                        'Мошенники могут подменять номера, поэтому звонок с официального номера не всегда гарантирует надёжность.',
                },
                {
                    text: 'Звонящий требует срочно предоставить ваши личные данные или совершить финансовую операцию',
                    correct: true,
                    message:
                        'Срочное требование выдать конфиденциальную информацию или совершить финансовую операцию — явный признак мошенничества.',
                },
                {
                    text: 'Вам звонит начальник службы безопасности банка и просит назвать код от карточки, чтобы заблокировать её от действий мошенников',
                    correct: true,
                    message:
                        'Это типичная многоступенчатая мошенническая схема, когда злоумышленники придумывают ситуацию, что с вашим счётом якобы уже что-то делают мошенники',
                },
                {
                    text: 'Ваш начальник пишет вам в мессенджере с просьбой перейти по ссылке и заполнить анкету для государственного органа',
                    correct: true,
                    message:
                        'Это типичный пример фишинга, который может привести к передаче конфиденциальных данных мошенникам',
                },
            ],
        },
        {
            title: 'Вопрос 2. Как можно защитить себя от телефонных мошенников? Выберите все подходящие варианты',
            type: 'multiple',
            variants: [
                {
                    text: 'Нужно вступать в разговор со злоумышленниками, чтобы узнать, что они хотят',
                    correct: false,
                    message:
                        'Общение с мошенниками может усугубить ситуацию и привести к утечке личной информации. Напротив, нужно сразу прекратить разговор с незнакомцем.',
                },
                {
                    text: 'Нужно игнорировать звонки с незнакомых номеров и никогда не перезванивать',
                    correct: true,
                    message:
                        'Исключение контактов с незнакомыми номерами снижает риск попасться на уловки злоумышленников.',
                },
                {
                    text: 'Следует проверять информацию о звонящих через официальные сайты',
                    correct: true,
                    message:
                        'Переход по таким ссылкам может привести к заражению вашего устройства вредоносным ПО или к утечке личных данных.',
                },
                {
                    text: 'Нет верного ответа',
                    correct: false,
                    message:
                        'Советы об игнорировании звонков с незнакомых номеров и о проверке информации о звонящих помогут уберечься от злоумышленников.',
                },
            ],
        },
        {
            title: 'Вопрос 3. Верно ли данное утверждение? <br> Если вы передали данные злоумышленникам, нужно обратиться в банк по номеру на официальном сайте. Следует сообщить о случившемся и заблокировать карту. Также следует сообщить о произошедшем представителю сотового оператора.',
            type: 'single',
            variants: [
                {
                    text: 'Верно',
                    correct: true,
                    message:
                        'Обращение в банк и сотовому оператору — это первый шаг, который нужно сделать, если вы передали данные мошенникам.',
                },
                {
                    text: 'Неверно',
                    correct: false,
                    message:
                        'Нужно сразу позвонить в банк и сотовому оператору, рассказать им о ситуации и действовать по инструкции, которую дадут сотрудники.',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Какие признаки могут указывать на то, что вы сталкиваетесь с мошенничеством при онлайн-покупках? Выберите все подходящие варианты',
            type: 'multiple',
            variants: [
                {
                    text: 'Доменное имя сайта интернет-магазина или маркетплейса содержит опечатку',
                    correct: true,
                    message:
                        'Мошенники часто подделывают сайты и делают похожее доменное имя, чтобы ввести покупателей в заблуждение.',
                },
                {
                    text: 'На товар в интернет-магазине нет отзывов',
                    correct: false,
                    message:
                        'Это не признак мошенничества. Возможно, товар у продавца – новый.',
                },
                {
                    text: 'Товар продаётся с большой скидкой и по низкой цене',
                    correct: true,
                    message:
                        'Это может быть признаком недобросовестного продавца.',
                },
                {
                    text: 'Отзывы на товар однотипные',
                    correct: true,
                    message:
                        'Это может быть признаком мошенничества — насторожитесь.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Как обезопасить себя от мошенничества при онлайн-покупках? Выберите все подходящие варианты',
            type: 'multiple',
            variants: [
                {
                    text: 'Не доверять письмам и сообщениям, обещающим невероятные скидки',
                    correct: true,
                    message: 'Это может быть признаком мошенничества.',
                },
                {
                    text: 'Совершать покупки, подключившись к публичным WiFi-сетям',
                    correct: false,
                    message:
                        'Лучше отложите покупку до момента, когда вы сможете её сделать с помощью мобильной связи или в домашней сети.',
                },
                {
                    text: 'Проверять адреса сайтов, прежде чем вводить свои данные.',
                    correct: true,
                    message:
                        'Это поможет избежать фишинга и других схем злоумышленников.',
                },
                {
                    text: 'Завести отдельную карту для покупок в интернете',
                    correct: true,
                    message:
                        'Хранение на карте суммы только для предстоящей покупки, поможет уберечь средства от мошенников.',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Выберите все верные определения методов, которые используют злоумышленники в социальных сетях и мессенджерах',
            type: 'multiple',
            variants: [
                {
                    text: 'Киберсталкинг — использование цифровых средств для преследования или домогательств до человека, группы людей или организации',
                    correct: true,
                    message: 'Это определение верно описывает киберсталкинг.',
                },
                {
                    text: 'Кибербуллинг — это случай, когда кто-то не согласен с вашим мнением в интернете',
                    correct: false,
                    message:
                        'Кибербуллинг подразумевает травлю, а не просто разногласия.',
                },
                {
                    text: 'Доксинг — это создание поддельной личности с целью выманивания денег или информации',
                    correct: false,
                    message:
                        'Доксинг — это сбор и публикация личной информации без согласия её владельца.',
                },
                {
                    text: 'Кэтфишинг – это метод, когда злоумышленник притворяется попечителем фонда помощи бездомным животным и выманивает деньги под предлогом благотворительности',
                    correct: false,
                    message:
                        'Кэтфишинг — создание поддельной личности в соцсетях, мессенджерах или приложениях для знакомств с целью получения информации или денег.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Какие меры предосторожности следует соблюдать для безопасного использования социальных сетей и мессенджеров? Выберите все подходящие варианты.',
            type: 'multiple',
            variants: [
                {
                    text: 'Добавлять в друзья только тех, с кем вы знакомы лично',
                    correct: true,
                    message:
                        'Это помогает избежать контактов со злоумышленниками.',
                },
                {
                    text: 'Открыто публиковать номер телефона, чтобы восстановить аккаунт в случае взлома',
                    correct: false,
                    message:
                        'Не стоит публиковать номер телефона и адрес в соцсетях и мессенджерах. Ими могут воспользоваться злоумышленники.',
                },
                {
                    text: 'Проверять и настраивать параметры конфиденциальности ',
                    correct: true,
                    message:
                        'Это позволяет контролировать, кто может видеть ваши посты и данные о вас.',
                },
                {
                    text: 'Игнорировать сообщения об оскорблениях и избегать диалога с обидчиком',
                    correct: true,
                    message: 'Это помогает избежать усугубления конфликта.',
                },
            ],
        },
        {
            title: 'Вопрос 3. Что нужно сделать, если ваш аккаунт в социальной сети или мессенджере взломали? Выберите все подходящие варианты.',
            type: 'multiple',
            variants: [
                {
                    text: 'Проверить смартфон и компьютер с помощью защитного решения',
                    correct: true,
                    message:
                        'Это поможет обнаружить вредоносное ПО, если оно есть.',
                },
                {
                    text: 'Сбросить пароль',
                    correct: true,
                    message:
                        'Есть шанс, что злоумышленник не успел отвязать от аккаунта вашу почту или номер телефона. При этом убедитесь, что почта не взломана и для профилактики поменяйте на ней пароль. ',
                },
                {
                    text: 'Не предупреждать знакомых о взломе, чтобы избежать утечки информации',
                    correct: false,
                    message:
                        'Позвоните близким или напишите им в другом сервисе, опубликуйте сообщение о взломе в соцсетях, где вас читают.',
                },
                {
                    text: 'Обратиться  в службу поддержки соцсети или мессенджера и сообщить о взломе',
                    correct: true,
                    message:
                        'Если это удалось, смените пароль и контрольные вопросы, привяжите к аккаунту свою электронную почту и телефон и настройте двухфакторную аутентификацию',
                },
            ],
        },
    ],
    [
        {
            title: 'Вопрос 1. Какие из следующих данных о себе не стоит публиковать в интернете? Выберите все правильные варианты.',
            type: 'multiple',
            variants: [
                {
                    text: 'Название отеля, где вы планируете провести отпуск',
                    correct: true,
                    message:
                        'Публикация этих данных даст понять злоумышленникам, что вы не будете дома — это увеличивает риск кражи.',
                },
                {
                    text: 'Геолокация вашего постоянного проживания',
                    correct: true,
                    message:
                        'Публикация этого типа информации может угрожать вашей безопасности.',
                },
                {
                    text: 'Возраст вашего первого домашнего любимца',
                    correct: false,
                    message:
                        'Эта информация не является конфиденциальной. Однако не стоит включать в пароли имена, возраст ваших близких и питомцев.',
                },
                {
                    text: 'Номер школы, где учатся ваши дети',
                    correct: true,
                    message:
                        'Этой информацией могут воспользоваться злоумышленники и киберсталкеры. Не рискуйте безопасностью детей.',
                },
            ],
        },
        {
            title: 'Вопрос 2. Верно ли данное утверждение? </br> Вы можете требовать удаления своих персональных данных из базы данных оператора персональных данных. ',
            type: 'single',
            variants: [
                {
                    text: 'Верно',
                    correct: true,
                    message:
                        'Согласно Федеральному закону «О персональных данных», вы можете требовать удаления ваших персональных данных из баз данных оператора. Более того, если вы считаете, что оператор персональных данных нарушает ваши права, вы можете обратиться в Роскомнадзор с заявлением.',
                },
                {
                    text: 'Неверно',
                    correct: false,
                    message:
                        'Согласно Федеральному закону «О персональных данных», вы можете это требовать.',
                },
            ],
        },
    ],
];
