import { GetOrganizations, Organization } from 'types';
import { ApiService } from 'services';
import { PaginationOutput } from 'kl-b2c-ui-kit';

export class OrganizationApi extends ApiService {
    public getOrganizations(
        params: GetOrganizations
    ): Promise<PaginationOutput<Organization>> {
        return this.request<Organization>({
            method: 'GET',
            url: '/Organization/GetOrganizations',
            params,
        }).then((response) => response.data);
    }
}
