import { Question } from '../../types';
import { CourseDirection, CourseForWhom, CourseSkill, EventType } from 'types';
import TeacherWelcome from './components';

export const getData = (
    languages: { id: number; name: string }[]
): Question => {
    const baseSelect = (
        languagesId?: number[],
        skillsId?: CourseSkill[],
        directionsId?: CourseDirection[],
        studyFormsId?: EventType[],
        forWhomId?: CourseForWhom[]
    ) => {
        window.location.href = `/courses/${
            skillsId ? skillsId.join(',') : 'n'
        }/${languagesId ? languagesId.join(',') : 'n'}/${
            directionsId ? directionsId.join(',') : 'n'
        }/${studyFormsId ? studyFormsId.join(',') : 'n'}/${
            forWhomId ? forWhomId.join(',') : 'n'
        }/`;
    };
    const trueLanguages: Question[] = [
        ...languages.map((l) => ({
            id: '',
            title: 'select-course',
            answer: l.name,
            val: l.id,
        })),
        {
            id: '',
            title: 'select-course',
            answer: 'all',
        },
    ];

    const firstStep: Question = {
        id: window.crypto.randomUUID(),
        question: 'for-whom',
        title: 'select-course',
    };

    const secondStepMyself: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        question: 'select-skill',
        answer: 'for-myself',
        prev: firstStep,
    };

    const secondStepStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        question: 'select-skill',
        answer: 'for-staff',
        prev: firstStep,
    };

    const secondStepStudent: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        question: 'who-you-are',
        answer: 'for-students',
        prev: firstStep,
    };

    firstStep.next = [secondStepMyself, secondStepStaff, secondStepStudent];

    const thirdStepTechnicalSelf: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'technical',
        question: 'which-direction',
        prev: secondStepMyself,
    };

    const thirdStepCyberSecuritySelf: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'cyber-security',
        question: 'which-direction',
        prev: secondStepMyself,
    };

    secondStepMyself.next = [
        thirdStepTechnicalSelf,
        thirdStepCyberSecuritySelf,
    ];

    const thirdStepTechnical: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'technical',
        question: 'which-direction',
        prev: secondStepStaff,
    };

    const thirdStepCommunicative: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'communicative',
        question: 'which-direction',
        prev: secondStepStaff,
    };

    const thirdStepCyberSecurity: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'cyber-security',
        question: 'which-direction',
        prev: secondStepStaff,
    };

    secondStepStaff.next = [
        thirdStepTechnical,
        thirdStepCommunicative,
        thirdStepCyberSecurity,
    ];

    const thirdStepIAmStudent: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'i-am-student',
        prev: secondStepStudent,
    };

    const langForStudents: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: thirdStepIAmStudent,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                undefined,
                [
                    CourseDirection.ForStudentsAndInterns,
                    CourseDirection.FreeCourse,
                ],
                undefined,
                [CourseForWhom.ForStudents]
            );
        },
    }));

    thirdStepIAmStudent.next = langForStudents;

    const thirdStepIAmTeacher: Question = {
        id: window.crypto.randomUUID(),
        answer: 'i-am-teacher',
        title: 'select-course',
        prev: secondStepStudent,
        component: TeacherWelcome,
    };

    secondStepStudent.next = [thirdStepIAmStudent, thirdStepIAmTeacher];

    const fourthStepForItTechSelf: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-it-specialists',
        question: 'select-lang',
        prev: thirdStepTechnicalSelf,
    };

    const langForItTechSelf: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: fourthStepForItTechSelf,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                [CourseSkill.Technical],
                [CourseDirection.ForItProfessionals],
                undefined,
                [CourseForWhom.ForMySelf]
            );
        },
    }));

    fourthStepForItTechSelf.next = langForItTechSelf;

    const fourthStepForStudentsTechSelf: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'cyber-for-students',
        question: 'select-lang',
        prev: thirdStepTechnicalSelf,
    };

    const langStudentsTechSelf: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: fourthStepForStudentsTechSelf,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                [CourseSkill.Technical],
                [CourseDirection.ForStudentsAndInterns],
                undefined,
                [CourseForWhom.ForMySelf]
            );
        },
    }));

    fourthStepForStudentsTechSelf.next = langStudentsTechSelf;

    const forthStepForSecurityTechSelf: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-security-specializes',
        question: 'select-lang',
        prev: thirdStepTechnicalSelf,
    };

    const langSecurityTechSelf: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: forthStepForSecurityTechSelf,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                [CourseSkill.Technical],
                [CourseDirection.AdvancedCybersecurity],
                undefined,
                [CourseForWhom.ForMySelf]
            );
        },
    }));

    forthStepForSecurityTechSelf.next = langSecurityTechSelf;

    const forthStepAllTechSelf: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'all',
        question: 'select-lang',
        prev: thirdStepTechnicalSelf,
    };

    const langAllTechSelf: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: forthStepAllTechSelf,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                [CourseSkill.Technical, CourseSkill.KnowledgeCyberSecurity],
                undefined,
                undefined,
                [CourseForWhom.ForMySelf]
            );
        },
    }));

    forthStepAllTechSelf.next = langAllTechSelf;

    thirdStepTechnicalSelf.next = [
        fourthStepForItTechSelf,
        fourthStepForStudentsTechSelf,
        forthStepForSecurityTechSelf,
        forthStepAllTechSelf,
    ];

    const forthStepForSecurityCommunicativeSelf: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-security-specializes',
        question: 'select-lang',
        prev: thirdStepCyberSecuritySelf,
    };

    const langForSecurityCommunicativeSelf: Question[] = trueLanguages.map(
        (l) => ({
            id: window.crypto.randomUUID(),
            answer: l.answer,
            title: 'select-course',
            prev: forthStepForSecurityCommunicativeSelf,
            select: () => {
                baseSelect(
                    l.val ? [l.val] : undefined,
                    [CourseSkill.Technical],
                    [CourseDirection.AdvancedCybersecurity],
                    undefined,
                    [CourseForWhom.ForMySelf]
                );
            },
        })
    );

    forthStepForSecurityCommunicativeSelf.next =
        langForSecurityCommunicativeSelf;

    const forthStepAllCommunicativeSelf: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'all',
        question: 'select-lang',
        prev: thirdStepCyberSecuritySelf,
    };

    const langAllCommunicativeSelf: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: forthStepAllCommunicativeSelf,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                [CourseSkill.KnowledgeCyberSecurity, CourseSkill.Technical],
                undefined,
                undefined,
                [CourseForWhom.ForMySelf]
            );
        },
    }));

    forthStepAllCommunicativeSelf.next = langAllCommunicativeSelf;

    const forthStepBusinessOwnerCommunicativeSelf: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-business-owner',
        question: 'select-lang',
        prev: thirdStepCyberSecuritySelf,
    };

    const langBusinessOwnerCommunicativeSelf: Question[] = trueLanguages.map(
        (l) => ({
            id: window.crypto.randomUUID(),
            answer: l.answer,
            title: 'select-course',
            prev: forthStepBusinessOwnerCommunicativeSelf,
            select: () => {
                baseSelect(
                    l.val ? [l.val] : undefined,
                    [CourseSkill.KnowledgeCyberSecurity],
                    [CourseDirection.ForTopManagers],
                    undefined,
                    [CourseForWhom.ForMySelf]
                );
            },
        })
    );

    forthStepBusinessOwnerCommunicativeSelf.next =
        langBusinessOwnerCommunicativeSelf;

    const forthStepFreeCourseCyberCommunicativeSelf: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'free-course-for-cyber',
        question: 'select-lang',
        prev: thirdStepCyberSecuritySelf,
    };

    const langFreeCourseCyberCommunicativeSelf: Question[] = trueLanguages.map(
        (l) => ({
            id: window.crypto.randomUUID(),
            answer: l.answer,
            title: 'select-course',
            prev: forthStepFreeCourseCyberCommunicativeSelf,
            select: () => {
                baseSelect(
                    l.val ? [l.val] : undefined,
                    [CourseSkill.KnowledgeCyberSecurity],
                    [CourseDirection.FreeCourse],
                    undefined,
                    [CourseForWhom.ForMySelf]
                );
            },
        })
    );

    forthStepFreeCourseCyberCommunicativeSelf.next =
        langFreeCourseCyberCommunicativeSelf;

    thirdStepCyberSecuritySelf.next = [
        forthStepForSecurityCommunicativeSelf,
        forthStepBusinessOwnerCommunicativeSelf,
        forthStepFreeCourseCyberCommunicativeSelf,
        forthStepAllCommunicativeSelf,
    ];

    const forthStepForSecurityTechStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-security-specializes',
        question: 'select-lang',
        prev: thirdStepTechnical,
    };

    const langForSecurityTechStaff: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: forthStepForSecurityTechStaff,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                [CourseSkill.Technical],
                [CourseDirection.AdvancedCybersecurity],
                undefined,
                [CourseForWhom.ForEmployers]
            );
        },
    }));

    forthStepForSecurityTechStaff.next = langForSecurityTechStaff;

    const forthStepForItTechStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-it-specialists',
        question: 'select-lang',
        prev: thirdStepTechnical,
    };

    const langForItTechStaff: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: forthStepForItTechStaff,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                [CourseSkill.Technical],
                [CourseDirection.ForItProfessionals],
                undefined,
                [CourseForWhom.ForEmployers]
            );
        },
    }));

    forthStepForItTechStaff.next = langForItTechStaff;

    const forthStepForEveryoneTechStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'all',
        question: 'select-lang',
        prev: thirdStepTechnical,
    };

    const langForEveryoneTechStaff: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: forthStepForEveryoneTechStaff,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                [CourseSkill.Technical],
                undefined,
                undefined,
                [CourseForWhom.ForEmployers]
            );
        },
    }));

    forthStepForEveryoneTechStaff.next = langForEveryoneTechStaff;

    const forthStepForManagersTechStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-managers',
        question: 'select-lang',
        prev: thirdStepTechnical,
    };

    const langForManagersTechStaff: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: forthStepForManagersTechStaff,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                [CourseSkill.Technical],
                [CourseDirection.ManagementAndCommunications],
                undefined,
                [CourseForWhom.ForEmployers]
            );
        },
    }));

    forthStepForManagersTechStaff.next = langForManagersTechStaff;

    thirdStepTechnical.next = [
        forthStepForSecurityTechStaff,
        forthStepForItTechStaff,
        forthStepForManagersTechStaff,
        forthStepForEveryoneTechStaff,
    ];

    const forthStepForManagersCommunicateStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-managers',
        question: 'select-lang',
        prev: thirdStepCommunicative,
    };

    const langForManagersCommunicateStaff: Question[] = trueLanguages.map(
        (l) => ({
            id: window.crypto.randomUUID(),
            answer: l.answer,
            title: 'select-course',
            prev: forthStepForManagersCommunicateStaff,
            select: () => {
                baseSelect(
                    l.val ? [l.val] : undefined,
                    [CourseSkill.CommunicatesManagers],
                    [CourseDirection.ManagementAndCommunications],
                    undefined,
                    [CourseForWhom.ForEmployers]
                );
            },
        })
    );

    forthStepForManagersCommunicateStaff.next = langForManagersCommunicateStaff;

    const forthStepForSellerCommunicateStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-seller',
        question: 'select-lang',
        prev: thirdStepCommunicative,
    };

    const langForSellerCommunicateStaff: Question[] = trueLanguages.map(
        (l) => ({
            id: window.crypto.randomUUID(),
            answer: l.answer,
            title: 'select-course',
            prev: forthStepForSellerCommunicateStaff,
            select: () => {
                baseSelect(
                    l.val ? [l.val] : undefined,
                    [CourseSkill.CommunicatesManagers],
                    [CourseDirection.SalesAndMarketing],
                    undefined,
                    [CourseForWhom.ForEmployers]
                );
            },
        })
    );

    forthStepForSellerCommunicateStaff.next = langForSellerCommunicateStaff;

    const forthStepForBusinessOwnerCommunicateStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-business-owner',
        question: 'select-lang',
        prev: thirdStepCommunicative,
    };

    const langForBusinessOwnerCommunicateStaff: Question[] = trueLanguages.map(
        (l) => ({
            id: window.crypto.randomUUID(),
            answer: l.answer,
            title: 'select-course',
            prev: forthStepForBusinessOwnerCommunicateStaff,
            select: () => {
                baseSelect(
                    l.val ? [l.val] : undefined,
                    [CourseSkill.CommunicatesManagers],
                    [CourseDirection.ForTopManagers],
                    undefined,
                    [CourseForWhom.ForEmployers]
                );
            },
        })
    );

    forthStepForBusinessOwnerCommunicateStaff.next =
        langForBusinessOwnerCommunicateStaff;

    const forthStepEveryoneCommunicateStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'all',
        question: 'select-lang',
        prev: thirdStepCommunicative,
    };

    const langEveryoneCommunicateStaff: Question[] = trueLanguages.map((l) => ({
        id: window.crypto.randomUUID(),
        answer: l.answer,
        title: 'select-course',
        prev: forthStepEveryoneCommunicateStaff,
        select: () => {
            baseSelect(
                l.val ? [l.val] : undefined,
                [CourseSkill.CommunicatesManagers],
                undefined,
                undefined,
                [CourseForWhom.ForEmployers]
            );
        },
    }));

    forthStepEveryoneCommunicateStaff.next = langEveryoneCommunicateStaff;

    thirdStepCommunicative.next = [
        forthStepForManagersCommunicateStaff,
        forthStepForSellerCommunicateStaff,
        forthStepForBusinessOwnerCommunicateStaff,
        forthStepEveryoneCommunicateStaff,
    ];

    const forthStepForTopManagersCyberSecurityStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-business-owner',
        question: 'select-lang',
        prev: thirdStepCyberSecurity,
    };

    const langForSecurityCyberSecurityStaff: Question[] = trueLanguages.map(
        (l) => ({
            id: window.crypto.randomUUID(),
            answer: l.answer,
            title: 'select-course',
            prev: forthStepForTopManagersCyberSecurityStaff,
            select: () => {
                baseSelect(
                    l.val ? [l.val] : undefined,
                    [CourseSkill.KnowledgeCyberSecurity],
                    [CourseDirection.ForTopManagers],
                    undefined,
                    [CourseForWhom.ForEmployers]
                );
            },
        })
    );

    forthStepForTopManagersCyberSecurityStaff.next =
        langForSecurityCyberSecurityStaff;

    const forthStepForTeamOwnerCyberSecurityStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'knowledge-for-team',
        question: 'select-lang',
        prev: thirdStepCyberSecurity,
    };

    const langForBusinessOwnerCyberSecurityStaff: Question[] =
        trueLanguages.map((l) => ({
            id: window.crypto.randomUUID(),
            answer: l.answer,
            title: 'select-course',
            prev: forthStepForTeamOwnerCyberSecurityStaff,
            select: () => {
                baseSelect(
                    l.val ? [l.val] : undefined,
                    [CourseSkill.KnowledgeCyberSecurity],
                    [CourseDirection.ForEmployeesAwareness],
                    undefined,
                    [CourseForWhom.ForEmployers]
                );
            },
        }));

    forthStepForTeamOwnerCyberSecurityStaff.next =
        langForBusinessOwnerCyberSecurityStaff;

    const forthStepAdditionalSecurityCyberSecurityStaff: Question = {
        id: window.crypto.randomUUID(),
        title: 'select-course',
        answer: 'for-security-specializes',
        question: 'select-lang',
        prev: thirdStepCyberSecurity,
    };

    const langAdditionalSecurityCyberSecurityStaff: Question[] =
        trueLanguages.map((l) => ({
            id: window.crypto.randomUUID(),
            answer: l.answer,
            title: 'select-course',
            prev: forthStepAdditionalSecurityCyberSecurityStaff,
            select: () => {
                baseSelect(
                    l.val ? [l.val] : undefined,
                    [CourseSkill.KnowledgeCyberSecurity],
                    [CourseDirection.AdvancedCybersecurity],
                    undefined,
                    [CourseForWhom.ForEmployers]
                );
            },
        }));

    forthStepAdditionalSecurityCyberSecurityStaff.next =
        langAdditionalSecurityCyberSecurityStaff;

    thirdStepCyberSecurity.next = [
        forthStepForTopManagersCyberSecurityStaff,
        forthStepForTeamOwnerCyberSecurityStaff,
        forthStepAdditionalSecurityCyberSecurityStaff,
    ];

    return firstStep;
};
