export enum SocialLinksType {
    FOOTER = 'FOOTER',
    NEWS_ITEM = 'NEWS_ITEM',
}

export enum SocialLinkName {
    facebook = 'facebook',
    twitter = 'twitter',
    linkedin = 'linkedin',
    youtube = 'youtube',
    instagram = 'instagram',
    vk = 'vk',
    telegram = 'telegram',
}
