import React, {
    Context,
    createContext,
    FC,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';

interface MobileMenuState {
    isMobileMenuOpened: boolean;
    toggleMobileMenu: (opened: boolean) => void;
    toggleCorporateStyles: (isCorporate: boolean) => void;
    toggleProfessionStyles: (isProfession: boolean) => void;
    toggleCourseStyles: (isCourse: boolean) => void;
    isCorporatePage: boolean;
    isProfessionPage: boolean;
    isCoursePage: boolean;
}

const initState: MobileMenuState = {
    isMobileMenuOpened: false,
    toggleMobileMenu: () => {},
    isCorporatePage: window.location.pathname === '/for-businesses',
    isProfessionPage: window.location.pathname.includes('profession'),
    isCoursePage: window.location.pathname.includes('course'),
    toggleCorporateStyles: () => {},
    toggleProfessionStyles: () => {},
    toggleCourseStyles: () => {},
};

const MobileMenuContext: Context<MobileMenuState> = createContext(initState);

export const MobileMenuStateProvider: FC<{ children: ReactNode }> = (props) => {
    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(
        initState.isMobileMenuOpened
    );
    const [isCorporatePage, setIsCorporatePage] = useState(
        initState.isCorporatePage
    );
    const [isProfessionPage, setIsProfessionPage] = useState(
        initState.isProfessionPage
    );
    const [isCoursePage, setIsCoursePage] = useState(initState.isCoursePage);
    const location = useLocation();

    const toggleMobileMenu = useCallback((opened: boolean) => {
        setIsMobileMenuOpened(opened);
    }, []);

    const toggleCorporateStyles = useCallback((isCorporate: boolean) => {
        setIsCorporatePage(isCorporate);
    }, []);

    const toggleCourseStyles = useCallback((isCourse: boolean) => {
        setIsCoursePage(isCourse);
    }, []);

    const toggleProfessionStyles = useCallback((isProfession: boolean) => {
        setIsProfessionPage(isProfession);
    }, []);

    useEffect(() => {
        isMobileMenuOpened && toggleMobileMenu(false);
    }, [location]);

    useEffect(() => {
        const openMenuClass = 'is-header-menu-open';
        isMobileMenuOpened
            ? document.body.classList.add(openMenuClass)
            : document.body.classList.remove(openMenuClass);
    }, [isMobileMenuOpened]);

    return (
        <MobileMenuContext.Provider
            value={{
                toggleMobileMenu,
                isMobileMenuOpened,
                toggleCorporateStyles,
                toggleProfessionStyles,
                isCorporatePage,
                isProfessionPage,
                toggleCourseStyles,
                isCoursePage,
            }}
            {...props}
        />
    );
};

export const useMobileMenuContext = () => useContext(MobileMenuContext);
