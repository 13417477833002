import styled from 'styled-components';
import { colorPrimary } from 'theme/theme-helper-selectors';

export const NativeLink = styled.a`
    color: ${colorPrimary};
    text-decoration: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding: 10px 0;
`;
