import { urlEncodeUtil } from 'utils';
import { ApiService } from 'services';
import { INewsService, Lang, NewsListItem, NewsTag } from 'types';
import { AxiosResponse } from 'axios';

export class NewsApi extends ApiService implements INewsService {
    public getNewsList(
        params: Record<string, number | string>
    ): Promise<{ count: 0; items: [] }> {
        return this.request<{ count: 0; items: [] }>({
            method: 'GET',
            url: '/News/GetItems',
            params,
            paramsSerializer: urlEncodeUtil,
        }).then((response) => response?.data);
    }

    public getDetailedNewsItem(
        GroupName: string,
        DomainType: Lang
    ): Promise<AxiosResponse<NewsListItem>> {
        return this.request<NewsListItem>({
            method: 'GET',
            url: '/News/GetItem',
            params: {
                GroupName,
                DomainType,
            },
        }).then((response) => response);
    }

    public getTags(DomainType: Lang): Promise<NewsTag[]> {
        return this.request<NewsTag[]>({
            method: 'GET',
            url: '/News/GetTags',
            params: {
                DomainType,
            },
        }).then((response) => response?.data || []);
    }
}
