import { Button } from 'kl-b2c-ui-kit';
import styled, { css, CSSObject } from 'styled-components';
import {
    colorWhite,
    mediaMd,
    mediaSm,
    mediaXs,
    transitionBase,
} from 'theme/theme-helper-selectors';

export const Container = styled.div<{
    secondary?: boolean;
}>`
    display: flex;
    flex-direction: row;
    margin: 0 0 87px 0;

    ${({ secondary }) =>
        secondary &&
        css`
            transform-origin: left bottom;
            transform: skewY(-3deg);
        `};

    @media (${mediaMd}) {
        flex-direction: column;
        gap: 50px;
    }
`;

export const SpeakersButton = styled(Button)<{ styles?: CSSObject }>`
    border-radius: 8px;
    letter-spacing: 0;
    font-size: 16px;

    ${({ styles }) => ({ ...styles })};
`;

export const DescriptionWrapper = styled.div`
    width: 30%;

    @media (${mediaMd}) {
        width: 100%;
    }
`;

export const Title = styled.h2`
    font-size: 72px;
    line-height: 72px;
    font-weight: 400;
    margin: 0 0 26px 0;

    @media (${mediaSm}) {
        font-size: 52px;
        line-height: 64px;
        margin: 0 0 20px 0;
    }
`;

export const Subtitle = styled.p`
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 40px 0;
    font-weight: 300;

    @media (${mediaSm}) {
        font-size: 16px;
        line-height: 17px;
        margin: 0 0 20px 0;
    }
`;

export const SlideImage = styled.div<{ image?: string }>`
    border-radius: 16px;
    background-image: ${({ image }) => `url(${image})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 0 16px 0;
    width: 166px;
    height: 205px;
    transition: ${transitionBase};

    @media (${mediaSm}) {
        height: 205px !important;
        width: 166px !important;
    }
`;

export const SliderStyler = styled.div<{ alignTop?: boolean }>`
    width: 70%;
    padding: 0 0 0 100px;

    @media (${mediaMd}) {
        width: 100%;
        padding: 0;
    }

    .slick-track {
        display: flex;
        flex-direction: row;
        align-items: ${({ alignTop }) => (alignTop ? 'flex-start' : 'center')};
    }

    .slick-slide {
        &:has(+ :nth-child(3n)) {
            ${SlideImage} {
                height: 312px;
                width: 252px;
            }
        }
    }

    .slick-slide {
        &:not(.slick-active) {
            visibility: hidden;
        }
    }
`;

export const Slide = styled.div`
    display: flex !important;
    align-items: center;
    flex-direction: column;
`;

export const SlideTitle = styled.h4`
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    margin: 0 0 12px 0;
`;

export const SlideSubtitle = styled.p<{ secondary?: boolean }>`
    font-size: 16px;
    line-height: 18px;
    color: ${({ secondary }) => (secondary ? colorWhite : '#383f48')};
    font-weight: 300;
    text-align: center;
`;

export const Disclaimer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 63px;
`;

export const DisclaimerItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;

    &:nth-child(2) {
        align-self: flex-end;

        @media (${mediaMd}) {
            align-self: flex-start;
        }
    }

    @media (${mediaMd}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
`;

export const DisclaimerTitle = styled.div`
    font-size: 181px;
    line-height: 181px;
    font-weight: 500;

    @media (${mediaMd}) {
        font-size: 91px;
        line-height: 91px;
    }

    @media (${mediaXs}) {
        font-size: 71px;
        line-height: 71px;
    }
`;

export const DisclaimerSubtitle = styled.div`
    font-size: 42px;
    line-height: 42px;
    font-weight: 400;
    max-width: 250px;

    @media (${mediaMd}) {
        max-width: initial;

        font-size: 32px;
        line-height: 32px;
    }
`;

export const DisclaimerDescription = styled.div`
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;

    padding: 12px 0 0 0;
`;

export const Hexa = styled.div<{
    image: string;
    secondary?: boolean;
    styles?: CSSObject;
}>`
    position: absolute;
    height: 1120px;
    width: 100%;
    max-width: 1036px;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-image: ${({ image }) => `url(${image})`};
    background-size: cover;
    background-repeat: no-repeat;

    @media (${mediaMd}) {
        bottom: 8%;
        height: 1200px;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            height: 100%;
            left: 10%;
            transform: skewY(-3deg);
            bottom: -5%;
            max-width: 1336px;

            @media (${mediaMd}) {
                bottom: -5%;
                height: 100%;
            }

            @media (${mediaXs}) {
                left: 0;
            }
        `};

    ${({ styles }) => ({ ...styles })};
`;

export const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
`;

export const PersonalDataDisclaimer = styled.p`
    font-size: 10px;
    color: ${({ theme }) => theme.palette.gray[200]};
    margin: 10px 0 0 0;
`;
