import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
    colorPrimaryLight,
    colorText,
    mediaSm,
    mediaTablet,
} from 'theme/theme-helper-selectors';

const navLinkMixin = css`
    text-decoration: none;
    color: ${colorText};
    height: 100%;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;

    &:hover {
        @media (${mediaTablet}) {
            border-bottom: 4px solid ${colorPrimaryLight};
        }
    }

    @media (${mediaSm}) {
        width: 100%;
    }
`;

export const NativeNavLink = styled.a`
    ${navLinkMixin}
`;

export const RouterNavLink = styled(Link)`
    ${navLinkMixin}
`;

export const ButtonNavAction = styled.button`
    background: transparent;
    border: none;
`;
