import { InputCell, Hint, InputsRow } from 'components/cooperation-form/styled';
import { PhoneNumber, Select, TextField } from 'kl-b2c-ui-kit';
import React, { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CourseRegistration } from 'types';
import {
    CourseFormType,
    EventType,
    StudyCourse,
    StudyLevel,
} from 'types/enums';
import { DefaultTheme, withTheme } from 'styled-components';
import { CourseRegistrationUI, getCourseCooperationFormRules } from './configs';

const INPUT_CELL_STYLES = { width: '100%', margin: '0 0 8px 0' };
export const DEFAULT_FORM_STATE: CourseRegistrationUI = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    company: '',
    position: '',
    countOfPeople: undefined,
    studyCourse: undefined,
    studyLevel: undefined,
    studyForm: undefined,
};

interface RegistrationFormRowsProps {
    type: CourseFormType;
    theme: DefaultTheme;
}

const RegistrationFormRows: FC<RegistrationFormRowsProps> = ({
    type,
    theme,
}) => {
    const {
        control,
        formState: { errors },
        reset,
        clearErrors,
        watch,
    } = useFormContext<CourseRegistration>();

    const studyLevelWatch = watch('studyLevel')?.valueOf();

    const { t } = useTranslation([
        'pages/courses',
        'common/shared',
        'common/errors',
    ]);

    const selectStyles = {
        container: {
            border: `2px solid ${theme.palette.gray['500']} !important`,
            height: '46px',
        },
        placeholder: {
            color: theme.palette.text.secondary,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
        },
    };

    useEffect(() => {
        reset(DEFAULT_FORM_STATE);
        clearErrors();
    }, [type]);

    const rules = getCourseCooperationFormRules(t);

    return (
        <>
            <InputCell styles={INPUT_CELL_STYLES}>
                <Controller
                    name={'firstName'}
                    control={control}
                    rules={rules['firstName']}
                    render={({ field: { onChange } }) => (
                        <TextField
                            fullWidth
                            variant={'border'}
                            type={'text'}
                            onChange={onChange}
                            placeholder={t('pages/courses:firstName')}
                            error={!!errors.firstName}
                        />
                    )}
                />
                {errors.firstName && <Hint>{errors.firstName.message}</Hint>}
            </InputCell>
            <InputCell styles={INPUT_CELL_STYLES}>
                <Controller
                    name={'lastName'}
                    control={control}
                    rules={rules['lastName']}
                    render={({ field: { onChange } }) => (
                        <TextField
                            fullWidth
                            variant={'border'}
                            type={'text'}
                            onChange={onChange}
                            placeholder={t('pages/courses:lastName')}
                            error={!!errors.lastName}
                        />
                    )}
                />
                {errors.lastName && <Hint>{errors.lastName.message}</Hint>}
            </InputCell>
            <InputsRow styles={{ gap: '8px', margin: '0 0 8px 0' }}>
                <InputCell
                    styles={
                        type === CourseFormType.XTraining
                            ? { width: '100%' }
                            : {}
                    }>
                    <Controller
                        name={'email'}
                        control={control}
                        rules={rules['email']}
                        render={({ field: { onChange } }) => (
                            <TextField
                                fullWidth
                                variant={'border'}
                                type={'text'}
                                onChange={onChange}
                                placeholder={t('pages/courses:email')}
                                error={!!errors.email}
                            />
                        )}
                    />
                    {errors.email && <Hint>{errors.email.message}</Hint>}
                </InputCell>
                {type !== CourseFormType.XTraining && (
                    <InputCell>
                        <Controller
                            name={'phone'}
                            control={control}
                            rules={rules['phone']}
                            render={({ field: { onChange, value } }) => (
                                <PhoneNumber
                                    value={value}
                                    variant={'border'}
                                    placeholder={t('pages/courses:phone')}
                                    error={!!errors.phone}
                                    onChange={onChange}
                                    fullWidth
                                />
                            )}
                        />
                        {errors.phone && <Hint>{errors.phone.message}</Hint>}
                    </InputCell>
                )}
            </InputsRow>
            <InputCell styles={INPUT_CELL_STYLES}>
                <Controller
                    name={'country'}
                    control={control}
                    rules={rules['country']}
                    render={({ field: { onChange } }) => (
                        <TextField
                            fullWidth
                            variant={'border'}
                            type={'text'}
                            onChange={onChange}
                            placeholder={t('pages/courses:country')}
                            error={!!errors.country}
                        />
                    )}
                />
                {errors.country && <Hint>{errors.country.message}</Hint>}
            </InputCell>
            {type !== CourseFormType.Individual &&
                type !== CourseFormType.Student && (
                    <InputCell styles={INPUT_CELL_STYLES}>
                        <Controller
                            name={'company'}
                            control={control}
                            rules={rules['company']}
                            render={({ field: { onChange } }) => (
                                <TextField
                                    fullWidth
                                    variant={'border'}
                                    type={'text'}
                                    onChange={onChange}
                                    placeholder={
                                        type === CourseFormType.Teacher
                                            ? t('pages/courses:university')
                                            : t('pages/courses:company')
                                    }
                                    error={!!errors.company}
                                />
                            )}
                        />
                        {errors.company && (
                            <Hint>{errors.company.message}</Hint>
                        )}
                    </InputCell>
                )}
            {type === CourseFormType.Student && (
                <>
                    <InputCell styles={INPUT_CELL_STYLES}>
                        <Controller
                            name={'university'}
                            control={control}
                            rules={rules['university']}
                            render={({ field: { onChange } }) => (
                                <TextField
                                    fullWidth
                                    variant={'border'}
                                    type={'text'}
                                    onChange={onChange}
                                    placeholder={t('pages/courses:university')}
                                    error={!!errors.university}
                                />
                            )}
                        />
                        {errors.university && (
                            <Hint>{errors.university.message}</Hint>
                        )}
                    </InputCell>
                    <InputCell styles={INPUT_CELL_STYLES}>
                        <Controller
                            name={'studyLevel'}
                            control={control}
                            rules={rules['studyLevel']}
                            render={({ field: { onChange } }) => (
                                <Select
                                    error={!!errors?.studyLevel}
                                    styles={selectStyles}
                                    onChoose={(
                                        item:
                                            | {
                                                  key: string;
                                                  value: string | number;
                                              }[]
                                            | undefined
                                    ) => {
                                        if (item && item.length) {
                                            onChange(
                                                item[0].value as StudyLevel
                                            );
                                        }
                                    }}
                                    placeholder={t('pages/courses:studyLevel')}
                                    variant={'border'}
                                    items={Object.keys(StudyLevel)
                                        .filter((el) => isNaN(Number(el)))
                                        .map((key) => ({
                                            key: t(
                                                `common/shared:study-level-${key}`
                                            ),
                                            value: StudyLevel[
                                                key as keyof typeof StudyLevel
                                            ],
                                        }))}
                                />
                            )}
                        />
                        {errors.studyLevel && (
                            <Hint>{errors.studyLevel.message}</Hint>
                        )}
                    </InputCell>
                    {studyLevelWatch &&
                        studyLevelWatch !== StudyLevel.Postgraduate && (
                            <InputCell styles={INPUT_CELL_STYLES}>
                                <Controller
                                    name={'studyCourse'}
                                    control={control}
                                    rules={rules['studyCourse']}
                                    render={({ field: { onChange } }) => (
                                        <Select
                                            error={!!errors?.studyCourse}
                                            styles={selectStyles}
                                            onChoose={(
                                                item:
                                                    | {
                                                          key: string;
                                                          value:
                                                              | string
                                                              | number;
                                                      }[]
                                                    | undefined
                                            ) => {
                                                if (item && item.length) {
                                                    onChange(
                                                        item[0]
                                                            .value as StudyCourse
                                                    );
                                                }
                                            }}
                                            placeholder={t(
                                                'pages/courses:studyCourse'
                                            )}
                                            variant={'border'}
                                            items={Object.keys(StudyCourse)
                                                .filter((el) => {
                                                    const v = Number(el);
                                                    const ifIsNan = isNaN(v);
                                                    if (!ifIsNan) return false;

                                                    const val =
                                                        StudyCourse[
                                                            el as keyof typeof StudyCourse
                                                        ];
                                                    return (
                                                        (studyLevelWatch ===
                                                            StudyLevel.Bachelor &&
                                                            val < 6) ||
                                                        studyLevelWatch ===
                                                            StudyLevel.Specialty ||
                                                        (studyLevelWatch ===
                                                            StudyLevel.Magistracy &&
                                                            val < 4)
                                                    );
                                                })
                                                .map((key) => ({
                                                    key: t(
                                                        `common/shared:study-course-${key}`
                                                    ),
                                                    value: StudyCourse[
                                                        key as keyof typeof StudyCourse
                                                    ],
                                                }))}
                                        />
                                    )}
                                />
                                {errors.studyCourse && (
                                    <Hint>{errors.studyCourse.message}</Hint>
                                )}
                            </InputCell>
                        )}
                </>
            )}
            {(type === CourseFormType.Teacher ||
                type === CourseFormType.Company) && (
                <InputCell styles={INPUT_CELL_STYLES}>
                    <Controller
                        name={'position'}
                        control={control}
                        rules={rules['position']}
                        render={({ field: { onChange } }) => (
                            <TextField
                                fullWidth
                                variant={'border'}
                                type={'text'}
                                onChange={onChange}
                                placeholder={t('pages/courses:position')}
                                error={!!errors.position}
                            />
                        )}
                    />
                    {errors.position && <Hint>{errors.position.message}</Hint>}
                </InputCell>
            )}
            {type !== CourseFormType.Individual &&
                type !== CourseFormType.Student && (
                    <InputCell styles={INPUT_CELL_STYLES}>
                        <Controller
                            name={'countOfPeople'}
                            control={control}
                            rules={rules['countOfPeople']}
                            render={({ field: { onChange } }) => (
                                <TextField
                                    fullWidth
                                    variant={'border'}
                                    type={'text'}
                                    onChange={onChange}
                                    placeholder={t(
                                        'pages/courses:countOfPeople'
                                    )}
                                    error={!!errors.countOfPeople}
                                />
                            )}
                        />
                        {errors.countOfPeople && (
                            <Hint>{errors.countOfPeople.message}</Hint>
                        )}
                    </InputCell>
                )}
            {type === CourseFormType.Company && (
                <InputCell styles={INPUT_CELL_STYLES}>
                    <Controller
                        name={'studyForm'}
                        control={control}
                        rules={rules['studyForm']}
                        render={({ field: { onChange } }) => (
                            <Select
                                error={!!errors?.studyForm}
                                styles={selectStyles}
                                onChoose={(
                                    item:
                                        | {
                                              key: string;
                                              value: string | number;
                                          }[]
                                        | undefined
                                ) => {
                                    if (item && item.length) {
                                        onChange(item[0].value as EventType);
                                    }
                                }}
                                placeholder={t('pages/courses:studyForm')}
                                variant={'border'}
                                items={Object.keys(EventType)
                                    .filter((el) => isNaN(Number(el)))
                                    .map((key) => ({
                                        key,
                                        value: EventType[
                                            key as keyof typeof EventType
                                        ],
                                    }))}
                            />
                        )}
                    />
                    {errors.studyForm && (
                        <Hint>{errors.studyForm.message}</Hint>
                    )}
                </InputCell>
            )}
            {type === CourseFormType.XTraining && (
                <InputCell styles={INPUT_CELL_STYLES}>
                    <Controller
                        name={'comment'}
                        control={control}
                        rules={rules['comment']}
                        render={({ field: { onChange } }) => (
                            <TextField
                                isTextArea
                                fullWidth
                                variant={'border'}
                                type={'text'}
                                onChange={onChange}
                                placeholder={t('pages/courses:comment')}
                                error={!!errors.comment}
                            />
                        )}
                    />
                    {errors.comment && <Hint>{errors.comment.message}</Hint>}
                </InputCell>
            )}
        </>
    );
};

export default withTheme(RegistrationFormRows);
