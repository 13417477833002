import {
    newsItemSocialsRU,
    newsItemSocialsEN,
    footerSocialsRU,
    footerSocialsEN,
} from 'consts';
import { Lang, SocialLink, SocialLinksType } from 'types';
import { langMapper } from 'utils';

export const getSocialLinks = (
    langId: string,
    type: keyof typeof SocialLinksType
): SocialLink[] => {
    const lang = langMapper[langId];
    if (type === SocialLinksType.FOOTER)
        return lang === Lang.Ru ? footerSocialsRU : footerSocialsEN;
    if (type === SocialLinksType.NEWS_ITEM)
        return lang === Lang.Ru ? newsItemSocialsRU : newsItemSocialsEN;

    return [];
};
