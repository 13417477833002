export const ADVANTAGES: { title: string; description: string }[] = [
    {
        title: 'adv-title-1',
        description: 'adv-desc-1',
    },
    {
        title: 'adv-title-2',
        description: 'adv-desc-2',
    },
    {
        title: 'adv-title-3',
        description: 'adv-desc-3',
    },
];

export const DIRECTIONS: { title: string; url: string; image: string }[] = [
    {
        title: 'dir-title-1',
        url: '/courses/n/n/1/n/n',
        image: '/assets/images/home-direction-1.png',
    },
    {
        title: 'dir-title-2',
        url: '/courses/n/n/2/n/n',
        image: '/assets/images/home-direction-2.png',
    },
    {
        title: 'dir-title-3',
        url: '/courses/n/n/3,5/n/n',
        image: '/assets/images/home-direction-3.png',
    },
    {
        title: 'dir-title-4',
        url: '/courses/n/n/4/n/n',
        image: '/assets/images/home-direction-4.png',
    },
    {
        title: 'dir-title-5',
        url: '/courses/n/n/6/n/n',
        image: '/assets/images/home-direction-5.png',
    },
];
