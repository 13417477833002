import React from 'react';
import { ReactComponent as Case } from 'assets/icons/case-work.svg';
import { ReactComponent as Monitor } from 'assets/icons/monitor.svg';
import { DescriptionCard, PageSection } from 'components';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import {
    Container,
    Left,
    MainBlock,
    MainBlockTitle,
    MessagesContainer,
    Right,
} from './styled';

const sectionConfig = {
    CWrap: {
        css: {
            padding: '100px 0 60px',
            color: '#fff',

            '@media(max-width: 1023px)': {
                padding: '0 0 150px',
            },
        },
    },
    ContentArea: {
        css: {
            display: 'block',
            position: 'relative',
            zIndex: 5,
        },
    },
};

export const Disclaimer = () => {
    const { t } = useTranslation('pages/for-governments');

    return (
        <Container>
            <PageSection config={sectionConfig}>
                <MainBlock>
                    <Left>
                        <MainBlockTitle
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    t('disclaimer-description-title')
                                ),
                            }}
                        />
                    </Left>
                    <Right
                        image={
                            process.env.PUBLIC_URL +
                            '/assets/images/bussiness/developer.png'
                        }
                    />
                </MainBlock>
                <MessagesContainer>
                    <DescriptionCard
                        type={'secondary'}
                        config={{
                            icon: <Monitor />,
                            title: t('disclaimer-block-1'),
                        }}
                    />
                    <DescriptionCard
                        type={'secondary'}
                        config={{
                            icon: <Case />,
                            title: t('disclaimer-block-2'),
                        }}
                    />
                </MessagesContainer>
            </PageSection>
        </Container>
    );
};
