import styled from 'styled-components';
import {
    colorBlack,
    colorWhite,
    transitionBase,
} from 'theme/theme-helper-selectors';

export const Root = styled.div<{
    isVisible: boolean;
    isCorporatePage?: boolean;
    isHeaderDirty?: boolean;
}>`
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    transition: ${transitionBase};
    position: absolute;
    top: 100%;
    width: 100%;
    justify-content: center;
    background-color: ${({ isCorporatePage }) =>
        isCorporatePage ? colorBlack : colorWhite};

    ul {
        padding: 0;

        li {
            a {
                color: ${({ isCorporatePage }) =>
                    isCorporatePage ? colorWhite : colorBlack};
            }
        }
    }
`;

export const Navbar = styled.div`
    display: flex;
    width: 100%;
    max-width: 1240px;
    justify-content: flex-end;
    gap: 12px;
`;
