import { PageSection } from 'components';
import { PaginationOutput } from 'kl-b2c-ui-kit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import { getSpeakerApiService } from 'services/api.factory';
import { Lang, Speaker } from 'types';
import { langMapper } from 'utils/index';
import {
    DescriptionWrapper,
    Subtitle,
    Title,
    Container,
    Slide,
    SliderStyler,
    SlideSubtitle,
    SlideTitle,
    SlideImage,
    Disclaimer,
    DisclaimerItem,
    DisclaimerSubtitle,
    DisclaimerTitle,
    DisclaimerDescription,
    Wrapper,
    PersonalDataDisclaimer,
} from '../../../for-businesses/components/speakers/styled';
import { IntroButton } from 'containers/pages/for-businesses/components/intro/styled';
import { Blur } from '../../styled';
import { CarouselConfig } from '../organizations/Organizations';
import {
    DotsContainer,
    DotsList,
} from 'containers/pages/for-businesses/components/reviews/styled';

const sectionConfig = {
    CWrap: {
        css: {
            paddingTop: 45,
            paddingBottom: 250,
            color: '#fff',
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/bussiness/speakers-hexa.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',

            '@media(max-width: 1279px)': {
                paddingBottom: 50,
            },
        },
    },
    ContentArea: {
        css: {
            display: 'block',
            position: 'relative',
            zIndex: 5,
        },
    },
};

const DEFAULT_SLIDES_COUNT = 3;

export const Speakers = () => {
    const { t, i18n } = useTranslation('pages/for-governments');
    const speakersApi = getSpeakerApiService();
    const [speakers, setSpeakers] = useState<Speaker[]>();
    const navigate = useNavigate();
    const [carouselConfig, setCarouselConfig] = useState<CarouselConfig>({
        slidesToShow: 3,
        arrows: false,
        dots: true,
    });

    const CAROUSEL_SETTINGS: Settings = {
        slidesToScroll: 3,
        infinite: false,
        appendDots: (dots) => (
            <DotsContainer>
                <DotsList> {dots} </DotsList>
            </DotsContainer>
        ),
        ...carouselConfig,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    useEffect(() => {
        speakersApi
            .getSpeakers({
                page: 0,
                size: 20,
                isShowGovernmentPage: true,
                domainType: langMapper[i18n.language],
            })
            .then((speakers: PaginationOutput<Speaker>) => {
                if (speakers.items && speakers.items.length) {
                    setSpeakers(speakers?.items);

                    if (speakers?.items?.length <= DEFAULT_SLIDES_COUNT) {
                        setCarouselConfig({
                            dots: false,
                            arrows: false,
                            slidesToShow: speakers.items.length,
                        });
                    }
                }
            })
            .catch((e) => console.error(e));
    }, []);

    return (
        <Wrapper>
            <PageSection config={sectionConfig}>
                {speakers && speakers.length > 0 && (
                    <Container>
                        <DescriptionWrapper>
                            <Title>{t('speakers-title')}</Title>
                            <Subtitle>{t('speakers-subtitle')}</Subtitle>
                            <IntroButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    return navigate('/courses');
                                }}
                                variant={'contained'}
                                color={'primary-contrast'}
                                size={'large'}>
                                {t('choose-course')}
                            </IntroButton>
                        </DescriptionWrapper>

                        <SliderStyler alignTop={speakers.length < 3}>
                            <Slider
                                {...CAROUSEL_SETTINGS}
                                swipe={speakers.length > 3}>
                                {speakers.map((speaker) => (
                                    <Slide key={speaker.id}>
                                        <SlideImage
                                            image={speaker.image?.fileLink}
                                        />
                                        <SlideTitle>
                                            {speaker.firstName}{' '}
                                            {speaker.lastName}
                                        </SlideTitle>
                                        <SlideSubtitle secondary>
                                            {speaker.position}
                                        </SlideSubtitle>
                                    </Slide>
                                ))}
                            </Slider>
                            {langMapper[i18n.language] === Lang.Ru && (
                                <PersonalDataDisclaimer>
                                    {t('personal-data')}
                                </PersonalDataDisclaimer>
                            )}
                        </SliderStyler>
                    </Container>
                )}

                <Disclaimer>
                    <DisclaimerItem>
                        <DisclaimerTitle>
                            {t('disclaimer-title-1')}
                        </DisclaimerTitle>
                        <DisclaimerSubtitle>
                            {t('disclaimer-subtitle-1')}

                            <DisclaimerDescription>
                                {t('disclaimer-description-1')}
                            </DisclaimerDescription>
                        </DisclaimerSubtitle>
                    </DisclaimerItem>
                    <DisclaimerItem>
                        <DisclaimerTitle>
                            {t('disclaimer-title-2')}
                        </DisclaimerTitle>
                        <DisclaimerSubtitle>
                            {t('disclaimer-subtitle-2')}

                            <DisclaimerDescription>
                                {t('disclaimer-description-2')}
                            </DisclaimerDescription>
                        </DisclaimerSubtitle>
                    </DisclaimerItem>
                    <DisclaimerItem>
                        <DisclaimerTitle>
                            {t('disclaimer-title-3')}
                        </DisclaimerTitle>
                        <DisclaimerSubtitle>
                            {t('disclaimer-subtitle-3')}

                            <DisclaimerDescription>
                                {t('disclaimer-description-3')}
                            </DisclaimerDescription>
                        </DisclaimerSubtitle>
                    </DisclaimerItem>
                </Disclaimer>
            </PageSection>
            <Blur
                styles={{
                    top: 'auto',
                    bottom: '-80px',
                    width: 'calc(100% + 40px)',
                }}
            />
        </Wrapper>
    );
};
