import styled from 'styled-components';
import { colorGray, colorPrimary } from 'theme/theme-helper-selectors';
import { SocialLinksType } from 'types';

const customCWrapCSS = {
    FOOTER: {
        width: '212px',
    },
    NEWS_ITEM: {},
};

export const CWrap = styled.div<{ type: keyof typeof SocialLinksType }>`
    padding: 10px 0px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    ${({ type }) => customCWrapCSS[type]}
`;

export const Item = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    .svg {
        display: block;
        fill: ${colorGray};
        transition: all 0.5s ease;

        &:hover {
            fill: ${colorPrimary};
        }
    }
`;
