import { AxiosConfig } from 'types';

export const axiosConfig: AxiosConfig = {
    headers: {
        'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE, OPTIONS',
    },
    withCredentials: false,
};

export default axiosConfig;
