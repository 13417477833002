import styled from 'styled-components';
import { mediaMd, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const ContentWrap = styled.div`
    display: flex;
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;

    @media (${mediaSm}) {
        flex-direction: column;
        padding-bottom: 40px;
    }
`;

export const Column = styled.div<{ width: string }>`
    display: flex;
    width: ${(props) => props.width};
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (${mediaSm}) {
        width: 100%;
    }
`;

export const SpeakersCarouselTitle = styled.p`
    font-size: 36px;
    font-weight: 400;
    line-height: 43px;
    max-width: 150px;
    position: absolute;
    top: 0;
    left: 280px;

    @media (${mediaSm}) {
        margin-bottom: 20px;
        max-width: none;
        position: static;
    }
`;

export const Disclaimer = styled.p<{ bottom?: number }>`
    font-size: 10px;
    position: absolute;
    bottom: ${({ bottom }) => (bottom ? bottom : 20)}px;
    color: ${({ theme }) => theme.palette.gray[200]};

    @media (${mediaSm}) {
        position: static;
    }
`;

export const Title = styled.h1`
    font-size: 64px;
    font-weight: 400;
    line-height: 77px;
    padding-bottom: 20px;
    white-space: pre-wrap;
    margin: 0;

    @media (${mediaXs}) {
        font-size: 33px;
        line-height: 40px;
        white-space: normal;
        padding-top: 40px;
    }
`;

export const SpeakerPhoto = styled.div<{ src: string }>`
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${(props) => props.src}');
    width: 100%;
    height: 166px;
    border-radius: 30px;

    @media (${mediaSm}) {
        height: 350px;
    }
`;

export const SliderStyler = styled.div<{ alignTop?: boolean }>`
    padding: 0 0 0 100px;

    @media (${mediaMd}) {
        width: 100%;
        padding: 0;
    }

    /* the slides */
    .slick-slide {
        margin: 0 15px;
    }

    /* the parent */
    .slick-list {
        margin: 0 -15px;
    }

    .slick-track {
        display: flex;
        flex-direction: row;
        align-items: ${({ alignTop }) => (alignTop ? 'flex-start' : 'center')};
    }

    .slick-slide {
        &:has(+ :nth-child(3n)) {
            ${SpeakerPhoto} {
                height: 312px;
            }
        }
    }
`;

export const SpeakerPosition = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    text-align: center;
    padding-bottom: 20px;
`;

export const SpeakerName = styled.div`
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    padding: 16px 0 8px;
`;

export const SpeakerWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (${mediaXs}) {
        width: 100%;
    }
`;
