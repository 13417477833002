import styled, { css } from 'styled-components';
import { CardType } from './DescriptionCard';
import {
    colorPrimary,
    colorWhite,
    mediaSm,
} from 'theme/theme-helper-selectors';

export const Container = styled.div<{ type: CardType }>`
    padding: 24px;
    border-radius: 16px;
    flex-grow: 1;
    flex-basis: 0;

    background-color: hsl(213, 11%, 15%);
    background-image: ${({ type }) => css`radial-gradient(
        circle farthest-corner at left top,
        ${type === 'primary' ? colorPrimary : '#3355ff'} -19%,
        #23272c 56%
    )`};

    @media (${mediaSm}) {
        padding: 20px;
    }
`;

export const IconContainer = styled.div`
    margin: 0 0 32px 0;

    @media (${mediaSm}) {
        margin: 0 0 20px 0;
    }
`;

export const Title = styled.h4`
    font-size: 24px;
    line-height: 27px;
    font-weight: 400;
    color: ${colorWhite};

    @media (${mediaSm}) {
        font-size: 19px;
        line-height: 22px;
    }
`;

export const Subtitle = styled.p`
    font-size: 16px;
    line-height: 18px;
    color: ${colorWhite};
    opacity: 0.8;
    font-weight: 200;
    margin: 16px 0 0 0;

    @media (${mediaSm}) {
        font-size: 14px;
        line-height: 16px;
    }
`;
