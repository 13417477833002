import styled, { css } from 'styled-components';
import {
    colorText,
    colorPrimary,
    mediaMd,
    colorTeal,
} from 'theme/theme-helper-selectors';
import { Link } from 'react-router-dom';

const linkStyles = css`
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    border: 1px solid transparent;
    transition: all 0.5s ease;
    text-decoration: none;
    padding: 4px 0px;
`;

export const MenuList = styled.ul<{ isFooter: boolean }>`
    display: flex;
    flex-direction: ${({ isFooter }) => (isFooter ? 'column' : 'row')};
    align-items: flex-start;
    gap: 8px;
    margin: 0;
`;

export const MenuItem = styled.li<{ isFooter: boolean }>`
    position: relative;
    display: flex;
    padding: ${({ isFooter }) => (isFooter ? '0px' : '30px')};

    @media (${mediaMd}) {
        padding: ${({ isFooter }) => (isFooter ? '0px' : '30px 20px')};
    }
`;

export const ArrowIcon = styled.span`
    display: inline-block;
    margin-left: 3px;
    transform: translateX(0px);
    transition: transform 0.5s ease;
`;

export const MenuLink = styled(Link)`
    ${linkStyles}
    color: ${colorText};
    text-wrap: nowrap;
`;

export const MenuExternalLink = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    ${linkStyles}
    color: ${colorPrimary} !important;
    text-wrap: nowrap;

    &:hover {
        color: ${colorTeal} !important;

        ${ArrowIcon} {
            transform: translateX(8px);
        }
    }
`;
