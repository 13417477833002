import styled from 'styled-components';
import { headerHeight, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Wrapper = styled.div`
    background-image: url(/assets/images/bussiness/homepage-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -${headerHeight};
    position: relative;
    overflow: hidden;
`;

export const Slide = styled.div`
    text-align: center;
`;

export const SliderWrapper = styled.div`
    max-width: 700px;
    margin: 0 auto;
`;

export const SliderStyler = styled.div`
    width: 100%;
    margin: 0 0 40px 0;

    .slick-slide {
        &:not(.slick-active) {
            visibility: hidden;
        }
    }
`;

export const SliderTitle = styled.h1`
    font-weight: 400;
    line-height: 72px;
    font-size: 72px;
    margin: 0 0 34px 0;

    @media (${mediaSm}) {
        font-size: 50px;
        line-height: 50px;
        margin: 0 0 20px 0;
    }

    @media (${mediaXs}) {
        font-size: 30px;
        line-height: 30px;
    }
`;

export const SliderSubtitle = styled.p`
    font-size: 24px;
    line-height: 27px;
    margin: 0 0 34px 0;
    font-weight: 300;

    @media (${mediaSm}) {
        font-size: 20px;
        line-height: 22px;
        margin: 0 0 20px 0;
    }
`;

export const TeachTitle = styled.h3`
    font-size: 54px;
    line-height: 54px;
    font-weight: 400;
    margin: 0 0 42px 0;

    @media (${mediaSm}) {
        font-size: 35px;
        line-height: 35px;
    }
`;
