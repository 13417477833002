import styled from 'styled-components';
import { colorGray, colorPrimary } from '../../../theme/theme-helper-selectors';

export const FooterSocialsList = styled.div`
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    gap: 20px;
`;

export const FooterSocial = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    .svg {
        display: block;
        fill: ${colorGray};
        transition: all 0.5s ease;

        &:hover {
            fill: ${colorPrimary};
        }
    }
`;
