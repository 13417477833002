import { AxiosResponse } from 'axios';
import { RegistrationModal, SendMessage, Tab } from 'components';
import FormBase from 'components/form-base';
import { RegistrationFormRows } from 'containers/pages/courses-item/components';
import { Modal, ModalBody, ModalElement, useUtmParams } from 'kl-b2c-ui-kit';
import React, { forwardRef, LegacyRef, useState } from 'react';
import {
    FieldValues,
    FormProvider,
    SubmitHandler,
    useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CSSObject, DefaultTheme, withTheme } from 'styled-components';
import {
    CourseFormType,
    CourseDetail,
    CourseRegistration,
    ProfessionDetail,
    ProfessionRegistration,
} from 'types';

import { langMapper } from 'utils';

export enum CourseRegisterModalType {
    Default = 1,
    Success = 2,
    Error = 3,
}
export type CourseSecondaryModalConfig = {
    styles: CSSObject;
    config?: {
        title: string;
        description: string;
        color?: 'error' | 'primary';
        buttonText: string;
        action: () => void;
    };
};

interface CourseRegisterModalProps {
    theme: DefaultTheme;
    course: CourseDetail | ProfessionDetail;
    register: <T>(data: T) => Promise<AxiosResponse>;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const courseGuardType = (object: any): object is CourseDetail =>
    'courseId' in object;

const CourseRegisterModal = forwardRef<ModalElement, CourseRegisterModalProps>(
    ({ theme, course, register }, ref) => {
        const { i18n, t } = useTranslation([
            'pages/courses',
            'common/cooperation-form',
            'translation',
        ]);
        const langDomain = langMapper[i18n.language];
        const [registrationLoading, setRegistrationLoading] =
            useState<boolean>(false);
        const [doi, setDoi] = useState<string>();
        const [chosenFormType, setChosenFormType] = useState<CourseFormType>(
            CourseFormType.Individual
        );

        const [modalType, setModalType] = useState<CourseRegisterModalType>(
            CourseRegisterModalType.Default
        );

        const methods = useForm<FieldValues>();

        const getBg = () => {
            switch (chosenFormType) {
                case CourseFormType.Student:
                    return 'course_student_bg';
                case CourseFormType.Company:
                    return 'course_b2b_bg';
                case CourseFormType.Individual:
                    return 'course_legal_bg';
                case CourseFormType.Teacher:
                    return 'course_b2a_bg';
                case CourseFormType.XTraining:
                    return 'course_xtraining_bg';
                default:
                    return '';
            }
        };

        const getConfig = (
            type: CourseRegisterModalType
        ): CourseSecondaryModalConfig => {
            switch (type) {
                case CourseRegisterModalType.Error: {
                    return {
                        styles: {
                            background:
                                'linear-gradient(135deg, #FF5D73 5%, #e3bdc1 100%)',
                        },
                        config: {
                            title: t('pages/courses:err-title-send-message'),
                            description: t(
                                'pages/courses:err-description-send-message'
                            ),
                            color: 'error',
                            buttonText: t('pages/courses:button-send-message'),
                            //@ts-ignore
                            action: () => ref?.current?.close(),
                        },
                    };
                }
                case CourseRegisterModalType.Success: {
                    return {
                        styles: {
                            background:
                                'linear-gradient(154.05deg, #66FE99 6.25%, #A8E6FC 69.87%), radial-gradient(201.26% 196.4% at 100% 0%, #FBFAFE 0%, rgba(255, 255, 255, 0) 100%)',
                        },
                        config: {
                            title: t('pages/courses:title-send-message'),
                            description:
                                doi ||
                                t('pages/courses:description-send-message'),
                            buttonText: t('pages/courses:button-send-message'),
                            //@ts-ignore
                            action: () => ref?.current?.close(),
                        },
                    };
                }
                default: {
                    return {
                        styles: {
                            backgroundImage: `url(/assets/images/registrations/${getBg()}.png)`,
                            minHeight: 858,
                            '@media (max-width: 639px)': {
                                padding: '40px 16px 16px',
                            },
                        },
                    };
                }
            }
        };

        const getTabs = () => ({
            tabs:
                course?.formTypes.map((type) => ({
                    key: String(type),
                    isActive: type === CourseFormType.Individual,
                    body: <RegistrationFormRows type={type} />,
                    title: (
                        <span>
                            {t(`pages/courses:registration-tab-${type}`)}
                        </span>
                    ),
                })) || [],
            styles: {
                rootStyles: `width: 100%;`,
                headerStyles: 'text-align: center;',
                bodyStyles: `padding: 24px 0 0 0;`,
                menuStyles: `
                    padding: 0 20px;
                    border-bottom: 3px solid #ebebeb;
                    display: inline-flex;
                    @media only screen and (max-width: 1023px) { flex-direction: column !important; border-bottom: none !important; gap: 10px !important; padding: 20px 0 0 0; flex-wrap: wrap; justify-content: flex-start; width: 100%; }
                `,
                menuItemStyles: `font-size: 14px;
                            line-height: 16px;
                            padding: 20px 0;
                            font-weight: 300;
                            color: ${theme.palette.black};
                            cursor: pointer;
                            &:after {
                                height: 3px;
                                bottom: -3px;
                                background: ${theme.palette.teal};
                            }
                            @media only screen and (max-width: 1023px) { &:after { display: none; }; font-size: 16px; line-height: 14px; padding: 10px 5px; background: #ECEFF3; color: #000000; white-space: nowrap; border-radius: 24px; width: 100% !important; &.is-active { background: linear-gradient(45deg, #3DE8CA 38.74%, #33FF77 100%);  } } 
                            `,
            },
        });

        const onSubmit: SubmitHandler<FieldValues> = async (
            data: FieldValues
        ) => {
            setRegistrationLoading(true);
            const utm = useUtmParams();

            let status = 0;

            try {
                let requestData: CourseRegistration | ProfessionRegistration = {
                    ...(data as CourseRegistration | ProfessionRegistration),
                    agreement: {
                        ...data.agreement,
                        marketingCheckBoxText: t(
                            'common/cooperation-form:marketingLabel'
                        ),
                        privacyPolicyCheckBoxText: t(
                            'common/cooperation-form:privacyPolicyLabel'
                        ),
                    },
                    formType: chosenFormType,
                    domainType: langDomain,
                    ...utm,
                };

                if (courseGuardType(course)) {
                    requestData = { ...requestData, courseId: course.courseId };
                } else {
                    requestData = {
                        ...requestData,
                        professionId: course.professionId,
                    };
                }
                const result = await register(requestData);
                status = result.status;
                if (status === 202) setDoi(t('translation:success-confirm'));
            } catch (error) {
                return setModalType(CourseRegisterModalType.Error);
            } finally {
                setModalType(CourseRegisterModalType.Success);
            }

            return status;
        };

        const modalConfig = getConfig(modalType);

        return (
            <Modal
                ref={ref as LegacyRef<ModalElement>}
                size={'md'}
                outsideClose
                showClose={false}
                styles={{
                    borderRadius: '24px',
                    overflow: 'auto',
                }}
                wrapStyles={{
                    overflow: 'auto',
                }}>
                <ModalBody styles={{ ...modalConfig.styles }}>
                    {modalType === CourseRegisterModalType.Default ? (
                        <RegistrationModal
                            title={t('pages/courses:registration-modal-title')}
                            // @ts-ignore
                            close={() => ref?.current?.close()}>
                            <FormProvider {...methods}>
                                <FormBase
                                    styles={{
                                        width: '100%',
                                        '&:before': { display: 'none' },
                                        padding: '0',
                                        button: {
                                            width: '100%',
                                        },
                                    }}
                                    success={undefined}
                                    loading={registrationLoading}
                                    submit={onSubmit}
                                    resources={{
                                        submitLabel: t('pages/courses:submit'),
                                    }}
                                    policies={[
                                        {
                                            name: 'agreement.privacyPolicyIsSelected',
                                            label: t(
                                                'pages/courses:privacyPolicyLabel'
                                            ),
                                            required: true,
                                        },
                                        {
                                            name: 'agreement.marketingIsSelected',
                                            label: t(
                                                'pages/courses:marketingLabel'
                                            ),
                                        },
                                    ]}>
                                    <Tab
                                        {...getTabs()}
                                        onChange={(index) =>
                                            setChosenFormType(
                                                course?.formTypes[index] ??
                                                    CourseFormType.Company
                                            )
                                        }
                                    />
                                </FormBase>
                            </FormProvider>
                        </RegistrationModal>
                    ) : (
                        <SendMessage
                            title={modalConfig.config?.title || ''}
                            description={modalConfig.config?.description || ''}
                            buttonText={modalConfig.config?.buttonText || ''}
                            action={() => modalConfig.config?.action()}
                        />
                    )}
                </ModalBody>
            </Modal>
        );
    }
);

export default withTheme(CourseRegisterModal);
