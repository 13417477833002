import styled from 'styled-components';
import { colorGray, mediaSm } from 'theme/theme-helper-selectors';

export const SlideImage = styled.div<{ image: string }>`
    background-image: ${({ image }) => `url(${image})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 244px;
    background-color: #3a424f;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 20px 20px 0 0;
`;

export const Slide = styled.div`
    padding: 276px 32px 32px 32px;
    background: #2a3039;
    position: relative;
    height: 416px;
    border-radius: 20px;

    @media (${mediaSm}) {
        padding: 264px 20px 20px 20px;
    }
`;

export const SlideTitle = styled.h2`
    font-size: 32px;
    line-height: 36px;
    font-weight: 300;

    height: 100px;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 9px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${colorGray};
        height: 30px;
        border-radius: 5px;
    }

    @media (${mediaSm}) {
        font-size: 28px;
        line-height: 33px;
    }
`;

export const Title = styled.h1`
    font-size: 56px;
    line-height: 65px;
    margin: 0 0 40px 0;
    font-weight: 400;

    @media (${mediaSm}) {
        font-size: 46px;
        line-height: 55px;
    }
`;
