import React, { useEffect } from 'react';
import {
    AdditionalDescription,
    Contacts,
    Description,
    Inside,
    Memberships,
} from './components';
import { Body } from './styled';
import { useMobileMenuContext } from 'contexts';

export const AcademyAlliance: React.FC = (): JSX.Element => {
    const { toggleCorporateStyles } = useMobileMenuContext();

    useEffect(() => {
        toggleCorporateStyles(true);
        window.scrollTo(0, 0);

        return () => {
            toggleCorporateStyles(false);
        };
    }, []);

    return (
        <Body>
            <Description />
            <AdditionalDescription />
            <Inside />
            <Memberships />
            <Contacts />
        </Body>
    );
};

export default AcademyAlliance;
