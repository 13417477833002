import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Column = styled.div`
    display: flex;
    width: 40%;
    gap: 16px;

    &:first-child,
    &:last-child {
        flex-direction: column;
    }

    &:first-child {
        gap: 8px;

        @media (${mediaSm}) {
            width: 50%;
        }
        @media (${mediaXs}) {
            width: 100%;
        }
    }

    &:last-child {
        width: 20%;
        gap: 20px;

        @media (${mediaSm}) {
            width: 100%;
            padding: 0;
        }

        @media (${mediaXs}) {
            align-items: center;
            padding-bottom: 40px;
        }
    }

    @media (${mediaSm}) {
        justify-content: center;
        text-align: left;
        width: 50%;
    }

    @media (${mediaXs}) {
        justify-content: left;
        width: 100%;
    }
`;
