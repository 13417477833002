import { Button } from 'kl-b2c-ui-kit';
import styled from 'styled-components';
import {
    colorBlack,
    colorWhite,
    mediaSm,
    mediaXs,
} from 'theme/theme-helper-selectors';

export const Title = styled.h1`
    font-size: 121px;
    line-height: 101px;
    font-weight: 400;
    color: ${colorWhite};
    margin: 0 0 40px 0;

    @media (${mediaSm}) {
        font-size: 80px;
        line-height: 70px;
    }

    @media (${mediaXs}) {
        font-size: 50px;
        line-height: 50px;
        margin: 0 0 20px 0;
    }
`;

export const Subtitle = styled.p`
    font-size: 24px;
    line-height: 27px;
    color: ${colorWhite};
    max-width: 750px;
    font-weight: 300;

    margin: 0 0 32px 0;

    @media (${mediaSm}) {
        font-size: 16px;
        line-height: 18px;

        margin: 0 0 20px 0;
    }
`;

export const ButtonContainer = styled.div`
    margin: 0 0 110px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (${mediaSm}) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
`;

export const IntroButton = styled(Button)`
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: ${colorBlack};
    background: linear-gradient(216.53deg, #8dffb3 15.95%, #33bbff 83.66%);
    max-height: 48px;
    padding: 14px 24px;
    letter-spacing: 0;
`;

export const DescriptionTitle = styled.h3`
    font-size: 56px;
    line-height: 65px;
    color: ${colorWhite};
    font-weight: 400;
    margin: 0 0 40px 0;

    @media (${mediaSm}) {
        font-size: 35px;
        line-height: 38px;
    }
`;

export const DescriptionItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;

    @media (${mediaSm}) {
        display: none;
    }
`;

export const SliderContainer = styled.div`
    display: none;

    @media (${mediaSm}) {
        display: block;
    }
`;
