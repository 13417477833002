import React from 'react';
import { StyledNodeConfig } from 'types';
import { CWrap, ContentArea } from './styled';
import { CSSObject } from 'styled-components';

export interface Props {
    id?: string;
    config: {
        CWrap: StyledNodeConfig;
        ContentArea: StyledNodeConfig;
    };
    children: React.ReactNode;
    nested?: boolean;
}

export const PageSection: React.FC<Props> = ({
    id,
    config: {
        CWrap: { css: cwrapCSS },
        ContentArea: { css: contentAreaCSS },
    },
    children,
    nested,
}): JSX.Element => (
    <CWrap id={id} customCSS={cwrapCSS as CSSObject}>
        <ContentArea
            className={nested ? 'nested' : ''}
            customCSS={contentAreaCSS as CSSObject}>
            {children}
        </ContentArea>
    </CWrap>
);

export default PageSection;
