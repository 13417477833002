import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackButton, Header, HeaderContent, SubTitle, Title } from './styled';
import { Icon } from 'kl-b2c-ui-kit';

export const Description = () => {
    const { t } = useTranslation('pages/academy-alliance');
    return (
        <Header>
            <HeaderContent>
                <BackButton to={'/courses/'}>
                    <Icon name={'ArrowLeft'} color={'teal'} size={20} />
                    {t('back2ListBtnCaption')}
                </BackButton>
                <Title>{t('title')}</Title>
                <SubTitle>{t('subTitle')}</SubTitle>
            </HeaderContent>
        </Header>
    );
};
