import { IApiService, IConfigApi, AppConfig } from 'types';

export class ConfigApi implements IConfigApi {
    constructor(private readonly apiService: IApiService) {}

    public getConfig(): Promise<AppConfig> {
        return this.apiService
            .request<AppConfig>({
                method: 'GET',
                url: '/siteSettings/get',
            })
            .then((response) => response.data);
    }
}
