import styled from 'styled-components';
import { colorBlack, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const ListWrapper = styled.div`
    color: ${colorBlack};
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;

    ul {
        padding: 0;
    }

    li {
        padding: 14px 0;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        font-weight: 300;
        position: relative;
        padding: 14px 0 0 10px;
        color: ${({ theme }) => theme.palette.black};

        &:before {
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: ${({ theme }) => theme.palette.green};
            flex-shrink: 0;
        }

        @media (${mediaSm}) {
            font-size: 15px;
            line-height: 16px;
            padding: 7px 0;
        }
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 64px 0 0 0;
    padding: 32px 0 0 0;

    border-top: 1px solid #294d7517;

    @media (${mediaSm}) {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }
`;

export const FooterTitle = styled.p`
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    width: 50%;

    @media (${mediaSm}) {
        width: 100%;
    }
`;

export const ButtonContainer = styled.div`
    width: 50%;
    display: flex;
    gap: 20px;
    justify-content: flex-end;

    button {
        height: 48px;
        letter-spacing: 0;
    }

    @media (${mediaSm}) {
        width: 100%;
        justify-content: flex-start;
    }

    @media (${mediaXs}) {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

        button {
            width: 100%;
        }
    }
`;
