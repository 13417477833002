import { PageSection } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const sectionConfig = {
    CWrap: {
        css: {
            padding: '40px 0 0 0',

            '@media(max-width: 767px)': {
                padding: '20px 0 40px 0',
            },
        },
    },
    ContentArea: {
        css: {},
    },
};

export const NotFound: React.FC = (): JSX.Element => {
    const { t } = useTranslation('common/shared');

    return (
        <PageSection config={sectionConfig}>
            <h1>{t('not-found')}</h1>
        </PageSection>
    );
};

export default NotFound;
