import React, { useRef, useState } from 'react';
import {
    SectionTitle,
    CarouselContainer,
    SlideImage,
    SlideLink,
    Slide,
    ArrowNext,
    ArrowPrev,
    Container,
} from './styled';
import { useTranslation } from 'react-i18next';
import Slider, { Settings } from 'react-slick';
import { Icon } from 'kl-b2c-ui-kit';
import { DIRECTIONS } from 'consts';
import PageSection from 'components/page-section';

const sectionConfig = {
    CWrap: {
        css: {
            overflow: 'hidden',
        },
    },
    ContentArea: {
        css: {
            display: 'block',
        },
    },
};

export const Directions: React.FC = (): JSX.Element => {
    const { t } = useTranslation('pages/home');
    const [activeSlide, setActiveSlide] = useState<number>(0);

    const sliderRef = useRef<Slider>(null);

    const CAROUSEL_SETTINGS: Settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        beforeChange: (prev: number, next: number) => setActiveSlide(next),
        dots: false,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                },
            },
        ],
    };

    const getLinkGradient = (index: number): string => {
        switch (index) {
            case 1:
                return 'linear-gradient(144.23deg, #60D8A8 0%, #43B6F7 79.06%), #23D1AE;';
                break;
            case 2:
                return 'linear-gradient(151.95deg, #335AB8 26.1%, #23D1AE 110.69%), #23D1AE;';
                break;
            case 3:
                return 'linear-gradient(165.02deg, #FFA87D 26.22%, #FFDD33 165.91%), #FFA87D;';
                break;
            case 4:
                return 'linear-gradient(165.02deg, #A085F5 26.22%, #6AB2F8 165.91%), #A085F5;';
                break;
            case 5:
                return 'linear-gradient(165.02deg, #3355FF 26.22%, #A87DFF 165.91%), #A085F5;';
                break;
            default:
                return 'linear-gradient(144.23deg, #60D8A8 0%, #43B6F7 79.06%), #23D1AE';
                break;
        }
    };

    return (
        <PageSection config={sectionConfig}>
            <Container>
                <SectionTitle>{t('dir-section-title')}</SectionTitle>
                <CarouselContainer>
                    <Slider {...CAROUSEL_SETTINGS} ref={sliderRef}>
                        {DIRECTIONS.map(({ image, title, url }, index) => (
                            <Slide key={title}>
                                <SlideImage
                                    isActive={index === activeSlide}
                                    alt={'description image'}
                                    src={image}
                                />
                                <SlideLink
                                    to={url}
                                    isActive={index === activeSlide}
                                    gradient={getLinkGradient(index + 1)}>
                                    <ArrowPrev
                                        className={'arrow'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            sliderRef?.current?.slickPrev();
                                        }}>
                                        <Icon
                                            name={'SliderArrowLeft'}
                                            color={'black'}
                                            size={27}
                                        />
                                    </ArrowPrev>
                                    <span>{t(title)}</span>
                                    <ArrowNext
                                        className={'arrow'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            sliderRef?.current?.slickNext();
                                        }}>
                                        <Icon
                                            name={'SliderArrowRight'}
                                            color={'black'}
                                            size={32}
                                        />
                                    </ArrowNext>
                                </SlideLink>
                            </Slide>
                        ))}
                    </Slider>
                </CarouselContainer>
            </Container>
        </PageSection>
    );
};

export default Directions;
