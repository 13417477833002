import { useCourse } from 'contexts/course.context';
import React from 'react';
import { Line, MeyBe } from './styled';
import { useTranslation } from 'react-i18next';
import { ListBlockCourses } from '../body/styled';
import { CourseItem } from '../course-item';

export const ReservedView = () => {
    const { reservedCourses } = useCourse();
    const { t } = useTranslation('pages/courses');
    return (
        <>
            <Line />
            <MeyBe>{t('mey-be')}</MeyBe>
            <ListBlockCourses>
                {reservedCourses.map((course) => (
                    <CourseItem key={course.groupName} course={course} />
                ))}
            </ListBlockCourses>
        </>
    );
};
