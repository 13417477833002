import styled from 'styled-components';
import { mediaTablet } from '../../../theme/theme-helper-selectors';

export const Address = styled.p`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    white-space: pre-wrap;
    padding: 32px 0 20px;

    @media (${mediaTablet}) {
        text-align: left;
    }
`;
