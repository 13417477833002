import React, { FC } from 'react';
import { PageSection, Tab } from 'components';
import { useTranslation } from 'react-i18next';
import { Container, Title, SecondaryBlocksContainer } from './styled';
import { MainBlock } from './MainBlock';
import { SecondaryBlock } from './SecondaryBlock';
import { useLangContext } from 'contexts/language.context';
import { Lang } from 'types';
import { CSSObject } from 'styled-components';

const sectionConfig = {
    CWrap: {
        css: {
            paddingTop: 60,
            paddingBottom: 103,

            '@media(max-width: 1279px)': {
                paddingTop: 110,
            },

            '@media(max-width: 1023px)': {
                paddingTop: 60,
                paddingBottom: 27,
            },
        },
    },
    ContentArea: {
        css: {
            display: 'block',
            position: 'relative',
        },
    },
};

interface ConstructorProps {
    bgImagePath: string;
    title: string;
    bgStyles?: CSSObject;
}

export const Constructor: FC<ConstructorProps> = ({
    bgImagePath,
    title,
    bgStyles,
}) => {
    const { t } = useTranslation('pages/for-businesses');
    const { lang } = useLangContext();

    const tabs = {
        tabs: [
            {
                key: 'tech',
                isActive: true,
                body: (
                    <>
                        <MainBlock
                            config={{
                                title: t('constructor-title-1'),
                                subtitle: t('constructor-subtitle-1'),
                                imagePath:
                                    '/assets/images/bussiness/laptop.png',
                                link: '/courses/n/n/1/n/n',
                                imageStyles: {
                                    backgroundPositionX: '151%',
                                    backgroundPositionY: '19%',
                                    backgroundSize: '75%',
                                    '@media(max-width: 1023px)': {
                                        backgroundPosition: '50%',
                                    },
                                },
                            }}
                        />
                        <SecondaryBlocksContainer>
                            <SecondaryBlock
                                config={{
                                    title: t('constructor-title-4'),
                                    subtitle: t('constructor-subtitle-4'),
                                    link: '/courses/n/n/2/n/n',
                                }}
                            />
                            <SecondaryBlock
                                config={{
                                    title: t('constructor-title-5'),
                                    subtitle: t('constructor-subtitle-5'),
                                    link: '/courses/n/n/3/n/n',
                                }}
                            />
                        </SecondaryBlocksContainer>
                    </>
                ),
                title: <span>{t('constructor-tech')}</span>,
            },
            {
                key: 'communication',
                body: (
                    <>
                        <MainBlock
                            config={{
                                title: t('constructor-title-2'),
                                subtitle: t('constructor-subtitle-2'),
                                imagePath:
                                    '/assets/images/bussiness/communicate.jpg',
                                link: '/courses/n/n/5/n/n',
                                imageStyles: {
                                    backgroundPositionX: '360px',
                                    backgroundPositionY: '12%',
                                    '@media(max-width: 1023px)': {
                                        backgroundPositionX: '120px',
                                    },
                                },
                            }}
                        />
                        <SecondaryBlocksContainer>
                            <SecondaryBlock
                                config={{
                                    title: t('constructor-title-6'),
                                    subtitle: t('constructor-subtitle-6'),
                                    link:
                                        lang === Lang.Ru
                                            ? '/courses/n/n/2/n/n'
                                            : '/courses/n/n/4/n/n',
                                }}
                            />
                            <SecondaryBlock
                                config={{
                                    title: t('constructor-title-7'),
                                    subtitle: t('constructor-subtitle-7'),
                                    link:
                                        lang === Lang.Ru
                                            ? '/courses/n/n/3/n/n'
                                            : '/courses/n/n/5/n/n',
                                }}
                            />
                        </SecondaryBlocksContainer>
                    </>
                ),
                title: <span>{t('constructor-communication')}</span>,
            },
            {
                key: 'cyber',
                body: (
                    <>
                        <MainBlock
                            config={{
                                title: t('constructor-title-3'),
                                subtitle: t('constructor-subtitle-3'),
                                imagePath: '/assets/images/bussiness/cyber.jpg',
                                link: '/courses/n/n/7/n/n',
                                imageStyles: {
                                    backgroundPositionX: '120px',
                                    backgroundPositionY: '22%',
                                    '@media(max-width: 1023px)': {
                                        backgroundPositionX: 'right',
                                    },
                                },
                            }}
                        />
                        <SecondaryBlocksContainer>
                            <SecondaryBlock
                                config={{
                                    title: t('constructor-title-8'),
                                    subtitle: t('constructor-subtitle-8'),
                                    link: '/courses/n/n/1/n/n',
                                }}
                            />
                            <SecondaryBlock
                                config={{
                                    title: t('constructor-title-9'),
                                    subtitle: t('constructor-subtitle-9'),
                                    link: '/courses/n/n/5/n/n',
                                }}
                            />
                        </SecondaryBlocksContainer>
                    </>
                ),
                title: <span>{t('constructor-cyber')}</span>,
            },
        ],
        styles: {
            textColor: '#fff',
            rootStyles: `
                    border-radius: 10px 10px 0px 0px;
            `,
            menuStyles:
                'padding: 0; gap: 40px; padding: 0 70px; justify-content: center;' +
                '@media only screen and (max-width: 1023px) { flex-direction: row !important; border-bottom: none !important; gap: 10px !important; padding: 0; flex-wrap: wrap; justify-content: flex-start; }',
            menuItemStyles:
                'padding: 20px 0; font-size: 21px; line-height: 24px; font-weight: 300; &:after { background: #3DE8CA; bottom: -2px; }' +
                '@media only screen and (max-width: 1023px) { &:after { display: none; }; font-size: 16px; line-height: 14px; padding: 5px 12px; background: #ECEFF3; color: #000000; white-space: nowrap; ' +
                'border-radius: 24px; width: auto !important; &.is-active { padding-right: 26px; background: linear-gradient(45deg, #3DE8CA 38.74%, #33FF77 100%); &:before { content: "✖"; position: absolute; right: 10px; top: 50%; transform: translateY(-50%); font-size: 12px; }; } }',
            bodyStyles: 'padding: 40px 0 0 0;',
        },
    };

    return (
        <Container bgImagePath={bgImagePath} bgStyles={bgStyles}>
            <PageSection config={sectionConfig}>
                <Title>{title}</Title>
                <Tab {...tabs} />
            </PageSection>
        </Container>
    );
};
