import { FooterBottomBlockMenu, MenuElement } from 'types';

export const mainFooterMenuBlock1RU: MenuElement[] = [
    {
        i18nKey: 'corporateTraining',
        link: '/courses/',
        dataAtSelector: 'Link',
        withArrow: false,
        isExternal: false,
    },
    {
        i18nKey: 'news',
        link: '/news/',
        dataAtSelector: 'newsLink',
        withArrow: false,
        isExternal: false,
    },
    {
        i18nKey: 'about',
        link: '/about/',
        dataAtSelector: 'Link',
        withArrow: false,
        isExternal: false,
    },
    {
        i18nKey: 'events',
        link: '/events/',
        dataAtSelector: 'Link',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/academy-alliance/',
        i18nKey: 'academyAlliance',
        dataAtSelector: 'headerAllianceLink',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/for-businesses/',
        i18nKey: 'forBusinesses',
        dataAtSelector: 'headerForBusinessesLink',
        withArrow: false,
        isExternal: false,
    },
];

export const mainFooterMenuBlock1EN: MenuElement[] = [
    {
        i18nKey: 'corporateTraining',
        link: '/courses/',
        dataAtSelector: 'Link',
        withArrow: false,
        isExternal: false,
    },
    {
        i18nKey: 'news',
        link: '/news/',
        dataAtSelector: 'newsLink',
        withArrow: false,
        isExternal: false,
    },
    {
        i18nKey: 'about',
        link: '/about/',
        dataAtSelector: 'Link',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/academy-alliance/',
        i18nKey: 'academyAlliance',
        dataAtSelector: 'headerAllianceLink',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/for-businesses/',
        i18nKey: 'forBusinesses',
        dataAtSelector: 'headerForBusinessesLink',
        withArrow: false,
        isExternal: false,
    },
];

export const mainFooterMenuBlock2RU: MenuElement[] = [
    {
        i18nKey: 'internship',
        link: 'https://safeboard.kaspersky.ru/',
        dataAtSelector: 'safeboardExternalLink',
        withArrow: true,
        isExternal: true,
    },
    {
        link: '/for-governments/',
        i18nKey: 'forGovernments',
        dataAtSelector: 'headerForGovernmentsLink',
        withArrow: false,
        isExternal: false,
    },
    {
        i18nKey: 'kidsCyberResilience',
        link: '/kids-cyber-resilience/',
        dataAtSelector: 'kidsCyberResilience',
        withArrow: false,
        isExternal: false,
    },
];

export const mainFooterMenuBlock2EN: MenuElement[] = [
    {
        i18nKey: 'events',
        link: '/events/',
        dataAtSelector: 'Link',
        withArrow: false,
        isExternal: false,
    },
    {
        i18nKey: 'kidsCyberResilience',
        link: '/kids-cyber-resilience/',
        dataAtSelector: 'kidsCyberResilience',
        withArrow: false,
        isExternal: false,
    },
    {
        link: '/for-governments/',
        i18nKey: 'forGovernments',
        dataAtSelector: 'headerForGovernmentsLink',
        withArrow: false,
        isExternal: false,
    },
    {
        i18nKey: 'internship',
        link: 'https://cybergeneration.kaspersky.com/',
        dataAtSelector: 'safeboardExternalLink',
        withArrow: true,
        isExternal: true,
    },
];

export const footerBottomBlockMenuRu: FooterBottomBlockMenu = [
    {
        i18nKey: 'privacyPolicySite',
        dataAtSelector: 'footerPrivacySite',
        link: '/privacy-policy',
    },
];

export const footerBottomBlockMenuEn: FooterBottomBlockMenu = [
    {
        i18nKey: 'privacyPolicySite',
        dataAtSelector: 'footerPrivacySite',
        link: '/privacy-policy',
    },
];
