import styled from 'styled-components';
import { colorBlack, mediaSm } from 'theme/theme-helper-selectors';

export const Title = styled.h1`
    font-weight: 400;
    font-size: 72px;
    line-height: 72px;
    color: ${colorBlack};
    margin: 0 auto 40px;
    max-width: 1000px;
    text-align: center;

    @media (${mediaSm}) {
        font-size: 52px;
        line-height: 52px;
    }
`;
