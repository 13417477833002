import React, { FC, useEffect } from 'react';
import { Intro, Speakers, Organizations, Disclaimer } from './components';
import { useMobileMenuContext } from 'contexts';
import { Blur, Hexa } from './styled';
import { Constructor, Reviews, Test } from '../for-businesses/components';
import { useTranslation } from 'react-i18next';

const ForGovernments: FC = () => {
    const { toggleCorporateStyles } = useMobileMenuContext();
    const { t } = useTranslation('pages/for-governments');

    useEffect(() => {
        toggleCorporateStyles(true);

        window.scrollTo(0, 0);

        return () => {
            toggleCorporateStyles(false);
        };
    }, []);

    return (
        <>
            <Intro />
            <Speakers />
            <Organizations />
            <Constructor
                bgImagePath={
                    process.env.PUBLIC_URL +
                    '/assets/images/bussiness/construct-bg.png'
                }
                title={t('constructor-title')}
            />
            <Reviews params={{ isVisibleGovernmentPage: true }} />
            <Disclaimer />
            <Test />
            <Hexa />
            <Blur styles={{ top: '1380px' }} />
        </>
    );
};

export default ForGovernments;
