import { Back2ListBtn } from 'containers/pages/news-item/styled';
import styled from 'styled-components';
import {
    mediaSm,
    mediaXs,
    mediaMd,
    colorPrimary,
} from 'theme/theme-helper-selectors';

export const Header = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    @media (max-width: 1600px) {
        max-width: 1240px;
    }
`;

export const HeaderContent = styled.div`
    background-image: url(/assets/images/alliance/alliace-promo.webp);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 115%;
    border-radius: 60px;
    margin: 0 -95px 107px;
    min-height: 1105px;
    padding: 100px 95px;
    @media (max-width: 1600px) {
        margin: 0 -60px 80px;
        min-height: 800px;
        padding: 80px 60px;
    }
    @media (${mediaMd}) {
        margin: 0 -60px 80px;
        min-height: 700px;
        padding: 80px 60px;
    }
    @media (${mediaSm}) {
        background-size: cover;
        border-radius: 40px;
        margin: 0 0 80px;
        min-height: 600px;
        padding: 60px 40px;
    }
    @media (${mediaXs}) {
        background-size: cover;
        border-radius: 40px;
        margin: 0 0 80px;
        min-height: 600px;
        padding: 60px 40px;
    }
`;

export const BackButton = styled(Back2ListBtn)`
    color: #000;
    span {
        fill: #000;
    }
    &:hover {
        color: #00a88e;
        span {
            fill: ${colorPrimary};
        }
    }
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.palette.white};
    font-size: 118px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
    max-width: 1025px;
    @media (${mediaSm}) {
        font-size: 72px;
    }
    @media (${mediaXs}) {
        font-size: 48px;
    }
`;

export const SubTitle = styled.p`
    color: ${({ theme }) => theme.palette.white};
    font-size: 51px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    max-width: 1025px;
    @media (${mediaSm}) {
        font-size: 36px;
    }
    @media (${mediaXs}) {
        font-size: 24px;
    }
`;
