import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Content,
    ContentBody,
    InsideItem1,
    InsideItem2,
    InsideItem3,
    InsideItem4,
    InsideItem5,
    InsideItem6,
    InsideList,
    Title,
} from './styled';
import DOMPurify from 'dompurify';

export const Inside = () => {
    const { t } = useTranslation('pages/academy-alliance');
    return (
        <Content>
            <ContentBody>
                <Title>{t('insideTitle')}</Title>
                <InsideList>
                    <InsideItem1
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t('inside1')),
                        }}
                    />
                    <InsideItem2
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t('inside2')),
                        }}
                    />
                    <InsideItem3
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t('inside3')),
                        }}
                    />
                    <InsideItem4
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t('inside4')),
                        }}
                    />
                    <InsideItem5
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t('inside5')),
                        }}
                    />
                    <InsideItem6
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t('inside6')),
                        }}
                    />
                </InsideList>
            </ContentBody>
        </Content>
    );
};
