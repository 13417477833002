import { Disclaimer } from 'containers/pages/about/components/promo-section/styled';
import styled, { CSSObject } from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const DisclaimerExperts = styled(Disclaimer)<{ styles?: CSSObject }>`
    position: inherit;
    padding-top: 30px;

    ${({ styles }) => ({ ...styles })};
`;

export const Wrapper = styled.div`
    position: relative;
    z-index: 10;
`;

export const SingleContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 80px;

    @media (${mediaSm}) {
        flex-direction: column;
        gap: 40px;
    }

    @media (${mediaXs}) {
        gap: 20px;
    }
`;

export const SingleImage = styled.div<{ src?: string }>`
    border-radius: 16px;
    width: 30%;
    height: 410px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('${(props) => props.src}');

    @media (${mediaSm}) {
        width: 50%;
    }

    @media (${mediaXs}) {
        width: auto;
    }
`;

export const SingleContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (${mediaSm}) {
        gap: 20px;
    }
`;

export const SingleContentTitle = styled.h2`
    font-weight: 400;
    font-size: 108px;
    line-height: 108px;

    @media (${mediaSm}) {
        font-size: 70px;
        line-height: 75px;
    }

    @media (${mediaXs}) {
        font-size: 40px;
        line-height: 45px;
    }
`;

export const SingleContentDescription = styled.p`
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;

    @media (${mediaSm}) {
        font-size: 20px;
        line-height: 24px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin: -25px -15px;
`;

export const SpeakerWrapper = styled.div`
    width: 25%;
    padding: 25px 15px;

    @media (${mediaSm}) {
        width: 50%;
    }

    @media (${mediaXs}) {
        width: 100%;
    }
`;

export const SpeakerItem = styled.div`
    width: 100%;
    text-align: left;
`;

export const SpeakerImage = styled.div<{ src?: string }>`
    height: 312px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('${(props) => props.src}');
    margin: 0 0 16px 0;
    border-radius: 16px;
`;

export const SpeakerTitle = styled.h5`
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
`;

export const SpeakerPosition = styled.p`
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
`;
