import React from 'react';
import { SocialLink, SocialLinkName } from 'types';
import { Icon } from 'kl-b2c-ui-kit';

export const vkRU: SocialLink = {
    link: 'https://vk.com/kaspersky',
    Icon: () => <Icon name={'Vk'} color={'gray'} size={32} />,
    type: SocialLinkName.vk,
};

export const twitterRU: SocialLink = {
    link: 'https://twitter.com/Kaspersky',
    Icon: () => <Icon name={'Twitter'} color={'gray'} size={32} />,
    type: SocialLinkName.twitter,
};

export const youtubeRU: SocialLink = {
    link: 'https://www.youtube.com/c/KasperskyLabRussia',
    Icon: () => <Icon name={'Youtube'} color={'gray'} size={32} />,
    type: SocialLinkName.youtube,
};

export const linkedinRU: SocialLink = {
    link: 'https://www.linkedin.com/company/kaspersky-lab',
    Icon: () => <Icon name={'Linkedin'} color={'gray'} size={32} />,
    type: SocialLinkName.linkedin,
};

export const newsItemSocialsRU: SocialLink[] = [
    twitterRU,
    vkRU,
    youtubeRU,
    linkedinRU,
];

export const footerSocialsRU: SocialLink[] = [
    vkRU,
    twitterRU,
    youtubeRU,
    linkedinRU,
];

export const instagramEN: SocialLink = {
    link: 'https://instagram.com/kasperskylab',
    Icon: () => <Icon name={'Instagram'} color={'gray'} size={32} />,
    type: SocialLinkName.instagram,
};

export const twitterEN: SocialLink = {
    link: 'https://twitter.com/Kaspersky',
    Icon: () => <Icon name={'Twitter'} color={'gray'} size={32} />,
    type: SocialLinkName.twitter,
};

export const youtubeEN: SocialLink = {
    link: 'https://www.youtube.com/Kaspersky',
    Icon: () => <Icon name={'Youtube'} color={'gray'} size={32} />,
    type: SocialLinkName.youtube,
};

export const linkedinEN: SocialLink = {
    link: 'https://www.linkedin.com/company/kaspersky/mycompany/',
    Icon: () => <Icon name={'Linkedin'} color={'gray'} size={32} />,
    type: SocialLinkName.linkedin,
};

export const newsItemSocialsEN: SocialLink[] = [
    twitterEN,
    youtubeEN,
    instagramEN,
    linkedinEN,
];

export const footerSocialsEN: SocialLink[] = [
    instagramEN,
    twitterEN,
    youtubeEN,
    linkedinEN,
];
