import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.div`
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    margin: 104px 0 120px;
    max-width: 1092px;
    @media (${mediaSm}) {
        margin: 60px 0;
    }
    @media (${mediaXs}) {
        font-size: 18px;
        line-height: 24px;
        margin: 40px 0 60px;
    }
`;

export const CourseHelpItem = styled.div`
    min-height: 296px;
    border-radius: 16px;
    position: relative;
    z-index: 5;
`;

export const CourseHelpItemImage = styled.div<{ src?: string }>`
    height: 176px;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    border-radius: 16px;
    margin: 0 0 16px 0;
`;

export const CourseHelpItemTitle = styled.h4`
    font-weight: 400;
    margin: 0 0 8px 0;
    font-size: 21px;
    line-height: 24px;
`;

export const CourseHelpItemDescription = styled.div`
    font-size: 16px;
    line-height: 18px;
    font-weight: 300;
    color: #2a3541b2;
`;
