import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BlueShare,
    HeaderBlock,
    Title,
    Description,
    ButtonBlack,
} from './styled';
import { CentralBlock } from '../styled';
import DOMPurify from 'dompurify';

interface IProps {
    searchCourse: () => void;
}

export const Header: FC<IProps> = ({ searchCourse }) => {
    const { t } = useTranslation('pages/professions');

    return (
        <HeaderBlock>
            <BlueShare />
            <CentralBlock>
                <Title>{t('title')}</Title>
                <Description
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(t('description')),
                    }}
                />
                <ButtonBlack onClick={searchCourse} color={'primary'}>
                    {t('button-text')}
                </ButtonBlack>
            </CentralBlock>
        </HeaderBlock>
    );
};
