import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MainBlockContainer,
    MainBlockTitle,
    MainBlockWrapper,
    MainBlockSubtitle,
    MainBlockButton,
} from './styled';
import { useNavigate } from 'react-router-dom';
import { CSSObject } from 'styled-components';

interface MainBlockProps {
    config: {
        title: string;
        subtitle: string;
        imagePath: string;
        link: string;
        imageStyles: CSSObject;
    };
}

export const MainBlock: FC<MainBlockProps> = ({
    config: { title, subtitle, imagePath, imageStyles, link },
}) => {
    const { t } = useTranslation('pages/for-businesses');
    const navigate = useNavigate();

    return (
        <MainBlockContainer
            to={link}
            imagePath={imagePath}
            imageStyles={imageStyles}>
            <MainBlockWrapper>
                <MainBlockTitle>{title}</MainBlockTitle>
                <MainBlockSubtitle>{subtitle}</MainBlockSubtitle>
                <MainBlockButton
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return navigate(link);
                    }}
                    variant={'outlined'}
                    color={'secondary'}
                    size={'large'}>
                    {t('all-courses')}
                </MainBlockButton>
            </MainBlockWrapper>
        </MainBlockContainer>
    );
};
