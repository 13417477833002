import styled from 'styled-components';
import { mediaSm } from 'theme/theme-helper-selectors';

export const LogoWrapper = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    text-align: left;
    flex-grow: 1;
    font-size: 28px;
    font-weight: 400;
    color: #000;
    height: 64px;
    background-image: url(/assets/images/logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: -20px;

    @media (${mediaSm}) {
        display: block;
        width: 100%;
    }
`;
