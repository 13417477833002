import { PrimaryButton } from 'components/button';
import { Checkbox } from 'kl-b2c-ui-kit';
import styled from 'styled-components';
import {
    colorBlack,
    colorTeal,
    colorWhite,
    mediaSm,
    mediaTablet,
    mediaXs,
} from 'theme/theme-helper-selectors';
import { CheckboxRow } from 'components/form-base/styled';

export const BodyContent = styled.div`
    background: ${({ theme }) => theme.palette.white};
    width: 100%;
    min-height: 344px;
    position: relative;
`;

export const WhiteHeader = styled.div`
    width: 100%;
    min-height: 300px;
    border-radius: 30px 30px 0px 0px;
    background: ${({ theme }) => theme.palette.white};
    z-index: 2;
    position: relative;
    transform: matrix(1, -0.06, 0, 1, 0, -100);
    position: absolute;
`;

export const Content = styled.div`
    z-index: 3;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContentBlock = styled.div`
    display: flex;
    flex-direction: row;
    @media (${mediaSm}) {
        flex-direction: column;
    }
`;
export const MenuBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 275px;
    padding-right: 25px;
    padding-bottom: 40px;
    @media (${mediaSm}) {
        max-width: 100%;
        width: auto;
    }
`;
export const MenuTitle = styled.h4`
    padding-bottom: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
`;
export const MenuList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 40px;
    @media (${mediaSm}) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;
export const MenuListItem = styled(CheckboxRow)`
    @media (${mediaSm}) {
        width: auto;

        label {
            gap: 8px;
            padding: 5px 12px;
            border-radius: 24px;
            background: rgba(236, 239, 243, 1);
            &[value='true'] {
                background: linear-gradient(
                    45deg,
                    ${colorTeal} 38.74%,
                    #33ff77 100%
                );
            }
            span {
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
`;
export const InnerCheckBox = styled(Checkbox)`
    span span {
        font-size: 16px;
        font-weight: 300;
        line-height: 18.75px;
        color: rgba(56, 63, 72, 1);
    }
`;
export const ListBlockCourses = styled.div`
    padding-top: 32px;
    padding-bottom: 26px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 26px;
    @media (${mediaSm}) {
        grid-template-columns: 1fr;
    }
`;
export const ListBlockProfessions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding-bottom: 26px;
    padding-top: 32px;
`;
export const SearchBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

export const TextInputMobileBlock = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    @media (${mediaTablet}) {
        display: none;
    }
`;

export const TextInputDesktopBlock = styled.div`
    display: flex;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 350px;
    @media (${mediaSm}) {
        display: none;
    }
`;

export const TextInput = styled.div`
    width: 100%;
`;

export const TitleBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const Title = styled.h2`
    font-size: 54px;
    font-weight: 400;
    line-height: 63.29px;
    color: ${({ theme }) => theme.palette.black};
    span {
        font-size: 54px;
        font-weight: 400;
        line-height: 63.29px;
        opacity: 0.3;
        text-decoration: underline;
        padding-left: 20px;
    }
    @media (${mediaXs}) {
        font-size: 24px;
        font-weight: 500;
        line-height: 27px;
        span {
            font-size: 24px;
            font-weight: 500;
            line-height: 27px;
            padding-left: 8px;
        }
    }
`;

export const DescriptionBlock = styled.p`
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
    opacity: 0.7;
    padding-top: 16px;
    max-width: 50%;
    @media (${mediaXs}) {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        padding-top: 12px;
        max-width: 100%;
    }
`;

export const ButtonBlock = styled.div`
    padding-bottom: 64px;
    @media (${mediaXs}) {
        padding-bottom: 48px;
    }
`;
export const MoreButton = styled(PrimaryButton)`
    border-radius: 8px;
    border: 2px solid rgba(34, 52, 73, 0.4);
    background: ${colorWhite};
    color: ${colorBlack};
    box-shadow: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    &:hover:not(:disabled) {
        background: ${colorWhite};
        box-shadow: none;
    }
    @media (max-width: 500px) {
        width: 100%;
    }
    span p {
        display: flex;
        flex-direction: row;
        span {
            margin-left: 10px;
        }
    }
    span span span {
        opacity: 0.7;
    }
`;

export const ListEmpty = styled.h4`
    padding-top: 50px;
    font-size: 48px;
    font-weight: 400;
    line-height: 56.26px;

    color: ${({ theme }) => theme.palette.black};
    max-width: 600px;
    @media (${mediaXs}) {
        font-size: 24px;
        font-weight: 400;
        line-height: 27px;
    }
`;

export const ChangeFilters = styled.p`
    color: ${({ theme }) => theme.palette.black};
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
    padding-top: 20px;
    padding-bottom: 148px;
    @media (${mediaXs}) {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        padding-top: 12px;
        padding-bottom: 24px;
    }
`;

export const MenuItemBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const AllFilterMobileBlock = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    @media (${mediaTablet}) {
        display: none;
    }
`;

export const HideBlock = styled.div<{ hide: boolean }>`
    display: flex;
    flex-direction: column;
    @media (${mediaSm}) {
        display: ${({ hide }) => (hide ? 'none' : 'flex')};
    }
`;
