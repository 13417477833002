import { AxiosResponse } from 'axios';
import React, {
    Context,
    createContext,
    PropsWithChildren,
    useContext,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getProfessionApiService } from 'services/api.factory';
import {
    GetProfession,
    GetProfessions,
    Paging,
    ProfessionDetail,
    ProfessionRegistration,
    ProfessionTable,
} from 'types';

interface ProfessionContext {
    professions: Paging<ProfessionTable>;
    profession?: ProfessionDetail;
    loading: boolean;
    isError: boolean;
    errorText: string;
    getList: (params: GetProfessions) => Promise<void>;
    appendList: (params: GetProfessions) => Promise<void>;
    getDetailed: (params: GetProfession) => Promise<void>;
    register: (data: ProfessionRegistration) => Promise<AxiosResponse>;
}

const ProfessionContext: Context<ProfessionContext> =
    createContext<ProfessionContext>({} as ProfessionContext);

const ProfessionProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation('pages/professions');
    const [professions, setProfessions] = useState<Paging<ProfessionTable>>({
        items: [],
        count: 0,
    });
    const [profession, setProfession] = useState<ProfessionDetail>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState('');
    const api = getProfessionApiService();

    const getList = (params: GetProfessions): Promise<void> => {
        setLoading(true);
        return api
            .getList(params)
            .then(
                (data) => {
                    setProfessions(
                        data?.data ?? {
                            items: [],
                            count: 0,
                        }
                    );
                },
                (e: unknown) => {
                    setIsError(true);

                    if (e instanceof Error) {
                        setErrorText(e.message);
                    } else {
                        setErrorText(t('error'));
                    }
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const appendList = (params: GetProfessions): Promise<void> => {
        setLoading(true);
        return api
            .getList(params)
            .then(
                (data) => {
                    setProfessions({
                        ...professions,
                        items: [...professions.items, ...data.data.items],
                    });
                },
                (e: unknown) => {
                    setIsError(true);

                    if (e instanceof Error) {
                        setErrorText(e.message);
                    } else {
                        setErrorText(t('error'));
                    }
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const getDetailed = (params: GetProfession): Promise<void> => {
        setLoading(true);
        setIsError(false);
        return api
            .getDetailed(params)
            .then(
                (data) => {
                    setProfession(data?.data ?? undefined);
                },
                (e) => {
                    setIsError(true);
                    throw e;
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const register = (data: ProfessionRegistration): Promise<AxiosResponse> =>
        api.register(data).catch(() => new Promise((_, reject) => reject()));

    const memoValue = useMemo(
        () => ({
            professions,
            profession,
            loading,
            isError,
            errorText,
            getList,
            getDetailed,
            appendList,
            register,
        }),
        [professions, profession, loading, register]
    );

    return (
        <ProfessionContext.Provider value={memoValue}>
            {children}
        </ProfessionContext.Provider>
    );
};

export const useProfessions = () => useContext(ProfessionContext);
export default ProfessionProvider;
