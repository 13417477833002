import { SpeakersResponseData, GetSpeakers } from 'types';
import { ApiService } from 'services';

export class SpeakerApi extends ApiService {
    public getSpeakers(params: GetSpeakers): Promise<SpeakersResponseData> {
        return this.request<SpeakersResponseData>({
            method: 'GET',
            url: '/Speaker/GetSpeakers',
            params,
        }).then((response) => response.data);
    }
}
