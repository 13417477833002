import React, { FC, useState } from 'react';
import { LanguageType } from 'types';
import { getData } from './data';
import { useTranslation } from 'react-i18next';
import {
    Answer,
    AnswersBlock,
    BackButton,
    ButtonBlock,
    Content,
    Question,
    Title,
} from './styled';
import { Question as QuestionType } from '../../types';

export const QuizModal: FC = () => {
    const { t } = useTranslation(['pages/quiz', 'common/shared']);
    const [app, setApp] = useState(
        getData(
            Object.keys(LanguageType)
                .filter((r) => !isNaN(Number(r)))
                .map((n) => ({
                    id: Number(n),
                    name: t(`common/shared:lang-${n}`),
                }))
        )
    );

    const selectEl = (next: QuestionType) => {
        if (next?.link) {
            window.location.href = next?.link;
            return;
        }
        if (next?.select) {
            next?.select();
            return;
        }
        setApp(next);
    };

    const prev = (prev?: QuestionType) => {
        if (!prev) return;

        setApp(prev);
    };

    return (
        <Content>
            <Title>{t(`pages/quiz:${app.title}`)}</Title>
            <Question>
                {app.question && t(`pages/quiz:${app.question}`)}
            </Question>
            {app.next?.length && (
                <AnswersBlock>
                    {app.next?.map((a) => (
                        <Answer key={a.id} onClick={() => selectEl(a)}>
                            {a.answer && t(`pages/quiz:${a.answer}`)}
                        </Answer>
                    ))}
                </AnswersBlock>
            )}
            {app.component && <app.component />}
            {app.prev && (
                <ButtonBlock>
                    <BackButton onClick={() => prev(app.prev)}>
                        {t('pages/quiz:back')}
                    </BackButton>
                </ButtonBlock>
            )}
        </Content>
    );
};
