import styled from 'styled-components';
import { mediaXs } from '../../../theme/theme-helper-selectors';

export const Copyright = styled.div`
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    padding: 0 16px 0 0px;

    @media (${mediaXs}) {
        width: 100%;
    }
`;
