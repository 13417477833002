import styled from 'styled-components';
import {
    colorMenuBackground,
    headerHeight,
    mediaSm,
} from 'theme/theme-helper-selectors';

export const NavList = styled.ul`
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    height: ${headerHeight};

    @media (${mediaSm}) {
        background: ${colorMenuBackground};
    }

    @media (${mediaSm}) {
        width: 100%;
        flex-wrap: wrap;
        height: auto;
    }
`;

export const MobileSubmenuList = styled.ul`
    list-style: none;
    display: none;
    margin: 0;
    padding: 0;
    height: ${headerHeight};

    @media (${mediaSm}) {
        display: flex;
        background: ${colorMenuBackground};
        width: 100%;
        flex-wrap: wrap;
        height: auto;
    }
`;
