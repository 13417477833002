import { useMobileMenuContext } from 'contexts';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getProfessionApiService } from 'services';
import { ProfessionDetail } from 'types';
import { langMapper } from 'utils';
import { Courses, Reviews, Speakers } from '../for-businesses/components';
import {
    CourseDetailWithType,
    DetailType,
} from '../for-businesses/components/courses/Courses';
import NewsList from '../news-list';
import { Constructor, Intro } from './components';
import { NewsContainer, NewsTitle } from './styled';
import { DefaultTheme, withTheme } from 'styled-components';
import PageSection from 'components/page-section';

const newsSectionConfig = {
    CWrap: {
        css: {
            paddingBottom: 110,
        },
    },
    ContentArea: {
        css: {
            display: 'block',
        },
    },
};

export const Home: FC<{ theme: DefaultTheme }> = ({ theme }): JSX.Element => {
    const { t, i18n } = useTranslation('pages/home');
    const navigate = useNavigate();
    const [courses, setCourses] = useState<CourseDetailWithType[]>();

    const { toggleProfessionStyles } = useMobileMenuContext();
    const professionsApi = getProfessionApiService();

    useEffect(() => {
        toggleProfessionStyles(true);

        professionsApi
            .getList({
                page: 0,
                size: 20,
                search: '',
                domainType: langMapper[i18n.language],
                isVisibleHomePage: true,
            })
            .then((courses) => {
                const professionsProcessed = courses.data.items.map(
                    (course) => ({
                        id: (course as ProfessionDetail).id,
                        name: course.name,
                        groupName: course.groupName,
                        description: (course as ProfessionDetail)
                            .announcementDescription,
                        type: DetailType.Profession,
                        link: course.link,
                        previous: course.previous,
                    })
                );

                setCourses([...professionsProcessed]);
            });

        return () => {
            toggleProfessionStyles(false);
        };
    }, []);

    return (
        <>
            <Intro />
            {courses && (
                <Courses
                    courses={courses}
                    config={{
                        description: t('course-description'),
                        image: '/assets/images/bussiness/worker-homepage.png',
                    }}
                    styles={{
                        background: theme.palette.black,
                        position: 'relative',
                        '&:before': {
                            content: '""',
                            position: 'absolute',
                            width: '100%',
                            height: '150px',
                            background: `linear-gradient(0deg, ${theme.palette.black} 5%, rgba(33, 39, 47, 0) 94.53%)`,
                            left: 0,
                            top: '-150px',
                            zIndex: 5,
                        },
                        ':after': {
                            content: '""',
                            width: '100%',
                            height: '135px',
                            position: 'absolute',
                            background: `${theme.palette.black}`,
                            bottom: 0,
                            transformOrigin: 'right bottom',
                            transform: 'translateY(100%) skewY(3deg)',
                            borderRadius: '0 0 40px 40px',
                            zIndex: 1,

                            '@media(max-width: 1023px)': {
                                height: '90px',
                            },
                        },
                    }}
                />
            )}

            <Constructor bgImagePath={''} title={t('constructor-title')} />
            <Speakers
                apiParams={{
                    page: 0,
                    size: 20,
                    isShowHomePage: true,
                    domainType: langMapper[i18n.language],
                }}
            />
            <Reviews
                params={{ isVisibleHomePage: true }}
                resources={{
                    subtitle: t('knowledge-share'),
                    button: t('all-courses'),
                }}
                redirect={() => navigate('/courses')}
            />
            <NewsContainer styles={{ paddingTop: '50px' }}>
                <PageSection config={newsSectionConfig}>
                    <NewsList
                        nested
                        secondary
                        tagHeapHidden
                        params={{ Size: 3, IsVisibleOnHomePage: true }}
                        redirectOnMore={() => navigate('/news')}
                        title={<NewsTitle>{t('news')}</NewsTitle>}
                        buttonStyles={{
                            background: 'transparent',
                            padding: '14px 24px',
                            border: '2px solid #fff',
                            borderRadius: '8px',
                            boxShadow: 'none !important',

                            '&:hover': {
                                background:
                                    'rgba(255, 255,255, 0.1) !important',
                            },
                        }}
                    />
                </PageSection>
            </NewsContainer>
        </>
    );
};

export default withTheme(Home);
