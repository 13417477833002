import { AxiosResponse } from 'axios';
import { ApiService } from 'services';
import { CooperationFormData, SubscriptionForm } from 'types';

export class FormsApi extends ApiService {
    public sendCooperationFormData(
        data: CooperationFormData
    ): Promise<AxiosResponse> {
        return this.request({
            method: 'POST',
            url: '/CooperationForm/Add',
            data,
        });
    }

    public sendSubscriptionFormData(
        data: SubscriptionForm
    ): Promise<AxiosResponse> {
        return this.request({
            method: 'POST',
            url: '/SubscriptionForm/Add',
            data,
        });
    }
}
