import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    DropdownContent,
    DropdownItem,
    DropdownRoot,
    DropdownTitle,
} from './styled';
import { useSettings } from 'contexts';

interface HeaderMenuDropdownProps {
    icon?: JSX.Element;
    title?: string;
    classTitleName?: string;
    children: JSX.Element[];
    level?: number;
    isOpen: boolean;
    open: () => void;
    close: () => void;
}

const HeaderMenuDropdown: React.FC<HeaderMenuDropdownProps> = ({
    icon,
    title,
    classTitleName,
    children,
    open,
    close,
    isOpen,
    level = 0,
}) => {
    const { settings } = useSettings();
    const { i18n } = useTranslation();

    return (
        <DropdownRoot isOpen={isOpen}>
            <DropdownTitle
                className={classTitleName}
                onClick={() => (isOpen ? close() : open())}>
                {icon}
                <span>{title}</span>
            </DropdownTitle>
            <DropdownContent root={level === 0}>
                {children.map((item, index) => (
                    <DropdownItem
                        key={index}
                        level={level}
                        className={`${
                            i18n.language === item.key ? 'isActive' : ''
                        }`}
                        onClick={() => {
                            window.location.assign(
                                `${
                                    item.key === 'en'
                                        ? settings?.originEN
                                        : settings?.originRU
                                }${
                                    window.location.pathname !== '/'
                                        ? window.location.pathname
                                        : ''
                                }`
                            );
                        }}>
                        {item}
                    </DropdownItem>
                ))}
            </DropdownContent>
        </DropdownRoot>
    );
};

export default HeaderMenuDropdown;
