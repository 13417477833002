import dayjs from 'dayjs';

export const getTimeLeft = (
    startDate: Date | string
): { days: number; hours: number } => {
    const now = dayjs();
    const date = dayjs(startDate);
    let hours = date.diff(now, 'hours');

    if (hours <= 0)
        return {
            days: 0,
            hours: 0,
        };

    const days = Math.floor(hours / 24);
    hours = hours - days * 24;

    return {
        days,
        hours,
    };
};
