import styled from 'styled-components';
import { colorWhite, mediaSm } from 'theme/theme-helper-selectors';

export const DescriptionSubtitle = styled.p`
    font-size: 24px;
    line-height: 27px;
    color: #d4d6d7;
    margin: 0 0 40px 0;
    font-weight: 300;
    max-width: 586px;

    @media (${mediaSm}) {
        font-size: 19px;
        line-height: 23px;
    }
`;

export const TitleSlider = styled.div`
    display: flex;
    align-items: center;
    gap: 22px;
    height: 56px;
`;

export const SliderArrow = styled.div`
    width: 56px;
    height: 56px;
    background: #21272f;
    border-radius: 50%;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (${mediaSm}) {
        width: 40px;
        height: 40px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
`;

export const Counter = styled.div`
    font-size: 24px;
    line-height: 28px;
    color: ${colorWhite};

    span {
        opacity: 0.5;
    }

    @media (${mediaSm}) {
        font-size: 20px;
        line-height: 24px;
    }
`;
