import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MainBlockTitle,
    MainBlockWrapper,
    MainBlockSubtitle,
    MainBlockButton,
    SecondaryBlockContainer,
} from './styled';
import { useNavigate } from 'react-router-dom';

interface SecondaryBlockProps {
    config: {
        title: string;
        subtitle: string;
        link: string;
    };
}

export const SecondaryBlock: FC<SecondaryBlockProps> = ({
    config: { title, subtitle, link },
}) => {
    const { t } = useTranslation('pages/for-businesses');
    const navigate = useNavigate();

    return (
        <SecondaryBlockContainer to={link}>
            <MainBlockWrapper secondary>
                <MainBlockTitle>{title}</MainBlockTitle>
                <MainBlockSubtitle>{subtitle}</MainBlockSubtitle>
                <MainBlockButton
                    secondary
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return navigate(link);
                    }}
                    variant={'outlined'}
                    color={'secondary'}
                    size={'large'}>
                    {t('all-courses')}
                </MainBlockButton>
            </MainBlockWrapper>
        </SecondaryBlockContainer>
    );
};
