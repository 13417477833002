import { PageSection } from 'components';
import { ArrowContainer } from 'containers/pages/for-businesses/components/courses/styled';
import { Icon, PaginationOutput } from 'kl-b2c-ui-kit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider, { Settings } from 'react-slick';
import { getOrganizationsApiService } from 'services';
import { Organization } from 'types';
import { langMapper } from 'utils/index';
import {
    DotsContainer,
    DotsList,
    SliderContainer,
} from '../../../for-businesses/components/reviews/styled';
import { Slide, SlideImage, SlideTitle, Title } from './styled';

const sectionConfig = {
    CWrap: {
        css: {
            padding: '60px 0',
            color: '#fff',
        },
    },
    ContentArea: {
        css: {
            display: 'block',
            position: 'relative',
            zIndex: 5,
        },
    },
};

const DEFAULT_SLIDES_COUNT = 3;

export type CarouselConfig = {
    slidesToShow: number;
    arrows: boolean;
    dots: boolean;
};

export const Organizations = () => {
    const { t, i18n } = useTranslation('pages/for-governments');
    const organizationsApi = getOrganizationsApiService();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [carouselConfig, setCarouselConfig] = useState<CarouselConfig>({
        slidesToShow: 3,
        arrows: true,
        dots: true,
    });

    const CAROUSEL_SETTINGS: Settings = {
        speed: 500,
        slidesToScroll: 3,
        infinite: false,
        nextArrow: (
            <ArrowContainer isPrev>
                <Icon name={'ArrowRightThin'} color={'black'} size={24} />
            </ArrowContainer>
        ),
        prevArrow: (
            <ArrowContainer>
                <Icon name={'ArrowLeftThin'} color={'black'} size={24} />
            </ArrowContainer>
        ),
        ...carouselConfig,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
        appendDots: (dots) => (
            <DotsContainer>
                <DotsList> {dots} </DotsList>
            </DotsContainer>
        ),
    };

    useEffect(() => {
        organizationsApi
            .getOrganizations({
                page: 0,
                size: 20,
                isVisibleGovernmentPage: true,
                domain: langMapper[i18n.language],
            })
            .then((organizations: PaginationOutput<Organization>) => {
                if (organizations.items && organizations.items.length) {
                    setOrganizations(organizations?.items);

                    if (organizations?.items.length <= DEFAULT_SLIDES_COUNT) {
                        setCarouselConfig({
                            dots: false,
                            arrows: false,
                            slidesToShow: organizations.items.length,
                        });
                    }
                }
            })
            .catch((e) => console.error(e));
    }, []);

    if (!organizations) {
        return null;
    }

    return (
        <PageSection config={sectionConfig}>
            <Title>{t('organizations-title')}</Title>

            <SliderContainer>
                <Slider {...CAROUSEL_SETTINGS} ref={(slider) => slider}>
                    {organizations.map((organization: Organization) => (
                        <Slide key={organization.id}>
                            <SlideImage image={organization.image?.fileLink} />
                            <SlideTitle>{organization.name}</SlideTitle>
                        </Slide>
                    ))}
                </Slider>
            </SliderContainer>
        </PageSection>
    );
};
