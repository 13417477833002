import React, { FC } from 'react';
import { useCourse } from 'contexts/course.context';
import { useTranslation } from 'react-i18next';
import {
    Left,
    Price,
    PriceTitle,
    Right,
    TitleWrapper,
    PriceDescription,
} from './styled';
import { CoursePrice } from 'types';
import DOMPurify from 'dompurify';
import { SectionTitle } from '../../styled';
import { SpeakersButton } from 'containers/pages/for-businesses/components/speakers/styled';

interface PricesProps {
    openRegistrationModal: () => void;
}

export const Prices: FC<PricesProps> = ({ openRegistrationModal }) => {
    const { t } = useTranslation('pages/courses');
    const { course } = useCourse();

    return (
        <>
            <TitleWrapper>
                <SectionTitle>{t('prices')}</SectionTitle>

                <SpeakersButton
                    onClick={openRegistrationModal}
                    variant={'contained'}
                    color={'primary-contrast'}
                    styles={{ height: '48px' }}>
                    {course?.isProduct
                        ? t('find-a-provider')
                        : t('registration-modal-title')}
                </SpeakersButton>
            </TitleWrapper>

            {course?.prices.map((price: CoursePrice) => (
                <Price key={price.id}>
                    <Left>
                        <PriceTitle>{price.name}</PriceTitle>
                        <PriceDescription>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        price.description
                                    ),
                                }}
                            />
                        </PriceDescription>
                    </Left>

                    <Right>
                        <PriceDescription
                            styles={{ fontSize: '16px', lineHeight: '18px' }}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        price.additionalDescription
                                    ),
                                }}
                            />
                        </PriceDescription>
                    </Right>
                </Price>
            ))}
        </>
    );
};
