import { APIMap, APIDictionary, EndPointName } from 'types';

import { axiosConfig } from 'consts';
import { genEndPointFn } from '.';
import axios from 'axios';

export const genAPI = (apiMap: APIMap, apiOrigin: string): APIDictionary => {
    const output: Partial<APIDictionary> = {};
    const axiosInst = axios.create({
        baseURL: apiOrigin,
    });
    for (const endPointName in apiMap) {
        if (Object.prototype.hasOwnProperty.call(apiMap, endPointName)) {
            const {
                name,
                path,
                type,
                callback = undefined,
            } = apiMap[endPointName as keyof typeof EndPointName];
            output[name as keyof typeof EndPointName] = genEndPointFn(
                axiosInst,
                path,
                type,
                axiosConfig,
                callback
            );
        }
    }

    return output;
};

export default genAPI;
