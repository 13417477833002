import i18n from 'config/i18n';
import React, { forwardRef } from 'react';
import { Lang } from 'types';
import { langMapper } from 'utils';
import { En, Ru } from './components';
import { Wrapper } from './styled';

interface PrivacyPolicyProps {
    isHidden?: boolean;
}

export const PrivacyPolicy = forwardRef<HTMLDivElement, PrivacyPolicyProps>(
    ({ isHidden = false }, ref): JSX.Element => (
        <Wrapper isHidden={isHidden} ref={ref}>
            {langMapper[i18n.language] === Lang.Ru ? <Ru /> : <En />}
        </Wrapper>
    )
);

export default PrivacyPolicy;
