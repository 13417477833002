import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { ParamsObj, GenEndPointFn } from 'types';

export const genEndPointFn: GenEndPointFn =
    (axiosInst, url, method, axiosConfig, callback?) =>
    async <T = unknown>(urlParams?: ParamsObj, data?: T) => {
        const curAxiosConfig: AxiosRequestConfig<T> = {
            url,
            method,
            ...axiosConfig,
        };
        console.warn(curAxiosConfig);
        if (urlParams) curAxiosConfig.params = { ...urlParams };
        if (data) curAxiosConfig.data = { ...data };
        return axiosInst
            .request(axiosConfig)
            .then((response: AxiosResponse<T, T>) => {
                console.warn(response);
                const curCallback = callback;
                if (curCallback) curCallback<T>(response);
                return response;
            })
            .catch((error: AxiosError) => {
                console.error(error);
            });
    };
