import React from 'react';
import { useCourse } from 'contexts/course.context';
import { useTranslation } from 'react-i18next';
import { PageSection } from 'components';
import {
    Container,
    SingleContainer,
    SingleContent,
    SingleContentDescription,
    SingleContentTitle,
    SingleImage,
    SpeakerImage,
    SpeakerItem,
    SpeakerPosition,
    SpeakerWrapper,
    Wrapper,
    SpeakerTitle,
} from './styled';
import { Hexa } from 'containers/pages/for-businesses/components/speakers/styled';
import { SectionTitle, SectionTitleWrapper } from '../../styled';
import { Speaker } from 'types';

const sectionConfig = {
    CWrap: {
        css: {
            background: '#3DE8CA',
            backgroundImage:
                'radial-gradient(ellipse farthest-corner at 92% 95%,#4DFF88,transparent 40%),radial-gradient(ellipse farthest-side at 10% 90%,#4DFF88,transparent 40%)',
            overflow: 'hidden',
        },
    },
    ContentArea: {
        css: {
            display: 'block',
            padding: '150px 20px 230px',

            '@media(max-width: 1023px)': {
                padding: '100px 20px 120px !important',
            },
        },
    },
};

export const Experts = () => {
    const { t } = useTranslation('pages/courses');
    const { course } = useCourse();

    return (
        <PageSection config={sectionConfig}>
            <Hexa
                secondary
                image={
                    process.env.PUBLIC_URL +
                    '/assets/images/bussiness/hexa2.png'
                }
                styles={{
                    left: '-15%',
                    transform: 'rotate(186deg)',
                    maxWidth: '1800px',
                    '@media(max-width: 1023px)': {
                        left: '-15%',
                    },
                }}
            />
            <Wrapper>
                <SectionTitleWrapper>
                    <SectionTitle>{t('experts')}</SectionTitle>
                </SectionTitleWrapper>
                {course?.speakers && course.speakers.length === 1 ? (
                    <SingleContainer>
                        <SingleImage src={course.speakers[0].image?.fileLink} />
                        <SingleContent>
                            <SingleContentTitle>
                                {course.speakers[0].firstName}{' '}
                                {course.speakers[0].lastName}
                            </SingleContentTitle>
                            <SingleContentDescription>
                                {course.speakers[0].position}
                            </SingleContentDescription>
                        </SingleContent>
                    </SingleContainer>
                ) : (
                    <Container>
                        {course?.speakers.map((speaker: Speaker) => (
                            <SpeakerWrapper key={speaker.id}>
                                <SpeakerItem>
                                    <SpeakerImage
                                        src={speaker.image?.fileLink}
                                    />
                                    <SpeakerTitle>
                                        {speaker.firstName} {speaker.lastName}
                                    </SpeakerTitle>
                                    <SpeakerPosition>
                                        {speaker.position}
                                    </SpeakerPosition>
                                </SpeakerItem>
                            </SpeakerWrapper>
                        ))}
                    </Container>
                )}
            </Wrapper>
        </PageSection>
    );
};
