import styled, { CSSObject } from 'styled-components';
import { mediaSm } from '../../theme/theme-helper-selectors';

export const CWrap = styled.div<{ customCSS: CSSObject }>`
    display: flex;
    justify-content: center;
    position: relative;

    ${(props) => props.customCSS}
`;

export const ContentArea = styled.div<{ customCSS: CSSObject }>`
    display: flex;
    width: 100%;
    max-width: 1240px;
    padding: 0 20px;

    ${(props) => props.customCSS}

    @media (${mediaSm}) {
        padding-top: 40px;
    }

    &.nested {
        padding: 40px 0 0 0;
    }
`;
