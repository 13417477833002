import styled, { css } from 'styled-components';
import { mediaXs, mediaSm } from 'theme/theme-helper-selectors';
import { PrimaryButton } from 'components/button';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px;
    @media (${mediaSm}) {
        padding: 32px;
    }
    @media (${mediaXs}) {
        padding: 0px;
    }
`;
export const Title = styled.h3`
    font-size: 72px;
    font-weight: 500;
    line-height: 72px;
    text-align: center;
    padding-bottom: 40px;
    @media (${mediaSm}) {
        font-size: 56px;
        line-height: 56px;
    }
    @media (${mediaXs}) {
        font-size: 48px;
        line-height: 48px;
    }
`;
export const Description = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 21.1px;
    text-align: center;
    padding-bottom: 40px;
`;
export const GoButton = styled(PrimaryButton)`
    border-radius: 10px;
    ${({ color }) =>
        color === 'error' &&
        css`
            background: ${({ theme }) => theme.palette.black};
            &:hover:not(:disabled) {
                background: ${({ theme }) => theme.palette.black};
            }
        `}
`;
