import React, { useState } from 'react';
import { DescriptionCard, PageSection } from 'components';
import { CardConfig } from 'components/description-card/DescriptionCard';
import { GOVERNMENTS_DESCRIPTION_CARDS } from 'consts';
import { useTranslation } from 'react-i18next';
import {
    ButtonContainer,
    DescriptionItemsContainer,
    DescriptionTitle,
    IntroButton,
    SliderContainer,
    Subtitle,
    Title,
} from '../../../for-businesses/components/intro/styled';
import {
    Counter,
    DescriptionSubtitle,
    SliderArrow,
    TitleSlider,
} from './styled';
import { Icon } from 'kl-b2c-ui-kit';
import { SliderStyler } from 'containers/pages/for-businesses/components/courses/styled';
import Slider, { Settings } from 'react-slick';
import {
    DotsContainer,
    DotsList,
} from 'containers/pages/for-businesses/components/reviews/styled';

const sectionConfig = {
    CWrap: {
        css: {},
    },
    ContentArea: {
        css: {
            paddingTop: 100,
            paddingBottom: 90,
            display: 'block',
            zIndex: 5,

            '@media(max-width: 1279px)': {
                paddingBottom: 20,
            },
        },
    },
};

export const Intro = () => {
    const { t } = useTranslation('pages/for-governments');
    const [currentTitle, setCurrentTitle] = useState<number>(0);

    const CAROUSEL_SETTINGS: Settings = {
        slidesToScroll: 1,
        infinite: false,
        slidesToShow: 1,
        arrows: false,
        dots: true,
        appendDots: (dots) => (
            <DotsContainer>
                <DotsList> {dots} </DotsList>
            </DotsContainer>
        ),
    };

    return (
        <PageSection config={sectionConfig}>
            <Title>{t(`title-${currentTitle}`)}</Title>
            <Subtitle>{t('subtitle')}</Subtitle>

            <ButtonContainer>
                <IntroButton
                    size={'large'}
                    onClick={() =>
                        (window.location.href = 'mailto:Academy@kaspersky.com')
                    }>
                    {t('intro-button')}
                </IntroButton>
                <TitleSlider>
                    <SliderArrow
                        onClick={() => {
                            if (currentTitle !== 0) {
                                setCurrentTitle((prev) => prev - 1);
                            }
                        }}>
                        <Icon
                            name={'ArrowLeftThin'}
                            color={'white'}
                            size={24}
                        />
                    </SliderArrow>
                    <Counter>
                        {currentTitle + 1} <span>/ 6</span>
                    </Counter>
                    <SliderArrow
                        onClick={() => {
                            if (currentTitle < 5) {
                                setCurrentTitle((prev) => prev + 1);
                            }
                        }}>
                        <Icon
                            name={'ArrowRightThin'}
                            color={'white'}
                            size={24}
                        />
                    </SliderArrow>
                </TitleSlider>
            </ButtonContainer>

            <DescriptionTitle>{t(`intro-description-title`)}</DescriptionTitle>
            <DescriptionSubtitle>
                {t('intro-description-subtitle')}
            </DescriptionSubtitle>
            <DescriptionItemsContainer>
                {GOVERNMENTS_DESCRIPTION_CARDS.map((item: CardConfig) => (
                    <DescriptionCard
                        key={item.title}
                        type={'secondary'}
                        config={{
                            ...item,
                            title: t(item.title),
                            subtitle: item.subtitle ? t(item.subtitle) : '',
                        }}
                    />
                ))}
            </DescriptionItemsContainer>
            <SliderContainer>
                <SliderStyler>
                    <Slider {...CAROUSEL_SETTINGS}>
                        {GOVERNMENTS_DESCRIPTION_CARDS.map(
                            (item: CardConfig) => (
                                <DescriptionCard
                                    key={item.title}
                                    type={'secondary'}
                                    config={{
                                        ...item,
                                        title: t(item.title),
                                        subtitle: item.subtitle
                                            ? t(item.subtitle)
                                            : '',
                                    }}
                                />
                            )
                        )}
                    </Slider>
                </SliderStyler>
            </SliderContainer>
        </PageSection>
    );
};
