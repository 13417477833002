import React from 'react';
import { CWrap } from './styled';
import { NewsCard } from '..';
import { NewsListItem } from 'types';
import { BASE_NEWS_COUNT } from 'containers/pages/news-list/NewsList';

export interface Props {
    items: NewsListItem[];
    secondary?: boolean;
}

export const NewsCardPlate: React.FC<Props> = ({
    items,
    secondary,
}): JSX.Element => (
    <CWrap>
        {items.map(({ baseImage, name, groupName, id }, index) => (
            <NewsCard
                key={id}
                secondary={secondary}
                data={{
                    imageURL: baseImage?.fileLink ?? '',
                    title: name,
                    link: `/news/${groupName}/`,
                }}
                isFirstInBlock={index % BASE_NEWS_COUNT === 0}
            />
        ))}
    </CWrap>
);

export default NewsCardPlate;
