import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorBlack, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    background: rgba(244, 247, 250, 1);
    overflow: hidden;
    &:hover {
        background: rgba(255, 247, 229, 1);
    }
`;

export const Description = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (${mediaXs}) {
        padding: 16px 24px;
    }
`;

export const TagBlock = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    flex-wrap: wrap;
`;

export const Tag = styled.span<{ isGreen?: boolean; white?: boolean }>`
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    padding: 5px 12px;
    border-radius: 24px;
    color: ${colorBlack};
    background: ${({ isGreen = false, white = false }) =>
        isGreen
            ? 'linear-gradient(45deg, #3DE8CA 38.74%, #33FF77 100%)'
            : white
            ? 'rgba(255, 239, 157, 1)'
            : 'rgba(190, 233, 255, 1)'};
    text-transform: uppercase;
`;

export const Title = styled(Link)`
    padding: 24px 0px;
    font-size: 36px;
    font-weight: 500;
    line-height: 42.19px;
    color: ${colorBlack};
    @media (${mediaXs}) {
        font-size: 21px;
        line-height: 24px;
        padding: 16px 0px 8px 0px;
    }
    &:hover {
        color: ${colorBlack};
    }
`;

export const Announcement = styled.p`
    padding-bottom: 24px;
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    color: ${colorBlack};
    @media (${mediaXs}) {
        padding-bottom: 16px;
        font-size: 12px;
        line-height: 14px;
        color: rgba(42, 53, 65, 0.7);
    }
`;

export const DurationText = styled.p`
    color: rgba(106, 114, 122, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    height: 100%;
`;
