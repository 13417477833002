import styled from 'styled-components';
import { mediaXs } from 'theme/theme-helper-selectors';

export const MessageBlock = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    @media (${mediaXs}) {
        flex-direction: column;
    }
`;

export const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const Title = styled.h4`
    font-size: 72px;
    font-weight: 400;
    line-height: 72px;
    padding-bottom: 24px;
`;

export const Text = styled.p`
    font-size: 24px;
    font-weight: 300;
    line-height: 27px;
    max-width: 620px;
`;

export const ImageBlock = styled.div<{ success: boolean }>`
    position: relative;
    min-width: ${({ success }) => (success ? 400 : 300)}px;
    height: ${({ success }) => (success ? '300px' : 'auto')};

    @media (${mediaXs}) {
        margin-top: 20px;
        transform: scale(0.6);
    }
`;

export const ErrorImage = styled.div`
    background-image: url(/assets/confirm/attention.png);
    width: 300px;
    height: 293px;
`;

export const MessageImage = styled.div`
    background-image: url(/assets/confirm/message.png);
    width: 238px;
    height: 224px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0px;
    top: 0px;
`;
export const ComputerImage = styled.div`
    background-image: url(/assets/confirm/computer.png);
    width: 223px;
    height: 144px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 161px;
    top: 139px;
`;
export const ArrowImage = styled.div`
    background-image: url(/assets/confirm/arrow.png);
    width: 107px;
    height: 112px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 255px;
    top: 0px;
    transform: rotateZ(264deg);
`;
