import { PageSection, Paragraph } from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSpeakerApiService } from 'services';
import { Lang, Speakers } from 'types';
import sectionConfig from './sectionConfig';
import {
    Column,
    ContentWrap,
    Disclaimer,
    SpeakerName,
    SpeakerPhoto,
    SpeakerPosition,
    SpeakerWrap,
    SliderStyler,
    Title,
} from './styled';
import { langMapper } from 'utils';
import Slider, { Settings } from 'react-slick';
import {
    DotsContainer,
    DotsList,
} from 'containers/pages/for-businesses/components/reviews/styled';

export const PromoSection: React.FC = (): JSX.Element => {
    const [fetchedSpeakers, setFetchedSpeakers] = useState<Speakers | null>(
        null
    );
    const speakerAPI = getSpeakerApiService();
    const { t, i18n } = useTranslation('pages/about');

    const CAROUSEL_SETTINGS: Settings = {
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        dots: true,
        infinite: false,
        appendDots: (dots) => (
            <DotsContainer styles={{ margin: '0' }}>
                <DotsList
                    styles={{
                        '.slick-active': {
                            backgroundColor: '#fff',
                        },
                    }}>
                    {dots}
                </DotsList>
            </DotsContainer>
        ),
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
        ],
    };

    useEffect(() => {
        speakerAPI
            .getSpeakers({
                page: 0,
                size: 300,
                domainType: langMapper[i18n.language],
                isShowAboutPage: true,
            })
            .then((response) => {
                if (response.items.length > 0)
                    setFetchedSpeakers(response.items);
            });
    }, []);

    return (
        <PageSection config={sectionConfig}>
            <ContentWrap>
                <Column width={'40%'}>
                    <Title>{t('promoTitle')}</Title>
                    <Paragraph>{t('promoP1Text')}</Paragraph>
                    <Paragraph>{t('promoP2Text')}</Paragraph>
                </Column>
                {fetchedSpeakers && (
                    <Column width={'60%'}>
                        <SliderStyler alignTop={fetchedSpeakers.length < 3}>
                            <Slider
                                {...CAROUSEL_SETTINGS}
                                swipe={fetchedSpeakers.length > 3}
                                dots={fetchedSpeakers.length > 3}>
                                {fetchedSpeakers.map((speaker) => (
                                    <SpeakerWrap key={speaker.id}>
                                        <SpeakerPhoto
                                            className={'isPhoto'}
                                            src={speaker.image?.fileLink ?? ''}
                                        />
                                        <SpeakerName>{`${speaker.firstName} ${speaker.lastName}`}</SpeakerName>
                                        <SpeakerPosition>
                                            {speaker.position}
                                        </SpeakerPosition>
                                    </SpeakerWrap>
                                ))}
                            </Slider>
                        </SliderStyler>
                    </Column>
                )}
                {langMapper[i18n.language] === Lang.Ru && (
                    <Disclaimer>{t('disclaimer')}</Disclaimer>
                )}
            </ContentWrap>
        </PageSection>
    );
};

export default PromoSection;
