import { colorWhite, mediaSm } from 'theme/theme-helper-selectors';
import styled from 'styled-components';

export const CWrap = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    padding: 20px 0;
    color: ${colorWhite};
`;

export const MessageWrap = styled.div`
    width: 80%;
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;

    @media (${mediaSm}) {
        width: 100%;
    }

    a {
        padding: 0 10px;
        font-weight: 500;
    }
`;

export const BtnWrap = styled.div`
    width: 20%;

    @media (${mediaSm}) {
        width: 100%;
    }
`;

export const ContentWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;

    @media (${mediaSm}) {
        flex-direction: column;
        gap: 12px;
    }
`;
