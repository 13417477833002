import { Button } from 'kl-b2c-ui-kit';
import { Link } from 'react-router-dom';
import styled, { css, CSSObject } from 'styled-components';
import {
    colorBlack,
    colorWhite,
    mediaMd,
    mediaSm,
} from 'theme/theme-helper-selectors';

export const Container = styled.div<{
    bgImagePath: string;
    bgStyles?: CSSObject;
    afterColor?: string;
}>`
    position: relative;
    color: ${colorWhite};
    background: ${({ bgImagePath }) => `url(${bgImagePath})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -50px;

    ${({ bgStyles }) => ({ ...bgStyles })};

    @media (${mediaMd}) {
        background-position: center;
        background-size: cover;
    }

    &:after {
        content: '';
        width: 100%;
        height: 135px;
        position: absolute;
        background: ${({ afterColor }) =>
            afterColor ? afterColor : colorBlack};
        bottom: 0;
        transform-origin: left bottom;
        transform: translateY(100%) skewY(-3deg);
        border-radius: 0 0 40px 40px;
        z-index: 5;

        @media (${mediaSm}) {
            height: 100px;
        }
    }
`;

export const Title = styled.h2`
    text-align: center;
    max-width: 1000px;
    font-weight: 400;
    font-size: 72px;
    line-height: 72px;
    margin: 0 auto 40px;

    @media (${mediaSm}) {
        font-size: 42px;
        line-height: 42px;
    }
`;

export const MainBlockContainer = styled(Link)<{
    imagePath: string;
    imageStyles: CSSObject;
}>`
    display: block;
    padding: 40px;
    border-radius: 20px;
    position: relative;
    color: ${colorWhite};
    margin: 0 0 20px 0;

    @media (${mediaSm}) {
        padding: 25px;
        background-size: cover;
    }

    &:hover {
        color: ${colorWhite};
    }

    background-image: ${({ imagePath }) => `url(${imagePath})`};
    background-size: cover;
    background-repeat: no-repeat;

    ${({ imageStyles }) => ({ ...imageStyles })};

    &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
        background: linear-gradient(
            270deg,
            rgba(9, 9, 9, 0) 45.71%,
            #090909 63.46%
        );
        border-radius: 20px;
    }
`;

export const MainBlockWrapper = styled.div<{ secondary?: boolean }>`
    width: ${({ secondary }) => (secondary ? '100%' : '50%')};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    position: relative;
    z-index: 10;
    height: 100%;

    @media (${mediaSm}) {
        gap: 15px;
        width: 100%;
    }
`;

export const MainBlockTitle = styled.h4`
    color: inherit;
    font-weight: 300;
    font-size: 42px;
    line-height: 42px;

    @media (${mediaSm}) {
        font-size: 28px;
        line-height: 28px;
    }
`;

export const MainBlockSubtitle = styled.p`
    font-weight: 300;
    line-height: 21px;
    font-size: 18px;

    @media (${mediaSm}) {
        font-size: 15px;
        line-height: 19px;
    }
`;

export const MainBlockButton = styled(Button)<{ secondary?: boolean }>`
    font-size: 16px;
    font-weight: 600;
    padding: 14px 24px;
    border-radius: 8px;
    letter-spacing: 0;
    border-width: 2px;

    @media (${mediaSm}) {
        font-size: 13px;
        padding: 10px 17px;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            color: ${colorBlack};
            border-color: ${colorBlack};
            margin-top: auto;
        `};
`;

export const SecondaryBlocksContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 0 0 20px 0;

    @media (${mediaSm}) {
        grid-template-columns: 1fr;
    }
`;

export const SecondaryBlockContainer = styled(Link)`
    padding: 40px;
    border-radius: 20px;
    background: #f4f7fa;
    color: ${colorBlack};
    position: relative;
    z-index: 5;

    @media (${mediaSm}) {
        padding: 25px;
    }

    &:hover {
        color: ${colorBlack};
    }
`;
