import styled from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Content = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 1240px;
    padding: 0 34px;
    @media (${mediaSm}) {
        padding: 0 20px;
    }
    @media (${mediaXs}) {
        padding: 0 15px;
    }
`;

export const ContentBody = styled.div`
    margin-bottom: 180px;
    @media (${mediaSm}) {
        margin-bottom: 80px;
    }
    @media (${mediaXs}) {
        margin-bottom: 40px;
    }
`;

export const Title = styled.h2`
    font-size: 51px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 50px;
    text-align: center;
    color: ${({ theme }) => theme.palette.white};
    @media (${mediaXs}) {
        font-size: 32px;
        margin-bottom: 20px;
    }
`;

export const MembersDescription = styled.p`
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 118px;
    @media (max-width: 1600px) {
        margin-bottom: 80px;
    }
    @media (${mediaXs}) {
        font-size: 24px;
        margin-bottom: 40px;
        text-align: center;
    }
`;

export const List = styled.ul`
    grid-row-gap: 40px;
    display: grid;
    list-style: none;
    margin: 0 0 94px;
    padding: 0;
`;

const ItemCard = styled.li`
    border: 10px solid;
    border-radius: 40px;
    @media (${mediaXs}) {
        border: 4px solid;
        border-radius: 20px;
    }
`;

export const BlueCard = styled(ItemCard)`
    border-color: rgb(51, 194, 255);
`;

export const PurpleCard = styled(ItemCard)`
    border-color: rgb(168, 125, 255);
`;

export const CardTitle = styled.h3`
    cursor: pointer;
    font-size: 51px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding: 109px 58px;
    transition: all 0.5s ease;
    color: ${({ theme }) => theme.palette.white};
    &:hover {
        color: ${({ theme }) => theme.palette.teal};
    }
    @media (max-width: 1600px) {
        padding: 80px 40px;
    }
    @media (${mediaXs}) {
        border-radius: 20px;
        font-size: 32px;
        padding: 30px 16px;
    }
`;

export const CardBody = styled.div<{ isActive?: boolean }>`
    padding: 0 58px 109px;
    overflow: hidden;
    transition: all 0.5s ease;
    @media (max-width: 1600px) {
        padding: 0 40px 80px;
    }
    @media (${mediaXs}) {
        padding: 0 16px 30px;
    }
    ${({ isActive = false }) =>
        isActive
            ? 'max-height: 200vh'
            : 'max-height: 0vh; padding: 0px 40px!important;'}
`;

export const CardContent = styled.p`
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    @media (max-width: 1600px) {
        font-size: 32px;
    }
    @media (${mediaXs}) {
        font-size: 16px;
    }
`;

export const LearnMore = styled.a`
    border: 2px solid ${({ theme }) => theme.palette.white};
    border-radius: 10px;
    display: inline-flex;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    margin-top: 109px;
    padding: 28px;
    text-align: center;
    color: ${({ theme }) => theme.palette.white};
    transition: all 0.5s ease;
    @media (max-width: 1600px) {
        margin-top: 80px;
    }
    @media (${mediaXs}) {
        border-width: 1px;
        font-size: 14px;
        margin-top: 40px;
        padding: 12px;
    }
`;
