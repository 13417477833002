import React, { useEffect, useState } from 'react';
import { HeaderNav, HeaderHamburger, SubMenu } from './components';
import {
    LogoWrapper,
    HeaderRoot,
    HeaderWrapper,
    WrapperContainer,
} from './styled';
import { MenuElement } from 'types';
import { useMobileMenuContext, useStickyHeaderContext } from 'contexts';

export interface Props {
    links: MenuElement[];
}

export const Header: React.FC<Props> = (props) => {
    const [isHeaderHide, setIsHeaderHide] = useState(false);
    const [isHeaderDirty, setIsHeaderDirty] = useState(false);
    const [isVisibleSubMenu, setIsVisibleSubMenu] = useState(false);
    const { disabledStickyHeader } = useStickyHeaderContext();
    const {
        isMobileMenuOpened,
        toggleMobileMenu,
        isCorporatePage,
        isProfessionPage,
    } = useMobileMenuContext();

    useEffect(() => {
        let prevScroll = 0;

        const changeHeaderState = () => {
            if (window.scrollY > 100 && prevScroll < window.scrollY) {
                setIsHeaderHide(true);
                setIsHeaderDirty(true);
            } else {
                setIsHeaderHide(false);
            }

            if (window.scrollY === 0) {
                setIsHeaderDirty(false);
            }
            prevScroll = window.scrollY;
        };

        document.addEventListener('scroll', changeHeaderState, {
            passive: true,
        });
        return () => {
            document.removeEventListener('scroll', changeHeaderState);
        };
    }, []);

    return (
        <HeaderRoot
            isHeaderHide={isHeaderHide}
            disabledStickyHeader={disabledStickyHeader}
            isCorporate={isCorporatePage}
            isProfession={isProfessionPage}
            isHeaderDirty={isHeaderDirty}>
            <HeaderWrapper>
                <WrapperContainer>
                    <LogoWrapper
                        onClick={() => window.scrollTo(0, 0)}
                        to={'/'}
                    />
                </WrapperContainer>
                <HeaderHamburger
                    isMobile
                    isPressed={isMobileMenuOpened}
                    handleOnClick={() => {
                        toggleMobileMenu(!isMobileMenuOpened);
                        setIsVisibleSubMenu(false);
                    }}
                />
                <HeaderNav
                    {...props}
                    isPressed={isVisibleSubMenu}
                    isMobileMenuOpened={isMobileMenuOpened}
                    handleOnClick={() => setIsVisibleSubMenu(!isVisibleSubMenu)}
                    closeMenu={() => setIsVisibleSubMenu(false)}
                />
            </HeaderWrapper>
            <SubMenu
                isVisible={isVisibleSubMenu}
                isCorporatePage={isCorporatePage}
                onItemClick={() => setIsVisibleSubMenu(false)}
            />
        </HeaderRoot>
    );
};

export default Header;
