import styled, { CSSObject } from 'styled-components';
import { colorBlack, colorWhite, mediaSm } from 'theme/theme-helper-selectors';

export const NewsContainer = styled.div<{ styles?: CSSObject }>`
    background: ${colorBlack};
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 190px;
        position: absolute;
        background: ${colorBlack};
        bottom: 0;
        transform-origin: left bottom;
        transform: translateY(30%) skewY(3deg);
        border-radius: 0 0 40px 40px;
        z-index: 2;

        @media (${mediaSm}) {
            height: 100px;
        }
    }

    ${({ styles }) => ({ ...styles })};
`;

export const NewsTitle = styled.h1`
    font-size: 72px;
    font-weight: 400;
    line-height: 72px;
    margin: 50px 0 45px;
    color: ${colorWhite};

    @media (${mediaSm}) {
        font-size: 52px !important;
        line-height: 52px !important;
        margin: 0 0 45px !important;
    }
`;
