import styled from 'styled-components';
import {
    headerHeight,
    mediaSm,
    mediaXs,
    transitionBase,
} from 'theme/theme-helper-selectors';

export const Nav = styled.nav`
    transition: ${transitionBase};

    @media (${mediaSm}) {
        width: 330px;
        left: 0;
        bottom: 0;
        position: absolute;
        top: ${headerHeight};
        z-index: 100;
        padding: 0;
    }

    @media (${mediaXs}) {
        width: 100%;
    }
`;
