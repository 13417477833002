import {
    ArrowIcon,
    MenuExternalLink,
    MenuItem,
    MenuLink,
    MenuList,
} from './styled';
import React from 'react';
import { MenuElement } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
    items: MenuElement[];
    isFooter?: boolean;
    onItemClick?: () => void;
}

const MenuBlock: React.FC<Props> = ({
    items,
    isFooter = false,
    onItemClick,
}) => {
    const { t } = useTranslation('common/nav');

    return (
        <MenuList isFooter={isFooter}>
            {items.map(
                ({ i18nKey, link, dataAtSelector, isExternal, withArrow }) => (
                    <MenuItem
                        key={link}
                        isFooter={isFooter}
                        onClick={onItemClick}>
                        {isExternal ? (
                            <MenuExternalLink
                                href={link}
                                data-at-selector={dataAtSelector}>
                                {t(i18nKey)}
                                {withArrow && <ArrowIcon>➜</ArrowIcon>}
                            </MenuExternalLink>
                        ) : (
                            <MenuLink
                                to={link}
                                data-at-selector={dataAtSelector}>
                                {t(i18nKey)}
                                {withArrow && <ArrowIcon>➜</ArrowIcon>}
                            </MenuLink>
                        )}
                    </MenuItem>
                )
            )}
        </MenuList>
    );
};

export default MenuBlock;
