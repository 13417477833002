import React, { useState } from 'react';
import { HeaderMenuDropdown } from '..';
import { useTranslation } from 'react-i18next';
import { ButtonNavAction } from '../header-link/styled';
import { languages } from 'consts';
import { ReactComponent as WebIcon } from 'assets/icons/web.svg';

export const LanguageMenu: React.FC = () => {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleChangeLanguage = async (lang: string) => {
        try {
            await i18n.changeLanguage(lang);
            setIsOpen(false);
        } catch (error) {
            console.error('Failed change language', { lang, error });
        }
    };

    return (
        <HeaderMenuDropdown
            level={0}
            title={languages[i18n.language as keyof typeof languages]}
            icon={<WebIcon fill={'black'} />}
            isOpen={isOpen}
            open={() => setIsOpen(true)}
            close={() => setIsOpen(false)}>
            {Object.entries(languages).map(([code, title]) => (
                <ButtonNavAction
                    type={'button'}
                    key={code}
                    onClick={() => handleChangeLanguage(code)}>
                    {code.toUpperCase()} - {title}
                </ButtonNavAction>
            ))}
        </HeaderMenuDropdown>
    );
};

export default LanguageMenu;
